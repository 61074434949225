import {TemplateDocumentsService as ITemplateDocumentsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {TemplateDocuments} from "../../store/interfaces";
import store from "../../store";
import {FileTypes} from "../../store/constants";
import {cloneDeep} from "lodash";
import {decodeText, encodeText} from "../../functions/textFunctions";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";


export class TemplateDocumentsService implements ITemplateDocumentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  excludedFields = ["website", "practiceName"]; //excluded fields

  async getTemplateDocuments(): Promise<TemplateDocuments> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TemplateDocuments`
    );
    return decodeText(response.data, this.excludedFields);
  }

  async getTemplateDocumentsWithOtherForms(): Promise<TemplateDocuments> {
    const response = await this.http.get(
        `${this.apiUrl}/api/TemplateDocuments/DetailWithOtherForms`
    );
    return decodeText(response.data, this.excludedFields);
  }

  async updateTemplateDocumentOptions(
    clientId: string,
    body: TemplateDocuments
  ): Promise<void> {
    const data = cloneDeep(body) as any;
    await this.http.put(`${this.apiUrl}/api/TemplateDocuments`, encodeText(data, this.excludedFields), {
      params: { id: clientId },
    });
  }

  async loadDocumentPreview(): Promise<void> {
    await this.http
      .post(
        `${this.apiUrl}/api/TemplateDocuments/postPreview`,
        {},
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: FileTypes.PDF });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        }
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
        //TODO ask since popups are blocked on first request (not sure the best way to handle it)
      })
      .catch((error) => {
        ErrorNotification(store.dispatch, error, error.data);
      });
  }
}
