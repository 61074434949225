export default {
  // apps quickmenu
  apps: [
    {
      icon: "$users",
      text: "Patients",
      key: "menu.patients",
      subtitle: "Main",
      link: "/patients/list",
    },
    {
      icon: "$users-cog",
      text: "Users",
      key: "menu.users",
      subtitle: "modify access",
      link: "/users/list",
    },
    {
      icon: "$cogs",
      text: "Configuration",
      key: "menu.configuration",
      subtitle: "update forms, pricing, settings, etc.",
      link: "/configuration/treatment/types",
    },
    {
      icon: "$shapes",
      key: "menu.subscription",
      subtitle: "manage subscriptions and billing information",
      text: "Subscriptions",
      link: "/subscriptions",
    },
    {
      icon: "$handHoldingMedical",
      text: "Insurance Verification",
      key: "menu.insuranceVerification",
      subtitle: "remote insurance verification, list, etc.",
      link: "/insurance-verification",
    },
    {
      icon: "$handHoldingMedical",
      text: "Insurance Verification",
      key: "menu.insuranceVerification",
      subtitle: "remote insurance verification, list, etc.",
      link: "/insurance-verification",
    },
    {
      icon: "$admin",
      text: "Admin",
      key: "menu.admin",
      subtitle: "administrate the system",
      link: "/admin",
    },
  ],

  // user dropdown menu
  user: [

    {
      icon: "$user-cog",
      key: "menu.profile",
      text: "Profile",
      link: "/users/profile",
    },
],
};
