import {ProductFee} from "../../store/calculations/interfaces";
import {ProductFeesService as IProductFeeService} from "../interfaces";
import {AxiosInstance} from "axios";

export class ProductFeesService implements IProductFeeService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getProductFeesLite(): Promise<ProductFee[]> {
    const response = await this.http.get(`${this.apiUrl}/api/ProductFees/lite`);
    return response.data;
  }

  async enableUpfrontChargesForList(values: string[]): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/ProductFees/EnableUpfrontChargesForList`,
      [...values]
    );
    return response.status;
  }

  async disableUpfrontChargesForList(values: string[]): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/ProductFees/DisableUpfrontChargesForList`,
      [...values]
    );
    return response.status;
  }
}
