import {DoctorService as IDoctorService} from "../interfaces";
import {Doctor} from "../../store/practice/interfaces";
import {AxiosInstance} from "axios";

export class DoctorService implements IDoctorService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getDoctors(): Promise<Doctor[]> {
    const response = await this.http.get(`${this.apiUrl}/api/Doctors`);
    return response.data;
  }

  async updateDoctor(id: string, doctor: Doctor): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/Doctors`,
      { ...doctor },
      {
        params: { id },
      }
    );
    return response.status;
  }

  async createDoctor(doctor: Doctor): Promise<Doctor> {
    const response = await this.http.post(`${this.apiUrl}/api/Doctors`, {
      ...doctor,
    });
    return response.data;
  }

  async deleteDoctor(id: string, doctor: Doctor): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/Doctors`,
      { ...doctor },
      {
        params: { id },
      }
    );
    return response.status;
  }
}
