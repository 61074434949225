/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState, Select} from "../../index";
import Vue from "vue";
import {ResponsiblePartyTiersService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {ResponsiblePartyTier} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface ResponsiblePartyTiersState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    responsiblePartyTiers?: ResponsiblePartyTier[];
}

export enum ResponsiblePartyTiersActions {
    LOAD_RESPONSIBLE_PARTY_TIERS = "ResponsiblePartyTiersModule/loadResponsiblePartyTiers",
    UPDATE_RESPONSIBLE_PARTY_TIER = "ResponsiblePartyTiersModule/updateResponsiblePartyTier",
    DELETE_RESPONSIBLE_PARTY_TIER = "ResponsiblePartyTiersModule/deleteResponsiblePartyTier",
    CREATE_RESPONSIBLE_PARTY_TIER = "ResponsiblePartyTiersModule/createResponsiblePartyTier",
}

export enum ResponsiblePartyTiersMutations {
    SET_LOADING = "ResponsiblePartyTiersModule/setLoading",
    SET_RESPONSIBLE_PARTY_TIERS = "ResponsiblePartyTiersModule/setResponsiblePartyTiers",
    RESET = "ResponsiblePartyTiersModule/reset",
}

export enum ResponsiblePartyTiersGetters {
    LOADING = "ResponsiblePartyTiersModule/loading",
    SAVING = "ResponsiblePartyTiersModule/saving",
    DELETING = "ResponsiblePartyTiersModule/deleting",
    RESPONSIBLE_PARTY_TIERS = "ResponsiblePartyTiersModule/responsiblePartyTiers",
    RESPONSIBLE_PARTY_TIERS_SELECT = "ResponsiblePartyTiersModule/responsiblePartyTiersSelect",
    RESPONSIBLE_PARTY_TIERS_SELECT_INCLUDING_OBSOLETE = "ResponsiblePartyTiersModule/responsiblePartyTiersSelectIncludingObsolete",
    HAS_RESPONSIBLE_PARTY_TIERS = "ResponsiblePartyTiersModule/hasResponsiblePartyTiers",
    RESET = "ResponsiblePartyTiersModule/reset",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    responsiblePartyTiers: undefined,
});

export const ResponsiblePartyTiersModule: Module<ResponsiblePartyTiersState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadResponsiblePartyTiers(
            {commit, dispatch},
            payload: {
                service: ResponsiblePartyTiersService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const responsiblePartyTiers: ResponsiblePartyTier[] = await service.getPartyTiers(true);
                commit("setResponsiblePartyTiers", responsiblePartyTiers);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async updateResponsiblePartyTier(
            {commit, dispatch, getters},
            payload: {
                id: string;
                values: ResponsiblePartyTier;
                service: ResponsiblePartyTiersService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id, values} = payload;
                const response = await service.updatePartyTier(id, values);
                if (response === 204) {
                    const tiers = cloneDeep(getters.responsiblePartyTiers);
                    tiers.forEach((item: ResponsiblePartyTier, index: number) => {
                        if (item.patientResponsiblePartyTierTypeId === id) {
                            tiers[index] = values;
                        }
                    });
                    commit("setResponsiblePartyTiers", tiers);

                    success = true;
                }
                await SuccessNotification(dispatch, `Updated Responsible Party Tier!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async deleteResponsiblePartyTier(
            {commit, dispatch, getters},
            payload: {
                id: string;
                service: ResponsiblePartyTiersService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, id} = payload;
                const deleted = await service.deletePartyTier(id);
                if (deleted.patientResponsiblePartyTierTypeId === id) {
                    const tiers = cloneDeep(getters.responsiblePartyTiers).filter(
                        (tier: ResponsiblePartyTier) =>
                            tier.patientResponsiblePartyTierTypeId !== id
                    );
                    commit("setResponsiblePartyTiers", tiers);
                    success = await SuccessNotification(dispatch, `Deleted Responsible Party Tier`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async createResponsiblePartyTier(
            {commit, dispatch, getters},
            payload: {
                values: ResponsiblePartyTier;
                service: ResponsiblePartyTiersService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;
                const newPartyTier = await service.createPartyTier(values);
                const tiers = cloneDeep(getters.responsiblePartyTiers);
                tiers.push(newPartyTier);
                commit("setResponsiblePartyTiers", tiers);
                success = await SuccessNotification(dispatch, `Created Responsible Party Tier!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: ResponsiblePartyTiersState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setResponsiblePartyTiers(
            state: ResponsiblePartyTiersState,
            responsiblePartyTiers?: ResponsiblePartyTier[]
        ) {
            responsiblePartyTiers = responsiblePartyTiers?.sort(
                (a: ResponsiblePartyTier, b: ResponsiblePartyTier) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            Vue.set(state, "responsiblePartyTiers", responsiblePartyTiers);
        },
        reset: function (state: ResponsiblePartyTiersState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('ResponsiblePartyTiersState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        responsiblePartyTiers: (state) => state.responsiblePartyTiers,
        responsiblePartyTiersSelect: (state) => {
            //default None selection
            const responsiblePartyTiersSelect = [{text: "None", value: null, disabled: false}] as Select[];
            state.responsiblePartyTiers?.map((item) => {
                    if (!item?.isObsolete) {
                        responsiblePartyTiersSelect.push({
                            text: item.name,
                            value: item.patientResponsiblePartyTierTypeId,
                            disabled: false
                        });
                    }
                }
            );
            return responsiblePartyTiersSelect;
        },
        responsiblePartyTiersSelectIncludingObsolete: (state) => {
            //default None selection
            const responsiblePartyTiersSelect = [{text: "None", value: null, disabled: false}] as Select[];
            state.responsiblePartyTiers?.map((item) => {
                    responsiblePartyTiersSelect.push({
                        text: item.name,
                        value: item.patientResponsiblePartyTierTypeId,
                        disabled: !!item?.isObsolete
                    });
                }
            );
            return responsiblePartyTiersSelect;
        },
        hasResponsiblePartyTiers: (state) => !!state.responsiblePartyTiers?.filter((tier: ResponsiblePartyTier) => !tier?.isObsolete)?.length,
    },
};
