/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {DiscountService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {Discount} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface DiscountsState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  discounts?: Discount[];
}

export enum DiscountsActions {
  LOAD_DISCOUNTS = "DiscountsModule/loadDiscounts",
  UPDATE_DISCOUNT = "DiscountsModule/updateDiscount",
  DELETE_DISCOUNT = "DiscountsModule/deleteDiscount",
  CREATE_DISCOUNT = "DiscountsModule/createDiscount",
  RESET = "DiscountsModule/reset",
}

export enum DiscountsMutations {
  SET_LOADING = "DiscountsModule/setLoading",
  SET_DISCOUNTS = "DiscountsModule/setDiscounts",
  RESET = "DiscountsModule/reset",
}

export enum DiscountsGetters {
  LOADING = "DiscountsModule/loading",
  SAVING = "DiscountsModule/saving",
  DELETING = "DiscountsModule/deleting",
  DISCOUNTS = "DiscountsModule/discounts",
  HAS_DISCOUNTS = "DiscountsModule/hasDiscounts",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  discounts: undefined,
});

export const DiscountsModule: Module<DiscountsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadDiscounts(
      { commit, dispatch },
      payload: {
        service: DiscountService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const discounts: Discount[] = await service.getDiscounts();
        commit("setDiscounts", discounts);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateDiscount(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: Discount;
        service: DiscountService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;
        const response = await service.updateDiscount(id, values);

        if (response.discountTypeId) {
          const discounts = cloneDeep(getters.discounts);
          discounts.forEach((item: Discount, index: number) => {
            if (item.discountTypeId === id) {
              discounts[index] = response;
            }
          });
          commit("setDiscounts", discounts);
          success = true;
        }
        await SuccessNotification(dispatch, `Updated discount!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteDiscount(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: DiscountService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteDiscount(id);
        if (deleted.discountTypeId === id) {
          const discounts = cloneDeep(getters.discounts).filter(
            (discount: Discount) => discount.discountTypeId !== id
          );
          commit("setDiscounts", discounts);
          success = await SuccessNotification(dispatch, `Deleted discount!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async createDiscount(
      { commit, dispatch, getters },
      payload: {
        values: Discount;
        service: DiscountService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newDiscount = await service.createDiscount(values);
        const discounts = cloneDeep(getters.discounts) || [];
        discounts.push(newDiscount);
        commit("setDiscounts", discounts);
        success = await SuccessNotification(dispatch, `Created discount!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: DiscountsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setDiscounts(state: DiscountsState, discounts?: Discount[]) {
      Vue.set(state, "discounts", discounts);
    },
    reset: function (state: DiscountsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('DiscountsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    discounts: (state) => state.discounts,
    hasDiscounts: (state) => !!state.discounts?.length,
  },
};
