/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {InputFields, RootState} from "../index";
import Vue from "vue";
import {InputFieldsService} from "../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../constants";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface InputFieldsState {
  loading: boolean;
  saving: boolean;
  inputFieldOptions?: InputFields;
}

export enum InputFieldsActions {
  LOAD_INPUT_FIELDS = "InputFieldsModule/loadInputFields",
  UPDATE_INPUT_FIELDS = "InputFieldsModule/updateInputFields",
  RESET = "InputFieldsModule/reset",
}

export enum InputFieldsMutations {
  SET_LOADING = "InputFieldsModule/setLoading",
  SET_INPUT_FIELDS = "InputFieldsModule/setInputFields",
  RESET = "InputFieldsModule/reset",
}

export enum InputFieldsGetters {
  LOADING = "InputFieldsModule/loading",
  SAVING = "InputFieldsModule/saving",
  INPUT_FIELDS = "InputFieldsModule/inputFields",
}

const initialState = () => ({
  loading: false, //related to caching why it isn't true
  saving: false,
  inputFieldOptions: undefined,
});

export const InputFieldsModule: Module<InputFieldsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadInputFields(
      { commit, dispatch },
      payload: {
        service: InputFieldsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const inputFieldOptions: InputFields = await service.getInputFields();
        commit("setInputFields", inputFieldOptions);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async updateInputFields(
      { commit, dispatch },
      payload: {
        clientId: string;
        inputFieldOptions: InputFields;
        service: InputFieldsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {loading: true, loadingType: LoadingType.SAVING});

        const { service, clientId, inputFieldOptions } = payload;
        await service.updateInputFields(clientId, inputFieldOptions);
        commit("setInputFields", inputFieldOptions);
        success = await SuccessNotification(dispatch, "Updated Input Fields");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },

  },
  mutations: {
    setLoading(state: InputFieldsState, payload: {loading: boolean, loadingType: string }) {
      if(typeof payload === "boolean" ){
        Vue.set(state, "loading", payload);
      }
      else {
        if(payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setInputFields(state: InputFieldsState, inputFieldOptions?: InputFields) {
      Vue.set(state, "inputFieldOptions", inputFieldOptions);
    },
    reset: function (state: InputFieldsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('InputFieldsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    inputFields: (state) => state.inputFieldOptions,
  },
};
