import {ClientSubscriptions} from "@shared/store/constants";

export default [
  {
    path: "/patients/list",
    alias: "/patients",
    name: "patients",
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "patients-list" */ "../pages/patients/ListPatientPage.vue"
      ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Patients List",
      rank: 1,
    },
  },
  {
    path: "/patients/create",
    name: "patients-create",
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName:  "patients-edit" */ "../pages/patients/CreatePatientPage.vue"
      ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Create Patient",
      rank: 1,
    },
  },
  {
    path: "/patients/:id/responsible-parties/:responsiblePartyId",
    name: "patients-edit-responsible-party",
    component: () =>
        import(
            /* webpackChunkName: "patients-edit" */ "../pages/patients/CreatePatientPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Edit Patient - Responsible Party",
      rank: 1,
    },
  },
  {
    path: "/patients/:id/responsible-parties/:responsiblePartyId/insurances/:patientInsuranceId",
    name: "patients-edit-responsible-party-insurance",
    component: () =>
        import(
            /* webpackChunkName: "patients-edit" */ "../pages/patients/CreatePatientPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Edit Patient - Responsible Party - Insurance",
      rank: 1,
    },
  },
  {
    path: "/patients/:id",
    name: "patients-edit",
    component: () =>
      import(
        /* webpackChunkName: "patients-edit" */ "../pages/patients/CreatePatientPage.vue"
      ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Edit Patient",
      rank: 1,
    },
  },
  {
    path: "/patients/:id/history",
    name: "patients-history",
    component: () =>
        import(
            /* webpackChunkName: "patients-history" */ "../pages/patients/history/HistoryPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Patient History",
      rank: 2,
    },
  },
  {
    path: "/patients/additional-forms/create",
    name: "patients-additional-forms-create",
    component: () =>
        import(
            /* webpackChunkName: "patients-additional-forms" */ "../pages/patients/IntakeAndAnytimeFormPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Additional Forms (Intake / Anytime)",
      rank: 1,
      subscription: ClientSubscriptions.INTAKE_AND_ANYTIME_FORMS,
    },
  },
  {
    path: "/patients/:id/additional-forms/create",
    name: "patients-additional-forms",
    component: () =>
        import(
            /* webpackChunkName: "patients-additional-forms" */ "../pages/patients/IntakeAndAnytimeFormPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Additional Forms (Intake / Anytime)",
      rank: -1,
      subscription: ClientSubscriptions.INTAKE_AND_ANYTIME_FORMS,
    },
  },

  {
    path: "/patients/:id/treatment-plans/create",
    alias: ["/patients/:id/treatmentplans/create","/patients/:id/treatment-plans/"],
    name: "patients-treatment-plans-create",
    component: () =>
        import(
            /* webpackChunkName: "patients-treatment-plan" */ "../pages/patients/treatment-plans/TreatmentPlanPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Create Treatment Plan",
      rank: 3,
      subscription: ClientSubscriptions.FEE_PRESENTATIONS,
      cache: false,
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId",
    alias: ["/patients/:id/treatmentplans/:treatmentPlanId"],
    name: "patients-treatment-plan-update",
    component: () =>
        import(
            /* webpackChunkName: "patients-treatment-plan-update" */ "../pages/patients/treatment-plans/TreatmentPlanPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Update Treatment Plan",
      rank: 2,
      cache: false,
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId/payment-options/",
    alias: ["/patients/:id/treatmentplans/:treatmentPlanId/paymentoptions", "/patients/:id/treatment-plans/:treatmentPlanId/payment-options/0", "/patients/:id/treatmentplans/:treatmentPlanId/paymentoptions/*"],
    name: "patients-tx-plan-payment-options-basic",
    props: { refresh: false },
    component: () =>
        import(
            /* webpackChunkName: "patients-tx-plan-payment-options" */ "../pages/patients/payment-options/PaymentOptionsPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Payment Options",
      rank: 3,
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId/payment-options/responsible-parties/:responsiblePartyId/treatment-types/:treatmentTypeId",
    name: "patients-tx-plan-payment-options",
    props: { refresh: false },
    component: () =>
        import(
            /* webpackChunkName: "patients-tx-plan-payment-options" */ "../pages/patients/payment-options/PaymentOptionsPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Payment Options",
      rank: 3,
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId/payment-options/responsible-parties/:responsiblePartyId/sliders",
    name: "patients-tx-plan-sliders",
    props: { refresh: false },
    component: () =>
        import(
            /* webpackChunkName: "patients-tx-plan-sliders" */ "../pages/patients/payment-options/PaymentOptionsSlidersPage.vue"
            ),
    meta: {
      layout: "Simple",
      type: "Patients",
      title: "Payment Option Sliders",
      rank: 3,
      cache: false
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId/treatment-start-docs",
    alias: ["/patients/:id/treatment-plans/:treatmentPlanId/contracts",
            "/patients/:id/treatmentplans/:treatmentPlanId/contracts"],
    name: "patients-tx-plan-tx-start-docs-basic",
    component: () =>
        import(
            /* webpackChunkName: "patients-tx-plan-tx-start-docs" */ "../pages/patients/treatment-start-docs/TreatmentStartDocsPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Treatment Start Docs",
      rank: 3,
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId/treatment-start-docs/responsible-parties/:responsiblePartyId/treatment-types/:treatmentTypeId/payment-plans/:paymentPlanId",
    name: "patients-tx-plan-tx-start-docs",
    component: () =>
        import(
            /* webpackChunkName: "patients-tx-plan-tx-start-docs" */ "../pages/patients/treatment-start-docs/TreatmentStartDocsPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Treatment Start Docs",
      rank: 3,
    },
  },
  {
    path: "/patients/:id/treatment-plans/:treatmentPlanId/treatment-start-docs/:contractId",
    alias: ["/patients/:id/treatment-plans/:treatmentPlanId/signing-documents/:contractId",
            "/patients/:id/treatmentplans/:treatmentPlanId/signing-documents/:contractId",
            "/patients/:id/treatmentplans/:treatmentPlanId/signingdocuments/:contractId",
            "/patients/:id/treatment-plans/:treatmentPlanId/contracts:contractId",
            "/patients/:id/treatmentplans/:treatmentPlanId/contracts/:contractId"],
    name: "patients-tx-plan-tx-start-docs-single",
    component: () =>
        import(
            /* webpackChunkName: "patients-tx-plan-tx-start-docs" */ "../pages/patients/treatment-start-docs/TreatmentStartDocsPage.vue"
            ),
    meta: {
      layout: "Default",
      type: "Patients",
      title: "Treatment Start Docs",
      rank: 3,
    },
  },

];
