/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../index";
import Vue from "vue";
import {ResponsiblePartiesService} from "../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {ResponsiblePartyType} from "./interfaces";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface ResponsiblePartiesState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  types?: ResponsiblePartyType[];
}

export enum ResponsiblePartiesActions {
  LIST_PARTY_RELATIONSHIP_TYPES = "ResponsiblePartiesModule/listTypes",
  RESET = "ResponsiblePartiesModule/reset",
}

export enum ResponsiblePartiesMutations {
  SET_LOADING = "ResponsiblePartiesModule/setLoading",
  SET_PARTY_TYPES = "ResponsiblePartiesModule/setTypes",
  RESET = "ResponsiblePartiesModule/reset",
}

export enum ResponsiblePartiesGetters {
  LOADING = "ResponsiblePartiesModule/loading",
  SAVING = "ResponsiblePartiesModule/saving",
  DELETING = "ResponsiblePartiesModule/deleting",
  RELATIONSHIP_TYPES = "ResponsiblePartiesModule/types",
  RELATIONSHIP_TYPES_SELECT = "ResponsiblePartiesModule/typesSelect",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  types: undefined,
});

export const ResponsiblePartiesModule: Module<
  ResponsiblePartiesState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async listTypes(
      { commit, dispatch },
      payload: {
        service: ResponsiblePartiesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const types: ResponsiblePartyType[] = await service.getResponsiblePartyTypes();
        commit("setTypes", types);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: ResponsiblePartiesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setTypes(state: ResponsiblePartiesState, types?: ResponsiblePartyType[]) {
      Vue.set(state, "types", types);
    },
    reset: function (state: ResponsiblePartiesState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('ResponsiblePartiesState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    types: (state) => state.types,
    typesSelect: (state) =>
      state.types?.map((item) => {
        return {
          text: item.name,
          value: item.patientResponsiblePartyRelationshipId,
        };
      }),
  },
};
