import {PatientContractsService as IPatientContractsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {PatientContract} from "../../store/documents/patient-contracts/interfaces";
import {cloneDeep} from "lodash";
import {BROWSER_TYPES, LocalStorage} from "../../store/constants";
import {PatientSplitTreatmentPlanPortion, TreatmentPlan} from "../../store";

export class PatientContractsService implements IPatientContractsService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    //todo probably want these included to but caused issues with saving doc notes , "recurringPaymentDay", "insuranceDetails"
    fields = ["informedConsentNotes", "notes"];

    async getIncludeSliders(): Promise<boolean> {
        const value = await window.localStorage.getItem(LocalStorage.INCLUDE_SLIDERS);
        return !value ? true : value === '1';
    }

    async saveIncludeSliders(value: boolean): Promise<void> {
        await window.localStorage.setItem(LocalStorage.INCLUDE_SLIDERS, value ? '1' : '0');
    }

    async getPatientContractsList(id: string): Promise<PatientContract[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientContracts?patientTreatmentPlanId=${id}`
        );
        response.data.forEach((item: PatientContract, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });
        return response.data;
    }

    async getPatientContracts(id: string, refresh = false): Promise<PatientContract[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientContracts?patientTreatmentPlanId=${id}&refresh=${refresh}`
        );
        response.data.forEach((item: PatientContract, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });
        return response.data;
    }

    async updatePatientContractsNotesAndAdjustDates(id: string, patientContract: PatientContract, applyDoctorNotesToAllContracts: boolean, applyAdjustDatesToAllContracts: boolean, applyContractNotesToAllContracts: boolean): Promise<{lastModifiedDate: string}> {
        const data = cloneDeep(patientContract);
        for (const [key, value] of Object.entries(data)) {
            if (this.fields.includes(key)) {
                if (typeof value === "string") {
                    // @ts-ignore
                    data[key] = encodeURIComponent(value);
                }
            }
        }
        const response = await this.http.put(`${this.apiUrl}/api/PatientContracts/NotesAndAdjustDates`, data,
            {
                params: {id, applyDoctorNotesToAllContracts, applyAdjustDatesToAllContracts, applyContractNotesToAllContracts},
            });

        return response.data;
    }

    async updatePatientContract(id: string, patientContract: PatientContract, applyDatesToAllContracts: boolean = false, isEmailSignInProgress: boolean = false, isOfficeMode: boolean = true): Promise<void> {
        const data = cloneDeep(patientContract);
        for (const [key, value] of Object.entries(data)) {
            if (this.fields.includes(key)) {
                if (typeof value === "string") {
                    // @ts-ignore
                    data[key] = encodeURIComponent(value);
                }
            }
        }
        return await this.http.put(`${this.apiUrl}/api/PatientContracts`, data,
            {
                params: {id, applyDatesToAllContracts, isEmailSignInProgress, isOfficeMode},
            });
    }

    async splitPatientContracts(patientSplitTreatmentPlanPortions: PatientSplitTreatmentPlanPortion[], patientTreatmentPlan: TreatmentPlan): Promise<PatientContract[]> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientContracts/PostSplit`,
            {
                patientSplitTreatmentPlanPortions: patientSplitTreatmentPlanPortions,
                patientTreatmentPlan: patientTreatmentPlan
            },
        );

        response.data.forEach((item: PatientContract, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });
        return response.data;
    }

    async unsplitPatientContracts(id: string): Promise<PatientContract[]> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientContracts/PostMergeSplit`,
            null,
            {
                params: {
                    id: id,
                }
            }
        );

        response.data.forEach((item: PatientContract, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });
        return response.data;
    }

    async postPdf(
        contractId: string,
        showLetterhead: boolean = false,
        browserType: BROWSER_TYPES,
    ): Promise<string> {
        try {
            const response = await this.http.post(
                `${this.apiUrl}/api/PatientContracts/postPdf`,
                null,
                {params: {id: contractId, letterhead: showLetterhead}, responseType: "blob"}
            );
            const file = new Blob([response.data], {type: 'application/pdf'});
            if (browserType === BROWSER_TYPES.INTERNET_EXPLORER && window.navigator?.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return "";
            } else {
                const fileURL = window.URL.createObjectURL(file);
                window.open(fileURL, "_blank");
                return fileURL;
            }
        } catch (e) {
            window.close();
            throw e;
        }
    }
}
