import {InputFieldsService as IInputFieldsService} from "./interfaces";
import {AxiosInstance} from "axios";
import {InputFields} from '../store/interfaces';

export class InputFieldsService implements IInputFieldsService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getInputFields(): Promise<InputFields>{
        const response = await this.http.get(`${this.apiUrl}/api/InputFieldOptions`);
        return response.data
    }

    async updateInputFields(clientId: string, body: InputFields ): Promise<void>{
        await this.http.put(`${this.apiUrl}/api/InputFieldOptions`, body, {params: {id: clientId}});
    }

  
}