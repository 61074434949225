import {inject, provide} from "vue";
import VueRouter from "vue-router";

export const routerSymbol = Symbol();

export function provideRouter(router: VueRouter) {
  return provide(routerSymbol, router);
}

export function useRouter() {
  const router = inject<VueRouter>(routerSymbol);
  if (!router) {
    throw Error("No Router to Inject!!");
  }
  return router;
}
