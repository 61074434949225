import { IAppNotificationsService } from "./interfaces";
import { AxiosInstance } from "axios";
import { AppNotificationRecipient } from "@shared/store/financials/interfaces";

export class AppNotificationsService implements IAppNotificationsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  fields = ["message", "title", "shortDescription"];

  async getAppNotifications(): Promise<AppNotificationRecipient[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/AppNotifications
      `
    );

    response.data.forEach((item: AppNotificationRecipient, index: number) => {
      for (const [key, value] of Object.entries(item)) {
        if (key === "appNotification" && typeof value === "object") {
          // @ts-ignore
          for (const [key, value] of Object.entries(item["appNotification"])) {
            if (this.fields.includes(key) && typeof value === "string") {
              response.data[index]["appNotification"][key] = decodeURIComponent(value);
            }
          }
        }
      }
    });
    return response.data;
  }

  async updateAppNotifications(appNotificationRecipient: AppNotificationRecipient): Promise<void> {
    const response = await this.http.put(
      `${this.apiUrl}/api/AppNotifications
      `, appNotificationRecipient
    );
    return response.data;
  }

  async markAllAsRead(): Promise<void> {
    const response = await this.http.put(
      `${this.apiUrl}/api/AppNotifications/MarkAllAsRead
      `, null
    );
    return response.data;
  }
}