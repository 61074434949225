import {PpoRateGroupService} from "../services/interfaces";
import {inject, provide} from "vue";

export const ppoRateGroupServiceSymbol = Symbol();

export function providePpoRateGroupService(service: PpoRateGroupService) {
  return provide(ppoRateGroupServiceSymbol, service);
}

export function usePpoRateGroupService() {
  const ppoRateGroupService = inject<PpoRateGroupService>(ppoRateGroupServiceSymbol);
  if (!ppoRateGroupService) {
    throw Error("No PPO Rate Group Service to Inject!!");
  }
  return ppoRateGroupService;
}