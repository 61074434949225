<template>
  <v-text-field
      :dense="dense"
      class="pt-1"
      @input="changeDate($event, 'MM/DD/YYYY')"
      :value="value | formatDate"
      placeholder="MM/DD/YYYY"
      :persistent-hint="(value || '').length < 10 && ((dateFieldRef || {}).lazyValue || '').length < 10"
      :hint="(value || '').length < 10 && ((dateFieldRef || {}).lazyValue || '').length < 10  ? 'MM/DD/YYYY' : ''"
      :label=label
      date
      :outlined="outlined"
      :disabled="disable"
      ref="dateFieldRef"
      v-maska="DATE_MASK"
      :rules="dateRules"
      :suffix="suffix ? suffix : undefined"
      :validate-on-blur="validateOnBlur"
      @keyup.enter="shouldBlur"
  >
    <template v-slot:append>
      <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="slide-y-transition"
          min-width="290px"
          offset-y
          left
          :disabled="disable"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="disable" icon v-bind="attrs" v-on="on" class="pl-0, ml-0">
            <v-icon
                :color="!dateMenu && hasError && !(dateFieldRef && dateFieldRef.isFocused && dateFieldRef.hasInput) && (dateFieldRef && dateFieldRef.hasFocused) ? 'error' : ''">
              $calendar
            </v-icon>
          </v-btn>
        </template>
        <v-date-picker
            @change="changeDate($event)"
            @input="dateMenu = false"
            :value="value && moment.utc(value).isValid() ? moment.utc(value).format('YYYY-MM-DD') : undefined"
            :min="minDate ? minDate : '1900-01-01'"
            :max="customMaxDate"
            :activePicker.sync="picker"
            :disabled="disable"
        ></v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script lang="ts">
import { computed, defineComponent, ExtractPropTypes, onUpdated, PropType, ref, watch } from "vue";
import {DATE_MASK} from "@shared/store/constants";
import {maxDateRule, minDateRule, regexDateRule, requiredRule, validDateRule} from "@main/composables/validation";
import Vue from "vue";
import moment from "moment";
import {cloneDeep} from "lodash";

const propsDefinition = {
  value: {
    type: String as PropType<string>,
    required: false,
  },
  required: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  formValidated: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  activePicker: {
    type: String as PropType<string>,
    default: 'YEAR',
  },
  label: {
    type: String as PropType<string>,
    default: 'Birthday',
  },
  disable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  minDate: {
    type: String as PropType<string>,
    default: '1900-01-01',
  },
  maxDate: {
    type: String as PropType<string>,
    default: undefined,
  },
  suffix: {
    type: String as PropType<string>,
    default: undefined,
  },
  outlined: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  dense: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  validateOnBlur: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
};

type Props = ExtractPropTypes<typeof propsDefinition>;

export default defineComponent({
  name: "DateField",
  props: propsDefinition,
  setup(props: Props, {emit}) {
    const dateMenu = ref<boolean>(false);
    const dateFieldRef = ref<HTMLFormElement>();
    const picker = ref<string | undefined>('Date');
    const hasError = computed<boolean>(() => !!dateFieldRef?.value?.hasError);
    const customMaxDate = computed<string>(() =>
        props?.maxDate && moment.utc(props.maxDate, 'YYYY-MM-DD').isValid() && moment.utc(props.maxDate, 'YYYY-MM-DD')?.isBefore(moment().utc())
            ? moment.utc(props.maxDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
            : props?.label?.toLowerCase()?.includes('birth') ? moment.utc().format('YYYY-MM-DD') : '');
    const dateRules = computed<any[]>(() => {
      const rules = [validDateRule()];
      if (props?.required) {
        rules.push(requiredRule());
      }

      if ((props?.minDate)) {
        const minDate = moment.utc(props.minDate, 'YYYY-MM-DD');
        if (minDate.isValid()) {
          rules.push(minDateRule(minDate.format('YYYY-MM-DD')));
        }
      }

      if (customMaxDate.value) {
        rules.push(maxDateRule(customMaxDate.value));
      }
      rules.push(regexDateRule());
      return rules;
    });

    function changeDate(event: string | Date | undefined, format = 'YYYY-MM-DD') {
      if (typeof event !== 'undefined') {
        if (typeof event === 'string' && event?.trim()?.length === 0) emit("input", undefined);
        if (typeof event === 'string' && event.length < 10) return;
        const date = moment.utc(event, format);
        if (date.isValid()) {
          emit("input", date.format('YYYY-MM-DD'));
        } else {
          emit("input", undefined);
        }
      } else if (!event) {
        emit("input", undefined);
      } else {
        emit('update:hasError', hasError.value);
      }

    }
    function shouldBlur() {
      if(dateFieldRef?.value?.isFocused) { // || props?.forceBlur
        dateFieldRef?.value?.blur();
        dateFieldRef?.value?.validate();
      }
    }

    watch(dateMenu, async (newVal) => {
      if (newVal) {
        dateFieldRef?.value?.resetValidation();
        await Vue.nextTick();
        setTimeout(() => (picker.value = cloneDeep(props.activePicker))
        );
      }

    }, {immediate: true});

    onUpdated(() => {
      // the DOM element will be assigned to the ref after initial render
      Vue.nextTick(() => {
        emit('update:hasError', !!(hasError.value && dateFieldRef?.value?.hasInput));
      });

    });

    watch(() => props.formValidated, async () => {
      if (props.formValidated) {
        Vue.nextTick(() => {
          emit('update:hasError', hasError.value);
        });
      }
    });

    // watch(() => props.forceBlur, async () => {
    //   if (props.forceBlur) {
    //     shouldBlur();
    //     emit('update:forceBlur', false);
    //   }
    // });

    return {
      dateMenu,
      changeDate,
      DATE_MASK,
      picker,
      dateFieldRef,
      dateRules,
      moment,
      hasError,
      customMaxDate,
      shouldBlur
    };
  },
});
</script>
