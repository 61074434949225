/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {AdditionalCharge, RootState} from "../../index";
import Vue from "vue";
import {AdditionalChargesService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface AdditionalChargesState {
  loading: boolean;
  saving: boolean;
  additionalCharges?: AdditionalCharge[];
}

export enum AdditionalChargesActions {
  LOAD_ADDITIONAL_CHARGES = "AdditionalChargesModule/loadAdditionalCharges",
  RESET = "AdditionalChargesModule/reset",
}

export enum AdditionalChargesMutations {
  SET_LOADING = "AdditionalChargesModule/setLoading",
  SET_ADDITIONAL_CHARGES = "AdditionalChargesModule/setAdditionalCharges",
  RESET = "AdditionalChargesModule/reset",
}

export enum AdditionalChargesGetters {
  LOADING = "AdditionalChargesModule/loading",
  SAVING = "AdditionalChargesModule/saving",
  ADDITIONAL_CHARGES = "AdditionalChargesModule/additionalCharges",
  ADDITIONAL_CHARGES_SELECT = "AdditionalChargesModule/additionalChargesSelect",
}

const initialState = () => ({
  loading: false,
  saving: false,
  additionalCharges: undefined,
});

export const AdditionalChargesModule: Module<
  AdditionalChargesState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadAdditionalCharges(
      { commit, dispatch },
      payload: {
        service: AdditionalChargesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const additionalCharges: AdditionalCharge[] = await service.getAdditionalCharges();
        commit("setAdditionalCharges", additionalCharges);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: AdditionalChargesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setAdditionalCharges(
      state: AdditionalChargesState,
      additionalCharges?: AdditionalCharge[]
    ) {
      Vue.set(state, "additionalCharges", additionalCharges);
    },
    reset: function (state: AdditionalChargesState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('AdditionalChargesState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    additionalCharges: (state) => state.additionalCharges,
    additionalChargesSelect: (state) => {
    return state.additionalCharges?.map((item) =>  {return {text: item.productName, value: item.productFeeId} 
      });
    },
  },
};
