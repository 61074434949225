import {inject, provide} from "vue";
import {AnytimeDocumentsService} from '../services/document-services/AnytimeDocumentsService';

export const anytimeDocumentsServiceSymbol = Symbol();

export function provideAnytimeDocumentsService(service: AnytimeDocumentsService) {
  return provide(anytimeDocumentsServiceSymbol, service);
}

export function useAnytimeDocumentsService() {
  const anytimeDocumentsService = inject<AnytimeDocumentsService>(anytimeDocumentsServiceSymbol);
  if (!anytimeDocumentsService) {
    throw Error("No Anytime Documents Service to Inject!!");
  }
  return anytimeDocumentsService;
}