/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {PpoRateGroupService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {PpoRateGroup} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface PpoRateGroupsState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    ppoRateGroups?: PpoRateGroup[];
}

export enum PpoRateGroupsActions {
    LOAD_PPO_RATE_GROUPS = "PpoRateGroupsModule/loadPpoRateGroups",
    DELETE_PPO_RATE_GROUP = "PpoRateGroupsModule/deletePpoRateGroup",
    UPDATE_PPO_RATE_GROUP = "PpoRateGroupsModule/updatePpoRateGroup",
    CREATE_PPO_RATE_GROUP = "PpoRateGroupsModule/createPpoRateGroup",
    RESET = "PpoRateGroupsModule/reset",
}

export enum PpoRateGroupsMutations {
    SET_LOADING = "PpoRateGroupsModule/setLoading",
    SET_PPO_RATE_GROUPS = "PpoRateGroupsModule/setPpoRateGroups",
    RESET = "PpoRateGroupsModule/reset",

}

export enum PpoRateGroupsGetters {
    LOADING = "PpoRateGroupsModule/loading",
    SAVING = "PpoRateGroupsModule/saving",
    DELETING = "PpoRateGroupsModule/deleting",
    PPO_RATE_GROUPS = "PpoRateGroupsModule/ppoRateGroups",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    ppoRateGroups: undefined,
});

export const PpoRateGroupsModule: Module<PpoRateGroupsState, RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadPpoRateGroups(
            {commit, dispatch},
            payload: {
                service: PpoRateGroupService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const groups: PpoRateGroup[] = await service.getPpoRateGroups();
                commit("setPpoRateGroups", groups);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async deletePpoRateGroup(
            {commit, dispatch, getters},
            payload: {
                id: string;
                service: PpoRateGroupService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, id} = payload;
                const deleted = await service.deletePpoRateGroup(id);
                if (deleted.ppoRateGroupId === id) {
                    const rateGroups = cloneDeep(getters.ppoRateGroups).filter(
                        (rateGroup: PpoRateGroup) => rateGroup.ppoRateGroupId !== id
                    );
                    commit("setPpoRateGroups", rateGroups);
                    success = await SuccessNotification(dispatch, `Deleted rate group!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async createPpoRateGroup(
            { commit, dispatch, getters },
            payload: {
                values: PpoRateGroup;
                service: PpoRateGroupService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const { service, values } = payload;
                const newRateGroup = await service.createPpoRateGroup(values);
                const rateGroups = cloneDeep(getters.ppoRateGroups);
                rateGroups.push(newRateGroup);
                commit("setPpoRateGroups", rateGroups);
                success = await SuccessNotification(dispatch, `Created rate group!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
            return success;
        },
        async updatePpoRateGroup(
            {commit, dispatch, getters},
            payload: {
                id: string;
                values: PpoRateGroup;
                service: PpoRateGroupService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id, values} = payload;
                const response = await service.updatePpoRateGroup(id, values);
                if (response === 204) {
                    const rateGroups = cloneDeep(getters.ppoRateGroups);
                    rateGroups.forEach((item: PpoRateGroup, index: number) => {
                        if (item.ppoRateGroupId === id) {
                            rateGroups[index] = values;
                        }
                    });
                    commit("setPpoRateGroups", rateGroups);
                    success = true;
                }
                await SuccessNotification(dispatch, `Updated rate group!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        reset({ commit }) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: PpoRateGroupsState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setPpoRateGroups(state: PpoRateGroupsState, groups?: PpoRateGroup[]) {
            Vue.set(state, "ppoRateGroups", groups);
        },
        reset: function (state: PpoRateGroupsState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('PpoRateGroupsState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        ppoRateGroups: (state) => state.ppoRateGroups,
    },
};
