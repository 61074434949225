import moment from "moment";

export const defaultFromDate = moment(moment().subtract(30, 'days')).format("YYYY-MM-DD");

export function getDateRange(dateRange: number) {
    let fromDate = undefined;
    let toDate = undefined;
    switch (dateRange) {
        case 30:
            fromDate = defaultFromDate;
            toDate = undefined;
            break;
        case 60:
            fromDate = moment(moment().subtract(60, 'days')).format("YYYY-MM-DD");
            toDate = undefined;
            break;
        case 90:
            fromDate = moment(moment().subtract(90, 'days')).format("YYYY-MM-DD");
            toDate = undefined;
            break;
        case 7:
            fromDate = moment(moment().subtract(7, 'days')).format("YYYY-MM-DD");
            toDate = undefined;
            break;
        case 31:
            fromDate = moment(moment().subtract(1, 'months')).startOf('month').format("YYYY-MM-DD");
            toDate = moment().startOf('month').format("YYYY-MM-DD");
            break;
        case 0:
            fromDate = moment().startOf('month').format("YYYY-MM-DD");
            toDate = undefined;
            break;
        case -1:
            break;
        default:
            fromDate = defaultFromDate;
            toDate = undefined;
            break;
    }

    return {fromDate, toDate};
}


export function getFullDateTime(dateTime: string | Date | null | undefined, outputFormat = 'MM/DD/YY, h:mm a') {
    if (typeof dateTime !== 'undefined') {
        if (typeof dateTime === 'string' && dateTime.length < 10) {
            return "";
        }

        const momentDate = moment(dateTime);
        if (momentDate.isValid()) {
            return momentDate.format(outputFormat);
        }
    }
};

export function getDate(date: string | Date | null | undefined, inputFormat = 'YYYY-MM-DD', outputFormat = 'MM/DD/YYYY') {

    if (typeof date !== 'undefined') {
        if (typeof date === 'string' && date.length < 10) {
            return "";
        }
    }
    const momentDate = typeof date === 'string' && (date?.trim()?.length === 10 || date?.endsWith('T00:00:00')) ? moment.utc(date?.endsWith('T00:00:00') ? (date + '').replace('T00:00:00', '') : date) : moment(date, inputFormat);
    return momentDate.isValid() ? momentDate.format(outputFormat) : '';
};

export function displayTextForDate(fromDate: string = "", toDate: string = "", dateRange: number = -1) {
    let displayText: string = "";
    if (toDate) { //has to be previous month or custom range

        if (fromDate === moment(moment().subtract(1, 'months')).startOf('month').format("YYYY-MM-DD") &&
            toDate === moment().startOf('month').format("YYYY-MM-DD")) {
            displayText = "previous month";
        } else {
            displayText = `custom range: ${fromDate} - ${toDate}`;
        }
    } else {
        switch (fromDate) {
            case moment(moment().subtract(30, 'days')).format("YYYY-MM-DD"):
                displayText = "past 30 days thru current";
                break;
            case moment(moment().subtract(60, 'days')).format("YYYY-MM-DD"):
                displayText = "past 60 days thru current"
                break;
            case moment(moment().subtract(90, 'days')).format("YYYY-MM-DD"):
                displayText = "past 90 days thru current"
                break;
            case moment(moment().subtract(7, 'days')).format("YYYY-MM-DD"):
                displayText = "past 7 days thru current";
                break;
            default:
                displayText = "unknown";
                break;
        }
        if (dateRange === 0 && fromDate === moment().startOf('month').format("YYYY-MM-DD")) {
            displayText = "current month";
        }
    }
    return displayText;
}


export function compareLastModifiedDatesOnArray(array: any, compareDate: Date | string) {
    let result = false;
    if (!array || !Array.isArray(array) || !compareDate) {
        return result;
    }


    for (let i = 0; i < array.length; i++) {
        // eslint-disable-next-line no-prototype-builtins
        if (array[i]?.hasOwnProperty('lastModifiedDate')) {
            const compareDateUtc = moment.utc(compareDate)?.isValid() ? moment.utc(compareDate) : moment.utc();
            const arrayDate = moment.utc(array[i]?.lastModifiedDate)?.isValid() ? moment.utc(array[i].lastModifiedDate) : compareDateUtc;
            result = compareDateUtc?.isBefore(arrayDate);
            if (result) {
                break;
            }
        }
    }
    return result;
}

export function shouldCheckForMaintenanceFile() {
    // Get the current time in Central Standard Time (could be off due to daylight saving by an hour)
    const currentCentralTime = moment().utc().subtract(6, 'hours');

    // Get the current day of the week (0 is Sunday, 1 is Monday, etc.)
    const dayOfWeek = currentCentralTime.day();

    // Get the current hour in 24-hour format
    const currentHour = currentCentralTime.hour();

    // Check if the day is Thursday, Friday, or Saturday
    const isThursdayFridaySaturday = dayOfWeek === 4 || dayOfWeek === 5 || dayOfWeek === 6;

    // Check if the time is between 6 pm and 12 pm
    const isBetween6pmAnd12pm = currentHour >= 18 && currentHour < 24;

    // Check if both conditions are true
    return isThursdayFridaySaturday && isBetween6pmAnd12pm;
}

export function updateSessionExpiry() {
    return process.env.VUE_APP_IDLE_TIME_LIMIT_IN_SECS ? moment.utc().add(parseInt(process.env.VUE_APP_IDLE_TIME_LIMIT_IN_SECS), 'seconds').format() : moment.utc().add(900, 'seconds').format();
}