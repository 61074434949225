import {PpoRateGroupService as IPpoRateGroupService} from "../interfaces";
import {PpoRateGroup} from "../../store/insurance/interfaces";
import {AxiosInstance} from "axios";

export class PpoRateGroupService implements IPpoRateGroupService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPpoRateGroups(): Promise<PpoRateGroup[]> {
    const response = await this.http.get(`${this.apiUrl}/api/PpoRateGroups`);
    return response.data;
  }

  async deletePpoRateGroup(id: string): Promise<PpoRateGroup> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/PpoRateGroups`,
      {
        params: { id },
      }
    );
    return response.data;
  }

  async createPpoRateGroup(values: PpoRateGroup): Promise<PpoRateGroup>{
    const response = await this.http.post(`${this.apiUrl}/api/PpoRateGroups`, {...values});
    return response.data;
  }

  async updatePpoRateGroup(id: string, values: PpoRateGroup): Promise<number>{
    const response = await this.http.put(`${this.apiUrl}/api/PpoRateGroups`, {...values}, {
      params: {id}
    });
    return response.status;
  }
}
