import {ProductTypeService as IProductTypeService} from "../interfaces";
import {AxiosInstance} from "axios";
import {ProductType} from "../../store/financials/interfaces";

export class ProductTypeService implements IProductTypeService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getProductTypesDetail(): Promise<ProductType[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/ProductTypes/Detail`
    );
    return response.data;
  }

  async deleteProductType(id: string): Promise<ProductType> {
    const response = await this.http.delete(`${this.apiUrl}/api/ProductTypes`, {
      params: { id },
    });
    return response.data;
  }

  async updateProductType(id: string, values: ProductType): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/ProductTypes`,
      { ...values },
      { params: { id } }
    );
    return response.status;
  }

  async createProductType(values: ProductType): Promise<ProductType> {
    const response = await this.http.post(`${this.apiUrl}/api/ProductTypes`, {
      ...values,
    });
    return response.data;
  }
}
