import {InformedConsentService} from "../services/interfaces";
import {inject, provide} from "vue";

export const informedConsentServiceSymbol = Symbol();

export function provideInformedConsentService(service: InformedConsentService) {
  return provide(informedConsentServiceSymbol, service);
}

export function useInformedConsentService() {
  const informedConsentService = inject<InformedConsentService>(informedConsentServiceSymbol);
  if (!informedConsentService) {
    throw Error("No Informed Consent Service to Inject!!");
  }
  return informedConsentService;
}