/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {PatientVisit, RootState} from "../../index";
import Vue from "vue";

// DO NOT MOVE THIS OR ELSE
import {PatientVisitsService} from "../../../services/interfaces";
import {cloneDeep} from "lodash";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface PatientVisitsState {
    loading: boolean;
    patientVisit?: PatientVisit;
    officeInfoFooter?: string;
}

export enum PatientVisitsActions {
    LOAD_PATIENT_VISIT_WITH_OFFICE_DETAILS = "PatientVisitsModule/loadPatientVisitWithOfficeDetails",
}

export enum PatientVisitsMutations {
    SET_LOADING = "PatientVisitsModule/setLoading",
    SET_PATIENT_VISIT = "PatientVisitsModule/setPatientVisit",
    RESET = "PatientVisitsModule/reset",
}

export enum PatientVisitsGetters {
    LOADING = "PatientVisitsModule/loading",
    PATIENT_VISIT = "PatientVisitsModule/patientVisit",
    OFFICE_INFO_FOOTER = "PatientVisitsModule/officeInfoFooter",
}

const initialState = () => ({
    loading: false,
    patientVisit: undefined,
    officeInfoFooter: undefined,
});

function getOfficeInfoFooter(patientVisit?: PatientVisit) {
    const addressInfo = [];
    if (patientVisit?.office?.address) {
        addressInfo.push(patientVisit.office.address.streetAddressLine1);
        if ((patientVisit?.office?.address?.streetAddressLine2 || '').length > 0) {
            addressInfo.push(patientVisit.office.address.streetAddressLine2);
        }
        if ((patientVisit?.office?.address?.streetAddressLine3 || '').length > 0) {
            addressInfo.push(patientVisit.office.address.streetAddressLine3);
        }
        addressInfo.push(patientVisit.office.address.city);
        let lastPiece = patientVisit.office.address.state + ' ' + patientVisit.office.address.zipCode;
        if ((patientVisit.office.phoneNumber || '').length > 0) {
            lastPiece += ('  |  ' + patientVisit.office.phoneNumber);
        }
        if ((patientVisit.office.faxNumber || '').length > 0) {
            lastPiece += ('  |  ' + patientVisit.office.faxNumber + ' fax');
        }
        addressInfo.push(lastPiece);
    }
    return addressInfo?.length > 0 ? addressInfo.join(', ') : '';
};

export const PatientVisitsModule: Module<PatientVisitsState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadPatientVisitWithOfficeDetails(
            {commit, dispatch},
            payload: {
                service: PatientVisitsService;
                patientTreatmentTypeId: string;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", true);
                const {service, patientTreatmentTypeId} = payload;
                const patientVisit = await service.getPatientVisitWithOfficeDetails(
                    patientTreatmentTypeId
                );
                commit("setPatientVisit", patientVisit);
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
            return success;
        },
    },
    mutations: {
        setLoading(
            state: PatientVisitsState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setPatientVisit(
            state: PatientVisitsState,
            patientVisit?: PatientVisit
        )
        {
            Vue.set(state, "officeInfoFooter", getOfficeInfoFooter(cloneDeep(patientVisit)));
            Vue.set(state, "patientVisit", patientVisit);
        },
        reset: function (state: PatientVisitsState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('PatientVisitsState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        patientVisit: (state) => state.patientVisit,
        officeInfoFooter: (state) => state.officeInfoFooter,
    },
};
