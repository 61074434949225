import {inject, provide} from "vue";

export function createServiceInjector<T>(serviceName: string) {
    const symbol = Symbol(serviceName);
    return {
        provideService(service: T) {
            provide(symbol, service);
        },
        useService() {
            const service = inject<T>(symbol);
            if (!service) {
                throw Error(`No ${serviceName} to Inject!!`);
            }
            return service;
        },
    };
}

// Usage example:
//import { AdminClientSubscriptionService  as IAdminClientSubscriptionService} from "../services/interfaces";
//import {createServiceInjector} from "@shared/providers/createServiceInjector";
//import {AdminClientSubscriptionService } from "@shared/services/admin-services/AdminClientSubscriptionService";
//
// export const {
//     provideService: provideAdminClientSubscriptionService,
//     useService: useAdminClientSubscriptionService,
// } = createServiceInjector<IAdminClientSubscriptionService>('AdminClientSubscriptionService');

//    provideAdminClientSubscriptionService(new AdminClientSubscriptionService(axios));