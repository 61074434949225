import menuPages from "@portal/configs/menus/payment-options.menu";

export default {
    // main navigation - side menu
    menu: [menuPages],
    // footer links
    footer: [
        {
            text: "Contact Us",
            key: "Contact Us",
            link: "/contact-us",
            href: null,
        },
]
};
