import {PatientFormsEsignDocumentsService as IPatientFormsEsignDocumentsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {PatientFormsEsignDocument} from "../../store/interfaces";

export class PatientFormsEsignDocumentsService implements IPatientFormsEsignDocumentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPatientFormsEsignDocuments(patientId: string): Promise<PatientFormsEsignDocument[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/PatientFormsEsignDocuments/`,
        { params: { patientId: patientId } }
    );
    return response.data;
  }

  async getFormNextSigner(folderId: string | number): Promise<string> {
    const response = await this.http.get(
        `${this.apiUrl}/api/PatientFormsEsignDocuments/getFormNextSigner`,
        { params: { folderId: folderId } }
    );

    if(Array.isArray(response.data)) {
      return response.data.length ? response.data[0] : "";
    }
    return response.data;
  }

  async sendReminder(folderId: string | number, patientId: string): Promise<boolean> {
    const response = await this.http.post(
        `${this.apiUrl}/api/PatientFormsEsignDocuments/sendReminder`,
        { folderId: folderId, patientId: patientId }
    );
    return response.data;
  }

  async updateRecipient(folderId: string | number, patientId: string, email: string): Promise<boolean> {
    const request = {
      folderId: folderId,
      patientId: patientId,
      signer: {
        email: email
      }
    };

    const response = await this.http.post(
        `${this.apiUrl}/api/PatientFormsEsignDocuments/updateRecipient`,
            request
    );
    return response.data;
  }

  async deleteEsignature(folderId: string | number): Promise<boolean> {
    const response = await this.http.delete(
        `${this.apiUrl}/api/PatientFormsEsignDocuments/deleteEsignature`,
        { params: { folderId: folderId } }
    );
    return response.data;
  }

  async disableEsignatureReminders(folderId: string | number): Promise<boolean> {
    const response = await this.http.post(
        `${this.apiUrl}/api/PatientFormsEsignDocuments/${folderId}/DisableEsignatureReminders`,
        { params: { folderId: folderId } }
    );
    return response.data;
  }
}
