import {PatientTreatmentPlansService as IPatientTreatmentPlansService} from "../interfaces";
import {AxiosInstance} from "axios";
import {TreatmentPlan} from "../../store/interfaces";
import {
    PatientContractToEmail,
    PatientTreatmentPlanToEmail,
    ResponsibleParty
} from "../../store/responsible-parties/interfaces";
import {ESignerData} from "../../store/treatment-plans/interfaces";
import {cloneDeep} from "lodash";
import {PatientContract} from "../../store/documents/patient-contracts/interfaces";
import {APP_TYPES, BROWSER_TYPES} from "../../store/constants";

export class PatientTreatmentPlansService
    implements IPatientTreatmentPlansService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    fields = ["notes"];

    async saveTreatmentPlan(values: TreatmentPlan): Promise<TreatmentPlan> {

        const data = cloneDeep(values);
        for (const [key, value] of Object.entries(data)) {
            if (this.fields.includes(key)) {
                if (typeof value === "string") {
                    // @ts-ignore
                    data[key] = encodeURIComponent(value);
                }
            }
        }

        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans`,
            {
                patientInsurances: [], //not used anymore since this was just added for backwards compatibility
                patientPpoRate: data?.patientPpoRates?.length ? data.patientPpoRates[0] : null, //can only add 1 ppo rate
                patientTreatmentPlan: {...data, patientPpoRates: []}, //need to clear ppo rates here
            }
        );
        return response.data;
    }

    async getTreatmentPlans(id: string): Promise<TreatmentPlan[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientTreatmentPlans/List`,
            {params: {patientId: id}}
        );

        response.data.forEach((item: PatientContract, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });

        return response.data;
    }

    async getSecureData(folderId: string): Promise<string[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientTreatmentPlans/GetSignedPlanSecureFields`,
            {params: {folderId: folderId}}
        );
        return response.data;
    }

    async putSecureDataAccessRemoval(folderId: string): Promise<number> {
        const response = await this.http.put(
            `${this.apiUrl}/api/PatientTreatmentPlans/RemoveSecureFieldsAccess`,
            null,
            {params: {folderId: folderId}}
        );
        return response.status;
    }

    async getSelectedTreatmentPlan(id: string): Promise<TreatmentPlan> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientTreatmentPlans`,
            {params: {id}}
        );

        for (const [key, value] of Object.entries(response.data)) {
            if (this.fields.includes(key) && typeof value === "string") {
                response.data[key] = decodeURIComponent(value);
            }
        }

        return response.data;
    }

    async sendPaymentOptionsPdf( values: PatientTreatmentPlanToEmail): Promise<number> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans/SendPymtOptions`,
            values,
            {params: {patientTreatmentPlanId: values.patientTreatmentPlanId}}
        );
        return response.status;
    }

    // this will give patient access to adjust the treatment plan (aka 'single slider')
    async sendTreatmentPlanAdjustments(values: PatientTreatmentPlanToEmail): Promise<number> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans/SendPlanAdjustments`,
            values,
        );
        return response.status;
    }

    async sendPdf(patientContractId: string, values: PatientContractToEmail): Promise<number> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans/SendPdf`,
            values,
            {params: {patientContractId: patientContractId}}
        );
        return response.status;
    }

    async generatePaymentOptionsPdf(
        patientTreatmentPlanId: string,
        html: string,
        browserType: BROWSER_TYPES,
    ): Promise<string> {
        try {
            const response = await this.http.post(
                `${this.apiUrl}/api/PatientTreatmentPlans/GeneratePaymentOptions`,
                {patientTreatmentPlanId: patientTreatmentPlanId,
                    html: html },
                {
                    params: {isPortal: process.env.VUE_APP_TYPE === APP_TYPES.PORTAL ? 1 : 0},
                    responseType: "blob"
                }
            );
            const file = new Blob([response.data], {type: 'application/pdf'});
            if (browserType === BROWSER_TYPES.INTERNET_EXPLORER && window.navigator?.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return "";
            } else {
                const fileURL = window.URL.createObjectURL(file);
                window.open(fileURL, "_blank");
                return fileURL;
            }
        } catch (e) {
            window.close();
            throw e;
        }
    }

    async emailSigningSession(eSignerData: ESignerData): Promise<string> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans/SendEmailSigning`,
            eSignerData,
            {
                params: {
                    patientContractId: eSignerData.patientContractId,
                    patientTreatmentPlanId: eSignerData.patientTreatmentPlanId
                }
            }
        );

        if (Array.isArray(response.data)) {
            return response.data.length ? response.data[0] : "";
        }

        return response.data;
    }

    async embeddedSigningSession(eSignerData: ESignerData): Promise<string> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans/ContractSigningSession`,
            eSignerData,
            {
                params: {
                    patientContractId: eSignerData.patientContractId,
                    patientTreatmentPlanId: eSignerData.patientTreatmentPlanId,
                    version: 2
                }
            }
        );

        if (Array.isArray(response.data)) {
            return response.data.length ? response.data[0] : "";
        }

        return response.data;
    }

    async updateTreatmentPlan(
        id: string,
        values: TreatmentPlan
    ): Promise<{ patientTreatmentPlanId: string }> {

        const data = cloneDeep(values);
        for (const [key, value] of Object.entries(data)) {
            if (this.fields.includes(key)) {
                if (typeof value === "string") {
                    // @ts-ignore
                    data[key] = encodeURIComponent(value);
                }
            }
        }

        const response = await this.http.put(
            `${this.apiUrl}/api/PatientTreatmentPlans`,
            {
                patientInsurances: [], //not used anymore since this was just added for backwards compatibility
                patientPpoRate: data?.patientPpoRates?.length ? data.patientPpoRates[0] : null, //can only add 1 ppo rate
                patientTreatmentPlan: {...data, patientPpoRates: []}, //need to clear ppo rates here
            },
            {params: {id}}
        );

        return response.data;
    }

    async resetTreatmentPlanStatus(
        id: string
    ): Promise<number> {

        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlans/reset-status`, null,
            {params: {id}}
        );

        return response.status;
    }

    async updateLastAskedToRegenerate(id: string): Promise<string> {
        const response = await this.http.put(
            `${this.apiUrl}/api/PatientTreatmentPlans/PutLastAskedToRegenerateDateUpdate`, null,
            {params: {id}}
        );
        if (response?.data?.date) {
            return response.data.date;
        }
        return response.data;
    }

    async updateTreatmentPlanResponsibleParties(
        id: string,
        values: ResponsibleParty[]
    ): Promise<ResponsibleParty[]> {
        const response = await this.http.patch(
            `${this.apiUrl}/api/PatientTreatmentPlans/ResponsibleParties`,
            {
                patientResponsibleParties: values
            },
            {params: {id}}
        );
        return response.data;
    }


    async updateTreatmentPlanNotes(
        id: string,
        notes: string
    ): Promise<{ lastModifiedDate: string, lastAskedToRegenerateDate: string }> {
        const data = encodeURIComponent(notes);
        const response = await this.http.put(
            `${this.apiUrl}/api/PatientTreatmentPlans/Notes`,
            {
                patientTreatmentPlanId: id,
                notes: data
            },
            {params: {id}}
        );
        return response.data;
    }

    async deletePlan(id: string): Promise<TreatmentPlan> {
        const response = await this.http.delete(`${this.apiUrl}/api/PatientTreatmentPlans`, {params: {id}});
        return response.data;
    }

    async disableReminders(id: string): Promise<number> {
        const response = await this.http.post(`${this.apiUrl}/api/PatientTreatmentPlans/${id}/DisableReminders`,
            {params: {id}});
        return response.status;
    }
}
