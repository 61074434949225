import {SharedFormTypes} from "../../store/constants";
import {PatientIntakeDocumentsService as IPatientIntakeDocumentsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {IntakeForm, PacketBody, SharedDocumentsFormUpdate,} from "../../store/interfaces";
import {NewPatientPacket} from "../../store/responsible-parties/interfaces";
import {patientPacketToPacketBodyFormatter} from "@shared/functions/signingDocumentFunctions";

export class PatientIntakeDocumentsService
  implements IPatientIntakeDocumentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getIntakeForms(): Promise<IntakeForm[]> {
    const response = await this.http.get(`${this.apiUrl}/api/IntakeForms`);
    return response.data;
  }

  async getCustomIntakeForms(): Promise<IntakeForm[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/IntakeForms/Detail`
    );
    return response.data;
  }

  async createCustomForm(formId: string, body: IntakeForm) {
    const response = await this.http.post(
      `${this.apiUrl}/api/IntakeForms`,
      body,
      {
        params: { formId},
      }
    );
    return response.data[0];
  }

  async deleteForm(id: string): Promise<IntakeForm> {
    const response = await this.http.delete(`${this.apiUrl}/api/IntakeForms`, {
      params: { id },
    });

    return response.data;
  }

  async getSharedIntakeForms(): Promise<IntakeForm[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/SharedLibraryForms/Detail?formTypeId=${SharedFormTypes.INTAKE}`
    );
    return response.data;
  }

  async updateSharedIntakeForms(
    values: SharedDocumentsFormUpdate
  ): Promise<boolean> {
    const response = await this.http.put(
      `${this.apiUrl}/api/SharedLibraryForms`,
      values,
      {
        params: { ...values },
      }
    );
    return response.data;
  }

  async updateCustomIntakeForms(
    formId: string,
    values: IntakeForm
  ): Promise<IntakeForm> {
    const response = await this.http.put(
      `${this.apiUrl}/api/IntakeForms`,
      values,
      {
        params: { formId },
      }
    );
    return response.data;
  }

  async updateFormFields(
      formId: string
  ): Promise<boolean> {
    const response = await this.http.post(
        `${this.apiUrl}/api/IntakeForms/UpdateFormFields`,
        null,
        {
          params: { formId },
        }
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  async reorderCustomIntakeForms(body: IntakeForm[]): Promise<void> {
    await this.http.put(`${this.apiUrl}/api/IntakeForms/Sort`, body);
  }

  async requestEsignURL(formId: string): Promise<boolean> {
    const response = await this.http.post(
      `${this.apiUrl}/api/IntakeForms/UrlRequest`,
      { formId },
      {
        params: {
          formId,
        },
      }
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  async postSinglePdf(
    formId: string,
    isShared: boolean | number
  ): Promise<string> {
    if (typeof isShared === "boolean") {
      isShared = isShared === true ? 1 : 0;
    }
    //Create a Blob from the PDF Stream
    try {
      const response = await this.http.post(
          `${this.apiUrl}/api/IntakeForms/postSinglePdf`,
          null,
          { params: { formId, isShared }, responseType: "blob" }
      );
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], {type: "application/pdf"});
      return URL.createObjectURL(file);
    } catch (e) {
      console.error(e);
      const text = " Preview currently unavailable for this form. ";
      const mimeType = "text/plain";
      const file = new Blob([text], { type: mimeType });
      return URL.createObjectURL(file);
    }
  }

  async postPdf(values: NewPatientPacket): Promise<number> {
    const body: PacketBody = patientPacketToPacketBodyFormatter(values);

    const response = await this.http.post(
      `${this.apiUrl}/api/IntakeForms/postPdf`,
      body
    );

    return response.status;
  }
}
