export default[
      {
        key: "Subscriptions",
        icon: "$users",
        text: "Subscriptions",
        link: "/subscriptions",
        group: "/subscriptions",
      }
]


