/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {PatientTypeService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {Select} from "../../interfaces";
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {PatientType} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface PatientTypesState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  patientTypes?: PatientType[];
}

export enum PatientTypesActions {
  LOAD_PATIENT_TYPES = "PatientTypesModule/loadPatientTypes",
  DELETE_PATIENT_TYPE = "PatientTypesModule/deletePatientType",
  CREATE_PATIENT_TYPE = "PatientTypesModule/createPatientType",
  UPDATE_PATIENT_TYPE = "PatientTypesModule/updatePatientType",
  RESET = "PatientTypesModule/reset",
}

export enum PatientTypesMutations {
  SET_LOADING = "PatientTypesModule/setLoading",
  SET_PATIENT_TYPES = "PatientTypesModule/setPatientTypes",
  RESET = "PatientTypesModule/reset",
}

export enum PatientTypesGetters {
  LOADING = "PatientTypesModule/loading",
  SAVING = "PatientTypesModule/saving",
  DELETING = "PatientTypesModule/deleting",
  PATIENT_TYPES = "PatientTypesModule/patientTypes",
  HAS_PATIENT_TYPES = "PatientTypesModule/hasPatientTypes",
  PATIENT_TYPES_SELECT = "PatientTypesModule/patientTypesSelect",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  patientTypes: undefined,
});

export const PatientTypesModule: Module<PatientTypesState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadPatientTypes(
      { commit, dispatch },
      payload: {
        service: PatientTypeService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const patientTypes: PatientType[] = await service.getTypes();
        commit("setPatientTypes", patientTypes);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async deletePatientType(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: PatientTypeService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteType(id);
        if (deleted.patientTypeId === id) {
          const types = cloneDeep(getters.patientTypes).filter(
            (type: PatientType) => type.patientTypeId !== id
          );
          commit("setPatientTypes", types);
          success = await SuccessNotification(dispatch, `Deleted patient type ${deleted?.name}!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async createPatientType(
      { commit, dispatch, getters },
      payload: {
        values: PatientType;
        service: PatientTypeService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newType = await service.createType(values);
        const types = cloneDeep(getters.patientTypes);
        types.push(newType);
        commit("setPatientTypes", types);
        success = await SuccessNotification(dispatch, `Created patient type ${values?.name}!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updatePatientType(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: PatientType;
        service: PatientTypeService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;
        const response = await service.updateType(id, values);

        const types = cloneDeep(getters.patientTypes);
        types.forEach((item: PatientType, index: number) => {
          if (item.patientTypeId === id) {
            types[index] = values;
          }
        });
        commit("setPatientTypes", types);
        if (response === 204) {
          success = true;
        }
        await SuccessNotification(dispatch, `Updated patient type!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: PatientTypesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setPatientTypes(state: PatientTypesState, patientTypes?: PatientType[]) {
      Vue.set(state, "patientTypes", patientTypes);
    },
    reset: function (state: PatientTypesState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('PatientTypesState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    patientTypes: (state) => state.patientTypes,
    hasPatientTypes: (state) => !!state.patientTypes?.filter((type: PatientType) => !type?.isObsolete)?.length,
    patientTypesSelect: (state) =>
      state.patientTypes?.map((item: PatientType) => {
        if(!item.isObsolete) {
          return {text: item.name, value: item.patientTypeId, disabled: !!item.isObsolete};
        }
      }) as Select[],
  },
};
