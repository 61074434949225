/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState, TemplateDocuments} from "../../index";
import Vue from "vue";
import {TemplateDocumentsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface TemplateDocumentsState {
  loading: boolean;
  saving: boolean;
  templateDocumentOptions?: TemplateDocuments;
  templateDocumentOptionsWithOtherForms?: TemplateDocuments;
}

export enum TemplateDocumentsActions {
  LOAD_TEMPLATE_DOCUMENT_OPTIONS = "TemplateDocumentsModule/loadTemplateDocumentOptions",
  LOAD_TEMPLATE_DOCUMENT_OPTIONS_WITH_OTHER_FORMS = "TemplateDocumentsModule/loadTemplateDocumentOptionsWithOtherForms",
  UPDATE_TEMPLATE_DOCUMENT_OPTIONS = "TemplateDocumentsModule/updateTemplateDocumentOptions",
  RESET = "TemplateDocumentsModule/reset",
}

export enum TemplateDocumentsMutations {
  SET_LOADING = "TemplateDocumentsModule/setLoading",
  SET_TEMPLATE_DOCUMENT_OPTIONS = "TemplateDocumentsModule/setTemplateDocumentOptions",
  SET_TEMPLATE_DOCUMENT_OPTIONS_WITH_OTHER_FORMS = "TemplateDocumentsModule/setTemplateDocumentOptionsWithOtherForms",
  RESET = "TemplateDocumentsModule/reset",
}

export enum TemplateDocumentsGetters {
  LOADING = "TemplateDocumentsModule/loading",
  SAVING = "TemplateDocumentsModule/saving",
  TEMPLATE_DOCUMENT_OPTIONS = "TemplateDocumentsModule/templateDocumentOptions",
  TEMPLATE_DOCUMENT_OPTIONS_WITH_OTHER_FORMS = "TemplateDocumentsModule/templateDocumentOptionsWithOtherForms",
}

const initialState = () => ({
  loading: true,
  saving: false,
  templateDocumentOptions: undefined,
});

export const TemplateDocumentsModule: Module<
  TemplateDocumentsState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadTemplateDocumentOptions(
      { commit, dispatch },
      payload: {
        service: TemplateDocumentsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const templateDocumentOptions: any = await service.getTemplateDocuments();
        commit("setTemplateDocumentOptions", templateDocumentOptions);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadTemplateDocumentOptionsWithOtherForms(
        { commit, dispatch },
        payload: {
          service: TemplateDocumentsService;
        }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const templateDocumentOptions: TemplateDocuments = await service.getTemplateDocumentsWithOtherForms();
        commit("setTemplateDocumentOptionsWithOtherForms", templateDocumentOptions);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateTemplateDocumentOptions(
      { commit, dispatch },
      payload: {
        clientId: string;
        templateDocumentOptions: TemplateDocuments;
        service: TemplateDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, clientId, templateDocumentOptions } = payload;

        if(typeof templateDocumentOptions["canSignAfterSliders"] !== 'undefined') delete templateDocumentOptions["canSignAfterSliders"];
        if(typeof templateDocumentOptions["arePaymentDatesSpecified"] !== 'undefined') delete templateDocumentOptions["arePaymentDatesSpecified"];

        await service.updateTemplateDocumentOptions(
          clientId,
          templateDocumentOptions
        );
        commit("setTemplateDocumentOptions", templateDocumentOptions);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: TemplateDocumentsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setTemplateDocumentOptions(
      state: TemplateDocumentsState,
      templateDocumentOptions?: TemplateDocuments
    ) {
      Vue.set(state, "templateDocumentOptions", templateDocumentOptions);
    },
    setTemplateDocumentOptionsWithOtherForms(
        state: TemplateDocumentsState,
        templateDocumentOptions?: TemplateDocuments
    ) {

      //templateDocumentOptions order customEsignFiles by orderId
      if(templateDocumentOptions && templateDocumentOptions.customEsignFiles) {
        templateDocumentOptions.customEsignFiles = templateDocumentOptions.customEsignFiles.sort((a, b) => {
          if (!a.orderId) {
            a.orderId = 0;
          }
          if (!b.orderId) {
            b.orderId = 0;
          }
          return (a.orderId as number) - (b.orderId as number);
        });
      }
      Vue.set(state, "templateDocumentOptionsWithOtherForms", templateDocumentOptions);
    },
    reset: function (state: TemplateDocumentsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('TemplateDocumentsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    templateDocumentOptions: (state) => state.templateDocumentOptions,
    templateDocumentOptionsWithOtherForms: (state) => state.templateDocumentOptionsWithOtherForms,
  },
};
