import { AdminAppNotificationsService as IAdminAppNotificationsService } from "@shared/services/interfaces";
import { AppNotification } from "@shared/store/financials/interfaces";
import { AxiosInstance } from "axios";
import { cloneDeep } from "lodash";

export class AdminAppNotificationsService implements IAdminAppNotificationsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  fields = ["message", "title", "shortDescription"];

  async getAdminAppNotifications(): Promise<AppNotification[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/admin/AppNotifications`
    );

    response.data.forEach((item: AppNotification, index: number) => {
      for (const [key, value] of Object.entries(item)) {
        if (this.fields.includes(key) && typeof value === "string") {
          response.data[index][key] = decodeURIComponent(value);
        }
      }
    });

    return response.data;
  }

  async getAdminAppNotificationDetail(appNotificationId: number): Promise<AppNotification> {
    const response = await this.http.get(
      `${this.apiUrl}/api/admin/AppNotifications`,
      { params: { id: appNotificationId } }
    );

    for (const [key, value] of Object.entries(response.data)) {
      if (this.fields.includes(key) && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      }
    }
    return response.data;
  }

  async saveAdminAppNotification(appNotification: AppNotification): Promise<AppNotification> {

    const data = cloneDeep(appNotification);
    for (const [key, value] of Object.entries(data)) {
      if (this.fields.includes(key)) {
        if (typeof value === "string") {
          // @ts-ignore
          data[key] = encodeURIComponent(value);
        }
      }
    }

    const response = await this.http.post(
      `${this.apiUrl}/api/admin/AppNotifications`,
      { ...data },
      { timeout: process.env.VUE_APP_API_TIMEOUT_IN_SECS_ADMIN_NOTIFICATIONS ? (parseInt(process.env.VUE_APP_API_TIMEOUT_IN_SECS_ADMIN_NOTIFICATIONS) * 1000) : 600000 }
    );

    for (const [key, value] of Object.entries(response.data)) {
      if (this.fields.includes(key) && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      }
    }
    return response.data;
  }

  async deleteAdminAppNotification(id: number): Promise<AppNotification> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/admin/AppNotifications`,
      {
        params: { id },
        timeout: process.env.VUE_APP_API_TIMEOUT_IN_SECS_ADMIN_NOTIFICATIONS ? (parseInt(process.env.VUE_APP_API_TIMEOUT_IN_SECS_ADMIN_NOTIFICATIONS) * 1000) : 600000
      }
    );

    return response.data;
  }
}