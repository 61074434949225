import maps from "@main/configs/maps";
import time from "@main/configs/time";
import theme from "@main/configs/theme";
import toolbar from "@main/configs/toolbar";
import analytics from "@main/configs/analytics";
import currencies from "@main/configs/currencies";
import navigation from "./navigation";
import {PORTAL_APP_NAME} from "@shared/store/constants";
import directions from "@portal/configs/menus/directions.menu";
import paymentOptions from "@portal/configs/menus/payment-options.menu";
import signingDocuments from "@portal/configs/menus/signing-documents.menu";
import signingDocumentsOptional from "@portal/configs/menus/signing-documents-optional.menu";

export default {
  // product display information
  product: {
    name: PORTAL_APP_NAME,
    version: typeof process.env.VUE_APP_VERSION === 'string' ? process.env.VUE_APP_VERSION : "2.X.X",
  },

  // google maps
  maps,

  // time configs
  time,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // analytics configs
  analytics,

  // currencies configs
  currencies,

  // navigation configs
  navigation,

  //nav directions config
  directions,

  //nav payment options config
  paymentOptions,

  //nav signing documents config
  signingDocuments,

  //nav signing documents config
  signingDocumentsOptional,
};
