import {DiscountService} from "../services/interfaces";
import {inject, provide} from "vue";

export const discountServiceSymbol = Symbol();

export function provideDiscountService(service: DiscountService) {
  return provide(discountServiceSymbol, service);
}

export function useDiscountService() {
  const discountService = inject<DiscountService>(discountServiceSymbol);
  if (!discountService) {
    throw Error("No Discount Service to Inject!!");
  }
  return discountService;
}
