import {PatientFormsEsignDocumentsService} from "../services/interfaces";
import {inject, provide} from "vue";


export const patientFormsEsignDocumentsSymbol = Symbol();

export function providePatientFormsEsignDocumentsService(
  service: PatientFormsEsignDocumentsService
) {
  return provide(patientFormsEsignDocumentsSymbol, service);
}

export function usePatientFormsEsignDocumentsService() {
  const patientFormsEsignDocumentsService = inject<PatientFormsEsignDocumentsService>(
      patientFormsEsignDocumentsSymbol
  );
  if (!patientFormsEsignDocumentsService) {
    throw Error("No Patient Forms Esign Documents Service to Inject!!");
  }
  return patientFormsEsignDocumentsService;
}
