import {provideStore} from "./use-store";
import store from "../store";
import {provideAcountService} from "./use-account-service";
import {AccountService} from "../services/AccountService";
import axios from "../axios";
import axiosExternal from "../axios-external";
import {provideInputFieldsService} from "./use-input-fields-service";
import {InputFieldsService} from "../services/InputFieldsService";
import {provideTemplateDocumentsService} from "./use-template-documents-service";
import {TemplateDocumentsService} from "../services/document-services/TemplateDocumentsService";
import {providePatientContractsService} from "./use-patient-contracts-service";
import {PatientContractsService} from "../services/document-services/PatientContractsService";
import {providePatientService} from "./use-patient-service";
import {PatientService} from "../services/patient-services/PatientService";
import {providePatientIntakeDocumentsService} from "./use-patient-intake-documets-service";
import {PatientIntakeDocumentsService} from "../services/document-services/PatientIntakeDocumentsService";
import {provideInformedConsentService} from "./use-informed-consent-service";
import {InformedConsentService} from "../services/document-services/InformedConsentService";
import {provideAutoPaymentsService} from "./use-auto-payments-service";
import {AutoPaymentsService} from "../services/document-services/AutoPaymentsService";
import {provideClientRemindersService} from "./use-client-reminders-service";
import {ClientRemindersService} from "../services/document-services/ClientRemindersService";
import {provideTreatmentClassesService} from "./use-treatment-classes-service";
import {TreatmentClassesService} from "../services/treatment-services/TreatmentClassesService";
import {provideTreatmentTypesService} from "./use-treatment-types-service";
import {TreatmentTypesService} from "../services/treatment-services/TreatmentTypesService";
import {provideAdditionalChargesService} from "./use-additional-charges-service";
import {AdditionalChargesService} from "../services/treatment-services/AdditionalChargesService";
import {provideConditionalTextService} from "./use-conditional-text-service";
import {ConditionalTextService} from "../services/document-services/ConditionalTextService";
import {provideAnytimeDocumentsService} from "./use-anytime-documents-service";
import {AnytimeDocumentsService} from "../services/document-services/AnytimeDocumentsService";
import {provideCustomFormService} from "./use-custom-form-service";
import {CustomFormService} from "../services/document-services/CustomFormService";
import {providePDFService} from "./use-pdf-service";
import {PDFService} from "../services/PDFService";
import {provideUIService} from "./use-ui-service";
import {UIService} from "../services/UIService";
import {provideOfficeService} from "./use-office-service";
import {OfficeService} from "../services/practice-services/OfficeService";
import {provideDoctorService} from "./use-doctor-service";
import {DoctorService} from "../services/practice-services/DoctorService";
import {provideNotificationRecipientsService} from "./use-notification-recipients-service";
import {NotificationRecipientsService} from "../services/practice-services/NotificationRecipientsService";
import {provideTreatmentCoordinatorsService} from "./use-treatment-coordinators-service";
import {TreatmentCoordinatorsService} from "../services/practice-services/TreatmentCoordinatorsService";
import {providePatientTypeService} from "./use-patient-type-service";
import {PatientTypeService} from "../services/practice-services/PatientTypeService";
import {provideResponsiblePartyTiersService} from "./use-responsible-party-tiers-service";
import {ResponsiblePartyTiersService} from "../services/calculation-services/ResponsiblePartyTiersService";
import {provideDiscountService} from "./use-discount-service";
import {DiscountService} from "../services/finance-services/DiscountService";
import {provideClientSubscriptionService} from "./use-client-subscription-service";
import {ClientSubscriptionService} from "../services/ClientSubscriptionService";
import {providePpoOptionService} from "./use-ppo-option-service";
import {PpoOptionService} from "../services/insurance-services/PpoOptionService";
import {providePpoRateGroupService} from "./use-ppo-rate-groups-service";
import {PpoRateGroupService} from "../services/insurance-services/PpoRateGroupService";
import {providePaymentPlansService} from "./use-payment-plans-service";
import {PaymentPlansService} from "../services/calculation-services/PaymentPlansService";
import {provideCalculationRulesService} from "./use-calculation-rules-service";
import {CalculationRulesService} from "../services/calculation-services/CalculationRulesService";
import {provideProductFeesService} from "./use-product-fees-service";
import {ProductFeesService} from "../services/calculation-services/ProductFeesService";
import {provideInsuranceVerificationService} from "./use-insurance-verification-service";
import {InsuranceVerificationService} from "../services/insurance-services/InsuranceVerificationService";
import {provideLogoService} from "./use-logo-service";
import {LogoService} from "../services/practice-services/LogoService";
import {provideFileService} from "./use-file-service";
import {FileService} from "../services/FileService";
import {provideRegistrationService} from "./use-registration-service";
import {RegistrationService} from "../services/RegistrationService";
import {provideProductTypeService} from "./use-product-type-service";
import {ProductTypeService} from "../services/finance-services/ProductTypeService";
import {provideUserService} from "./use-user-service";
import {UserService} from "../services/UserService";
import {provideResponsiblePartiesService} from "./use-responsible-parties-service";
import {ResponsiblePartiesService} from "../services/patient-services/ResponsiblePartiesService";
import {provideClientConfigurationSettingsService} from "./use-client-configuration-settings-service";
import {ClientConfigurationSettingsService} from "../services/ClientConfigurationSettingsService";
import {PatientTreatmentPlansService} from "../services/patient-services/PatientTreatmentPlansService";
import {providePatientTreatmentPlansService} from "./use-patient-treatment-plans-service";
import {TreatmentComplexitiesService} from "../services/treatment-services/TreatmentComplexitiesService";
import {providetreatmentComplexitiesService} from "./use-treatment-complexities-service";
import {PatientTreatmentPlanDiscountsService} from "../services/patient-services/PatientTreatmentPlanDiscountsService";
import {providePatientTreatmentPlanDiscountsService} from "./use-patient-treatment-plan-discounts-service";
import {
    providePatientTreatmentPlanAdditionalChargesService
} from "./use-patient-treatment-plan-additional-charges-service";
import {
    PatientTreatmentPlanAdditionalChargesService
} from "../services/patient-services/PatientTreatmentPlanAdditionalChargesService";
import {PatientFormsEsignDocumentsService} from "../services/patient-services/PatientFormsEsignDocumentsService";
import {providePatientFormsEsignDocumentsService} from "./use-patient-forms-esign-documents-service";
import {PatientWetSignDocumentsService} from "../services/document-services/PatientWetSignDocumentsService";
import {providePatientWetSignDocumentsService} from "./use-patient-wet-sign-documents-service";
import {PatientTreatmentTypesService} from "../services/patient-services/PatientTreatmentTypesService";
import {providePatientTreatmentTypesService} from "./use-patient-treatment-types-service";
import {providePatientVisitsService} from "./use-patient-visits-service";
import {providePatientTreatmentPlanCommunicationsService} from "./use-patient-treatment-plan-communications-service";
import {PatientVisitsService} from "../services/patient-services/PatientVisitsService";
import {providePatientEsignDocumentsService} from "./use-patient-esign-documents-service";
import {PatientEsignDocumentsService} from "../services/document-services/PatientEsignDocumentsService";
import {
    PatientTreatmentPlanCommunicationsService
} from "@shared/services/patient-services/PatientTreatmentPlanCommunicationsService";
import {DeletedEsignDocumentsService} from "@shared/services/document-services/DeletedEsignDocumentsService"
import {provideDeletedEsignDocumentsService} from "@shared/providers/use-deleted-esign-documents-service";
import {provideMaintenanceService} from "@shared/providers/use-maintenance-service";
import {MaintenanceService} from "@shared/services/MaintenanceService";
import {provideChargeOverService} from "@shared/providers/use-chargeover-service";
import {ChargeOverService} from "@shared/services/ChargeOverService";

//Generics
import {
    AdminClientSubscriptionService as IAdminClientSubscriptionService,
    IAdminUsersService, IAppNotificationsService, IVideosService
} from "../services/interfaces";
import {AdminAppNotificationsService as IAdminAppNotificationsService} from "../services/interfaces";
import {createServiceInjector} from "@shared/providers/createServiceInjector";
import {AdminClientSubscriptionsService} from "@shared/services/admin-services/AdminClientSubscriptionsService";
import {
    AdminAppNotificationsService,
} from "@shared/services/admin-services/AdminAppNotificationsService";
import {AdminUsersService} from "@shared/services/admin-services/AdminUsersService";
import { AppNotificationsService } from "@shared/services/AppNotificationsService";
import { VideosService } from "@shared/services/VideosService";

export * from "./use-router";
export * from "./use-store";
export * from "./use-account-service";
export * from "./use-input-fields-service";

export * from "./use-template-documents-service";
export * from "./use-patient-service";
export * from "./use-patient-treatment-plan-communications-service"
export * from "./use-patient-intake-documets-service";
export * from "./use-informed-consent-service";
export * from "./use-auto-payments-service";
export * from "./use-client-reminders-service";
export * from "./use-treatment-classes-service";
export * from "./use-treatment-types-service";
export * from "./use-additional-charges-service";
export * from "./use-conditional-text-service";
export * from "./use-anytime-documents-service";
export * from "./use-custom-form-service";
export * from "./use-pdf-service";
export * from "./use-ui-service";
export * from "./use-office-service";
export * from "./use-doctor-service";
export * from "./use-notification-recipients-service";
export * from "./use-patient-type-service";
export * from "./use-treatment-coordinators-service";
export * from "./use-discount-service";
export * from "./use-responsible-party-tiers-service";
export * from "./use-client-subscription-service";
export * from "./use-ppo-option-service";
export * from "./use-payment-plans-service";
export * from "./use-calculation-rules-service";
export * from "./use-ppo-rate-groups-service";
export * from "./use-product-fees-service";
export * from "./use-insurance-verification-service";
export * from "./use-logo-service";
export * from "./use-file-service";
export * from "./use-registration-service";
export * from "./use-product-type-service";
export * from "./use-user-service";
export * from "./use-responsible-parties-service";
export * from "./use-client-configuration-settings-service";
export * from "./use-treatment-complexities-service";
export * from "./use-patient-treatment-plans-service";
export * from "./use-patient-treatment-plan-discounts-service";
export * from "./use-patient-treatment-plan-additional-charges-service";
export * from "./use-patient-contracts-service";
export * from "./use-patient-forms-esign-documents-service";
export * from "./use-patient-wet-sign-documents-service";
export * from "./use-patient-treatment-types-service";
export * from "./use-patient-visits-service";
export * from "./use-patient-esign-documents-service";
export * from "./use-deleted-esign-documents-service";
export * from "./use-maintenance-service";
export * from "./use-chargeover-service";

export const {
    provideService: provideAdminClientSubscriptionService,
    useService: useAdminClientSubscriptionService,
} = createServiceInjector<IAdminClientSubscriptionService>('AdminClientSubscriptionService');

export const {
    provideService: provideAdminAppNotificationsService,
    useService: useAdminAppNotificationsService,
} = createServiceInjector<IAdminAppNotificationsService>('AdminAppNotificationsService');

export const {
    provideService: provideAdminUsersService,
    useService: useAdminUsersService,
} = createServiceInjector<IAdminUsersService>('AdminUsersService');

export const {
    provideService: provideAppNotificationsService,
    useService: useAppNotificationsService,
} = createServiceInjector<IAppNotificationsService>('AppNotificationsService');

export const {
    provideService: provideVideosService,
    useService: useVideosService,
} = createServiceInjector<IVideosService>('VideosService');

export function getAllProviders() {
    provideStore(store);
    //services
    provideAcountService(new AccountService(axios));
    provideInputFieldsService(new InputFieldsService(axios));
    provideTemplateDocumentsService(new TemplateDocumentsService(axios));
    providePatientService(new PatientService(axios));
    providePatientIntakeDocumentsService(
        new PatientIntakeDocumentsService(axios)
    );
    provideInformedConsentService(new InformedConsentService(axios));
    provideAutoPaymentsService(new AutoPaymentsService(axios));
    provideClientRemindersService(new ClientRemindersService(axios));
    provideTreatmentClassesService(new TreatmentClassesService(axios));
    provideTreatmentTypesService(new TreatmentTypesService(axios));
    provideAdditionalChargesService(new AdditionalChargesService(axios));
    provideConditionalTextService(new ConditionalTextService(axios));
    provideAnytimeDocumentsService(new AnytimeDocumentsService(axios));
    provideCustomFormService(new CustomFormService(axios));
    provideAnytimeDocumentsService(new AnytimeDocumentsService(axios));
    providePDFService(new PDFService(axios));
    provideUIService(new UIService(axios));
    provideOfficeService(new OfficeService(axios));
    provideDoctorService(new DoctorService(axios));
    provideNotificationRecipientsService(
        new NotificationRecipientsService(axios)
    );
    provideTreatmentCoordinatorsService(
        new TreatmentCoordinatorsService(axios)
    );
    providePatientTypeService(new PatientTypeService(axios));
    provideResponsiblePartyTiersService(
        new ResponsiblePartyTiersService(axios)
    );
    provideDiscountService(new DiscountService(axios));
    provideClientSubscriptionService(new ClientSubscriptionService(axios));
    providePpoOptionService(new PpoOptionService(axios));
    providePpoRateGroupService(new PpoRateGroupService(axios));
    providePaymentPlansService(new PaymentPlansService(axios));
    provideCalculationRulesService(new CalculationRulesService(axios));
    provideProductFeesService(new ProductFeesService(axios));
    provideInsuranceVerificationService(
        new InsuranceVerificationService(axios)
    );
    provideLogoService(new LogoService(axios));
    provideFileService(new FileService(axios));
    provideRegistrationService(new RegistrationService(axios));
    provideProductTypeService(new ProductTypeService(axios));
    provideUserService(new UserService(axios));
    provideResponsiblePartiesService(new ResponsiblePartiesService(axios));

    provideClientConfigurationSettingsService(
        new ClientConfigurationSettingsService(axios)
    );
    providePatientTreatmentPlanCommunicationsService(
        new PatientTreatmentPlanCommunicationsService(axios)
    );
    providetreatmentComplexitiesService(
        new TreatmentComplexitiesService(axios)
    );
    providePatientTreatmentPlansService(
        new PatientTreatmentPlansService(axios)
    );
    providePatientTreatmentPlanDiscountsService(
        new PatientTreatmentPlanDiscountsService(axios)
    );
    providePatientTreatmentPlanAdditionalChargesService(
        new PatientTreatmentPlanAdditionalChargesService(axios)
    );
    providePatientContractsService(new PatientContractsService(axios));
    providePatientFormsEsignDocumentsService(new PatientFormsEsignDocumentsService(axios));
    providePatientWetSignDocumentsService(new PatientWetSignDocumentsService(axios));
    providePatientTreatmentTypesService(new PatientTreatmentTypesService(axios));
    providePatientVisitsService(new PatientVisitsService(axios));
    providePatientEsignDocumentsService(new PatientEsignDocumentsService(axios));
    provideDeletedEsignDocumentsService(new DeletedEsignDocumentsService(axios));
    provideChargeOverService(new ChargeOverService(axios));
    provideAppNotificationsService(new AppNotificationsService(axios));
    provideVideosService(new VideosService(axios));

    //admin services
    provideAdminClientSubscriptionService(new AdminClientSubscriptionsService(axios));
    provideAdminAppNotificationsService(new AdminAppNotificationsService(axios));
    provideAdminUsersService(new AdminUsersService(axios));

    //external services
    provideMaintenanceService(new MaintenanceService(axiosExternal));
}