/* eslint-disable no-unused-vars */
import { Module } from "vuex";
import { RootState, User } from "../../index";
import Vue from "vue";
// DO NOT MOVE THIS OR ELSE
import { ErrorNotification } from "@shared/functions/NotificationFunctions";
import { IAdminUsersService } from "@shared/services/interfaces";

export interface AdminClientUsersState {
  loading: boolean;
  clientUsers?: User[];
}

export enum AdminClientUsersActions {
  LOAD_CLIENT_USERS = "AdminClientUsersModule/loadClientUsers",
  RESET = "AdminClientUsersModule/reset",
}

export enum AdminClientUsersMutations {
  SET_LOADING = "AdminClientUsersModule/setLoading",
  SET_CLIENT_USERS = "AdminClientUsersModule/setClientUsers",
  RESET = "AdminClientUsersModule/reset",
}

export enum AdminClientUsersGetters {
  LOADING = "AdminClientUsersModule/loading",
  CLIENT_USERS = "AdminClientUsersModule/clientUsers",
}

const initialState = () => ({
  loading: false,
  clientUsers: undefined
});

export const AdminClientUsersModule: Module<
  AdminClientUsersState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadClientUsers(
      { commit, dispatch },
      payload: {
        service: IAdminUsersService; //todo add type
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const clientUsers: User[] = await service.getClientUsers();//todo add correct service call

        commit("setClientUsers", clientUsers);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    reset({ commit }) {
      commit("reset");
    }
  },
  mutations: {
    setLoading(
      state: AdminClientUsersState,
      payload: { loading: boolean; loadingType: string } | boolean
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setClientUsers(
      state: AdminClientUsersState,
      users?: User[]
    ) {
      Vue.set(state, "clientUsers", users);
    },
    reset: function(state: AdminClientUsersState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error("AdminClientUsersState Reset Error: ", ex.message);
        }
      });
    }
  },
  getters: {
    loading: (state) => state.loading,
    clientUsers: (state) => state.clientUsers
  }
};
