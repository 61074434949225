/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {PatientTreatmentType, RootState} from "../../index";
import Vue from "vue";

// DO NOT MOVE THIS OR ELSE
import {PatientTreatmentTypesService} from "../../../services/interfaces";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface PatientTreatmentTypesState {
    loading: boolean;
    patientTreatmentTypes?: PatientTreatmentType[];
}

export enum PatientTreatmentTypesActions {
    LOAD_PATIENT_TREATMENT_TYPES = "PatientTreatmentTypesModule/loadPatientTreatmentTypes",
}

export enum PatientTreatmentTypesMutations {
    SET_LOADING = "PatientTreatmentTypesModule/setLoading",
    SET_PATIENT_TREATMENT_TYPES = "PatientTreatmentTypesModule/setPatientTreatmentTypes",
    RESET = "PatientTreatmentTypesModule/reset",
}

export enum PatientTreatmentTypesGetters {
    LOADING = "PatientTreatmentTypesModule/loading",
    PATIENT_TREATMENT_TYPES = "PatientTreatmentTypesModule/patientTreatmentTypes",
}

const initialState = () => ({
    loading: false,
    patientTreatmentTypes: undefined,
});

export const PatientTreatmentTypesModule: Module<PatientTreatmentTypesState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadPatientTreatmentTypes(
            {commit, dispatch},
            payload: {
                service: PatientTreatmentTypesService;
                patientTreatmentTypeId: string;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", true);
                const {service, patientTreatmentTypeId} = payload;
                const patientTreatmentTypes = await service.getPatientTreatmentTypesDetail(
                    patientTreatmentTypeId
                );
                commit("setPatientTreatmentTypes", patientTreatmentTypes);
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
            return success;
        },
    },
    mutations: {
        setLoading(
            state: PatientTreatmentTypesState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setPatientTreatmentTypes(
            state: PatientTreatmentTypesState,
            patientTreatmentTypes?: PatientTreatmentType[]
        )
        {
            Vue.set(state, "patientTreatmentTypes", patientTreatmentTypes);
        },
        reset: function (state: PatientTreatmentTypesState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('PatientTreatmentTypesState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        patientTreatmentTypes: (state) => state.patientTreatmentTypes,
    },
};
