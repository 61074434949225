import {Dispatch} from "vuex";
import {NotificationActions} from "@shared/store/notification";
import {Alert, AlertStatus} from "@shared/store";

function parseErrorMessage(error?: any) {
    let message = "";
    if (typeof error === 'string' && error?.trim()?.length > 0) {
        message = error;
    }
    if (typeof error?.message === 'string' && error?.message?.trim()) {
        message = error.message;
    }
    if (typeof error?.data === 'string' && error?.data?.trim()) {
        message = error.data;
    }
    return message;
}

export async function ErrorNotification(dispatch: Dispatch, error?: any, message?: string): Promise<void> {
    if (process.env.NODE_ENV == "development") {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    if (typeof message !== 'string' || message?.trim()?.length === 0) {
        message = parseErrorMessage(error);
    }

    await dispatch(
        NotificationActions.ALERT,
        {status: AlertStatus.ERROR, error, message} as Alert,
        {root: true}
    );
}

export async function SuccessNotification(dispatch: Dispatch, message: string, suppressNotification: boolean = false): Promise<boolean> {
    if (!suppressNotification) {
        await dispatch(
            NotificationActions.ALERT,
            {status: AlertStatus.SUCCESS, message} as Alert,
            {root: true}
        );
    }

    return true;
}

export async function WarningNotification(dispatch: Dispatch, message: string): Promise<void> {
    await dispatch(
        NotificationActions.ALERT,
        {status: AlertStatus.WARNING, message} as Alert,
        {root: true}
    );
}

export async function WarningOrErrorNotification(dispatch: Dispatch, error?: any, message?: string): Promise<void> {

    if (typeof message !== 'string' || message?.trim()?.length === 0) {
        message = parseErrorMessage(error);
    }

    (error?.response?.status as number) >= 500
        ? await ErrorNotification(dispatch, error, message)
        : await WarningNotification(dispatch, message || "Unknown Error");

}

export async function InfoNotification(dispatch: Dispatch, message: string): Promise<void> {
    await dispatch(
        NotificationActions.ALERT,
        {status: AlertStatus.WARNING, message} as Alert,
        {root: true}
    );
}