import {RegistrationService as IRegistrationService} from "./interfaces";
import {Registration} from "../store/interfaces";
import {AxiosInstance} from "axios";

export class RegistrationService implements IRegistrationService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async register(registration: Registration): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/register/signup`,
      { ...registration }
    );
    return response.status;
  }

  async defaultConfigurations(website: string): Promise<number> {
    const response = await this.http.post(
        `${this.apiUrl}/api/register/defaultconfigurations`,
        { website }
    );
    return response.status;
  }
}
