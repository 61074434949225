/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
// DO NOT MOVE THIS OR ELSE
import {ErrorNotification} from "@shared/functions/NotificationFunctions";
import {ClientSubscriptionDetail} from "@shared/store/financials/interfaces";
import { AdminClientSubscriptionService } from "@shared/services/interfaces";

export interface AdminClientSubscriptionsState {
  loading: boolean;
  saving: boolean;
  clientSubscriptions?: ClientSubscriptionDetail[];
}

export enum AdminClientSubscriptionsActions {
  LOAD_CLIENT_SUBSCRIPTIONS = "AdminClientSubscriptionsModule/loadClientSubscriptions",
  RESET = "AdminClientSubscriptionsModule/reset",
}

export enum AdminClientSubscriptionsMutations {
  SET_LOADING = "AdminClientSubscriptionsModule/setLoading",
  SET_CLIENT_SUBSCRIPTIONS = "AdminClientSubscriptionsModule/setClientSubscriptions",
  RESET = "AdminClientSubscriptionsModule/reset",
}

export enum AdminClientSubscriptionsGetters {
  LOADING = "AdminClientSubscriptionsModule/loading",
  SAVING = "AdminClientSubscriptionsModule/saving",
  CLIENT_SUBSCRIPTIONS = "AdminClientSubscriptionsModule/clientSubscriptions",
}

const initialState = () => ({
  loading: false,
  saving: false,
  clientSubscriptions: undefined,
});

export const AdminClientSubscriptionsModule: Module<
    AdminClientSubscriptionsState,
    RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadClientSubscriptions(
        {commit, dispatch},
        payload: {
          service: AdminClientSubscriptionService; //todo add type
        }
    ) {
      try {
        commit("setLoading", true);
        const {service} = payload;
        const clientSubscriptions: ClientSubscriptionDetail[] = await service.getAdminClientSubscriptions();//todo add correct service call

        commit("setClientSubscriptions", clientSubscriptions);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    reset({commit}) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
        state: AdminClientSubscriptionsState,
        payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setClientSubscriptions(
        state: AdminClientSubscriptionsState,
        clientSubscriptions?: ClientSubscriptionDetail[]
    ) {
      Vue.set(state, "clientSubscriptions", clientSubscriptions);
    },
    reset: function (state: AdminClientSubscriptionsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('AAdminClientSubscriptionsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    clientSubscriptions: (state) => state.clientSubscriptions,
  },
};
