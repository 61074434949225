/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {PpoOptionService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {PpoOption} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface PpoOptionsState {
  loading: boolean;
  saving: boolean;
  ppoOption?: PpoOption;
}

export enum PpoOptionsActions {
  LOAD_PPO_OPTION = "PpoOptionsModule/loadPpoOption",
  UPDATE_PPO_OPTION = "PpoOptionsModule/updatePpoOption",
  RESET = "PpoOptionsModule/reset",
}

export enum PpoOptionsMutations {
  SET_LOADING = "PpoOptionsModule/setLoading",
  SET_PPO_OPTION = "PpoOptionsModule/setPpoOption",
  RESET = "PpoOptionsModule/reset",
}

export enum PpoOptionsGetters {
  LOADING = "PpoOptionsModule/loading",
  SAVING = "PpoOptionsModule/saving",
  PPO_OPTION = "PpoOptionsModule/ppoOption",
}

const initialState = () => ({
  loading: false,
  saving: false,
  ppoOption: undefined,
});


export const PpoOptionsModule: Module<PpoOptionsState, RootState> = {
  namespaced: true,
    state: initialState(),
  actions: {
    async loadPpoOption(
      { commit, dispatch },
      payload: {
        service: PpoOptionService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const option: PpoOption = await service.getPpoOption();
        commit("setPpoOption", option);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updatePpoOption(
      { commit, dispatch },
      payload: {
        id: string;
        values: PpoOption;
        service: PpoOptionService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;
        const response = await service.updatePpoOption(id, values);

        if (response === 204) {
          commit("setPpoOption", values);
          success = true;
        }
        await SuccessNotification(dispatch, `Updated PPO Option!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: PpoOptionsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setPpoOption(state: PpoOptionsState, option?: PpoOption) {
      Vue.set(state, "ppoOption", option);
    },
    reset: function (state: PpoOptionsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('PpoOptionsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    ppoOption: (state) => state.ppoOption,
  },
};
