import {NewPatientForm, PacketBody} from "@shared/store";
import moment from "moment/moment";
import {NewPatientPacket} from "@shared/store/responsible-parties/interfaces";

export function patientPacketToPacketBodyFormatter(values: NewPatientPacket) {
    const body: PacketBody = {} as PacketBody;
    body.forms = [] as NewPatientForm[];
    body.patientId = values.patientId;
    body.patientFirstName = values.patientFirstName as string;
    body.patientLastName = values.patientLastName as string;
    body.patientResponsiblePartyId = values.patientResponsiblePartyId ? values.patientResponsiblePartyId : '';
    body.responsiblePartyFirstName = values.signers[0].firstName;
    body.responsiblePartyLastName = values.signers[0].lastName;
    body.initialExamDateTimeString = values.initialExamDateTimeString && moment(values.initialExamDateTimeString).isValid() ? moment(
        values.initialExamDateTimeString
    ).format("MM/DD/YYYY hh:mm A") : "";
    body.officeId = values.officeId as string;
    body.email = values.signers[0].email as string;
    values?.patientSpecificPrefillInfo?.forEach((info, index) => {
        values.patientSpecificPrefillInfo[index].value = encodeURIComponent(info?.value || "");
    });
    body.patientSpecificPrefillInfo = values?.patientSpecificPrefillInfo || [];
    values.formsToInclude?.forEach((form) => body.forms.push({formId: form}));
    if (values.signers.length > 1) {
        values.signers.forEach((signer, index) => {
            switch (index) {
                case 0:
                    break;
                case 1:
                    body.additionalSigner2 = {
                        signer: index + 1,
                        firstName: signer.firstName,
                        lastName: signer.lastName,
                        email: signer.email as string,
                    };
                    break;
                case 2:
                    body.additionalSigner3 = {
                        signer: index + 1,
                        firstName: signer.firstName,
                        lastName: signer.lastName,
                        email: signer.email as string,
                    };
                    break;
                case 3:
                    body.additionalSigner4 = {
                        signer: index + 1,
                        firstName: signer.firstName,
                        lastName: signer.lastName,
                        email: signer.email as string,
                    };
                    break;

                case 4:
                    body.additionalSigner5 = {
                        signer: index + 1,
                        firstName: signer.firstName,
                        lastName: signer.lastName,
                        email: signer.email as string,
                    };
                    break;
                default:
                    break;
            }
        });
    }

    return body;
}