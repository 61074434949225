import {CustomFormService as ICustomFormService} from "../interfaces";
import {AxiosInstance} from "axios";
import {CustomForm} from "../../store";
import {cloneDeep} from "lodash";

export class CustomFormService implements ICustomFormService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getCustomForms(): Promise<CustomForm[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/CustomEsignFiles/Detail`
    );

    return response.data;
  }

  async updateCustomForms(
    formId: string,
    values: CustomForm
  ): Promise<CustomForm> {
    const response = await this.http.put(
      `${this.apiUrl}/api/CustomEsignFiles`,
        values,
        {
          params: { formId },
        }
    );
    return response.data;
  }

  async updateFormFields(
      formId: string
  ): Promise<boolean> {
    const response = await this.http.post(
        `${this.apiUrl}/api/CustomEsignFiles/UpdateFormFields`,
        null,
        {
          params: { formId },
        }
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  async reorderCustomForms(customForms: CustomForm[]): Promise<boolean> {
    const data = cloneDeep(customForms) as any;
    const response = await this.http.put(
      `${this.apiUrl}/api/CustomEsignFiles/Sort`,
      data
    );
    if (response.status === 204) {
      return true;
    }
    return false;
  }

  async createCustomForm(formId: string, body: CustomForm) {
    const response = await this.http.post(
      `${this.apiUrl}/api/CustomEsignFiles`,
      body,
      {
        params: { formId},
      }
    );
    return response.data[0];
  }

  async deleteForm(id: string): Promise<CustomForm> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/CustomEsignFiles`,
      {
        params: { id },
      }
    );

    return response.data;
  }

  async postPdf(
      formId: string,
      initialFormId: string,
      contractId: string,
  ): Promise<string> {
    const response = await this.http.post(
        `${this.apiUrl}/api/CustomEsignFiles/postPdf`,
        null,
        { params: { formId, initialFormId, contractId }, responseType: "blob" }
    );
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });
      return URL.createObjectURL(file);
  }
}
