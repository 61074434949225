import {useStore} from "@shared/providers";
import {computed} from "vue";
import {PatientContractsGetters} from "@shared/store/documents/patient-contracts";
import {ISignedContract, PatientContract} from "@shared/store/documents/patient-contracts/interfaces";
import {APP_TYPES, ESIGNATURE_DATA} from "@shared/store/constants";
import {PatientTreatmentPlansGetters} from "@shared/store/treatment-plans";
import {cloneDeep} from "lodash";
import {useRouteComponents} from "@main/composables/helpers/use-route-components";
import {PatientsGetters} from "@shared/store/patients";
import {ResponsibleParty} from "@shared/store/responsible-parties/interfaces";
import {SessionGetters} from "@shared/store/session";
import moment from "moment";
import { getContractLastMarkedAsPrimary } from "@shared/functions/contractFunctions";

export function usePatientTxPlanAndContractsInfo() {
    const store = useStore();
    const {routeName, routeParams, routeQuery} = useRouteComponents();
    const contractId = computed<string | undefined>(
        () => routeParams.value?.contractId
    );
    const folderId = computed<string | undefined>(
        () => routeQuery.value?.["folderId"]?.toString()
    );
    const isSplit = computed<boolean>(
        () => store.getters[PatientTreatmentPlansGetters.IS_SPLIT] || store.getters[PatientContractsGetters.ARE_CONTRACTS_SPLIT]
    );
    const responsiblePartyId = computed<string | undefined>(
        () => {
            if (process.env.VUE_APP_TYPE === APP_TYPES.MAIN) {
                if (routeParams.value?.responsiblePartyId?.length) {
                    return routeParams.value?.responsiblePartyId === 'multiple' ? '' : routeParams.value?.responsiblePartyId;
                }

                if (contractId?.value) {
                    const contracts = cloneDeep(store.getters[PatientContractsGetters.PATIENT_CONTRACTS]) || [];
                    return contracts?.find((contract: PatientContract) => contract.patientContractId === contractId?.value)?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId;
                }
                return undefined;
            }
            if (process.env.VUE_APP_TYPE === APP_TYPES.PORTAL) {
                const patient = store.getters[PatientsGetters.SELECTED_PATIENT];
                const user = store.getters[SessionGetters.USER];
                if (patient?.patientResponsibleParties?.length > 0) {
                    const rpIndex = patient?.patientResponsibleParties?.length === 1
                        ? 0
                        : patient?.patientResponsibleParties?.findIndex((x: ResponsibleParty) => x.email?.toLowerCase() === user?.email?.toLowerCase() || (x.firstName?.toLowerCase() === user?.firstName?.toLowerCase() && x.lastName?.toLowerCase() === user?.lastName?.toLowerCase()));
                    if (rpIndex > -1 && patient?.patientResponsibleParties[rpIndex]?.patientResponsiblePartyId) {
                        return patient?.patientResponsibleParties[rpIndex].patientResponsiblePartyId;
                    }
                }
                return undefined;
            }
            return undefined;
        }
    );

    const patientContractsLoading = computed<boolean>(
        () => store.getters[PatientContractsGetters.LOADING]
    );

    const isSplitFixed = computed<boolean>(
        () => isSplit.value && (!!store.getters[PatientContractsGetters.FIRST_SIGNED_CONTRACT] || !!store.getters[PatientContractsGetters.SECOND_SIGNED_CONTRACT])
    );

    const arePaymentOptionsFixed = computed<boolean>(
        () => store.getters[PatientContractsGetters.ARE_PAYMENT_OPTIONS_FIXED_LEGACY]
            || store.getters[PatientTreatmentPlansGetters.ARE_PAYMENT_OPTIONS_FIXED]
    );
    const isTreatmentTypeFixed = computed<boolean>(
        () => isSplit.value && (isSplitFixed.value || arePaymentOptionsFixed.value)
    );

    const splitFixedTreatmentTypeId = computed<string | null | undefined>(
        () => {
            if (isTreatmentTypeFixed.value) {
                //check signed contract if so use that tx type id
                const firstSignedContract = store.getters[PatientContractsGetters.FIRST_SIGNED_CONTRACT];
                if (firstSignedContract && firstSignedContract?.treatmentTypeId) return firstSignedContract?.treatmentTypeId;
                const secondSignedContract = store.getters[PatientContractsGetters.SECOND_SIGNED_CONTRACT];
                if (secondSignedContract && secondSignedContract?.treatmentTypeId) return secondSignedContract?.treatmentTypeId;

                //check payment options fixed (if fixed should have a last marked primary date)
                const contracts = cloneDeep(store.getters[PatientContractsGetters.PATIENT_CONTRACTS]) || [];
                const primaryContracts = cloneDeep(contracts?.filter((item: PatientContract) => item.lastMarkedPrimaryDate) as PatientContract[]);
                // sort those contracts in chronological order of being marked primary, so we can get the most recent one
                const sortedPrimaryContracts = primaryContracts?.sort((a: PatientContract, b: PatientContract) => {
                    if (moment(a?.lastMarkedPrimaryDate) < moment(b?.lastMarkedPrimaryDate)) {
                        return 1;
                    } else if (moment(a?.lastMarkedPrimaryDate) > moment(b?.lastMarkedPrimaryDate)) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                if (sortedPrimaryContracts && sortedPrimaryContracts?.length > 0) {
                    return sortedPrimaryContracts?.[0]?.treatmentTypeId;
                }
            }

            //default case
            return undefined;
        });

    const patientContracts = computed<PatientContract[]>(
        () => {
            let contracts = cloneDeep(store.getters[PatientContractsGetters.PATIENT_CONTRACTS]) || [] as PatientContract[];
            if(!contracts?.length) return [];

            //on payment options we need to include all the tx types even if they are fixed
            if(isSplit.value && splitFixedTreatmentTypeId.value && !routeName.value?.startsWith("patients-tx-plan-payment-options")) {
                const filteredContracts = contracts?.filter((contract: PatientContract) => contract?.treatmentTypeId === splitFixedTreatmentTypeId.value);
                if(filteredContracts?.length) contracts = filteredContracts;
            }
            if (responsiblePartyId?.value && responsiblePartyId.value?.toLowerCase() !== 'multiple') {
                const filteredContracts = contracts?.filter((contract: PatientContract) => (!contract?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId || contract?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === responsiblePartyId?.value));
                if(filteredContracts?.length) contracts = filteredContracts;
            }
            if (routeParams.value?.treatmentTypeId && routeParams.value?.treatmentTypeId?.toLowerCase() !== 'comparison') { //single tx type
                const filteredContracts = contracts.filter((contract: PatientContract) => contract.treatmentTypeId === routeParams.value?.treatmentTypeId);
                if(filteredContracts?.length) contracts = filteredContracts;
            }
            if(routeParams.value?.paymentPlanId && routeParams.value?.paymentPlanId?.length){
                const filteredContracts = contracts.filter((contract: PatientContract) => contract?.paymentPlanId === routeParams.value?.paymentPlanId);
                if(filteredContracts?.length) contracts = filteredContracts;
            }
            if (contractId?.value) {
                const filteredContracts = contracts?.filter((contract: PatientContract) => contract.patientContractId === contractId?.value);
                if(filteredContracts?.length) contracts = filteredContracts;
            }
            if(folderId?.value) {
                const filteredContracts = contracts?.filter((c:PatientContract) => c?.patientEsignDocuments?.length && c?.patientEsignDocuments[0]?.patientEsignature?.folderId?.toString() === folderId?.value);
                if(filteredContracts?.length) contracts = filteredContracts;
            }
            return contracts;
        });

    //only works for esigned documents (not sure if this is correct though)
    const primaryContract = computed<PatientContract | null | undefined>( () => {
        let contracts = cloneDeep(store.getters[PatientContractsGetters.PATIENT_CONTRACTS]) || [] as PatientContract[];
        if(!contracts?.length) return undefined;

        if(isSplit.value && splitFixedTreatmentTypeId.value) {
            contracts = contracts?.filter((contract: PatientContract) => contract?.treatmentTypeId === splitFixedTreatmentTypeId.value);
        }
        if (contracts?.length && responsiblePartyId?.value && responsiblePartyId.value?.toLowerCase() !== 'multiple') {
            contracts = contracts?.filter((contract: PatientContract) => (!contract?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId || contract?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === responsiblePartyId?.value));
        }
        if(!contracts?.length) return undefined;


        //TODO: make sure when a contract is e-signed, it's marked primary, otherwise this logic isn't complete
        contracts = contracts?.filter((item: PatientContract) => item.lastMarkedPrimaryDate);
        if(!contracts?.length) return undefined;
        // sort those contracts in chronological order of being marked primary, so we can get the most recent one
        const sortedSelectedContracts = contracts?.sort((a: PatientContract, b: PatientContract) => {
            if (moment(a?.lastMarkedPrimaryDate) < moment(b?.lastMarkedPrimaryDate)) {
                return 1;
            } else if (moment(a?.lastMarkedPrimaryDate) > moment(b?.lastMarkedPrimaryDate)) {
                return -1;
            } else {
                return 0;
            }
        });

        if (sortedSelectedContracts && sortedSelectedContracts?.length > 0) {
            return sortedSelectedContracts?.[0];
        }

        //something is wrong if we get here
        return undefined;

        }
    );

    const isSplitPaymentOptionFixed = computed<boolean>(
      () => isSplit.value && (selectedPatientContract?.value?.patientSplitTreatmentPlanPortion?.status === 0 || selectedPatientContract?.value?.patientSplitTreatmentPlanPortion?.status === 1)
    );

    const firstSignedContract = computed<ISignedContract | null | undefined>(() => store.getters[PatientContractsGetters.FIRST_SIGNED_CONTRACT]);
    const secondSignedContract = computed<ISignedContract | null | undefined>(() => store.getters[PatientContractsGetters.SECOND_SIGNED_CONTRACT]);
    const signedContract = computed<ISignedContract | null | undefined>(
        () => {
            const signedContract = store.getters[PatientContractsGetters.FIRST_SIGNED_CONTRACT];
            if (!!signedContract && (!isSplit.value || signedContract?.patientContractId === contractId?.value || signedContract?.patientResponsiblePartyId === responsiblePartyId?.value)) {
                return signedContract;
            }
            const secondSignedContract = store.getters[PatientContractsGetters.SECOND_SIGNED_CONTRACT];
            if (!!secondSignedContract && (secondSignedContract?.patientContractId === contractId?.value || secondSignedContract?.patientResponsiblePartyId === responsiblePartyId?.value || !isSplit.value)) {
                return secondSignedContract;
            }
            return undefined;
        }
    );

  const areResponsiblePartyContractsFixed = computed<boolean>(
    () => !!signedContract.value
  );

  const areContractsFixed = computed<boolean>(
    () => (!!firstSignedContract.value || !!secondSignedContract.value)
  );

  //duplicate of useSelectedPatientContractsInfo() selectedPatientContract
  const selectedPatientContract = computed<PatientContract | undefined>(
    () => {
      const contracts =cloneDeep(patientContracts?.value);
      if (!Array.isArray(contracts) || !contracts?.length) {
        return undefined;
      }
      const contract = getContractLastMarkedAsPrimary(contracts);
      return getContractLastMarkedAsPrimary(contracts);
    }
  );
    return {
        patientContracts,
        patientContractsLoading,
        ESIGNATURE_DATA,
        areContractsFixed,
        areResponsiblePartyContractsFixed,
        isSplit,
        isSplitFixed,
        arePaymentOptionsFixed,
        isTreatmentTypeFixed,
        signedContract,
        firstSignedContract,
        secondSignedContract,
        splitFixedTreatmentTypeId,
        primaryContract,
        isSplitPaymentOptionFixed
    };
}
