/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {TreatmentCoordinatorsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType, NEW_ITEM_ID} from "../../constants";
import {cloneDeep} from "lodash";
import {TreatmentCoordinator} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface TreatmentCoordinatorsState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    treatmentCoordinators?: TreatmentCoordinator[];
}

export enum TreatmentCoordinatorsActions {
    LOAD_TREATMENT_COORDINATORS = "TreatmentCoordinatorsModule/loadTreatmentCoordinators",
    UPDATE_TREATMENT_COORDINATOR = "TreatmentCoordinatorsModule/updateTreatmentCoordinator",
    DELETE_TREATMENT_COORDINATOR = "TreatmentCoordinatorsModule/deleteTreatmentCoordinator",
    CREATE_TREATMENT_COORDINATOR = "TreatmentCoordinatorsModule/createTreatmentCoordinator",
    RESET = "TreatmentCoordinatorsModule/reset",
}

export enum TreatmentCoordinatorsMutations {
    SET_LOADING = "TreatmentCoordinatorsModule/setLoading",
    SET_TREATMENT_COORDINATORS = "TreatmentCoordinatorsModule/setTreatmentCoordinators",
    RESET = "TreatmentCoordinatorsModule/reset",
}

export enum TreatmentCoordinatorsGetters {
    LOADING = "TreatmentCoordinatorsModule/loading",
    SAVING = "TreatmentCoordinatorsModule/saving",
    DELETING = "TreatmentCoordinatorsModule/deleting",
    TREATMENT_COORDINATORS = "TreatmentCoordinatorsModule/treatmentCoordinators",
    TREATMENT_COORDINATORS_SELECT = "TreatmentCoordinatorsModule/treatmentCoordinatorsSelect",
    TREATMENT_COORDINATORS_WITH_EMPTY_SELECT = "TreatmentCoordinatorsModule/treatmentCoordinatorsWithEmptySelect",
    HAS_TREATMENT_COORDINATORS = "TreatmentCoordinatorsModule/hasTreatmentCoordinators",
}

const initialState = () => ({
    loading: false, //related to caching why it isn't true
    saving: false,
    deleting: false,
    treatmentCoordinators: undefined,
});

export const TreatmentCoordinatorsModule: Module<TreatmentCoordinatorsState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadTreatmentCoordinators(
            {commit, dispatch},
            payload: {
                service: TreatmentCoordinatorsService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const treatmentCoordinators: TreatmentCoordinator[] = await service.getCoordinators();
                commit("setTreatmentCoordinators", treatmentCoordinators);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async updateTreatmentCoordinator(
            {commit, dispatch, getters},
            payload: {
                id: string;
                values: TreatmentCoordinator;
                service: TreatmentCoordinatorsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id, values} = payload;
                const response = await service.updateCoordinator(id, values);

                const coordinators = cloneDeep(getters.treatmentCoordinators);
                coordinators.forEach((item: TreatmentCoordinator, index: number) => {
                    if (item.treatmentCoordinatorId === id) {
                        coordinators[index] = values;
                    }
                });
                commit("setTreatmentCoordinators", coordinators);
                if (response === 204) {
                    success = true;
                }
                await SuccessNotification(dispatch, `Updated treatment coordinator ${values.fullName}!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async deleteTreatmentCoordinator(
            {commit, dispatch, getters},
            payload: {
                id: string;
                values: TreatmentCoordinator;
                service: TreatmentCoordinatorsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, id, values} = payload;
                const response = await service.updateCoordinator(id, values, id);
                if (response === 204) {
                    const coordinators = cloneDeep(getters.treatmentCoordinators).filter(
                        (coordinator: TreatmentCoordinator) =>
                            coordinator.treatmentCoordinatorId !== id
                    );
                    commit("setTreatmentCoordinators", coordinators);
                    success = await SuccessNotification(dispatch, `Deleted treatment coordinator ${values.fullName}`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async createTreatmentCoordinator(
            {commit, dispatch, getters},
            payload: {
                values: TreatmentCoordinator;
                service: TreatmentCoordinatorsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;
                const newCoordinator = await service.createCoordinator(values);
                const coordinators = cloneDeep(getters.treatmentCoordinators);
                coordinators.push(newCoordinator);
                commit("setTreatmentCoordinators", coordinators);
                success = await SuccessNotification(dispatch, `Created treatment coordinator ${newCoordinator.fullName}`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: TreatmentCoordinatorsState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setTreatmentCoordinators(
            state: TreatmentCoordinatorsState,
            treatmentCoordinators?: TreatmentCoordinator[]
        ) {
            Vue.set(state, "treatmentCoordinators", treatmentCoordinators);
        },
        reset: function (state: TreatmentCoordinatorsState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('TreatmentCoordinatorsState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        treatmentCoordinators: (state) => state.treatmentCoordinators,
        hasTreatmentCoordinators: (state) => !!state.treatmentCoordinators?.filter((tc: TreatmentCoordinator) => !tc?.isObsolete)?.length,
        treatmentCoordinatorsSelect: (state) => {
            return state.treatmentCoordinators?.map((item) => {
                if (!item.isObsolete) {
                    return {text: item.fullName, value: item.treatmentCoordinatorId, disabled: false};
                }
            });
        },
        treatmentCoordinatorsWithEmptySelect: (state) => {
            const treatmentCoordinators = (state.treatmentCoordinators?.map((item) => {
                if (!item.isObsolete) {
                    return {text: item.fullName, value: item.treatmentCoordinatorId, disabled: false};
                }
            }));
            if (treatmentCoordinators?.length) {
                treatmentCoordinators?.push({text: "-Not Yet Chosen-", value: NEW_ITEM_ID, disabled: false});
            }
            return treatmentCoordinators;
        },
    },
};
