import {AxiosInstance} from "axios";
import {PatientVisit} from "../../store";
import {PatientVisitsService as IPatientVisitsService} from "../interfaces";

export class PatientVisitsService
  implements IPatientVisitsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPatientVisitWithOfficeDetails(patientTreatmentPlanId: string): Promise<PatientVisit> {
    const response = await this.http.get(
      `${this.apiUrl}/api/PatientVisits/PatientVisitWithOfficeDetails`,
        {
          params: {id: patientTreatmentPlanId},
        }
    );
    return response.data;
  }
}
