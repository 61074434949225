import {RootState} from "../store";
import {Store} from "vuex";
import {inject, provide} from "vue";

export const storeSymbol = Symbol();

export function provideStore(store: Store<RootState>) {
  return provide(storeSymbol, store);
}

export function useStore() {
  const store = inject<Store<RootState>>(storeSymbol);
  if (!store) {
    throw Error("No Store to Inject!!");
  }
  return store;
}
