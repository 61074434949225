import {inject, provide} from "vue";
import {RegistrationService} from "../services/interfaces";

export const registrationServiceSymbol = Symbol();

export function provideRegistrationService(service: RegistrationService) {
  return provide(registrationServiceSymbol, service);
}

export function useRegistrationService() {
  const registrationService = inject<RegistrationService>(
    registrationServiceSymbol
  );
  if (!registrationService) {
    throw Error("No Additional Charges Service to Inject!!");
  }
  return registrationService;
}
