import {AutoPaymentsService as IAutoPaymentsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {AutoPaymentForm} from "../../store/interfaces";

export class AutoPaymentsService implements IAutoPaymentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getAutoPayments(): Promise<AutoPaymentForm> {
    const response = await this.http.get(
      `${this.apiUrl}/api/AutoPaymentForms
      `
    );
    for (const [key, value] of Object.entries(response.data)) {
      if (key !== "clientId" && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      }
    }
    return response.data;
  }

  async updateAutoPayments(
    clientId: string,
    body: AutoPaymentForm
  ): Promise<void> {
    const data = {...body} as any;
    for (const [key, value] of Object.entries(data)) {
      if (key !== "clientId" && typeof value === "string") {
        data[key as keyof AutoPaymentForm] = encodeURIComponent(value);
      }
    }
    await this.http.put(`${this.apiUrl}/api/AutoPaymentForms`, data, {
      params: { id: clientId },
    });
  }
}
