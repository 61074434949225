import Vue from 'vue'

// For full framework
import Vuetify from 'vuetify/lib'
// For a-la-carte components - https://vuetifyjs.com/en/customization/a-la-carte/
// import Vuetify from 'vuetify/lib'
import * as directives from 'vuetify/lib/directives'
import config from '../../main/src/configs'
import {ICONS_FONTAWESOME} from "./fontawesome";
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from "@fortawesome/vue-fontawesome";

/**
 * Vuetify Plugin
 * Main components library
 *
 * https://vuetifyjs.com/
 *
 */
Vue.use(Vuetify, {
  directives
})

//define components to use globally with fontawesome
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

export default new Vuetify({
  rtl: config.theme.isRTL,
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  },
  icons: {
    iconfont: 'faSvg',
    values:   ICONS_FONTAWESOME,
  },
})
