import colors from "vuetify/lib/util/colors";

const palette = {
  violeta: {
    background: colors.shades.white,
    success: colors.teal.accent4,
    surface: colors.blueGrey.lighten5,
    primary: colors.blue.base, // primary main
    //TODO primary dark
    primarylight: colors.blue.darken4, // primary dark
    //TODO secondary dark
    secondarylight: colors.blueGrey.lighten4, // secondary main
    accent: colors.orange.base,
    secondarydark: colors.lightBlue.darken4, // secondary dark
    anchor: colors.blue.base, // link
    error: colors.deepOrange.darken4,
    warning: colors.yellow.darken4,
    info: colors.blueGrey.lighten3,
  },
};

const theme = {
  ...palette.violeta,
};

export default {
  // global theme for the app
  globalTheme: "light", // light | dark

  // side menu theme, use global theme or custom
  menuTheme: "global", // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: "global", // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  //Todo link these to palette?
  dark: {
    background: "#05090c",
    surface: "#111b27",
    primary: "#0096c7",
    secondary: "#829099",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  },

  // light theme colors
  light: {
    background: theme.background,
    surface: theme.surface,
    primary: theme.primarylight,
    secondary: theme.secondarylight,
    accent: theme.accent,
    error: theme.error,
    info: theme.info,
    success: theme.success,
    warning: theme.warning,
  },
};
