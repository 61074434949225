import {ConditionalTextService as IConditionalTextService} from "../interfaces";
import {AxiosInstance} from "axios";
import {ConditionalText} from "../../store";
import {cloneDeep} from "lodash";

export class ConditionalTextService implements IConditionalTextService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getConditionalText(): Promise<ConditionalText[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/ConditionalTexts/Detail`
    );
    response.data.forEach((item: ConditionalText, index: number) => {
      if (item.verbiage.isNotNullOrWhitespace()) {
        response.data[index].verbiage = decodeURIComponent(item.verbiage);
      }
    });
    return response.data;
  }

  async createConditionalText(
    conditionalText: ConditionalText
  ): Promise<ConditionalText> {
    const data = cloneDeep(conditionalText);
    if (data.verbiage) {
      data.verbiage = encodeURIComponent(data.verbiage);
    }
    const response = await this.http.post(
      `${this.apiUrl}/api/ConditionalTexts`,
      data
    );

    if (response.data.verbiage) {
      response.data.verbiage = decodeURIComponent(response.data.verbiage);
    }

    return response.data;
  }

  async updateConditionalText(
    id: string,
    conditionalText: ConditionalText
  ): Promise<ConditionalText> {
    const data = cloneDeep(conditionalText) as any;
    if (data.verbiage.isNotNullOrWhitespace()) {
      data.verbiage = encodeURIComponent(data.verbiage);
    }

    const response = await this.http.put(
      `${this.apiUrl}/api/ConditionalTexts`,
      data,
      {
        params: { id },
      }
    );
    if (response.data.verbiage.isNotNullOrWhitespace()) {
      response.data.verbiage = decodeURIComponent(response.data.verbiage);
    }
    return response.data;
  }

  async deleteConditionalText(id: string): Promise<ConditionalText> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/ConditionalTexts`,
      {
        params: {
          id,
        },
      }
    );
    return response.data;
  }
}
