import {PpoOptionService} from "../services/interfaces";
import {inject, provide} from "vue";

export const ppoOptionsServiceSymbol = Symbol();

export function providePpoOptionService(service: PpoOptionService) {
  return provide(ppoOptionsServiceSymbol, service);
}

export function usePpoOptionService() {
  const ppoOptionService = inject<PpoOptionService>(ppoOptionsServiceSymbol);
  if (!ppoOptionService) {
    throw Error("No PPO Option Service to Inject!!");
  }
  return ppoOptionService;
}
