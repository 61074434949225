<template>
  <v-menu offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon class="elevation-2" v-on="on">
        <v-badge :color="!!currentUser ? 'success' : 'error'" dot bordered offset-x="10" offset-y="10">
          <avatar v-if="!!currentUser && !!name" :username="name" :size="40"></avatar>
          <v-avatar v-else size="40">
            <v-icon>
              $user-circle
            </v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
          v-for="(item, index) in menu"
          :key="index"
          :to="item.link"
          :exact="item.exact"
          :disabled="item.disabled"
          link
      >
        <v-list-item-icon class="align-center">
          <v-icon small :class="{ 'grey--text': item.disabled }">{{
              item.icon
            }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" v-if="(menu || []).length"></v-divider>

      <v-list-item @click="signOut">
        <v-list-item-icon class="align-center">
          <v-icon small>$sign-out</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import config from "../../configs";
import {computed, defineComponent} from "vue";
import {SessionActions, SessionGetters} from "@shared/store/session";
import {useStore} from "@shared/providers/use-store";
import {useAccountService} from "@shared/providers/use-account-service";
import Avatar from "vue-avatar";
import {User} from "@shared/store";
import {useRouteComponents} from "@main/composables/helpers";
import {APP_TYPES} from "@shared/store/constants";
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
export default defineComponent({
  name: "ToolbarUser",
  components: {
    Avatar
  },
  setup() {
    const menu = process.env.VUE_APP_TYPE === APP_TYPES.PORTAL ? [] as any[] : config.toolbar.user;
    const store = useStore();
    const service = useAccountService();
    const currentUser = computed<User>(
        () => store.getters[SessionGetters.USER]
    );
    const {navigateToName, routePath} = useRouteComponents();
    const name = computed<string>(
        () => `${currentUser?.value?.firstName} ${currentUser?.value?.lastName}`
    );

    async function signOut() {
      if (process.env.VUE_APP_TYPE === APP_TYPES.MAIN && routePath.value !== '/' && routePath.value !== '/dashboard') await navigateToName("dashboard");
      await store.dispatch(SessionActions.SIGN_OUT, {
        service: service,
      });
    }

    return {
      menu,
      signOut,
      currentUser,
      name,
      routePath,
    };
  },
});
</script>

