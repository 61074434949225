import {InputFieldsService} from "../services/interfaces";
import {inject, provide} from "vue";

export const inputFieldsServiceSymbol = Symbol();

export function provideInputFieldsService(service: InputFieldsService) {
  return provide(inputFieldsServiceSymbol, service);
}

export function useInputFieldsService() {
  const inputFieldsService = inject<InputFieldsService>(inputFieldsServiceSymbol);
  if (!inputFieldsService) {
    throw Error("No Input Fields Service to Inject!!");
  }
  return inputFieldsService;
}