import {AxiosInstance} from "axios";
import {PatientEsignDocumentsService as IPatientEsignDocumentsService} from "../interfaces";
import {PatientEsignDocument} from "../../store/documents/patient-contracts/interfaces";

export class PatientEsignDocumentsService implements IPatientEsignDocumentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPatientEsignDocumentAccessUrl(patientContractId: string): Promise<string> {
    const response = await this.http.get(
        `${this.apiUrl}/api/PatientEsignDocument/Url`,
        { params: { patientContractId: patientContractId } }
    );

    if(Array.isArray(response.data)) {
      return response.data.length ? response.data[0] : "";
    }

    return response.data;
  }

  async deleteEsignature(patientContractId: string): Promise<PatientEsignDocument[]> {
    const response = await this.http.delete(
        `${this.apiUrl}/api/PatientEsignDocument/delete`,
        { params: { patientContractId: patientContractId } }
    );
    return response.data;
  }
}
