import {PatientTreatmentTypesService as IPatientTreatmentTypesService} from "../interfaces";
import {AxiosInstance} from "axios";
import {PatientTreatmentType} from "../../store";

export class PatientTreatmentTypesService
  implements IPatientTreatmentTypesService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPatientTreatmentTypesDetail(patientTreatmentPlanId: string): Promise<PatientTreatmentType[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/PatientTreatmentTypes/Detail`,
        {
          params: {patientTreatmentPlanId: patientTreatmentPlanId},
        }
    );
    return response.data;
  }
}
