import {MaintenanceService as IMaintenanceService} from "./interfaces";
import {AxiosInstance} from "axios";
import {MaintenanceFile} from "@shared/store";

export class MaintenanceService implements IMaintenanceService {
  private readonly url: string = process.env.VUE_APP_MAINTENANCE_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getMaintenanceFile(): Promise<MaintenanceFile | undefined | null> {
    try {
    const response = await this.http.get(
        `${this.url}/down.json`,
    );
      return response.data;
    }
    catch(e) {
      return undefined;
    }
  }
}