import { IAdminUsersService } from "@shared/services/interfaces";
import { AxiosInstance } from "axios";
import { User } from "@shared/store";

export class AdminUsersService implements IAdminUsersService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getClientUsers(): Promise<User[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/admin/ClientUsers`
        );
        return response.data;
    }
}