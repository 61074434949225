const dangerousHtmlTagsMessage =  "Field contains special HTML tags that are not allowed. Ex. script, style";
export function regexNoDangerousHtmlTagsRule(  //this is used in any case we use chevron in the regex
    regEx = new RegExp(/<\s*(img|audio|video|picture|svg|object|map|iframe|embed|script|style|link)[^>]*>/i),
    message = dangerousHtmlTagsMessage
): (value: any) => boolean | string {
  return (value) =>
      (!!value || value === 0 ? !regEx.test(value) : true) || message;
}

export function wysiwygRequiredRule(
  message = "Field is required"
): (value: any) => boolean | string {
  return (value) => (!!value && value !== '<p></p>') || message;
}
export function regexNoDangerousHtmlTags(value: string | number) {
  const regEx = new RegExp(/<\s*(img|audio|video|picture|svg|object|map|iframe|embed|script|style|link)[^>]*>/i);
  return (!!value || value === 0 ? !regEx.test(value?.toString()) : true);
}

export function regexRule( //removed <> characters from regex
  regEx = new RegExp(/^[\w.!@?#""$%&:';()*+,/\-=\[\\\]^_{|}~`‘’—“”\s]+$/), //removed <> characters from regex
  message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed: !@?#\"$%&:';()*+,\\/-=[]^_{|}~`‘’—“”"
): (value: any) => boolean | string {
  return (value) =>
    (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexEsignGenieSafeRule( //removed <> characters from regex
    regEx = new RegExp(/^[\w.!@?#$%&:';()*+,/\-=\[\\\]^_{|}~`‘’—“”\s]+$/), //removed <> due to security issue, double quote characters throw error in esign genie so none of the special characters are allowed
    message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed: !@?#$%&:';()*+,\\/-=[]^_{|}~`‘’—“”"
): (value: any) => boolean | string {
  return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexIncludeChevronRule(
    regEx = new RegExp(/^[\w.!@?#""$%&:';()*+<>,/\-=\[\\\]^_{|}~`‘’—“”\s]+$/),
    message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed: !@?#\"$%&:';()*+,<>\\/-=[]^_{|}~`‘’—“”"
): (value: any) => boolean | string {
  return (value) =>  (!!value || value === 0)
      ? (regexNoDangerousHtmlTags(value)
          ? regEx.test(value) ? true : message
          : dangerousHtmlTagsMessage)
      : true;
}
export function regexExtendedCharactersIncludeChevronRule(
    regEx = new RegExp(/^[\w\s.,\'\-:;<>=/&()+#%$]+$/),
    message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed: .,'-:;=<>/&()+#%$."
): (value: any) => boolean | string {
  return (value) =>  (!!value || value === 0)
                                       ? (regexNoDangerousHtmlTags(value)
                                              ? regEx.test(value) ? true : message
                                              : dangerousHtmlTagsMessage)
                                       : true;
}
export function regexExtendedCharactersRule(
    regEx = new RegExp(/^[\w\s.,\'\-:;=/&()+#%$]+$/), //removed <> characters from regex
    message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed: .,'-:;=/&()+#%$"
): (value: any) => boolean | string {
  return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexNameExtendedRule(
    message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed:  - , . ' &"
): (value: any) => boolean | string {
  const regEx = new RegExp(/^[a-zA-Z0-9 .,&'-]+$/);
  return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexNameRule(
    message = "Field contains invalid symbols - only alphanumeric characters and the following special characters are allowed: . , ' - "
): (value: any) => boolean | string {
  const regEx = new RegExp(/^[a-zA-Z0-9 .,'-]+$/);
    return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexWebsiteUrlRule(
  regEx = new RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  ),
  message = "Invalid website address"
): (value: any) => boolean | string {
  return (value) =>
    (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexRegistrationCustomWebsiteUrlRule(
    regEx = new RegExp(
        /^(((www.)|([a-zA-Z0-9\-]+\.)*)?[a-zA-Z0-9\-]+\.[a-zA-Z\-]+(\/[a-zA-Z0-9#\-]+\/?)*)?$/
    ),
    message = "Invalid format. Please enter like this: MyPracticeRocks.com or www.MyPracticeRocks.com. Do NOT include (http:// or https://) as part of the website address."
): (value: any) => boolean | string {
  return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}
export function regexScheduleRule(
    message = "Schedule can only contain positive whole numbers (1-99) separated by commas"
): (value: any) => boolean | string {
  const regEx = new RegExp(
      /^[1-9][\d]?(\,[1-9][\d]?)*$/
  );
  return (value) =>
      (!!value?.trim() || value === 0 ? regEx.test(value?.trim()) : true) || message;
}
export function regexEmailRule(
  message = "Email must be valid"
): (value: any) => boolean | string {
  const regEx = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return (value) =>
    (!!value?.trim() || value === 0 ? regEx.test(value?.trim()) : true) || message;
}
export function regexEmailValidity(value: string) {
  const regEx = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regEx.test(value?.trim()) || false;
}

export function regexArrayEmailRule(
  message = "Email must be valid"
): (v: any) => boolean | string {
  return (v: any) => {
    if (!v) return true;
    else if (Array.isArray(v) && v.length > 0) {
      const regEx = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      for (let i = 0; i < v.length; i++) {
        if ((!!v[i]?.trim() || v[i] === 0) && !regEx.test(v[i]?.trim())) {
          return "Email must be valid";
        }
      }
      return true;
    } else return true;
  };
}

export function regexPhoneRule(
  message = "Phone number must be 9 digits"
): (value: any) => boolean | string {
  const regEx = new RegExp(/^\(\d{3}\)\s\d{3}-\d{4}$/);
  return (value) =>
    (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexTaxIdRule(
  message = "Tax ID must be 9 digits formatted like this: ##-#######"
): (value: any) => boolean | string {
  const regEx = new RegExp(/^\d{2}-\d{7}$/);
  return (value) =>
    (!!value || value === 0 ? regEx.test(value) : true) || message;
}


export function regexDateRule(
    message = "Date must be formatted like this: MM/DD/YYYY with 10 characters total"
): (value: any) => boolean | string {
  const regEx = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
  return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}


export function regexZipCodeRule(
  message = "Invalid format. Please enter like this: US Zip Code: 12345 or 12345-6789 Canadian Postal Code: A1A 1X1"
): (value: any) => boolean | string {
  const regEx = new RegExp(
    /(^[AaBbCcEeGgHhJjKkLlMmNnPpRrSsTtVvXxYy]\d[AaBbCcEeGgHhJjKkLlMmNnPpRrSsTtVvWwXxYyZz]( )?\d[AaBbCcEeGgHhJjKkLlMmNnPpRrSsTtVvWwXxYyZz]\d$)|^\d{5}(-\d{4})?$/
  );
  return (value) =>
    (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function regexAmountIntegerRule(
  message = "Amount must be an integer"
): (value: any) => boolean | string {
  const regex = new RegExp(/^[\d]*$/);
  return (value) =>
    (!!value || value === 0 ? regex.test(value) : true) || message;
}

export function regexAmountRule(
    message = "Amount can have a maximum of 2 decimal places."
): (value: any) => boolean | string {
  const regEx = new RegExp(/^[\d,]*(\.[\d]{1,2})?$/);
  return (value) =>
      (!!value || value === 0 ? regEx.test(value) : true) || message;
}

export function adminPinRule(): (value: any) => boolean | string {
  const adminPin = process.env.VUE_APP_ADMIN_PIN;
  return (value) =>
    (!!value || value === 0 || value?.length < 4 ? adminPin === value : true) ||
    "Incorrect PIN! This process is for admins only, please talk to Mandi for permission.";
}

export function passwordStandardCharRule(): (value: any) => boolean | string {
  const regEx = new RegExp(/^[\w.!@?#""$%&:';()*+,/\-=[\\\]^_{|}<>~`\s]+$/);
  return (value) =>
    (!!value || value === 0 ? regEx.test(value) : true) ||
    "Password must only contain standard characters";
}
export function passwordThreeOfFour(): (value: any) => boolean | string {
  const regex = new RegExp(
    /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])|(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9])).*$/
  );
  return (value) =>
    (!!value || value === 0 ? regex.test(value) : true) ||
    "Must include at least 3 of the 4 following elements: one uppercase letter, one lowercase letter, one number, one special character.  ";
}
export function passwordTwoIdenticalCharacters(): (
  value: any
) => boolean | string {
  const regEx = new RegExp(/^.*(.)\1{2,}.*$/);
  return (value) =>
    (!!value || value === 0 ? !regEx.test(value) : true) ||
    "Must not include more than 2 identical characters. (Example: 111 or aaa)";
}

export function passwordTwoConsecutiveCharacters(): (
  value: any
) => boolean | string {
  const regEx = new RegExp(/^(.*123|.*456|.*789|.*[Aa][Bb][Cc]).*$/);
  return (value) =>
    (!!value || value === 0 ? !regEx.test(value) : true) ||
    "Must not include more than 2 consecutive characters. (Example: 123 or abc)";
}
