/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {ConditionalText, RootState} from "../../index";
import Vue from "vue";
import {ConditionalTextService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {ConditionalTextTypes, LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface ConditionalTextState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  conditionalTexts?: ConditionalText[];
}

export enum ConditionalTextActions {
  LOAD_CONDITIONAL_TEXTS = "ConditionalTextModule/loadConditionalTexts",
  UPDATE_CONDITIONAL_TEXTS = "ConditionalTextModule/updateConditionalTexts",
  CREATE_CONDITIONAL_TEXTS = "ConditionalTextModule/createConditionalTexts",
  DELETE_CONDITIONAL_TEXTS = "ConditionalTextModule/deleteConditionalTexts",
  RESET = "ConditionalTextModule/reset",
}

export enum ConditionalTextMutations {
  SET_LOADING = "ConditionalTextModule/setLoading",
  SET_CONDITIONAL_TEXTS = "ConditionalTextModule/setConditionalTexts",
  RESET = "ConditionalTextModule/reset",
}

export enum ConditionalTextGetters {
  LOADING = "ConditionalTextModule/loading",
  SAVING = "ConditionalTextModule/saving",
  DELETING = "ConditionalTextModule/deleting",
  CONDITIONAL_TEXTS = "ConditionalTextModule/conditionalTexts",
  CONTRACT_CONDITIONAL_TEXTS = "ConditionalTextModule/contractConditionalTexts",
  PAYMENT_CONDITIONAL_TEXTS = "ConditionalTextModule/paymentConditionalTexts",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  conditionalTexts: undefined,
});

export const ConditionalTextModule: Module<ConditionalTextState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    //TODO might be able to remove the refresh at some point leaving it as an example
    loadConditionalTexts: async function (
      { commit, dispatch, getters },
      payload: {
        service: ConditionalTextService;
        refresh: {
          type: Boolean;
          default: true;
        };
      }
    ) {
      try {
        commit("setLoading", true);
        const { service, refresh } = payload;

        const conditionalTexts: ConditionalText[] =
          !refresh &&
          getters.conditionalTexts &&
          getters.conditionalTexts.length
            ? cloneDeep(getters.conditionalTexts)
            : await service.getConditionalText();

        commit("setConditionalTexts", conditionalTexts);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateConditionalTexts(
      { commit, dispatch, getters },
      payload: {
        id: string;
        conditionalText: ConditionalText;
        service: ConditionalTextService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, conditionalText } = payload;
        const updatedConditionalText = await service.updateConditionalText(
          id,
          conditionalText
        );
        const conditionalTexts = cloneDeep(getters.conditionalTexts);
        conditionalTexts.forEach((text: ConditionalText, index: number) => {
          if (text.conditionalTextId === id) {
            //TODO change to filter to chance specific one instead of all
            conditionalTexts[index] = updatedConditionalText;
          }
        });
        commit("setConditionalTexts", conditionalTexts);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async createConditionalTexts(
      { commit, dispatch, getters },
      payload: {
        conditionalText: ConditionalText;
        service: ConditionalTextService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, conditionalText } = payload;
        const newText = await service.createConditionalText(conditionalText);
        const updateConditionalText = cloneDeep(getters.conditionalTexts);
        updateConditionalText.push(newText);
        commit("setConditionalTexts", updateConditionalText);
        success = await SuccessNotification(dispatch, "Saved!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteConditionalTexts(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: ConditionalTextService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteConditionalText(id);
        if (deleted.conditionalTextId === id) {
          const conditionalTexts = cloneDeep(getters.conditionalTexts).filter(
            (text: ConditionalText) => text.conditionalTextId !== id
          );
          commit("setConditionalTexts", conditionalTexts);
          success = await SuccessNotification(dispatch, "Deleted!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.DELETING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: ConditionalTextState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setConditionalTexts(
      state: ConditionalTextState,
      conditionalTexts?: ConditionalText[]
    ) {
      Vue.set(state, "conditionalTexts", conditionalTexts);
    },
    reset: function (state: ConditionalTextState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('ConditionalTextState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    conditionalTexts: (state) => state.conditionalTexts,
    contractConditionalTexts: (state) =>
      state.conditionalTexts
        ?.filter((text) => text.documentId === ConditionalTextTypes.CONTRACT)
        .sort((a: ConditionalText, b: ConditionalText) => {
          return (a.fieldId as number) - (b.fieldId as number);
        }),

    paymentConditionalTexts: (state) =>
      state.conditionalTexts
        ?.filter((text) => text.documentId === ConditionalTextTypes.PAYMENT)
        .sort((a: ConditionalText, b: ConditionalText) => {
          return (a.fieldId as number) - (b.fieldId as number);
        }),
  },
};
