/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {ClientConfiguration, RootState} from "../../index";
import Vue from "vue";
import {ClientConfigurationSettingsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {ClientConfigurationSettings} from "../../interfaces";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface ClientConfigurationSettingsState {
  loading: boolean;
  clientConfigurationSettings?: ClientConfigurationSettings; //includes additional information like role of user (generally unneeded)
  clientConfigurations?: ClientConfiguration;
  lastModifiedDate?: string;
}

export enum ClientConfigurationSettingsActions {
  LOAD_CLIENT_CONFIGURATION_SETTINGS = "ClientConfigurationSettingsModule/loadSettings",
  RESET = "ClientConfigurationSettingsModule/reset",
}

export enum ClientConfigurationSettingsMutations {
  SET_LOADING = "ClientConfigurationSettingsModule/setLoading",
  SET_CLIENT_CONFIGURATION_SETTINGS = "ClientConfigurationSettingsModule/setSettings",
  SET_LAST_MODIFIED_DATE = "ClientConfigurationSettingsModule/setLastModifiedDate",
  RESET = "ClientConfigurationSettingsModule/reset",
}

export enum ClientConfigurationSettingsGetters {
  LOADING = "ClientConfigurationSettingsModule/loading",
  CLIENT_CONFIGURATION = "ClientConfigurationSettingsModule/clientConfigurations",
  CLIENT_CONFIGURATION_SETTINGS = "ClientConfigurationSettingsModule/clientConfigurationSettings", //includes additional information like role of user (generally unneeded)
  LAST_MODIFIED_DATE = "ClientConfigurationSettingsModule/lastModifiedDate",
}

const initialState = () => ({
  loading: false,
  clientConfigurationSettings: undefined,
  clientConfigurations: undefined,
  lastModifiedDate: undefined,
});

export const ClientConfigurationSettingsModule: Module<
  ClientConfigurationSettingsState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    loadSettings: async function (
      { commit, dispatch },
      payload: { service: ClientConfigurationSettingsService }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const settings: ClientConfigurationSettings = await service.getConfigSettings();
        commit("setSettings", settings);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    reset({ commit }) {
      commit("reset");
    },

  },
  mutations: {
    setLoading(
      state: ClientConfigurationSettingsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setSettings(
      state: ClientConfigurationSettingsState,
      settings?: ClientConfigurationSettings
    ) {
      Vue.set(state, "clientConfigurationSettings", settings);
      Vue.set(state, "clientConfigurations", settings?.clientConfiguration);
      if(!state.lastModifiedDate) {
        Vue.set(state, "lastModifiedDate", settings?.clientConfiguration?.lastModifiedDate);
      }
    },
    setLastModifiedDate(
        state: ClientConfigurationSettingsState,
        dateString?: string
    ) {
      Vue.set(state, "lastModifiedDate", dateString);
    },
    reset: function (state: ClientConfigurationSettingsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('ClientConfigurationSettingsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    clientConfigurationSettings: (state) => state.clientConfigurationSettings,
    clientConfigurations: (state) => state?.clientConfigurations,
    lastModifiedDate: (state) => state?.lastModifiedDate,
  },
};
