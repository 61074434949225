/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState, TreatmentClass} from "../../index";
import Vue from "vue";
import {TreatmentClassesService, TreatmentTypesService,} from "../../../services/interfaces";
import {TreatmentComplexity, TreatmentType,} from "../../interfaces";
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
// DO NOT MOVE THIS OR ELSE
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface TreatmentClassesState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  treatmentClasses?: TreatmentClass[];
  treatmentClassesComplexities?: TreatmentClass[];
  treatmentClassesTypes?: TreatmentClass[];
  treatmentClassesConfigFees?: TreatmentClass[];
  treatmentClassesWithLinked?: TreatmentClass[];
}

export enum TreatmentClassesActions {
  LOAD_TREATMENT_CLASSES = "TreatmentClassesModule/loadTreatmentClasses",
  LOAD_TREATMENT_CLASSES_COMPLEXITIES = "TreatmentClassesModule/loadTreatmentClassesComplexities",
  LOAD_TREATMENT_CLASSES_TYPES = "TreatmentClassesModule/loadTreatmentClassesTypes",
  LOAD_TREATMENT_CLASSES_CONFIG_FEES = "TreatmentClassesModule/loadTreatmentClassesConfigFees",
  LOAD_TREATMENT_CLASSES_WITH_LINKED = "TreatmentClassesModule/loadTreatmentClassesWithLinked",
  UPDATE_TREATMENT_CLASS_COMPLEXITIES = "TreatmentClassesModule/updateTreatmentClassComplexities",
  UPDATE_TREATMENT_CLASS_TYPE = "TreatmentClassesModule/updateTreatmentClassType",
  DELETE_TREATMENT_CLASS = "TreatmentClassesModule/deleteTreatmentClass",
  CREATE_TREATMENT_CLASS = "TreatmentClassesModule/createTreatmentClass",
  UPDATE_TREATMENT_FEE_BY_COMPLEXITIES = "TreatmentClassesModule/updateTreatmentFeeByComplexities",
  RESET = "TreatmentClassesModule/reset",
}

export enum TreatmentClassesMutations {
  SET_LOADING = "AutoPaymentsModule/setLoading",
  SET_TREATMENT_CLASSES = "TreatmentClassesModule/setTreatmentClasses",
  SET_TREATMENT_CLASSES_WITH_LINKED = "TreatmentClassesModule/setTreatmentClassesWithLinked",
  SET_TREATMENT_CLASSES_COMPLEXITIES = "TreatmentClassesModule/setTreatmentClassesComplexities",
  SET_TREATMENT_CLASSES_CONFIG_FEES = "TreatmentClassesModule/setTreatmentClassesConfigFees",
  SET_TREATMENT_CLASSES_TYPES = "TreatmentClassesModule/setTreatmentClassesTypes",
  RESET = "TreatmentClassesModule/reset",
}

export enum TreatmentClassesGetters {
  LOADING = "TreatmentClassesModule/loading",
  SAVING = "TreatmentClassesModule/saving",
  DELETING = "TreatmentClassesModule/deleting",
  TREATMENT_CLASSES = "TreatmentClassesModule/treatmentClasses",
  TREATMENT_CLASSES_SELECT = "TreatmentClassesModule/treatmentClassesSelect",
  TREATMENT_CLASSES_COMPLEXITIES = "TreatmentClassesModule/treatmentClassesComplexities",
  TREATMENT_CLASSES_CONFIG_FEES = "TreatmentClassesModule/treatmentClassesConfigFees",
  HAS_TREATMENT_CLASSES = "TreatmentClassesModule/hasTreatmentClasses",
  TREATMENT_CLASSES_TYPES = "TreatmentClassesModule/treatmentClassesTypes",
  TREATMENT_CLASSES_WITH_LINKED = "TreatmentClassesModule/treatmentClassesWithLinked",
  TREATMENT_CLASSES_WITH_LINKED_SELECT = "TreatmentClassesModule/treatmentClassesWithLinkedSelect",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  treatmentClasses: undefined,
  treatmentClassesComplexities: undefined,
  treatmentClassesTypes: undefined,
  treatmentClassesConfigFees: undefined,
});

export const TreatmentClassesModule: Module<
  TreatmentClassesState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadTreatmentClasses(
      { commit, dispatch },
      payload: {
        service: TreatmentClassesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const treatmentClasses: TreatmentClass[] = await service.getTreatmentClasses();
        commit("setTreatmentClasses", treatmentClasses);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async loadTreatmentClassesWithLinked(
      { commit, dispatch },
      payload: {
        service: TreatmentClassesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const treatmentClasses: TreatmentClass[] = await service.getTreatmentClassesWithLinked();
        commit("setTreatmentClassesWithLinked", treatmentClasses);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async loadTreatmentClassesComplexities(
      { commit, dispatch },
      payload: {
        service: TreatmentClassesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const treatmentClasses: TreatmentClass[] = await service.getTreatmentClassesWithComplexities();
        commit("setTreatmentClassesComplexities", treatmentClasses);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async loadTreatmentClassesTypes(
      { commit, dispatch },
      payload: {
        service: TreatmentClassesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const treatmentClasses: TreatmentClass[] = await service.getTreatmentClassesWithTreatmentTypes();
        commit("setTreatmentClassesTypes", treatmentClasses);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async loadTreatmentClassesConfigFees(
      { commit, dispatch },
      payload: {
        service: TreatmentClassesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const treatmentClasses: TreatmentClass[] = await service.getTreatmentClassesConfigFees();
        commit("setTreatmentClassesConfigFees", treatmentClasses);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateTreatmentClassComplexities(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: TreatmentClass;
        service: TreatmentClassesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;

        //update rank
        values.treatmentComplexities?.map(
          (complexity: TreatmentComplexity, index: number) =>
            (complexity.rank = index + 1)
        );

        const response = await service.updateTreatmentClassComplexities(
          id,
          values
        );
        if (response.treatmentClassId) {
          const treatmentClasses = cloneDeep(
            getters.treatmentClassesComplexities
          );
          treatmentClasses.forEach((item: TreatmentClass, index: number) => {
            if (item.treatmentClassId === id) {
              treatmentClasses[index] = response;
            }
          });
          commit("setTreatmentClassesComplexities", treatmentClasses);
          success = await SuccessNotification(dispatch, `Updated Treatment Class Complexities!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateTreatmentClassType(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: TreatmentClass;
        service: TreatmentClassesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;
        const response = await service.updateTreatmentClassWithType(id, values);
        if (response === 204) {
          const treatmentClasses = cloneDeep(getters.treatmentClassesTypes);
          treatmentClasses.forEach((item: TreatmentClass, index: number) => {
            delete treatmentClasses[index].links;
            if (item.treatmentClassId === id) {
              treatmentClasses[index] = values;
            }
          });
          commit("setTreatmentClassesTypes", treatmentClasses);
          success = await SuccessNotification(dispatch, `Updated Treatment Class ${values.treatmentClassName}!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateTreatmentFeeByComplexities(
      { commit, dispatch, getters },
      payload: {
        treatmentTypeId: string;
        treatmentClassId: string;
        values: TreatmentType;
        service: TreatmentTypesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, treatmentClassId, treatmentTypeId, values } = payload;
        const response = await service.updateTreatmentFeeByComplexities(
          treatmentTypeId,
          values
        );
        if (!response || !(response.treatmentTypeId || []).length) {
          throw Error("Treatment fees failed to return a response!");
        }

        const treatmentClasses = cloneDeep(getters.treatmentClassesConfigFees);
        treatmentClasses.forEach(
          (txClass: TreatmentClass, txClassIndex: number) => {
            if (txClass.treatmentClassId === treatmentClassId) {
              const txTypeIndex = treatmentClasses[
                txClassIndex
              ].treatmentTypes.findIndex(
                (txType: TreatmentType) =>
                  txType.treatmentTypeId === treatmentTypeId
              );

              if (txTypeIndex < 0) {
                throw Error(
                  "Treatment fee updates could not be found, something went wrong!"
                );
              }

              treatmentClasses[txClassIndex].treatmentTypes[
                txTypeIndex
              ] = cloneDeep(response);
              commit("setTreatmentClassesConfigFees", treatmentClasses);
              success = true;
            }
          }
        );
        await SuccessNotification(dispatch, `Updated treatment fees!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteTreatmentClass(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: TreatmentClassesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteTreatmentClass(id);
        if (deleted.treatmentClassId === id) {
          const treatmentClasses = cloneDeep(
            getters.treatmentClassesTypes
          ).filter(
            (treatment: TreatmentClass) =>
              treatment.treatmentClassId !== deleted.treatmentClassId
          );
          commit("setTreatmentClassesTypes", treatmentClasses);
          success = await SuccessNotification(dispatch, `Deleted Treatment Type ${deleted?.treatmentClassName}!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async createTreatmentClass(
      { commit, dispatch, getters },
      payload: {
        values: TreatmentClass;
        service: TreatmentClassesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newTreatmentClass = await service.createTreatmentClass(values);
        const treatmentClasses = cloneDeep(getters.treatmentClassesTypes);
        treatmentClasses.push(newTreatmentClass);
        commit("setTreatmentClassesTypes", treatmentClasses);
        success = await SuccessNotification(dispatch, `Created Treatment Class ${newTreatmentClass?.treatmentClassName}!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: TreatmentClassesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setTreatmentClasses(
      state: TreatmentClassesState,
      treatmentClasses?: TreatmentClass[]
    ) {
      Vue.set(state, "treatmentClasses", treatmentClasses);
    },
    setTreatmentClassesWithLinked(
      state: TreatmentClassesState,
      treatmentClasses?: TreatmentClass[]
    ) {
      treatmentClasses?.sort((a: TreatmentClass, b: TreatmentClass) => {
        if (a.treatmentClassName < b.treatmentClassName) {
          return -1;
        }
        if (a.treatmentClassName > b.treatmentClassName) {
          return 1;
        }
        return 0;
      });
      Vue.set(state, "treatmentClassesWithLinked", treatmentClasses);
    },
    setTreatmentClassesComplexities(
      state: TreatmentClassesState,
      treatmentClasses?: TreatmentClass[]
    ) {
      treatmentClasses?.forEach(
        (treatmentClass: TreatmentClass, index: number) => {
          treatmentClasses[
            index
          ].treatmentComplexities = treatmentClass.treatmentComplexities?.sort(
            (a: TreatmentComplexity, b: TreatmentComplexity) => {
              return a.rank - b.rank;
            }
          );
          //update rank in case it came in correctly since this is a key
          treatmentClasses[index].treatmentComplexities?.map(
            (complexity: TreatmentComplexity, index: number) =>
              (complexity.rank = index + 1)
          );
        }
      );
      treatmentClasses?.sort((a: TreatmentClass, b: TreatmentClass) => {
        if (a.treatmentClassName < b.treatmentClassName) {
          return -1;
        }
        if (a.treatmentClassName > b.treatmentClassName) {
          return 1;
        }
        return 0;
      });
      Vue.set(state, "treatmentClassesComplexities", treatmentClasses);
    },
    setTreatmentClassesTypes(
      state: TreatmentClassesState,
      treatmentClasses?: TreatmentClass[]
    ) {
      treatmentClasses?.forEach((treatmentClass, i) => {
        if (treatmentClass.linkedTreatmentClass?.treatmentClassId) {
          if (!treatmentClass.linkedTreatmentClassId) {
            treatmentClasses[i].linkedTreatmentClassId =
              treatmentClass.linkedTreatmentClass.treatmentClassId;
          }
          const index: number = treatmentClasses?.findIndex(
            (item) =>
              item.treatmentClassId ===
              treatmentClass.linkedTreatmentClass?.treatmentClassId
          ) as number;
          (treatmentClasses || [])[index].links = totalLinkedTreatmentClasses(
            treatmentClass,
            treatmentClasses
          );
        }
      });
      Vue.set(state, "treatmentClassesTypes", treatmentClasses);
    },
    setTreatmentClassesConfigFees(
      state: TreatmentClassesState,
      treatmentClasses?: TreatmentClass[]
    ) {
      //TODO make a function
      //sort tx classes
      treatmentClasses?.sort((a: TreatmentClass, b: TreatmentClass) => {
        if (a.treatmentClassName < b.treatmentClassName) {
          return -1;
        }
        if (a.treatmentClassName > b.treatmentClassName) {
          return 1;
        }
        return 0;
      });
      //TODO make a function
      //sort tx complexities & types
      treatmentClasses?.forEach(
        (treatmentClass: TreatmentClass, index: number) => {
          //complexities
          //TODO see if this needs to be sorted by rank instead
          treatmentClasses[index].treatmentComplexities =
            treatmentClass.treatmentComplexities?.sort(
              (a: TreatmentComplexity, b: TreatmentComplexity) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              }
            ) || [];
          //types
          treatmentClasses[index].treatmentTypes =
            (treatmentClass.treatmentTypes as TreatmentType[])?.sort(
              (a: TreatmentType, b: TreatmentType) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              }
            ) || [];
        }
      );

      Vue.set(state, "treatmentClassesConfigFees", treatmentClasses);
    },
    reset: function (state: TreatmentClassesState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('TreatmentClassesState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    treatmentClasses: (state) => state.treatmentClasses,
    treatmentClassesSelect: (state) => {
      return state.treatmentClasses?.map((item) => {
        return { text: item.treatmentClassName, value: item.treatmentClassId };
      });
    },
    treatmentClassesComplexities: (state) => state.treatmentClassesComplexities,
    treatmentClassesConfigFees: (state) => state.treatmentClassesConfigFees,
    hasTreatmentClasses: (state) => state.treatmentClassesTypes?.length,
    treatmentClassesTypes: (state) => state.treatmentClassesTypes,
    treatmentClassesWithLinked: (state) => state.treatmentClassesWithLinked,
    treatmentClassesWithLinkedSelect: (state) =>
      state.treatmentClassesWithLinked?.map((tc) => {
        return { text: tc.treatmentClassName, value: tc.treatmentClassId };
      }),
  },
};

function totalLinkedTreatmentClasses(
  treatmentClass: TreatmentClass,
  treatmentClassTypes: TreatmentClass[]
) {
  let count = 0;
  treatmentClassTypes.forEach((item) => {
    if (
      item.treatmentClassId ===
      treatmentClass.linkedTreatmentClass?.treatmentClassId
    ) {
      count += 1;
    }
  });
  return count;
}
