import menuPages from "./menus/patients.menu";

export default {
  // main navigation - side menu
  menu: [menuPages],
  // footer links
  footer: [
    {
      text: "Business Associate Agreement",
      key: "Business Associate Agreement",
      link: "/business-associate-agreement",
      href: null,
    },
  ],
};
