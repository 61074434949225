import {inject, provide} from "vue";
import {TreatmentTypesService} from "../services/interfaces";

export const treatmentTypesServiceSymbol = Symbol();

export function provideTreatmentTypesService(
  service: TreatmentTypesService
) {
  return provide(treatmentTypesServiceSymbol, service);
}

export function useTreatmentTypesService() {
  const treatmentTypesService = inject<TreatmentTypesService>(
    treatmentTypesServiceSymbol
  );
  if (!treatmentTypesService) {
    throw Error("No Treatment Types Service to Inject!!");
  }
  return treatmentTypesService;
}