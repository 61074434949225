/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {MaintenanceFile, RootState} from "../index";
import Vue from "vue";

// DO NOT MOVE THIS OR ELSE
import {MaintenanceService} from "@shared/services/MaintenanceService";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface MaintenanceState {
    loading: boolean;
    requested: boolean;
    exists: boolean;
    text?: string;
    html?: string;
}

export enum MaintenanceActions {
    LOAD_MAINTENANCE_FILE = "MaintenanceModule/loadMaintenanceFile",
    RESET = "MaintenanceModule/reset",
}

export enum MaintenanceMutations {
    SET_LOADING = "MaintenanceModule/setLoading",
    SET_INFO = "MaintenanceModule/setInfo",
    RESET = "MaintenanceModule/reset",
}

export enum MaintenanceGetters {
    LOADING = "MaintenanceModule/loading",
    REQUESTED = "MaintenanceModule/requested",
    EXISTS = "MaintenanceModule/exists",
    TEXT = "MaintenanceModule/text",
    HTML = "MaintenanceModule/html",
}

const initialState = () => ({
    loading: false,
    requested: false,
    exists: false,
    text: undefined,
    html: undefined,
});

export const MaintenanceModule: Module<
    MaintenanceState,
    RootState
    > = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadMaintenanceFile(
            { commit, dispatch },
            payload: {
                service: MaintenanceService;
            }
        ) {
            try {
                commit("setLoading", true);
                const { service } = payload;
                const file: MaintenanceFile | undefined | null = await service.getMaintenanceFile();
                if(file) {
                    commit("setExists", true);
                        commit("setInfo", file);
                } else {
                    commit("setExists", false);
                    commit("setInfo", {});
                }


            } catch (error) {
                await ErrorNotification(dispatch, error, "Something went wrong checking for maintenance");
            }
            commit("setRequested", true);
            commit("setLoading", false);
        },

        reset({ commit }) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: MaintenanceState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setExists(
            state: MaintenanceState,
            exists: boolean = false
        ) {
            Vue.set(state, "exists", exists);
        },
        setRequested(
            state: MaintenanceState,
            requested: boolean = false
        ) {
            Vue.set(state, "requested", requested);
        },
        setInfo(
            state: MaintenanceState,
            maintenanceFile?: MaintenanceFile
        ) {
            Vue.set(state, "text", maintenanceFile?.text ? maintenanceFile.text : undefined);
            Vue.set(state, "html", maintenanceFile?.html  ? maintenanceFile.html : undefined);
        },
        reset: function (state: MaintenanceState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('MaintenanceState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        requested: (state) => state.requested,
        exists: (state) => state.exists,
        text: (state) => state.text,
        html: (state) => state.html,
    },
};