<template>
  <div>
    <v-expand-transition :duration="{ enter: 2000, leave: 100 }">
      <div class="pl-1 pr-0 body-2 pt-0 d-flex justify-start font-weight-black">
        <div class="align-self-center text-uppercase primary--text">
          Navigation Menu
        </div>
      </div>
    </v-expand-transition>
  <v-list nav dense :class="showDashboard ? 'pt-1' : 'py-0'">
    <nav-menu v-if="showDashboard" :menu="dashboard" class="pb-1"/>
    <div v-for="(item, index) in menu" :key="index">
      <div>
        <v-row dense class="mb-0 pt-1">
          <v-expansion-panels
              v-if="item.key || item.text"
              :disabled="apps.length <= 1"
              accordion
              class="condensed"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                  :hide-actions="apps.length <= 1"
                  disable-icon-rotate
                  class="pl-1 pr-0 overline pt-0"
              >{{ item.key ? item.key : item.text }}
                <template v-slot:actions>
                  <v-btn icon text>
                    <v-icon small>$th</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container
                    class="d-flex flex-row flex-wrap ma-0 pa-0 "
                    style="box-shadow: none"
                >
                  <div
                      v-for="app in apps"
                      :key="app.link"
                      class="app-tile overline mt-1"
                      @click="navigateTo(app.link)"
                  >
                    <span
                        v-if="item.key.toLowerCase() !== app.text.toLowerCase()"
                    ><v-icon style="min-width: 28px;" color="primary">{{ app.icon }}</v-icon>
                      <span class="pl-1 ml-1 mr-2">{{ app.text }}</span></span
                    >
                  </div>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </div>
      <nav-menu :menu="item.items"/>
    </div>
  </v-list>
  </div>
</template>

<script lang="ts">
import NavMenu from "./NavMenu.vue";
import {computed, defineComponent} from "vue";
import {AppType} from "@shared/store";
import {useRouteComponents} from "../../composables/helpers";
import dashboardNavigationMenu from "../../configs/menus/dashboard.menu";
import {useRolesToFilterApps} from "../../composables/filters/use-roles-to-filter-apps";

export default defineComponent({
  name: "MainMenu",
  components: {
    NavMenu,
  },
  props: {
    showDashboard: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Array,
      default: () => dashboardNavigationMenu.items,
    },
  },
  setup() {
    const apps = computed<AppType[]>(() => useRolesToFilterApps() || []);
    const {navigateTo} = useRouteComponents();

    return {
      apps,
      navigateTo,
      dashboardNavigationMenu,
    };
  },
});
</script>
<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none;
}

.app-tile {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--v-background-base);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}
 .v-expansion-panels.condensed .v-expansion-panel-header {
   padding-top: 2px;
   padding-bottom: 2px;
   min-height: auto;
 }
.v-expansion-panels.condensed
.v-expansion-panel--active
.v-expansion-panel-header {
  padding-top: 2px;
  padding-bottom: 2px;
}
.v-expansion-panels.condensed .v-expansion-panel--active:not(:first-child),
.v-expansion-panels.condensed .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px;
}
</style>
