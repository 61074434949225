import {ClientRemindersService as IClientRemindersService} from "../interfaces";
import {AxiosInstance} from "axios";
import {ClientReminderForm} from "../../store/interfaces";

export class ClientRemindersService implements IClientRemindersService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getClientReminders(): Promise<ClientReminderForm> {
    const response = await this.http.get(
      `${this.apiUrl}/api/ClientReminders`
    );
    for (const [key, value] of Object.entries(response.data)) {
      if (key !== "clientId" && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      }
    }
    return response.data;
  }

  async updateClientReminders(
    clientId: string,
    values: ClientReminderForm
  ): Promise<number> {
    const response = await this.http.put(
        `${this.apiUrl}/api/ClientReminders`,
        { ...values },
        {
          params: { id: clientId },
        }
    );
    return response.status;
  }
}
