import {PpoOptionService as IPpoOptionService} from "../interfaces";
import {PpoOption} from "../../store/insurance/interfaces";
import {AxiosInstance} from "axios";

export class PpoOptionService implements IPpoOptionService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPpoOption(): Promise<PpoOption> {
    const response = await this.http.get(`${this.apiUrl}/api/PpoOptions`);
    return response.data;
  }

  async updatePpoOption(id: string, values: PpoOption): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/PpoOptions`,
      { ...values },
      {
        params: { id },
      }
    );
    return response.status;
  }
}
