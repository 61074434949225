import Vue from 'vue'

Vue.filter('ellipse', function (value: any, size = 80, breakpointName = 'lg', defaultText = "N/A") {
    if (!value) return defaultText;
    value = value.toString();

    switch (breakpointName) {
        case "xs":
            size = size / 2.5;
            break;
        case "sm":
            size = size / 2;
            break;
        case "md":
            // this is the default size (assume medium)
            break;
        case "lg":
            size = size * 2;
            break;
        case "xl":
            size = size * 2.5;
            break;
    }

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});