import {CalculationRulesService as ICalculationRulesService} from "../interfaces";
import {AxiosInstance} from "axios";
import {CalculationRule} from "../../store/interfaces";
import {decodeText} from "../../functions/textFunctions";
import {cloneDeep} from "lodash";

export class CalculationRulesService implements ICalculationRulesService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    fields = ["description"];

    async getCalculationRules(): Promise<CalculationRule[]> {
        const response = await this.http.get(`${this.apiUrl}/api/CalculationRules`);

        response.data.forEach((item: CalculationRule, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });

        return response.data;
    }

    async updateCalculationRule(
        id: string,
        values: CalculationRule
    ): Promise<number> {

        const data = cloneDeep(values);
        for (const [key, value] of Object.entries(data)) {
            if (this.fields.includes(key)) {
                if (typeof value === "string") {
                    // @ts-ignore
                    data[key] = encodeURIComponent(value);
                }
            }
        }

        const response = await this.http.put(
            `${this.apiUrl}/api/CalculationRules`,
            {...data},
            {
                params: {id},
            }
        );
        return response.status;
    }

    async updateCalculationRulesList(
        values: CalculationRule[]
    ): Promise<CalculationRule[]> {

        const calcRules = cloneDeep(values);
        calcRules?.forEach((item: CalculationRule, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key)) {
                    if (typeof value === "string") {
                        // @ts-ignore
                        calcRules[index][key] = encodeURIComponent(value);
                    }
                }
            }
        });

        const response = await this.http.post(
            `${this.apiUrl}/api/CalculationRules/SaveList`,
            [...calcRules]
        );

        response.data.forEach((item: CalculationRule, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key) && typeof value === "string") {
                    response.data[index][key] = decodeURIComponent(value);
                }
            }
        });

        return response.data;
    }

    async sortFeeOrder(values: CalculationRule[]): Promise<number> {
        const calcRules = cloneDeep(values);
        calcRules?.forEach((item: CalculationRule, index: number) => {
            for (const [key, value] of Object.entries(item)) {
                if (this.fields.includes(key)) {
                    if (typeof value === "string") {
                        // @ts-ignore
                        calcRules[index][key] = encodeURIComponent(value);
                    }
                }
            }
        });

        const response = await this.http.put(
            `${this.apiUrl}/api/CalculationRules/Sort`,
            [...calcRules]
        );
        return response.status;
    }

    async deleteCalculationRule(id: string): Promise<CalculationRule> {
        const response = await this.http.delete(
            `${this.apiUrl}/api/CalculationRules`,
            {
                params: {id},
            }
        );

        return decodeText(response.data, this.fields);
    }

    async createCalculationRule(
        values: CalculationRule
    ): Promise<CalculationRule> {
        const data = cloneDeep(values);
        for (const [key, value] of Object.entries(data)) {
            if (this.fields.includes(key)) {
                if (typeof value === "string") {
                    // @ts-ignore
                    data[key] = encodeURIComponent(value);
                }
            }
        }
        const response = await this.http.post(
            `${this.apiUrl}/api/CalculationRules`,
            {...data}
        );
        return decodeText(response.data, this.fields);
    }
}
