/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {LogoService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {Logo} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface LogosState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  logos?: Logo[];
}

export enum LogosActions {
  LOAD_LOGOS = "LogosModule/loadLogos",
  LOAD_LOGO = "LogosModule/loadLogo",
  LOAD_LOGO_BY_TX_PLAN_ID = "LogosModule/loadLogoByTreatmentPlanId",
  UPDATE_LOGO = "LogosModule/updateLogo",
  DELETE_LOGO = "LogosModule/deleteLogo",
  CREATE_LOGO = "LogosModule/createLogo",
  RESET = "LogosModule/reset",
}

export enum LogosMutations {
  SET_LOADING = "LogosModule/setLoading",
  SET_LOGOS = "LogosModule/setLogos",
  RESET = "LogosModule/reset",
}

export enum LogosGetters {
  LOADING = "LogosModule/loading",
  SAVING = "LogosModule/saving",
  DELETING = "LogosModule/deleting",
  LOGOS = "LogosModule/logos",
  HAS_LOGOS = "LogosModule/hasLogos",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  logos: undefined,
});

export const LogosModule: Module<LogosState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadLogos(
      { commit, dispatch },
      payload: {
        service: LogoService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const logos: Logo[] = await service.getLogos();
        commit("setLogos", logos);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadLogo(
        { commit, dispatch, getters },
        payload: {
          id: string;
          service: LogoService;
        }
    ) {
      try {
        commit("setLoading", true);
        const { service, id } = payload;
        const logos: Logo[] = cloneDeep(getters.logos) || [];
        const index = logos.findIndex((x: Logo) => x.logoId === id);
        if(index === -1) {
          const logo: Logo = await service.getLogo(id);
          logos.push(logo);
          commit("setLogos", logos);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadLogoByTreatmentPlanId(
        { commit, dispatch, getters },
        payload: {
          logo: Logo;
        }
    ) {
      try {
        commit("setLoading", true);
        const { logo } = payload;
        const logos: Logo[] = cloneDeep(getters.logos) || [];
        if(logo?.logoId) {
          let exists = false;
          logos.forEach((item: Logo, index: number) => {
            if (item.logoId === logo?.logoId) {
              logos[index] = logo;
              exists = true;
            }
          });

          if(!exists) {
            logos.push(logo);
          }
          commit("setLogos", logos);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateLogo(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: Logo;
        service: LogoService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;
        const response = await service.updateLogo(id, values);
        if (response === 204) {
          const logos = cloneDeep(getters.logos)  || [];
          delete values.offices;
          logos.forEach((item: Logo, index: number) => {
            if (item.logoId === id) {
              logos[index] = values;
            }
          });
          commit("setLogos", logos);
          success = true;
        }
        await SuccessNotification(dispatch, `Updated Logo!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteLogo(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: LogoService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteLogo(id);
        if (deleted.logoId === id) {
          const logos = cloneDeep(getters.logos).filter(
            (logo: Logo) => logo.logoId !== id
          )  || [];
          commit("setLogos", logos);
          success = await SuccessNotification(dispatch, `Deleted Logo!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async createLogo(
      { commit, dispatch, getters },
      payload: {
        values: Logo;
        service: LogoService;
      }
    ) {
      let success = "";
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newLogo = await service.createLogo(values);
        if (newLogo.logoId) {
          const logos = cloneDeep(getters.logos) || [];
          logos.push(newLogo);
          commit("setLogos", logos);
          success = newLogo.logoId;
          await SuccessNotification(dispatch, `Created Logo!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: LogosState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setLogos(state: LogosState, logos?: Logo[]) {
      Vue.set(state, "logos", logos);
    },
    reset: function (state: LogosState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('LogosState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    logos: (state) => state.logos,
    hasLogos: (state) => !!state.logos?.length,
  },
};
