import {PaymentPlansService as IPaymentPlansService} from "../interfaces";
import {AxiosInstance} from "axios";
import {PaymentPlan, PaymentPlanType} from "../../store/calculations/interfaces";

export class PaymentPlansService implements IPaymentPlansService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPaymentPlanTypes(): Promise<PaymentPlanType[]> {
    const response = await this.http.get(`${this.apiUrl}/api/PaymentPlanTypes`);
    return response.data;
  }

  async getPaymentPlans(): Promise<PaymentPlan[]> {
    const response = await this.http.get(`${this.apiUrl}/api/PaymentPlans`);
    return response.data;
  }

  async getPaymentPlansWithDisplayText(includeDeleted = false): Promise<PaymentPlan[]> {
    const response = await this.http.get(`${this.apiUrl}/api/PaymentPlans/PaymentPlanWithDisplayText`
        , {params: {includeDeleted: includeDeleted}});
    return response.data;
  }


  async updatePaymentPlans(values: any[], isNameOverrideOnly = false): Promise<PaymentPlan[]> {
    const response = await this.http.put(`${this.apiUrl}/api/PaymentPlans`, [
      ...values,
    ],       {
      params: { isNameOverrideOnly: isNameOverrideOnly },
    });

    return response.data;
  }
}
