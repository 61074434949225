import {Module} from "vuex";
import {ForgotPassword, PatientLoginCredentials, PatientPortalInfo, Registration, RootState} from "../index";
import Vue from "vue";
import {AccountService, RegistrationService} from "../../services/interfaces";
// DO NOT MOVE THIS OR ELSE
import {AlertStatus, ChangePassword, Password, Session, User} from "../interfaces";
import {NotificationActions} from "../notification";
import {
    APP_LOCALSTORAGE_KEY,
    APP_SESSION_BROADCAST_CHANNEL,
    APP_TYPES,
    BROADCAST_CHANNEL_OPTIONS,
    PORTAL_APP_SESSION_BROADCAST_CHANNEL,
    SESSION_EXPIRY_DATE,
    SessionStatuses,
    SIGN_OUT,
    UserRoles
} from "../constants";
import {updateSessionExpiry} from "../../functions/dateFunctions";
import {delay} from "@shared/functions/helperFunctions";
import {BroadcastChannel} from 'broadcast-channel';
// for some reason I can't use "@shared/functions/NotificationFunctions"
// in the portal app so I converted these all manually and don't get a console error on SessionModule anymore
//TODO figure out how to fix this to be more consistent

export interface SessionState {
    authenticated: boolean;
    loading: boolean;
    user?: User;
    status?: string;
    xsrfToken?: string;
    patientPortalInfo?: PatientPortalInfo;
}

export enum SessionActions {
    LOAD_USER = "SessionModule/loadUser",
    SIGN_OUT = "SessionModule/signOut",
    LOGIN = "SessionModule/login",
    PATIENT_LOGIN = "SessionModule/patientLogin",
    TEXT_PATIENT_LOGIN = "SessionModule/textPatientLogin",
    FORGOT_PASSWORD = "SessionModule/forgotPassword",
    SIGNUP = "SessionModule/signUp",
    CREATE_PASSWORD = "SessionModule/createPassword",
    CONFIRM_EMAIL = "SessionModule/confirmEmail",
    CHANGE_PASSWORD = "SessionModule/changePassword",
    EXTEND_SESSION = "SessionModule/extendSession",
    REFRESH_ANTI_FORGERY_TOKEN = "SessionModule/refreshAntiForgeryToken",
    RESET = "SessionModule/reset",
}

export enum SessionMutations {
    SET_LOADING = "SessionModule/setLoading",
    SET_USER = "SessionModule/setUser",
    SET_STATUS = "SessionModule/setStatus",
    SET_PATIENT_PORTAL_INFO = "SessionModule/setPatientPortalInfo",
    SET_XSRF_TOKEN = "SessionModule/setXsrfToken",
    RESET = "SessionModule/reset",
}

export enum SessionGetters {
    AUTHENTICATED = "SessionModule/authenticated",
    LOADING = "SessionModule/loading",
    ROLES = "SessionModule/roles",
    USER = "SessionModule/user",
    PATIENT_PORTAL_INFO = "SessionModule/patientPortalInfo",
    STATUS = "SessionModule/status",
    XSRF_TOKEN = "SessionModule/xsrfToken",
    IS_CONFIG_MANAGER = "SessionModule/isConfigManager",
    IS_SUBSCRIPTION_MANAGER = "SessionModule/isSubscriptionManager",
    CAN_VIEW_PATIENTS = "SessionModule/canViewPatients",
    IS_USER_MANAGER = "SessionModule/isUserManager",
    IS_EXT_INSURANCE_VERIFICATION_ADMIN = "SessionModule/isExtInsVerificationAdmin",
    IS_ADMIN = "SessionModule/isAdmin",
    IS_PATIENT_BILLING_MANAGER = "SessionModule/isPatientBillingManager"
}

const initialState = () => ({
    authenticated: false,
    loading: false, //unusual to be false to start, this is to fix login page
    user: undefined,
    status: undefined,
    xsrfToken: undefined,
    patientPortalInfo: undefined,
});


export const SessionModule: Module<SessionState, RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadUser(
          {commit, dispatch},
          payload: {
              service: AccountService;
          }
        ) {
            try {
                commit("setLoading", true);
                commit("setStatus", SessionStatuses.LOADING_USER_STARTED);
                const {service} = payload;
                const session: Session = await service.session();
                commit("setXsrfToken", session.xsrfToken);
                if (session.user.email) {
                    commit("setUser", session.user);
                    commit("setStatus", SessionStatuses.LOADING_USER_SUCCESSFUL);
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.LOADING_USER_FAILED);
                await dispatch("signOut", payload);
            }
            commit("setLoading", false);
        },
        async refreshAntiForgeryToken(
          {commit, getters, dispatch},
          payload: {
              service: AccountService;
          }
        ) {
            if (getters.status === SessionStatuses.REFRESH_ANTI_FORGERY_TOKEN_STARTED) {
                return;
            }
            try {
                // don't load since it will cause the screen to flash
                commit("setStatus", SessionStatuses.REFRESH_ANTI_FORGERY_TOKEN_STARTED);
                const {service} = payload;
                const xsrfToken: string = await service.antiForgery();
                if (!xsrfToken) {
                    throw Error("Invalid anti forgery token returned");
                }
                commit("setXsrfToken", xsrfToken);
                commit("setStatus", SessionStatuses.REFRESH_ANTI_FORGERY_TOKEN_SUCCESSFUL);
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {
                      status:
                        (error?.response?.status as number) >= 500
                          ? AlertStatus.ERROR
                          : AlertStatus.WARNING,
                      message: error?.data ? error.data : error?.message,
                  },
                  {root: true}
                );
                commit("setStatus", SessionStatuses.REFRESH_ANTI_FORGERY_TOKEN_FAILED);
                await dispatch("signOut", payload);
            }
        },
        async extendSession(
          {commit, dispatch, getters},
          payload: {
              service: AccountService;
          }
        ) {
            if (!getters.authenticated || getters.status === SessionStatuses.EXTENDING_SESSION_STARTED) {
                return;
            }
            try {
                // don't load since it will cause the idle timeout to mess up modals and screen to flash
                commit("setStatus", SessionStatuses.EXTENDING_SESSION_STARTED);
                const {service} = payload;
                const status: number = await service.extendSession();
                if (status === 200) {
                    commit("setStatus", SessionStatuses.EXTENDING_SESSION_SUCCESSFUL);
                } else {
                    if(status === 401 || status === 403) {
                        await dispatch("signOut", payload);
                    }
                    throw Error("Extending session failed with status" + status);
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                if(error?.response?.status === 401 || error?.response?.status === 403) {
                    await dispatch("signOut", payload);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.EXTENDING_SESSION_FAILED);
            }
            return status;
        },
        async login(
          {commit, getters, dispatch},
          payload: {
              email: string;
              password: string;
              service: AccountService;
          }
        ) {
            try {
                commit("setLoading", true);
                commit("setStatus", SessionStatuses.LOGIN_STARTED);
                localStorage.setItem(SESSION_EXPIRY_DATE, updateSessionExpiry());
                const {email, password, service} = payload;
                // if (!getters.xsrfToken) {
                //     const xsrfToken: string = await service.antiForgery();
                //     commit("setXsrfToken", xsrfToken);
                // }
                const user: User | string = await service.login({
                    email,
                    password,
                    xsrfToken: getters.xsrfToken,
                    rememberMe: false,
                });
                if (
                  typeof user === "string" &&
                  user === SessionStatuses.VALIDATE_EMAIL
                ) {
                    commit("setStatus", SessionStatuses.VALIDATE_EMAIL);
                } else {
                    if((user as User)?.roles?.length === 1 && (user as User)?.roles?.includes(UserRoles.RESPONSIBLE_PARTY_READ_WRITE)) {
                        await dispatch("signOut", payload);
                        return;
                    }
                    const xsrfToken: string = await service.antiForgery();
                    commit("setXsrfToken", xsrfToken);
                    if (user && (user as User).email) {
                        localStorage.setItem(SESSION_EXPIRY_DATE, updateSessionExpiry());
                        commit("setUser", user);
                        commit("setStatus", SessionStatuses.LOGIN_SUCCESSFUL);
                    } else {
                        await dispatch("signOut", payload);
                    }
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }

                //TODO fix where error messages display on page since they are misaligned to login info
                dispatch(
                  NotificationActions.ALERT,
                  {
                      status:
                        (error?.response?.status as number) >= 500
                          ? AlertStatus.ERROR
                          : AlertStatus.WARNING,
                      message: error?.data ? error.data : error?.message,
                  },
                  {root: true}
                );
                commit("setStatus", SessionStatuses.LOGIN_FAILED);
                //TODO handle different error cases with signin
            }
            commit("setLoading", false);
        },
        async patientLogin(
          {commit, getters, dispatch},
          payload: {
              body: PatientLoginCredentials;
              service: AccountService;
          }
        ) {
            try {
                commit("setLoading", true);
                commit("setStatus", SessionStatuses.LOGIN_STARTED);
                if (getters.authenticated) {
                    await dispatch("signOut", payload);
                }
                localStorage.setItem(SESSION_EXPIRY_DATE, updateSessionExpiry());
                const {body, service} = payload;
                const user: User | string = await service.patientLogin(body);
                const xsrfToken: string = await service.antiForgery();
                commit("setXsrfToken", xsrfToken);
                if (user && (user as User).email) {
                    localStorage.setItem(SESSION_EXPIRY_DATE, updateSessionExpiry());
                    commit("setUser", user);
                    commit("setStatus", SessionStatuses.LOGIN_SUCCESSFUL);
                    const {patientTreatmentPlanId, code, userId, isEmail, isReadOnly} = {...body};
                    commit("setPatientPortalInfo", {patientTreatmentPlanId, code, userId, isEmail, isReadOnly})
                } else {
                    await dispatch("signOut", payload);
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }

                //TODO fix where error messages display on page since they are misaligned to login info
                dispatch(
                  NotificationActions.ALERT,
                  {
                      status:
                        (error?.response?.status as number) >= 500
                          ? AlertStatus.ERROR
                          : AlertStatus.WARNING,
                      message: error?.data ? error.data : error?.message,
                  },
                  {root: true}
                );
                commit("setStatus", SessionStatuses.LOGIN_FAILED);
                //TODO handle different error cases with signin
            }
            commit("setLoading", false);
        },
        async textPatientLogin(
          {commit, dispatch, getters},
          payload: {
              code: string;
              service: AccountService;
          }
        ) {
            if (getters.authenticated) {
                await dispatch("signOut", payload);
            }
            let redirectUrl = "";
            try {
                commit("setLoading", true);
                commit("setStatus", SessionStatuses.VALIDATING_TEXT_CODE_STARTED);
                // don't load since it will cause the idle timeout to mess up modals and screen to flash
                const {code, service} = payload;

                redirectUrl = await service.textPatientLogin(code);
                if (!redirectUrl?.length) {
                    throw Error("Unable to verify text code.");
                }
                commit("setPatientPortalInfo", {
                    patientTreatmentPlanId: "",
                    code: "",
                    userId: "",
                    isEmail: true,
                    isReadOnly: false
                });
                commit("setStatus", SessionStatuses.VALIDATING_TEXT_CODE_SUCCESSFUL);
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                commit("setStatus", SessionStatuses.VALIDATING_TEXT_CODE_FAILED);
                redirectUrl = "";
            }
            commit("setStatus", SessionStatuses.VALIDATING_TEXT_CODE_SUCCESSFUL);
            commit("setLoading", false);
            return redirectUrl as string;
        },
        async signUp(
          {commit, dispatch},
          payload: {
              values: Registration;
              service: AccountService;
              registrationService: RegistrationService;
          }
        ) {
            let success = false;
            try {
                commit("setLoading", true);
                commit("setStatus", SessionStatuses.LOADING_USER_STARTED);
                const {values, service, registrationService} = payload;

                const status: number = await registrationService.register(values);
                if (status === 204) {
                    const session: Session = await service.session();
                    commit("setXsrfToken", session.xsrfToken);
                    if (session.user.email) {
                        commit("setUser", session.user);
                        commit("setStatus", SessionStatuses.LOADING_USER_SUCCESSFUL);
                        await delay(5000);
                    }
                } else {
                    await dispatch("signOut", payload);
                    throw Error("Creating client and user failed!");
                }
                const defConfigStatus = await registrationService.defaultConfigurations(
                  values.website ? values.website : ""
                );
                if (defConfigStatus === 204) {
                    commit("setStatus", SessionStatuses.LOADING_USER_SUCCESSFUL);
                    dispatch(
                      NotificationActions.ALERT,
                      {
                          status: AlertStatus.SUCCESS,
                          message: "Created client and defaulted their configurations",
                      },
                      {root: true}
                    );
                    success = true;
                } else {
                    throw Error("Defaulting configurations failed!");
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }

                dispatch(
                  NotificationActions.ALERT,
                  {
                      status:
                        (error?.response?.status as number) >= 500
                          ? AlertStatus.ERROR
                          : AlertStatus.WARNING,
                      message: error?.data ? error.data : error?.message,
                  },
                  {root: true}
                );
                commit("setStatus", SessionStatuses.LOADING_USER_FAILED);
            }
            commit("setLoading", false);
            return success;
        },
        async forgotPassword(
          {commit, dispatch},
          payload: {
              service: AccountService;
              values: ForgotPassword;
          }
        ) {
            commit("setLoading", true);
            commit("setStatus", SessionStatuses.FORGOT_PASSWORD_STARTED);
            try {
                const {values, service} = payload;
                await service.forgotPassword(values);
                dispatch(
                  NotificationActions.ALERT,
                  {
                      status: AlertStatus.SUCCESS,
                      message:
                        "Email Sent - please check your email to reset your password!",
                  },
                  {root: true}
                );
                commit("setStatus", SessionStatuses.FORGOT_PASSWORD_SUCCESSFUL);
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.FORGOT_PASSWORD_FAILED);
            }
            commit("setLoading", false);
        },
        async signOut(
          {commit, dispatch, getters},
          payload: {
              service: AccountService;
          }
        ) {
            let success = false;

            if (getters.status === SessionStatuses.SIGN_OUT_STARTED || (getters.status === SessionStatuses.SIGN_OUT_SUCCESSFUL && getters.user === undefined)) {
                return;
            }
            const storageItem = localStorage.getItem(APP_LOCALSTORAGE_KEY);
            const commonData = storageItem ? JSON.parse(storageItem) : {};
            if (commonData?.SessionModule?.status === SessionStatuses.SIGN_OUT_STARTED || commonData?.SessionModule?.status === SessionStatuses.SIGN_OUT_SUCCESSFUL) {
                return;
            }
            commit("setLoading", true);
            commit("setStatus", SessionStatuses.SIGN_OUT_STARTED);
            try {
                const {service} = payload;
                await service.logoff();
                commit("setXsrfToken", undefined);
                commit("setStatus", SessionStatuses.SIGN_OUT_SUCCESSFUL);
                success = true;
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setXsrfToken", undefined);
                commit("setStatus", SessionStatuses.SIGN_OUT_FAILED);
            }
            commit("setUser", undefined);
            commit("setLoading", false);
            localStorage.setItem(SESSION_EXPIRY_DATE, SIGN_OUT);
            const bc = new BroadcastChannel((process.env.VUE_APP_TYPE === APP_TYPES.MAIN ? APP_SESSION_BROADCAST_CHANNEL : PORTAL_APP_SESSION_BROADCAST_CHANNEL), BROADCAST_CHANNEL_OPTIONS);
            await bc?.postMessage({
                sessionStatus: success ? SessionStatuses.SIGN_OUT_SUCCESSFUL : SessionStatuses.SIGN_OUT_FAILED,
                diff: false
            });
            await bc?.close();
            return success;
        },
        async createPassword(
          {commit, dispatch},
          payload: {
              service: AccountService;
              values: Password;
              isReset: boolean;
          }
        ) {
            commit("setLoading", true);
            commit("setStatus", SessionStatuses.SET_PASSWORD_STARTED);
            try {
                const {values, service, isReset} = payload;
                isReset
                  ? await service.resetPassword(values)
                  : await service.createPassword(values);
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.SUCCESS, message: "Password set!"},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.SET_PASSWORD_SUCCESSFUL);
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.SET_PASSWORD_FAILED);
            }
            commit("setLoading", false);
        },
        async confirmEmail(
          {commit, dispatch},
          payload: {
              service: AccountService;
              values: { userId: string; code: string };
          }
        ) {
            commit("setLoading", true);
            commit("setStatus", SessionStatuses.CONFIRM_EMAIL_STARTED);
            let success = false;
            try {
                const {values, service} = payload;
                const response = await service.confirmEmail(values);
                if (response === 200) {
                    success = true;
                    dispatch(
                      NotificationActions.ALERT,
                      {status: AlertStatus.SUCCESS, message: "Email confirmed!"},
                      {root: true}
                    );
                    commit("setStatus", SessionStatuses.CONFIRM_EMAIL_SUCCESSFUL);
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.CONFIRM_EMAIL_FAILED);
            }
            commit("setLoading", false);
            return success;
        },
        async changePassword(
          {commit, dispatch},
          payload: {
              service: AccountService;
              values: ChangePassword;
          }
        ) {
            try {
                const {values, service} = payload;
                await service.changePassword(values);
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.SUCCESS, message: "Password set!"},
                  {root: true}
                );
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                dispatch(
                  NotificationActions.ALERT,
                  {status: AlertStatus.ERROR, message: error?.data ? error.data : error?.message},
                  {root: true}
                );
                commit("setStatus", SessionStatuses.SET_PASSWORD_FAILED);
            }
        },
        reset({commit}, payload: {
            complete?: boolean
        }) {
            const {complete} = payload;
            commit("reset", !!complete);
        },
    },
    mutations: {
        setLoading(state: SessionState, loading: boolean) {
            Vue.set(state, "loading", loading);
        },
        setUser(state: SessionState, user?: User) {
            Vue.set(state, "authenticated", (user && typeof user?.email === 'string' && user?.email?.length > 0) || false);
            Vue.set(state, "user", user);
        },
        setStatus(state: SessionState, status: SessionStatuses) {
            Vue.set(state, "status", status);
        },
        setPatientPortalInfo(state: SessionState, info: PatientPortalInfo) {
            Vue.set(state, "patientPortalInfo", info);
        },
        setXsrfToken(state: SessionState, token?: string) {
            Vue.set(state, "xsrfToken", token);
        },
        reset: function (state: SessionState, complete = false) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    if(key !== 'patientPortalInfo' || complete) {
                        // @ts-ignore
                        state[key] = newState[key];
                    }
                } catch (ex) {
                    console.error('SessionState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        authenticated: (state) => state.authenticated,
        loading: (state) => state.loading,
        user: (state) => state.user,
        patientPortalInfo: (state) => state.patientPortalInfo,
        xsrfToken: (state) => state.xsrfToken,
        roles: (state) => state.user?.roles || [],
        status: (state) => state.status,
        isConfigManager: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.CONFIGURATION_MANAGER)
            : false,
        canViewPatients: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.PATIENT_MANAGER) ||
            state.user.roles?.includes(UserRoles.PATIENT_ADMINISTRATOR)
            : false,
        isPatientBillingManager: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.PATIENT_BILLING_MANAGER)
            : false,
        isUserManager: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.USER_MANAGER)
            : false,
        isAdmin: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.ODP_ADMIN)
            : false,
        isExtInsVerificationAdmin: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.EXT_INSURANCE_VERIFICATION_ADMIN)
            : false,
        isSubscriptionManager: (state) =>
          state.user && state.user.roles
            ? state.user.roles?.includes(UserRoles.SUBSCRIPTION_MANAGER)
            : false,
    },
};