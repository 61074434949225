import {DiscountService as IDiscountService} from "../interfaces";
import {Discount} from "../../store/financials/interfaces";
import {AxiosInstance} from "axios";

export class DiscountService implements IDiscountService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getDiscounts(): Promise<Discount[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/DiscountTypes/Detail`
    );
    return response.data;
  }

  async updateDiscount(id: string, values: Discount): Promise<Discount> {
    const response = await this.http.put(
      `${this.apiUrl}/api/DiscountTypes`,
      { ...values },
      {
        params: { id },
      }
    );

    return response.data;
  }

  async deleteDiscount(id: string): Promise<Discount> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/DiscountTypes`,
      {
        params: { id },
      }
    );

    return response.data;
  }

  async createDiscount(values: Discount): Promise<Discount> {
      const response = await this.http.post(`${this.apiUrl}/api/DiscountTypes`, {...values});
      return response.data;
  }
}
