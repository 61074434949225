export default [
    {
        icon: "$user-plus",
        key: "Create Client",
        text: "Create Client",
        link: "/admin/clients/signup",
        regex: /^\/admin\/clients\/signup/,
        group: "/admin/clients/signup",
    },
    {
        icon: "$bell",
        key: "Notifications",
        text: "Notifications",
        link: "/admin/notifications/list",
        regex: /^\/admin\/notifications\/list/,
        group: "/admin/notifications/list",
    },
    {
        icon: "$clients",
        key: "Clients",
        text: "Clients",
        link: "/admin/clients/list",
        regex: /^\/admin\/clients\/list/,
        group: "/admin/clients/list",
    }
]

