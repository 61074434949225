export default
{
    key: "Dashboard",
    text: "Dashboard",
    regex: /^\/dashboard/,
    group: "/dashboard",
    items: [
        {
            key: "Dashboard",
            icon: "$home",
            text: "Dashboard",
            link: "/dashboard",
            group: "/dashboard",
        }
    ]
}




