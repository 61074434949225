import {AdditionalChargesService as IAdditionalChargesService} from "../interfaces";
import {AxiosInstance} from "axios";
import {AdditionalCharge} from "../../store/interfaces";

export class AdditionalChargesService implements IAdditionalChargesService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getAdditionalCharges(): Promise<AdditionalCharge[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/ProductFees/Lite
      `
    );
    return response.data;
  }
}
