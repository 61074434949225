import {ConditionalText} from "@shared/store";
import {DOCUMENT_FIELDS} from "@shared/store/constants";

export function getTotal(item: ConditionalText, type: string) {
  let total = 0;
  let suffixText = "s";
  if (type === "Type" && item.treatmentTypes.length) {
    item.treatmentTypes.forEach((item) => total++);
  }

  if (type === "Class" && item.treatmentClasses.length) {
    item.treatmentClasses.forEach((item) => total++);
    suffixText = "es";
  }

  if (total > 0) {
    const typeText = total === 1 ? `${type}` : `${type}${suffixText}`;
    return `${total} Treament ${typeText}`;
  }
  return;
}

export function getHoverText(item: ConditionalText, type: string) {
  let hoverText = "";
  if (type === "Class") {
    item.treatmentClasses.forEach((treatmentClass, index) => {
      if (index + 1 < item.treatmentClasses.length) {
        hoverText += `${treatmentClass.treatmentClassName}, `;
      } else {
        hoverText += `${treatmentClass.treatmentClassName}`;
      }
    });
  }
  if (type == "Type") {
    item.treatmentTypes.forEach((treatmentType, index) => {
      if (index + 1 < item.treatmentTypes.length) {
        hoverText += `${treatmentType.name}, `;
      } else {
        hoverText += `${treatmentType.name}`;
      }
    });
  }
  return hoverText;
}

export function getFieldName(id: number) {
  return DOCUMENT_FIELDS.filter((field) => field.columnId === id)[0]
    .description;
}
