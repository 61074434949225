export default {
    key: "Signing Documents",
    text: "Signing Documents",
    regex: /^\/signing-documents/,
    group: "/signing-document",
    items: [
        {
            key: "Review",
            icon: "$filePdfLight",
            text: "Review",
            link: "/signing-documents/review",
            group: "/signing-documents/review",
        },
    ]
}