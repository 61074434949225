import {InsuranceVerificationService as IInsuranceVerificationService} from "../interfaces";
import {AxiosInstance} from "axios";
import {InsuranceRemoteVerificationOptions} from "../../store/insurance/interfaces";

export class InsuranceVerificationService
  implements IInsuranceVerificationService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getInsuranceRemoteVerificationOptions(): Promise<InsuranceRemoteVerificationOptions> {
    const response = await this.http.get(
      `${this.apiUrl}/api/InsuranceRemoteVerificationOptions`
    );
    return response.data;
  }

  async updateInsuranceRemoteVerificationOptions(
    values: InsuranceRemoteVerificationOptions
  ): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/InsuranceRemoteVerificationOptions`,
      { ...values }
    );
    return response.status;
  }
}
