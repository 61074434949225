import {PatientTypeService as IPatientTypeService} from "../interfaces";
import {PatientType} from "../../store/practice/interfaces";
import {AxiosInstance} from "axios";

export class PatientTypeService implements IPatientTypeService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getTypes(includeDeleted = false): Promise<PatientType[]> {
    const response = await this.http.get(`${this.apiUrl}/api/PatientTypes`);
    return response.data;
  }

  async deleteType(id: string): Promise<PatientType> {
    const response = await this.http.delete(`${this.apiUrl}/api/PatientTypes`, {
      params: { id },
    });
    return response.data;
  }

  async createType(values: PatientType): Promise<PatientType> {
    const response = await this.http.post(`${this.apiUrl}/api/PatientTypes`, {
      ...values,
    });
    return response.data;
  }

  async updateType(id: string, values: PatientType): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/PatientTypes`,
      { ...values },
      {
        params: {
          id,
          patientTypeId: id,
        },
      }
    );
    return response.status;
  }
}
