import {ResponsiblePartiesService as IResponsiblePartiesService} from "../interfaces";
import {AxiosInstance} from "axios";
import {ResponsibleParty, ResponsiblePartyType} from '../../store/responsible-parties/interfaces';


export class ResponsiblePartiesService implements IResponsiblePartiesService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getResponsiblePartyTypes(): Promise<ResponsiblePartyType[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientResponsiblePartyRelationships`
        );
        return response.data;
    }

    async saveResponsiblePartyEmail(values: ResponsibleParty): Promise<{ patientResponsiblePartyId: string; lastModifiedDate: string }> {
        const response = await this.http.put(
            `${this.apiUrl}/api/PatientResponsibleParties/PutEmail`,
            {
                ...values
            }
        );

        return response.data;
    }

    //create only
    async saveResponsibleParty(values: ResponsibleParty): Promise<{ patientResponsiblePartyId: string; lastModifiedDate: string }> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientResponsibleParties`,
            {
                ...values,
                isObsolete: false
            }
        );
        return response.data;
    }


}
