import {ClientConfigurationSettingsService as IClientConfigurationSettingsService} from "./interfaces";
import {AxiosInstance} from "axios";
import {ClientConfigurationSettings} from "../store/interfaces";

export class ClientConfigurationSettingsService implements IClientConfigurationSettingsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getConfigSettings(): Promise<ClientConfigurationSettings> {
    const response = await this.http.get(`${this.apiUrl}/api/ClientConfigurations`);
    return response.data;
  }


}