import {InformedConsentService as IInformedConsentService} from "../interfaces";
import {AxiosInstance} from "axios";
import {InformedConsent, InformedConsentSection} from "../../store/interfaces";
import {cloneDeep} from "lodash";

export class InformedConsentService implements IInformedConsentService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getInformedConsent(): Promise<InformedConsent> {
    const response = await this.http.get(
      `${this.apiUrl}/api/InformedConsentForms/Detail`
    );
    for (const [key, value] of Object.entries(response.data)) {
      if (key !== "clientId" && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      } else if (key === "sections") {
        response.data.sections.forEach((entry: any, index: number) => {
          for (const [key, value] of Object.entries(entry)) {
            //TODO could probably make this a function, also maybe endswith("Id") would be better
            if (typeof value === "string" && value && !key.includes("Id")) {
              response.data.sections[index][key] = decodeURIComponent(value);
            }
          }
        });
      }
    }

    return response.data;
  }

  async updateInformedConsent(
    id: string,
    body: InformedConsent
  ): Promise<void> {
    const data = cloneDeep(body) as any;
    for (const [key, value] of Object.entries(data)) {
      if (key !== "clientId" && typeof value === "string") {
        data[key] = encodeURIComponent(value);
      } else if (key === "sections") {
        data.sections.forEach((entry: any, index: number) => {
          for (const [key, value] of Object.entries(entry)) {
            if (typeof value === "string" && value && !key.includes("Id")) {
              data.sections[index][key] = encodeURIComponent(value);
            }
          }
        });
      }
    }
    await this.http.put(`${this.apiUrl}/api/InformedConsentForms`, data, {
      params: { id },
    });
  }

  async updateInformedConsentSection(
    id: string,
    body: InformedConsentSection
  ): Promise<InformedConsentSection> {
    const data = cloneDeep(body) as any;
    for (const [key, value] of Object.entries(data)) {
      if (!key.includes("Id") && typeof value === "string") {
        data[key] = encodeURIComponent(value);
      }
    }

    const response = await this.http.put(
      `${this.apiUrl}/api/InformedConsentSections`,
      data,
      {
        params: { id },
      }
    );
    for (const [key, value] of Object.entries(response.data)) {
      if (!key.includes("Id") && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      }
    }
    return response.data;
  }

  async newInformedConsentSection(
    body: InformedConsentSection
  ): Promise<InformedConsentSection> {
    const data = cloneDeep(body) as any;
    for (const [key, value] of Object.entries(data)) {
      if (!key.includes("Id") && typeof value === "string") {
        data[key] = encodeURIComponent(value);
      }
    }

    const response = await this.http.post(
      `${this.apiUrl}/api/InformedConsentSections`,
      data
    );
    for (const [key, value] of Object.entries(response.data)) {
      if (!key.includes("Id") && typeof value === "string") {
        response.data[key] = decodeURIComponent(value);
      }
    }
    return response.data;
  }

  async deleteInformedConsentSection(
    id: string
  ): Promise<InformedConsentSection> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/InformedConsentSections`,
      {
        params: { id },
      }
    );

    return response.data;
  }

  async updateInformedConsentOrder(
    informedConsentSections: InformedConsentSection[]
  ): Promise<void> {
    const data = cloneDeep(informedConsentSections) as any;
    data.forEach((item: any) => {
      for (const [key, value] of Object.entries(item)) {
        if (!key.includes("Id") && typeof value === "string") {
          item[key] = encodeURIComponent(value);
        }
      }
    });
    await this.http.put(
      `${this.apiUrl}/api/InformedConsentSections/Sort`,
      data
    );
  }
}
