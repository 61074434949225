/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {AccountService, ClientSubscriptionService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {ClientSubscriptions, LoadingType} from "../../constants";
import {ClientSubscription} from "../interfaces";
import {SessionActions} from "../../session";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface ClientSubscriptionsState {
    loading: boolean;
    saving: boolean;
    clientId?: string;
    clientSubscriptions?: ClientSubscription;
    isInsuranceVerificationEnabled: boolean;
    areIntakeAndAnytimeFormsEnabled: boolean;
    areFeePresentationsEnabled: boolean;
    clientSubscriptionsEnabled?: string[],
}

export enum ClientSubscriptionsActions {
    LOAD_CLIENT_SUBSCRIPTIONS = "ClientSubscriptionsModule/loadClientSubscriptions",
    UPDATE_CLIENT_SUBSCRIPTION = "ClientSubscriptionsModule/updateClientSubscription",
    DELETE_CLIENT_SUBSCRIPTIONS = "ClientSubscriptionsModule/deleteClientSubscriptions",
    RESET = "ClientSubscriptionsModule/reset",
}

export enum ClientSubscriptionsMutations {
    SET_LOADING = "ClientSubscriptionsModule/setLoading",
    SET_DISCOUNTS = "ClientSubscriptionsModule/setClientSubscriptions",
    RESET = "ClientSubscriptionsModule/reset",
}

export enum ClientSubscriptionsGetters {
    LOADING = "ClientSubscriptionsModule/loading",
    SAVING = "ClientSubscriptionsModule/saving",
    CLIENT_SUBSCRIPTIONS = "ClientSubscriptionsModule/clientSubscriptions",
    CLIENT_ID = "ClientSubscriptionsModule/clientId",
    CLIENT_SUBSCRIPTIONS_ENABLED = "ClientSubscriptionsModule/clientSubscriptionsEnabled",
    IS_INSURANCE_VERIFICATION_ENABLED = "ClientSubscriptionsModule/isInsuranceVerificationEnabled",
    ARE_FEE_PRESENTATIONS_ENABLED = "ClientSubscriptionsModule/areFeePresentationsEnabled",
    ARE_INTAKE_AND_ANYTIME_FORMS_ENABLED = "ClientSubscriptionsModule/areIntakeAndAnytimeFormsEnabled",
}

const initialState = () => ({
    loading: false,
    saving: false,
    clientId: undefined,
    isInsuranceVerificationEnabled: false,
    areIntakeAndAnytimeFormsEnabled: false,
    areFeePresentationsEnabled: false,
    clientSubscriptions: undefined,
    clientSubscriptionsEnabled: undefined,
});

export const ClientSubscriptionsModule: Module<ClientSubscriptionsState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadClientSubscriptions(
            {commit, dispatch},
            payload: {
                service: ClientSubscriptionService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const subscriptions: ClientSubscription[] = await service.getSubscriptions();
                commit("setClientSubscriptions", subscriptions);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async updateClientSubscription(
            {commit, dispatch},
            payload: {
                id: string;
                values: ClientSubscription;
                service: ClientSubscriptionService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id, values} = payload;
                const response = await service.updateSubscription(id, values);

                if (response === 204) {
                    commit("setClientSubscriptions", [values]);
                    success = true;
                }
                await SuccessNotification(dispatch, `Updated client subscriptions!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async deleteClientSubscriptions(
            {commit, dispatch},
            payload: {
                service: ClientSubscriptionService;
                accountService: AccountService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, accountService} = payload;
                const response = await service.deleteSubscription();

                if (response === 204) {
                    commit("setClientSubscriptions", undefined);
                    success = true;
                    dispatch(SessionActions.SIGN_OUT, {service: accountService}, {root: true});
                }
                await SuccessNotification(dispatch, `All your practice subscriptions have been disabled. We are sorry to see you go, please reach out to us if we can help you with your future needs!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: ClientSubscriptionsState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setClientSubscriptions(
            state: ClientSubscriptionsState,
            subscriptions?: ClientSubscription[]
        ) {
            const subs = typeof subscriptions !== 'undefined' && Array.isArray(subscriptions) ? (subscriptions as ClientSubscription[])[0] : undefined;
            Vue.set(
                state,
                "clientSubscriptions",
                subs
            );
            const clientSubscriptionsEnabled: string[] = [];
            if (subs?.enableInsuranceVerification) clientSubscriptionsEnabled.push(ClientSubscriptions.INSURANCE_VERIFICATION);
            if (subs?.enableIntakeAndAnytimeForms) clientSubscriptionsEnabled.push(ClientSubscriptions.INTAKE_AND_ANYTIME_FORMS);
            if (subs?.enableFeePresentations) clientSubscriptionsEnabled.push(ClientSubscriptions.FEE_PRESENTATIONS);
            Vue.set(
                state,
                "clientSubscriptionsEnabled",
                clientSubscriptionsEnabled
            );
            Vue.set(
                state,
                "clientId",
                subs?.clientId
            );
        },
        reset: function (state: ClientSubscriptionsState) {
            const newState = initialState();
            for (const key of Object.keys(newState)) {
                if (key === "clientId") {
                    continue;
                }
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('ClientSubscriptionsState Reset Error: ', ex.message);
                }
            }
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        clientId: (state) => state?.clientId,
        clientSubscriptions: (state) => state.clientSubscriptions,
        clientSubscriptionsEnabled: (state) => state.clientSubscriptionsEnabled,
        areIntakeAndAnytimeFormsEnabled: (state) =>
            state.clientSubscriptions?.enableIntakeAndAnytimeForms,
        areFeePresentationsEnabled: (state) =>
            state.clientSubscriptions?.enableFeePresentations,
        isInsuranceVerificationEnabled: (state) =>
            state.clientSubscriptions?.enableInsuranceVerification,
    },

};
