import {UIService as IUIService} from "./interfaces";
import Vuetify from "../plugins/vuetify";
import {LocalStorage, Themes} from "../store/constants";
import {AxiosInstance} from "axios";

export class UIService implements IUIService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;
  constructor(http: AxiosInstance) {
    this.http = http;
  }
  async getGlobalTheme(): Promise<string> {
    const theme = window.localStorage.getItem(LocalStorage.GLOBAL_THEME);
    Vuetify.framework.theme.dark = theme === Themes.DARK;
    return theme || Themes.LIGHT;
  }

  async saveGlobalTheme(theme: string): Promise<void> {
    window.localStorage.setItem(LocalStorage.GLOBAL_THEME, theme);
    Vuetify.framework.theme.dark = theme === Themes.DARK;
  }
}
