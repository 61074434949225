/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {DoctorService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import { LoadingType, NEW_ITEM_ID } from "../../constants";
import {cloneDeep} from "lodash";
import {Doctor} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface DoctorsState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    doctors?: Doctor[];
}

export enum DoctorsActions {
    LOAD_DOCTORS = "DoctorsModule/loadDoctors",
    UPDATE_DOCTOR = "DoctorsModule/updateDoctor",
    CREATE_DOCTOR = "DoctorsModule/createDoctor",
    DELETE_DOCTOR = "DoctorsModule/deleteDoctor",
    RESET = "DoctorsModule/reset",
}

export enum DoctorsMutations {
    SET_LOADING = "DoctorsModule/setLoading",
    SET_DOCTORS = "DoctorsModule/setDoctors",
    RESET = "DoctorsModule/reset",
}

export enum DoctorsGetters {
    LOADING = "DoctorsModule/loading",
    SAVING = "DoctorsModule/saving",
    DELETING = "DoctorsModule/deleting",
    DOCTORS = "DoctorsModule/doctors",
    HAS_DOCTORS = "DoctorsModule/hasDoctors",
    DOCTORS_SELECT = "DoctorsModule/doctorsSelect",
    DOCTORS_WITH_EMPTY_SELECT = "DoctorsModule/doctorsWithEmptySelect",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    doctors: undefined,
});

export const DoctorsModule: Module<DoctorsState, RootState> = {
        namespaced: true,
        state: initialState(),
        actions: {
            async loadDoctors(
                {commit, dispatch},
                payload: {
                    service: DoctorService;
                }
            ) {
                try {
                    commit("setLoading", true);
                    const {service} = payload;
                    const doctors: Doctor[] = await service.getDoctors();
                    commit("setDoctors", doctors);
                } catch (error) {
                    await ErrorNotification(dispatch, error, error.data);
                }
                commit("setLoading", false);
            },
            async updateDoctor(
                {commit, dispatch, getters},
                payload: {
                    id: string;
                    doctor: Doctor;
                    service: DoctorService;
                }
            ) {
                let success = false;
                try {
                    commit("setLoading", {
                        loading: true,
                        loadingType: LoadingType.SAVING,
                    });
                    const {service, id, doctor} = payload;
                    const response = await service.updateDoctor(id, doctor);

                    const doctors = cloneDeep(getters.doctors);
                    doctors.forEach((item: Doctor, index: number) => {
                        if (item.doctorId === id) {
                            doctors[index] = doctor;
                        }
                    });
                    commit("setDoctors", doctors);
                    if (response === 204) {
                        success = await SuccessNotification(dispatch, `Updated Dr. ${doctor.fullName}!`);
                    }
                } catch (error) {
                    await ErrorNotification(dispatch, error, error.data);
                }
                commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
                return success;
            },
            async createDoctor(
                {commit, dispatch, getters},
                payload: {
                    doctor: Doctor;
                    service: DoctorService;
                }
            ) {
                let success = false;
                try {
                    commit("setLoading", {
                        loading: true,
                        loadingType: LoadingType.SAVING,
                    });
                    const {service, doctor} = payload;
                    const newDoctor = await service.createDoctor(doctor);
                    const doctors = cloneDeep(getters.doctors);
                    doctors.push(newDoctor);
                    commit("setDoctors", doctors);
                    success = await SuccessNotification(dispatch, `Created Dr. ${doctor.fullName}!`);
                } catch (error) {
                    await ErrorNotification(dispatch, error, error.data);
                }
                commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
                return success;
            },
            async deleteDoctor(
                {commit, dispatch, getters},
                payload: {
                    id: string;
                    doctor: Doctor;
                    service: DoctorService;
                }
            ) {
                let success = false;
                try {
                    commit("setLoading", {
                        loading: true,
                        loadingType: LoadingType.DELETING,
                    });
                    const {service, id, doctor} = payload;
                    const deleted = await service.deleteDoctor(id, doctor);
                    if (deleted === 204) {
                        const doctors = cloneDeep(getters.doctors).filter(
                            (doctor: Doctor) => doctor.doctorId !== id
                        );
                        commit("setDoctors", doctors);
                        success = await SuccessNotification(dispatch, `Deleted Dr. ${doctor.fullName}!`);
                    }
                } catch (error) {
                    await ErrorNotification(dispatch, error, error.data);
                }
                commit("setLoading", {
                    loading: false,
                    loadingType: LoadingType.DELETING,
                });
                return success;
            },
            reset({commit}) {
                commit("reset");
            },
        },
        mutations: {
            setLoading(
                state: DoctorsState,
                payload: { loading: boolean; loadingType: string }
            ) {
                if (typeof payload === "boolean") {
                    Vue.set(state, "loading", payload);
                } else {
                    if (payload.loadingType) {
                        Vue.set(state, payload.loadingType, payload.loading);
                    }
                    Vue.set(state, "loading", payload.loading);
                }
            },
            setDoctors(state: DoctorsState, doctors?: Doctor[]) {
                Vue.set(state, "doctors", doctors);
            },
            reset: function (state: DoctorsState) {
                const newState = initialState();
                Object.keys(newState).forEach(key => {
                    try {
                        // @ts-ignore
                        state[key] = newState[key];
                    } catch (ex) {
                        console.error('DoctorsState Reset Error: ', ex.message);
                    }
                });
            },
        },
        getters: {
            loading: (state) => state.loading,
            saving: (state) => state.saving,
            deleting: (state) => state.deleting,
            doctors: (state) => state.doctors,
            hasDoctors: (state) => !!state.doctors?.filter((doctor: Doctor) => !doctor?.isObsolete)?.length,
            doctorsSelect: (state) =>
                state.doctors?.filter((doc) => !doc?.isObsolete).map((doctor) => {
                        return {text: doctor.fullName, value: doctor.doctorId, disabled: false};
                }),
            doctorsWithEmptySelect: (state) => {
                const doctors = (state.doctors?.map((item) => {
                    if (!item.isObsolete) {
                        return {text: item.fullName, value: item.doctorId, disabled: false};
                    }
                }));
                if (doctors?.length) {
                    doctors?.push({text: "-Not Yet Chosen-", value: NEW_ITEM_ID, disabled: false});
                }
                return doctors;
            },
        },
    }
;
