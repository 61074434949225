import {AppNames} from "@shared/store/constants";

export default [
  {
    path: "/users/dashboard",
    name: "users-dashboard",
    component: () =>
        import(
            /* webpackChunkName: "dashboard" */ "../pages/dashboard/DashboardPage.vue"
            ),
    meta: {
      layout: "User",
      title: "Dashboard",
      type: AppNames.USERS,
      rank: -1,
    },
  },
  {
    path: "/users/list",
    name: "users-list",
    component: () =>
      import(/* webpackChunkName: "users-list" */ "../pages/users/ListUsersPage.vue"),
    meta: {
      rank: 1,
      type: AppNames.USERS,
      title: "List",
      layout: "User",
    },
  },
  {
    path: "/users/:id/detail",
    name: "users-edit",
    component: () =>
      import(/* webpackChunkName: "users-edit" */ "../pages/users/EditUsersPage.vue"),
    meta: {
      rank: -1,
      type: AppNames.USERS,
      title: "Edit",
      layout: "User",
    },
  },
  {
    path: "/users/create",
    name: "users-create",
    component: () =>
      import(/* webpackChunkName: "users-edit" */ "../pages/users/EditUsersPage.vue"),
    meta: {
      rank: 2,
      type: AppNames.USERS,
      title: "Add / Create",
      layout: "User",
    },
  },
];
