import Vue from 'vue'
import {InfoNotification} from "@shared/functions/NotificationFunctions";

/**
 * Copy to clipboard the text passed
 * @param {String} text string to copy
 * @param {String} toastText message to appear on the toast notification
 */
Vue.prototype.$clipboard = function (text: string, toastText = 'Copied to clipboard!'): void {
  const el = document.createElement('textarea')

  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  if (this.$store && this.$store.dispatch) {
      InfoNotification(this.$store.dispatch, toastText);
  }
}
