import {PatientWetSignDocumentsService as IPatientWetSignDocumentsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {PatientWetSignDocument} from "../../store/documents/patient-contracts/interfaces";

export class PatientWetSignDocumentsService implements IPatientWetSignDocumentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async savePatientWetSignDocument(patientTreatmentPlanId: string, patientWetSignDocument: PatientWetSignDocument ): Promise<{ patientContractId?: string, patientResponsiblePartyId?: string }> {
    const response = await this.http.post(
        `${this.apiUrl}/api/PatientWetSignDocuments`,
        patientWetSignDocument,
        { params: { patientTreatmentPlanId: patientTreatmentPlanId } }
    );
    return response.data;
  }

  async deletePatientWetSignDocument(patientContractId: string): Promise<number> {
    const response = await this.http.delete(
        `${this.apiUrl}/api/PatientWetSignDocuments`,
        { params: { patientContractId: patientContractId } }
    );
    return response.status;
  }
}
