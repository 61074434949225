import maps from "./maps";
import time from "./time";
import theme from "./theme";
import toolbar from "./toolbar";
import analytics from "./analytics";
import currencies from "./currencies";
import navigation from "./navigation";
import configNavigation from "./configuration.navigation";
import userNavigation from "./user.navigation";
import insuranceVerificationNavigation from "./insuranceVerification.navigation";
import subscriptionsNavigation from "./subscriptions.navigation";
// @ts-ignore
import adminNavigation from "./admin.navigation";
import {APP_NAME} from "@shared/store/constants";

export default {
  // product display information
  product: {
    name: APP_NAME,
    version: typeof process.env.VUE_APP_VERSION === 'string' ? process.env.VUE_APP_VERSION : "2.X.X",
  },

  // google maps
  maps,

  // time configs
  time,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // analytics configs
  analytics,

  // currencies configs
  currencies,

  // navigation configs
  navigation,

  //configuration navigation
  configNavigation,

  //admin navigation
  adminNavigation,

  //insurance verification navigation
  insuranceVerificationNavigation,

  //user navigation
  userNavigation,

  //subscription navigation
  subscriptionsNavigation
};
