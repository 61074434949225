/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {IntakeForm, IntakePdf, RootState} from "../../index";
import Vue from "vue";
import {PatientIntakeDocumentsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {SharedDocumentsFormUpdate} from "../../interfaces";
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {NewPatientPacket} from "../../responsible-parties/interfaces";
import router from "@main/router";
import {ErrorNotification, SuccessNotification, WarningNotification} from "@shared/functions/NotificationFunctions";

export interface PatientIntakeFormsState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    intakeForms?: IntakeForm[];
    customIntakeForms?: IntakeForm[];
    sharedIntakeForms?: IntakeForm[];
    intakePDFs?: IntakePdf[];
}

export enum PatientIntakeFormsActions {
    LOAD_INTAKE_FORMS = "PatientIntakeFormsModule/loadIntakeForms",
    LOAD_CUSTOM_INTAKE_FORMS = "PatientIntakeFormsModule/loadCustomIntakeForms",
    LOAD_SHARED_INTAKE_FORMS = "PatientIntakeFormsModule/loadSharedIntakeForms",
    UPDATE_SHARED_INTAKE_FORMS = "PatientIntakeFormsModule/updateSharedIntakeForms",
    UPDATE_CUSTOM_INTAKE_FORMS = "PatientIntakeFormsModule/updateCustomIntakeForms",
    UPDATE_CUSTOM_FORMS_FIELDS = "PatientIntakeFormsModule/updateFormFields",
    UPDATE_INTAKE_FORMS_ORDER = "PatientIntakeFormsModule/updateIntakeFormsOrder",
    GET_ESIGN_URL = "PatientIntakeFormsModule/getEsignUrl",
    CREATE_CUSTOM_FORM = "PatientIntakeFormsModule/createCustomForm",
    DELETE_CUSTOM_FORM = "PatientIntakeFormsModule/deleteCustomForm",
    LOAD_INTAKE_PDFS = "PatientIntakeFormsModule/loadIntakePDFs",
    SAVE_NEW_PATIENT_PACKET = "PatientIntakeFormsModule/postPdf",
    RESET = "PatientIntakeFormsModule/reset",
}

export enum PatientIntakeFormsMutations {
    SET_LOADING = "PatientIntakeFormsModule/setLoading",
    SET_CUSTOM_INTAKE_FORMS = "PatientIntakeFormsModule/setCustomIntakeForms",
    SET_SHARED_INTAKE_FORMS = "PatientIntakeFormsModule/setSharedIntakeForms",
    SET_INTAKE_FORMS = "PatientIntakeFormsModule/setIntakeForms",
    SET_INTAKE_PDFS = "PatientIntakeFormsModule/setIntakePDFs",
    RESET = "PatientIntakeFormsModule/reset",
}

export enum PatientIntakeFormsGetters {
    LOADING = "PatientIntakeFormsModule/loading",
    SAVING = "PatientIntakeFormsModule/saving",
    DELETING = "PatientIntakeFormsModule/deleting",
    INTAKE_FORMS = "PatientIntakeFormsModule/intakeForms",
    CUSTOM_INTAKE_FORMS = "PatientIntakeFormsModule/customIntakeForms",
    SHARED_INTAKE_FORMS = "PatientIntakeFormsModule/sharedIntakeForms",
    INTAKE_PDFS = "PatientIntakeFormsModule/intakePDFs",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    intakeForms: undefined,
    customIntakeForms: undefined,
    sharedIntakeForms: undefined,
    intakePDFs: undefined,
});

export const PatientIntakeFormsModule: Module<
    PatientIntakeFormsState,
    RootState
> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadIntakePDFs(
            {commit, dispatch},
            payload: {
                intakeForms: IntakeForm[];
                service: PatientIntakeDocumentsService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service, intakeForms} = payload;
                const pdfArray: IntakePdf[] = [];
                const preFetchDataPromises: Promise<any>[] = [];
                for (const form of intakeForms) {
                    preFetchDataPromises.push(service.postSinglePdf(
                        form.formId,
                        form.isSharedLibraryForm as boolean
                    ));
                }
                const pdfs = await Promise.all(preFetchDataPromises);
                for (let i = 0; i < intakeForms?.length; i++) {

                    pdfArray.push({formId: intakeForms[i]?.formId, data: pdfs[i]});
                }
                commit("setIntakePDFs", pdfArray);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async loadIntakeForms(
            {commit, dispatch},
            payload: {
                service: PatientIntakeDocumentsService;
                skipLoadingPdfData?: boolean;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service, skipLoadingPdfData} = payload;
                const intakeForms: IntakeForm[] = await service.getIntakeForms();
                //TODO don't await these just show default image instead
                if(!skipLoadingPdfData) {
                    await dispatch("loadIntakePDFs", {
                        service,
                        intakeForms,
                    });
                }
                commit("setIntakeForms", intakeForms);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async loadCustomIntakeForms(
            {commit, dispatch},
            payload: {
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", true);
                const {service} = payload;
                const customIntakeForms: IntakeForm[] = await service.getCustomIntakeForms();

                commit("setCustomIntakeForms", customIntakeForms);
                success = true;
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
            return success;
        },

        async loadSharedIntakeForms(
            {commit, dispatch},
            payload: {
                service: PatientIntakeDocumentsService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const sharedIntakeForms: IntakeForm[] = await service.getSharedIntakeForms();
                commit("setSharedIntakeForms", sharedIntakeForms);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async updateSharedIntakeForms(
            {commit, dispatch},
            payload: {
                values: SharedDocumentsFormUpdate;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;
                await service.updateSharedIntakeForms(values);
                commit("setIntakeForms", undefined);
                success = await SuccessNotification(dispatch, "Updated!");
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async postPdf(
            {commit, dispatch},
            payload: {
                values: NewPatientPacket;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;
                const response = await service.postPdf(values);
                if (response === 204) {
                    success = await SuccessNotification(dispatch, "Submitted!");
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateCustomIntakeForms(
            {commit, dispatch, getters},
            payload: {
                formId: string;
                values: IntakeForm;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values, formId} = payload;
                const response: IntakeForm = await service.updateCustomIntakeForms(
                    formId,
                    values
                );
                const customIntakeForms = cloneDeep(getters.customIntakeForms);
                customIntakeForms.forEach((item: IntakeForm, index: number) => {
                    if (item.formId === formId) {
                        customIntakeForms[index] = response;
                    }
                });
                commit("setCustomIntakeForms", customIntakeForms);
                commit("setIntakeForms", undefined);
                success = await SuccessNotification(dispatch, "Updated!");
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateFormFields(
            {commit, dispatch, getters},
            payload: {
                formId: string;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, formId} = payload;
                const response = await service.updateFormFields(
                    formId
                );
                success = response;
                if (success) {
                    await SuccessNotification(dispatch, "Updated!");
                    //update the store
                    const loadIsSuccessful: boolean = await dispatch("loadCustomIntakeForms", {
                        service: service
                    });
                    if (!loadIsSuccessful) {
                        await router.push({name: "intake-forms"});
                    }
                } else {
                    await WarningNotification(dispatch, "Form updates failed!");
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateIntakeFormsOrder(
            {commit, dispatch},
            payload: {
                intakeForms: IntakeForm[];
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, intakeForms} = payload;
                await service.reorderCustomIntakeForms(intakeForms);
                commit("setCustomIntakeForms", intakeForms);
                commit("setIntakeForms", undefined);
                success = await SuccessNotification(dispatch, "Updated!");
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },

        async getEsignUrl(
            {commit, dispatch, getters},
            payload: {
                initialFormId: string;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, initialFormId} = payload;
                const response = await service.requestEsignURL(initialFormId);
                if (response) {
                    const customForms = await cloneDeep(getters.customIntakeForms);
                    customForms.forEach((form: IntakeForm, index: number) => {
                        if (form.formId === initialFormId) {
                            customForms[index].requested = true;
                        }
                        commit("setCustomIntakeForms", customForms);
                        commit("setIntakeForms", undefined);
                    });
                    success = true;
                }
                await SuccessNotification(dispatch, "Successfully sent request for the e-sign url!");
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.SAVING,
            });
            return success;
        },
        async createCustomForm(
            {commit, dispatch},
            payload: {
                values: IntakeForm;
                formId: string;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success: boolean | string = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values, formId} = payload;
                const response = await service.createCustomForm(formId, values);
                if (response) {
                    success = response;
                }
                commit("setIntakeForms", undefined);
                await SuccessNotification(dispatch, "Saved!");
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async deleteCustomForm(
            {commit, dispatch, getters},
            payload: {
                formId: string;
                service: PatientIntakeDocumentsService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, formId} = payload;
                const deleted = await service.deleteForm(formId);
                if (deleted.formId === formId) {
                    const customForms = cloneDeep(getters.customIntakeForms).filter(
                        (form: IntakeForm) => form.formId !== formId
                    );
                    commit("setCustomIntakeForms", customForms);
                    commit("setIntakeForms", undefined);
                    success = await SuccessNotification(dispatch, "Deleted!");
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: PatientIntakeFormsState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setIntakeForms(state: PatientIntakeFormsState, intakeForms?: IntakeForm[]) {
            intakeForms = intakeForms?.sort((a: IntakeForm, b: IntakeForm) => {
                if (!a.orderId) {
                    a.orderId = 0;
                }
                if (!b.orderId) {
                    b.orderId = 0;
                }
                return (a.orderId as number) - (b.orderId as number);
            });
            Vue.set(state, "intakeForms", intakeForms);
        },
        setCustomIntakeForms(
            state: PatientIntakeFormsState,
            customIntakeForms?: IntakeForm[]
        ) {
            //sort items when setting them
            if (customIntakeForms && customIntakeForms.length) {
                customIntakeForms.sort((a: IntakeForm, b: IntakeForm) => {
                    return (a.orderId as number) - (b.orderId as number);
                });
            } else return state.customIntakeForms;
            Vue.set(state, "customIntakeForms", customIntakeForms);
        },
        setSharedIntakeForms(
            state: PatientIntakeFormsState,
            sharedIntakeForms?: IntakeForm[]
        ) {
            Vue.set(state, "sharedIntakeForms", sharedIntakeForms);
        },
        setIntakePDFs(state: PatientIntakeFormsState, pdfArray: IntakePdf[]) {
            Vue.set(state, "intakePDFs", pdfArray);
        },
        reset: function (state: PatientIntakeFormsState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('PatientIntakeFormsState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        intakeForms: (state) => state.intakeForms,
        customIntakeForms: (state) => state.customIntakeForms,
        sharedIntakeForms: (state) => state.sharedIntakeForms,
        intakePDFs: (state) => state.intakePDFs,
    },
};
