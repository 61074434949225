import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { SessionActions, SessionGetters } from "./store/session";
import store from "./store";
import router from "../main/src/router";
import { AccountService } from "./services/AccountService";
import moment from "moment";
import { APP_TYPES, SESSION_EXPIRY_DATE } from "./store/constants";
import { updateSessionExpiry } from "./functions/dateFunctions";
import { MaintenanceActions, MaintenanceGetters } from "@shared/store/maintenance";
import Vue from "vue";
import { MaintenanceService } from "@shared/services/MaintenanceService";
import axiosExternal from "@shared/axios-external";
import { PatientsMutations } from "@shared/store/patients";

function getErrorMessage(error: AxiosError, defaultText = "An unknown error has occurred") {
    let errorMessage = defaultText;
    if (error?.response) {
        if (error.response?.data) {
            // @ts-ignore
            if (error.response.data?.message) {
                // @ts-ignore
                return error.response.data.message;
            }
            // @ts-ignore
            if (error.response.data?.exceptionMessage) {
                // @ts-ignore
                return error.response.data.exceptionMessage;
            }
            // @ts-ignore
            if (error.response.data?.error) {
                // @ts-ignore
                return error.response.data.error;
            }
            if(typeof error.response.data === "string" && error.response.data.length > 0 && !error.response.data?.toLowerCase()?.startsWith("request failed with status code")) {
                return error.response.data;
            }
        }
        if (error.response?.statusText) {
            return error.response.statusText;
        }
        if(error.response?.status) {
            errorMessage += ` (status code: ${error.response.status})`;
        }
    }
    if (error?.message && !error?.message?.toLowerCase()?.startsWith("request failed with status code")) {
        return error.message;
    }
    return errorMessage;
}

const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
    withCredentials: true,
    withXSRFToken: true,
});

const requestHandler = async (request: InternalAxiosRequestConfig<any>) => {
    request.url = (request.url || "").toLowerCase();
    request['headers']["X-XSRF-Token"] = store.getters[SessionGetters.XSRF_TOKEN] || "";
    return request;
};

const responseSuccessHandler = async (response: AxiosResponse) => {
    // await delay(3000);//todo remove qh testing only
    if (store.getters[SessionGetters.AUTHENTICATED]) {
        const currentExpiry = localStorage.getItem(SESSION_EXPIRY_DATE);
        const updatedExpiry = updateSessionExpiry();
        if (!currentExpiry || (moment.utc(currentExpiry).isValid() && moment.utc(updatedExpiry).isAfter(moment.utc(currentExpiry)))) {
            localStorage.setItem(SESSION_EXPIRY_DATE, updatedExpiry);
        }
    }

    if (process.env.VUE_APP_TYPE === APP_TYPES.MAIN
        && response?.config?.method?.toLowerCase() !== 'get'
        && response?.config?.url?.toLowerCase().includes('/patient')
        && !(response?.config?.url?.toLowerCase().includes('/patients/list')
            || response?.config?.url?.toLowerCase().includes('/account/')
            || response?.config?.url?.toLowerCase().includes('/putlastaskedtoregeneratedateupdate')
            || response?.config?.url?.toLowerCase().includes('/patientcontracts/doctornotesandadjustdates') //this currently doesn't change anything in status
            || response?.config?.url?.toLowerCase().includes('/patienttreatmentplans/responsibleparties') //this currently doesn't change anything in status
        )
        ) {
        store.commit(PatientsMutations.SET_SELECTED_PATIENT_UPDATED, true);
    }

    return response;
};
//TODO clean up error logic
const responseErrorHandler = async (error: AxiosError) => {
    let errMessage: any;
    if (error && error.response && error.response.status) {
        switch (error.response.status as number) {
            case 400:
                //model state errors
                errMessage = errMessage = {
                    ...error,
                    data: "The request is invalid. ",
                };
                if (error && error.response && error.response.data) {
                    // @ts-ignore
                    if (error.response.data?.message && error.response.data.message !== "An error has occurred.") {

                        // @ts-ignore
                        errMessage = {...error, data: (error.response.data.message || "") + "  "};
                    }
                }
                // @ts-ignore
                if (error?.response?.data?.modelState) {
                    // @ts-ignore
                    Object.keys(error?.response?.data?.modelState)?.forEach((key, index) => {
                            if (index < 2) {
                                // @ts-ignore
                                if (Array.isArray(error?.response?.data?.modelState[key]) && error?.response?.data?.modelState[key].length > 0) {
                                    // @ts-ignore
                                    errMessage.data += (error?.response?.data?.modelState[key][0] || "") + "  ";
                                } else {
                                    // @ts-ignore
                                    errMessage.data += (error?.response?.data?.modelState[key] || "") + "  ";
                                }
                            }
                            if (index === 2) errMessage.data += " ...";
                        }
                    )
                    ;
                }
                break;
            case 401:
                //on login screen
                errMessage = error.response.config.url?.endsWith("api/account/login")
                    ?
                    {
                        ...error,
                        data:
                            "Unauthorized - incorrect email / password combination, please try again.",
                    }
                    :
                    {
                        ...error,
                        data:
                            "Unauthorized - please re-authenticate to try your request again.",
                    };
                if (!error.response.config.url?.endsWith("api/account/login") && !store.getters[SessionGetters.LOADING]) await store.dispatch(SessionActions.SIGN_OUT, {service: new AccountService(axios)});
                break;
            case 403:

                errMessage = {
                    ...error,
                    data:
                            "Forbidden - invalid anti-forgery token, try refreshing your browser.",
                };
                //TODO code not do the below since it caused loops to occur
                // if (!store.getters[SessionGetters.LOADING]) await store.dispatch(SessionActions.REFRESH_ANTI_FORGERY_TOKEN, {service: new AccountService(axios)});
                break;
            case 404:
                errMessage = {
                    ...error,
                    data: getErrorMessage(error, "Requested resource could not be found.")
                };
                break;
            case 409:
                errMessage = {
                    ...error,
                    data:
                            "Conflict - unable to save due to status mismatch, try refreshing your browser.",
                };
                break;
            case 413:
                errMessage = {
                    ...error,
                    data: getErrorMessage(error, "The request is too large.  Please try again with a smaller request.  Regarding file uploads - please ensure the file size is less than 5MB.")
                };
                break;
            case 415:
                errMessage = {
                    ...error,
                    data: getErrorMessage(error, "The request is invalid.  Please try again with a valid request.  Regarding file uploads - please ensure the file type is valid.")
                };
                break;
            case 500:
                if (error && error?.response && error?.response?.data) {
                    // @ts-ignore
                    if (error.response.data?.message && error.response.data.message !== "An error has occurred.") {
                        // @ts-ignore
                        errMessage = {...error, data: error.response.data.message};
                    } else { // @ts-ignore
                        if (error.response.data?.exceptionMessage) {
                                                // @ts-ignore
                            errMessage = {...error, data: error.response.data.exceptionMessage};
                                            } else {
                                                errMessage = {
                                                    ...error,
                                                    data: "Something went wrong - please wait and try your request again later.  If the problem persists, please contact your IT support team."
                                                };
                                            }
                    }
                } else {
                    errMessage = {
                        ...error,
                        data:
                            "Something went wrong - please wait and try your request again later.  If the problem persists, please contact your IT support team.",
                    };
                }
                break;
            case 503:
                errMessage = {
                    data:
                        "Sorry, we are down for planned maintenance right now.  We are currently working on the site, please come back later.",
                };
                await router.replace("utility-maintenance");
                break;
            default:
                if (error.response?.status === 502 || error.response?.status === 504) {
                    if (store.getters[MaintenanceGetters.EXISTS] || !store.getters[MaintenanceGetters.REQUESTED]) {
                        await store.dispatch(MaintenanceActions.LOAD_MAINTENANCE_FILE, {
                            service: new MaintenanceService(axiosExternal),
                        });

                        await Vue.nextTick();
                        if (store.getters[MaintenanceGetters.EXISTS]) {
                            await router.replace({name: "utility-maintenance"});
                        }
                    }
                }

                if (error && error.response && error.response.data) {
                    // @ts-ignore
                    if (error.response.data?.exceptionMessage) {
                        // @ts-ignore
                        errMessage = {...error, data: error.response.data.exceptionMessage};
                    } else { // @ts-ignore
                        if (error.response.data?.message && error.response.data.message !== "An error has occurred.") {
                                                // @ts-ignore
                            errMessage = {...error, data: error.response.data.message};
                                            } else if (typeof error.response.data === "string" && error.response.data.length > 0) {
                                                errMessage = {...error, data: error.response.data};
                                            } else {
                                                errMessage = {...error, data: "Unknown error has occurred"};
                                            }
                    }
                } else if (error?.message) {
                    errMessage = {...error, data: error.message};
                } else if (error?.response?.statusText) {
                    errMessage = {...error, data: error.response.statusText};
                } else {
                    errMessage = {...error, data: "Unknown error has occurred"};
                }
        break;
    }
}
else
{
    // Axios errors out for some reason / timeout etc
    if(error?.message?.length > 0) {
        if (error?.message?.toLowerCase()?.startsWith("timeout of ")) {
            errMessage = {
                ...error,
                data: "Request timed out.  Please try again later.",
            };
        } else {
            errMessage = {
                ...error,
                data: error.message,
            };
        }
    } else {
        errMessage = {
            ...error,
            data: "Unknown error has occurred with no status code.",
        };
    }
}
return Promise.reject(errMessage);
}
;
axiosInstance.defaults.timeout = process.env.VUE_APP_API_TIMEOUT_IN_SECS ? (parseInt(process.env.VUE_APP_API_TIMEOUT_IN_SECS) * 1000) : 60000;
axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfig<any>) => {
    return requestHandler(request);
});
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => responseSuccessHandler(response),
    (error: AxiosError) => responseErrorHandler(error)
);

export default axiosInstance;
