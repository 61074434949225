import {AnytimeDocumentsService as IAnytimeDocumentsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {AnytimeForm, PacketBody, SharedDocumentsFormUpdate,} from "../../store/interfaces";
import {SharedFormTypes} from "../../store/constants";
import {NewPatientPacket} from "../../store/responsible-parties/interfaces";
import {patientPacketToPacketBodyFormatter} from "@shared/functions/signingDocumentFunctions";

export class AnytimeDocumentsService implements IAnytimeDocumentsService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getAnytimeForms(): Promise<AnytimeForm[]> {
        const response = await this.http.get(`${this.apiUrl}/api/AnytimeForms`);
        return response.data;
    }

    async getCustomForms(): Promise<AnytimeForm[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/AnytimeForms/Detail`
        );
        return response.data;
    }

    async createCustomForm(formId: string, body: AnytimeForm) {
        const response = await this.http.post(
            `${this.apiUrl}/api/AnytimeForms`,
            body,
            {
                params: {formId},
            }
        );
        return response.data[0];
    }

    async getSharedForms(): Promise<AnytimeForm[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/SharedLibraryForms/Detail?formTypeId=${SharedFormTypes.ANYTIME}`
        );
        return response.data;
    }

    async updateShareForms(values: SharedDocumentsFormUpdate): Promise<boolean> {
        const response = await this.http.put(
            `${this.apiUrl}/api/SharedLibraryForms`,
            values,
            {
                params: {...values},
            }
        );
        return response.data;
    }

    async updateCustomForms(
        formId: string,
        values: AnytimeForm
    ): Promise<AnytimeForm> {
        const response = await this.http.put(
            `${this.apiUrl}/api/AnytimeForms`,
            values,
            {
                params: {formId},
            }
        );
        return response.data;
    }

  async updateFormFields(
      formId: string
  ): Promise<boolean> {
    const response = await this.http.post(
        `${this.apiUrl}/api/AnytimeForms/UpdateFormFields`,
        null,
        {
          params: {
            formId,
          },
        }
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  }

    async updateAnytimeFormsOrder(body: AnytimeForm[]): Promise<void> {
        await this.http.put(`${this.apiUrl}/api/AnytimeForms/Sort`, body);
    }

    async deleteForm(id: string): Promise<AnytimeForm> {
        const response = await this.http.delete(`${this.apiUrl}/api/AnytimeForms`, {
            params: {id},
        });

        return response.data;
    }

    async requestEsignURL(formId: string): Promise<boolean> {
        const response = await this.http.post(
            `${this.apiUrl}/api/AnytimeForms/UrlRequest`,
            {formId},
            {
                params: {
                    formId,
                },
            }
        );
        if (response.status === 200) {
            return true;
        }
        return false;
    }

    async postSinglePdf(
        formId: string,
        isShared: boolean | number
    ): Promise<string> {
        if (typeof isShared === "boolean") {
            isShared = isShared === true ? 1 : 0;
        }
        try {
            const response = await this.http.post(
                `${this.apiUrl}/api/AnytimeForms/postSinglePdf`,
                null,
                {params: {formId, isShared}, responseType: "blob"}
            );
            //Create a Blob from the PDF Stream
            const file = new Blob([response.data], {type: "application/pdf"});
            return URL.createObjectURL(file);
        } catch (e) {
              console.error(e);
            const text = " Preview currently unavailable for this form. ";
            const mimeType = "text/plain";
            const file = new Blob([text], { type: mimeType });
            return URL.createObjectURL(file);
        }
    }

    //TODO: Add patient values when patient search is ready
    async postPdf(values: NewPatientPacket): Promise<number> {
        const body: PacketBody = patientPacketToPacketBodyFormatter(values);

        const response = await this.http.post(
            `${this.apiUrl}/api/AnytimeForms/postPdf`,
            body,
        );

        return response.status;
    }
}
