import {AutoPaymentsService} from "../services/interfaces";
import {inject, provide} from "vue";

export const autoPaymentsServiceSymbol = Symbol();

export function provideAutoPaymentsService(service: AutoPaymentsService) {
  return provide(autoPaymentsServiceSymbol, service);
}

export function useAutoPaymentsService() {
  const autoPaymentsService = inject<AutoPaymentsService>(autoPaymentsServiceSymbol);
  if (!autoPaymentsService) {
    throw Error("No Auto Payments Service to Inject!!");
  }
  return autoPaymentsService;
}