import { PatientContract } from "@shared/store/documents/patient-contracts/interfaces";
import { cloneDeep } from "lodash";
import moment from "moment/moment";
import { PAYMENT_PLAN_TYPES_CUSTOM_ORDER, PaymentPlanTypes } from "@shared/store/constants";

//TODO: make sure when a contract is e-signed, it's marked primary, otherwise this logic isn't complete
export function getContractLastMarkedAsPrimary(patientContracts?: PatientContract[]) {
  const selectedContracts = cloneDeep(patientContracts?.filter((item) => item.lastMarkedPrimaryDate) as PatientContract[]);
  // sort those contracts in chronological order of being marked primary, so we can get the most recent one
  const sortedSelectedContracts = selectedContracts?.sort((a: PatientContract, b: PatientContract) => {
    if (moment(a?.lastMarkedPrimaryDate) < moment(b?.lastMarkedPrimaryDate)) {
      return 1;
    } else if (moment(a?.lastMarkedPrimaryDate) > moment(b?.lastMarkedPrimaryDate)) {
      return -1;
    } else {
      return 0;
    }
  });

  if (sortedSelectedContracts && sortedSelectedContracts?.length > 0) {
    return sortedSelectedContracts?.[0];
  }

  const keys = Object.keys(PAYMENT_PLAN_TYPES_CUSTOM_ORDER)?.filter((v) => isNaN(Number(v)));
  for (let i = 0; i < keys?.length; i++) {
    const contract = patientContracts?.find((contract) => PaymentPlanTypes[contract.paymentPlan?.paymentPlanTypeId] === PAYMENT_PLAN_TYPES_CUSTOM_ORDER[i]);
    if (contract) {
      return contract;
    }
  }

  //something is wrong if we get here
  return undefined;

}
