export default[
      {
        key: "Insurance Verification",
        icon: "$handHoldingMedical",
        text: "Insurance Verification",
        link: "/insurance-verification",
        group: "/insurance-verification",
      }
]


