import {inject, provide} from "vue";
import {PatientContractsService} from "../services/interfaces";

export const patientContractsServiceSymbol = Symbol();

export function providePatientContractsService(
  service: PatientContractsService
) {
  return provide(patientContractsServiceSymbol, service);
}

export function usePatientContractsService() {
  const patientContractsService = inject<PatientContractsService>(
      patientContractsServiceSymbol
  );
  if (!patientContractsService) {
    throw Error("No Patient Contracts Service to Inject!!");
  }
  return patientContractsService;
}
