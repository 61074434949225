/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {CalculationRule, RootState, TreatmentType} from "../../index";
import Vue from "vue";
import {CalculationRulesService, TreatmentTypesService,} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {CalculationRulesActions} from "../../calculations/calculation-rules";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface TreatmentTypesState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    treatmentTypes?: TreatmentType[];
    treatmentTypesDetails?: TreatmentType[];
    treatmentTypesWithClasses?: TreatmentType[];
}

export enum TreatmentTypesActions {
    LOAD_TREATMENT_TYPES = "TreatmentTypesModule/loadTreatmentTypes",
    LOAD_TREATMENT_TYPES_DETAILS = "TreatmentTypesModule/loadTreatmentTypesDetails",
    LOAD_TREATMENT_TYPES_WITH_CLASSES = "TreatmentTypesModule/loadTreatmentTypesWithClasses",
    UPDATE_TREATMENT_TYPE = "TreatmentTypesModule/updateTreatmentType",
    DELETE_TREATMENT_TYPE = "TreatmentTypesModule/deleteTreatmentType",
    CREATE_TREATMENT_TYPE = "TreatmentTypesModule/createTreatmentType",
    UPDATE_TREATMENT_TYPE_CALC_RULES = "TreatmentTypesModule/updateTreatmentTypeCalculationRules",
    UPDATE_TREATMENT_TYPES_ORDER = "TreatmentTypesModule/updateTreatmentTypesOrder",
    RESET = "TreatmentTypesModule/reset",
}

export enum TreatmentTypesMutations {
    SET_LOADING = "TreatmentTypesModule/setLoading",
    SET_TREATMENT_TYPES = "TreatmentTypesModule/setTreatmentTypes",
    SET_TREATMENT_TYPES_DETAILS = "TreatmentTypesModule/setTreatmentTypesDetails",
    SET_TREATMENT_TYPES_WITH_CLASSES = "TreatmentTypesModule/setTreatmentTypesWithClasses",
    RESET = "TreatmentTypesModule/reset",
}

export enum TreatmentTypesGetters {
    LOADING = "TreatmentTypesModule/loading",
    SAVING = "TreatmentTypesModule/saving",
    TREATMENT_TYPES = "TreatmentTypesModule/treatmentTypes",
    TREATMENT_TYPES_DETAILS = "TreatmentTypesModule/treatmentTypesDetails",
    TREATMENT_TYPES_WITH_CLASSES = "TreatmentTypesModule/treatmentTypesWithClasses",
    TREATMENT_TYPES_SELECT = "TreatmentTypesModule/treatmentTypesSelect",
    DELETING = "TreatmentTypesModule/deleting",
    HAS_TREATMENT_TYPES = "TreatmentTypesModule/hasTreatmentTypes",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    treatmentTypes: undefined,
    treatmentTypesDetails: undefined,
});

export const TreatmentTypesModule: Module<TreatmentTypesState, RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadTreatmentTypes(
            {commit, dispatch},
            payload: {
                service: TreatmentTypesService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const treatmentTypes: TreatmentType[] = await service.getTreatmentTypes();
                commit("setTreatmentTypes", treatmentTypes);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
    async loadTreatmentTypesWithClasses(
      { commit, dispatch },
      payload: {
        service: TreatmentTypesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const treatmentTypes: TreatmentType[] = await service.getTreatmentTypesWithClasses();
        commit("setTreatmentTypesWithClasses", treatmentTypes);
      } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

        async loadTreatmentTypesDetails(
            {commit, dispatch},
            payload: {
                service: TreatmentTypesService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const treatmentTypes: TreatmentType[] = await service.getTreatmentTypesDetail();
                commit("setTreatmentTypesDetails", treatmentTypes);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },

        async updateTreatmentType(
            {commit, dispatch, getters},
            payload: {
                id: string;
                values: TreatmentType;
                service: TreatmentTypesService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id, values} = payload;

                if(typeof values?.isHybrid !== 'boolean') {
                    values.isHybrid = false;
                }

                const response = await service.updateTreatmentType(id, values);

                const treatmentTypes = cloneDeep(getters.treatmentTypesDetails);
                treatmentTypes.forEach((item: TreatmentType, index: number) => {
                    if (item.treatmentTypeId === id) {
                        treatmentTypes[index] = values;
                    }
                });
                commit("setTreatmentTypesDetails", treatmentTypes);
                if (response === 204) {
                    success = true;
                }
                await SuccessNotification(dispatch, `Updated Treatment Type ${values?.name}!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async deleteTreatmentType(
            {commit, dispatch, getters},
            payload: {
                id: string;
                service: TreatmentTypesService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, id} = payload;
                const deleted = await service.deleteTreatmentType(id);
                if (deleted.treatmentTypeId === id) {
                    const treatmentTypes = cloneDeep(
                        getters.treatmentTypesDetails
                    ).filter(
                        (treatment: TreatmentType) =>
                            treatment.treatmentTypeId !== deleted.treatmentTypeId
                    );
                    commit("setTreatmentTypesDetails", treatmentTypes);
                    success = await SuccessNotification(dispatch, `Deleted Treatment Type ${deleted?.name}!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        async createTreatmentType(
            {commit, dispatch, getters},
            payload: {
                values: TreatmentType;
                service: TreatmentTypesService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;

                if(typeof values?.isHybrid !== 'boolean') {
                    values.isHybrid = false;
                }
                const newTreatmentType = await service.createTreatmentType(values);
                const treatmentTypes = cloneDeep(getters.treatmentTypesDetails);
                treatmentTypes.push(newTreatmentType);
                commit("setTreatmentTypesDetails", treatmentTypes);
                success = await SuccessNotification(dispatch, `Created Treatment Type ${newTreatmentType?.name}!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateTreatmentTypeCalculationRules(
            {commit, dispatch, getters},
            payload: {
                values: CalculationRule[];
                service: CalculationRulesService;
                treatmentTypeId: string;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {values, service, treatmentTypeId} = payload;
                const response = await dispatch(
                    CalculationRulesActions.UPDATE_CALCULATION_RULES_LIST,
                    {
                        values,
                        service,
                        initiatedExternally: true,
                    },
                    {root: true}
                );
                if (response && response.success) {
                    const treatmentTypes = cloneDeep(getters.treatmentTypesDetails);
                    const txTypeIndex = treatmentTypes.findIndex(
                        (item: TreatmentType) => item.treatmentTypeId === treatmentTypeId
                    );

                    if (txTypeIndex === -1)
                        throw Error("Treatment Type no longer exists!");

                    response?.calculationRules?.forEach(
                        (calcRule: CalculationRule, respIndex: number) => {
                            const txTypeCalcRuleIndex = (
                                treatmentTypes[txTypeIndex].calculationRules || []
                            ).findIndex(
                                (item: CalculationRule) =>
                                    item.calculationRuleId === calcRule.calculationRuleId
                            );
                            if (txTypeCalcRuleIndex > -1) {
                                treatmentTypes[txTypeIndex].calculationRules[
                                    txTypeCalcRuleIndex
                                    ] = cloneDeep(
                                    response.calculationRules[respIndex] as CalculationRule
                                );
                            } else {
                                treatmentTypes[txTypeIndex].calculationRules?.push(response.calculationRules[respIndex] as CalculationRule);
                            }
                        }
                    );
                    commit("setTreatmentTypesDetails", treatmentTypes);
                    success = true;
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async updateTreatmentTypesOrder(
            {commit, dispatch, getters},
            payload: {
                values: TreatmentType[];
                service: TreatmentTypesService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;
                const response = await service.updateOrder(values);
                if (response === 204) {
                    commit("setTreatmentTypesDetails", values);
                    success = await SuccessNotification(dispatch, "Updated Order of Treatment Types!");
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: TreatmentTypesState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setTreatmentTypes(
            state: TreatmentTypesState,
            treatmentTypes?: TreatmentType[]
        ) {
            treatmentTypes = treatmentTypes?.sort(
                (a: TreatmentType, b: TreatmentType) => {
                    return a.rank - b.rank;
                }
            );
            Vue.set(state, "treatmentTypes", treatmentTypes);
        },

    setTreatmentTypesWithClasses(
      state: TreatmentTypesState,
      treatmentTypes?: TreatmentType[]
    ) {
      treatmentTypes = treatmentTypes?.sort(
        (a: TreatmentType, b: TreatmentType) => {
          return a.rank - b.rank;
        }
      );
      Vue.set(state, "treatmentTypesWithClasses", treatmentTypes);
    },

        setTreatmentTypesDetails(
            state: TreatmentTypesState,
            treatmentTypes?: TreatmentType[]
        ) {
            treatmentTypes = treatmentTypes?.sort(
                (a: TreatmentType, b: TreatmentType) => {
                    return a.rank - b.rank;
                }
            );
            Vue.set(state, "treatmentTypesDetails", treatmentTypes);
        },
        reset: function (state: TreatmentTypesState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('TreatmentTypesState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        treatmentTypes: (state) => state.treatmentTypes,
        treatmentTypesDetails: (state) => state.treatmentTypesDetails,
    treatmentTypesWithClasses: (state) => state.treatmentTypesWithClasses,
        treatmentTypesSelect: (state) => {
            return (state.treatmentTypes || state.treatmentTypesDetails || []).map(
                (item) => {
                    return {text: item.name, value: item.treatmentTypeId};
                }
            );
        },
        hasTreatmentTypes: (state) => !!state.treatmentTypesDetails?.length,
    },
};
