import {
    PatientTreatmentPlanAdditionalChargesService as IPatientTreatmentPlanAdditionalChargesService
} from "../interfaces";
import {AxiosInstance} from "axios";
import {PatientAdditionalCharge} from "../../store";

export class PatientTreatmentPlanAdditionalChargesService
  implements IPatientTreatmentPlanAdditionalChargesService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getSelectedTreatmentPlanAdditionalCharges(id: string): Promise<PatientAdditionalCharge[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/PatientAdditionalCharges?patientTreatmentPlanId=${id}`,
    );
    return response.data;
  }
}
