//Font Awesome Icons:  https://fontawesome.com/icons?d=gallery
// library info: https://github.com/FortAwesome/vue-fontawesome#usage
// vuetify with pro icons: https://vuetifyjs.com/en/features/icons/#missing-material-icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAnalytics as falAnalytics,
  faArchive as falArchive,
  faArrowsAltV as falArrowsAltV,
  faBan as falBan,
  faBars as falBars,
  faBriefcase as falBriefcase,
  faCaretDown as falCaretDown,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faCheckSquare as falCheckSquare,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faCircle as falCircle,
  faCircleExclamation as falCircleExclamation,
  faCircleQuestion as falCircleQuestion,
  faClipboardUser as falClipboardUser,
  faCogs as falCogs,
  faCompressAlt as falCompressAlt,
  faDotCircle as falDotCircle,
  faEdit as falEdit,
  faExclamationTriangle as falExclamationTriangle,
  faExpandAlt as falExpandAlt,
  faFileInvoiceDollar as falFileInvoiceDollar,
  faFilePdf as falFilePdf,
  faFileSignature as falFileSignature,
  faFilesMedical as falFilesMedical,
  faFileUser as falFileUser,
  faHistory as falHistory,
  faInfoCircle as falInfoCircle,
  faMinus as falMinus,
  faMinusSquare as falMinusSquare,
  faPaperclip as falPaperclip,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faPlusSquare as falPlusSquare,
  faSearch as falSearch,
  faShapes as falShapes,
  faSignOut as falSignOut,
  faSlidersH as falSliders,
  faSortUp as falSortUp,
  faSquare as falSquare,
  faStar as falStar,
  faStarHalf as falStarHalf,
  faStepBackward as falStepBackward,
  faStepForward as falStepForward,
  faSync as falSync,
  faSyncAlt as falSyncAlt,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faUserCircle as falUserCircle,
  faUserCog as falUserCog,
  faUserCrown as falUserCrown,
  faUserFriends as falUserFriends,
  faUsersCog as falUsersCog,
  faSplit as falSplit,
} from "@fortawesome/pro-light-svg-icons";
import {
  faAlignLeft as farAlignLeft,
  faArrowRight as farArrowRight,
  faArrowUpRightAndArrowDownLeftFromCenter as farArrowRightArrowLeftFromCenter,
  faCalculatorAlt as farCalculatorAlt,
  faBell as farBell,
  faCalendar as farCalendar,
  faCalendarCheck as farCalendarCheck,
  faCamera as farCamera,
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faClinicMedical as farClinicMedical,
  faClipboard as farClipboard,
  faCopy as farCopy,
  faDollarSign as farDollarSign,
  faDownload as farDownload,
  faEllipsisV as farEllipsisV,
  faEnvelope as farEnvelope,
  faExclamationCircle as farExclamationCircle,
  faExternalLink as farExternalLink,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFileContract as farFileContract,
  faFileInvoiceDollar as farFileInvoiceDollar,
  faFileMedicalAlt as farFileMedicalAlt,
  faFilePdf as farFilePdf,
  faFilePlus as farFilePlus,
  faFileSpreadsheet as farFileSpreadsheet,
  faFilter as farFilter,
  faHandHoldingMedical as farHandHoldingMedical,
  faHistory as farHistory,
  faHome as farHome,
  faInfoCircle as farInfoCircle,
  faLock as farLock,
  faMobile as farMobile,
  faPercent as farPercent,
  faPlus as farPlus,
  faPrint as farPrint,
  faQuestionCircle as farQuestionCircle,
  faRedo as farRedo,
  faRightFromBracket as farRightFromBracket,
  faSave as farSave,
  faSignsPost as farSignsPost,
  faSortAlt as farSortAlt,
  faStickyNote as farStickyNote,
  faTextSize as farTextSize,
  faTimesCircle as farTimesCircle,
  faTrash as farTrash,
  faUserCheck as farUserCheck,
  faUserGroup as farUsersGroup,
  faUserPlus as farUserPlus,
  faUsers as farUsers,
  faWrench as farWrench,
  faBug as farBug,
  faCalendarStar as farCalendarStar,
  faCalendarClock as farCalendarClock,
  faFileLines as farFileLines,
  faCommentQuestion as farCommentQuestion,
  faEnvelopeDot as farEnvelopeDot,
  faEnvelopeOpenText as farEnvelopeOpenText,
  faBellOn as farBellOn,
  faBellSlash as farBellSlash,
  faCircleChevronUp as farCircleChevronUp,
  faCircleChevronDown as farCircleChevronDown,
  faSlash as farSlash,

} from "@fortawesome/pro-regular-svg-icons";

import {
    faArchive as fasArchive,
    faArrowLeft as fasArrowLeft,
    faArrowUp as fasArrowUp,
    faBellSlash as fasBellSlash,
    faCircle as fasCircle,
    faFilter as fasFilter,
    faMoon as fasMoon,
    faPencil as fasPencil,
    faShieldAlt as fasShieldAlt,
    faSun as fasSun,
    faTh as fasTh,
    faBell as fasBell,
    faCircleQuestion as fasCircleQuestion,
    faPeopleArrows as fasPeopleArrows,
  faBriefcaseBlank as fasBriefcaseBlank,
} from "@fortawesome/pro-solid-svg-icons";

import { faYoutube as fabYoutube } from "@fortawesome/free-brands-svg-icons";
import { VuetifyIcons } from "vuetify/types/services/icons";

const makeIconComponentDeclaration = (icon: any, size: string = "sm") => {
  library.add(icon); // This is important!
  return {
    component: FontAwesomeIcon,
    props: {
      size: size,
      icon: [icon.prefix, icon.iconName]
    }
  };
};

// Maps internal Vuetify icon names to Font-Awesome 5+ icon names.
export const ICONS_FONTAWESOME: VuetifyIcons = {
  clipboard: makeIconComponentDeclaration(farClipboard),
  fileContract: makeIconComponentDeclaration(farFileContract),
  alignLeft: makeIconComponentDeclaration(farAlignLeft),
  clinicMedical: makeIconComponentDeclaration(farClinicMedical),
  calculatorAlt: makeIconComponentDeclaration(farCalculatorAlt),
  fileInvoiceDollar: makeIconComponentDeclaration(farFileInvoiceDollar),
  fileMedicalAlt: makeIconComponentDeclaration(farFileMedicalAlt),
  complete: makeIconComponentDeclaration(falCheck),
  cancel: makeIconComponentDeclaration(falTimesCircle),
  close: makeIconComponentDeclaration(falTimes),
  delete: makeIconComponentDeclaration(falTimesCircle), // delete (e.g. v-chip close)
  clear: makeIconComponentDeclaration(falTimesCircle), // delete (e.g. v-chip close)
  success: makeIconComponentDeclaration(falCheckCircle),
  info: makeIconComponentDeclaration(falInfoCircle),
  warning: makeIconComponentDeclaration(falCircleExclamation),
  error: makeIconComponentDeclaration(falExclamationTriangle),
  prev: makeIconComponentDeclaration(falChevronLeft),
  next: makeIconComponentDeclaration(falChevronRight),
  checkboxOn: makeIconComponentDeclaration(falCheckSquare),
  checkboxOff: makeIconComponentDeclaration(falSquare), // note 'far'
  checkboxIndeterminate: makeIconComponentDeclaration(falMinusSquare),
  delimiter: makeIconComponentDeclaration(falCircle), // for carousel
  sort: makeIconComponentDeclaration(falSortUp),
  expand: makeIconComponentDeclaration(falChevronDown),
  menu: makeIconComponentDeclaration(falBars),
  subgroup: makeIconComponentDeclaration(falCaretDown),
  dropdown: makeIconComponentDeclaration(falCaretDown),
  radioOn: makeIconComponentDeclaration(falDotCircle),
  radioOff: makeIconComponentDeclaration(falCircle),
  edit: makeIconComponentDeclaration(falEdit),
  ratingEmpty: makeIconComponentDeclaration(falStar),
  ratingFull: makeIconComponentDeclaration(falStar),
  ratingHalf: makeIconComponentDeclaration(falStarHalf),
  loading: makeIconComponentDeclaration(falSync),
  first: makeIconComponentDeclaration(falStepBackward),
  last: makeIconComponentDeclaration(falStepForward),
  unfold: makeIconComponentDeclaration(falArrowsAltV),
  file: makeIconComponentDeclaration(falPaperclip),
  plus: makeIconComponentDeclaration(falPlus),
  minus: makeIconComponentDeclaration(falMinus),
  cogs: makeIconComponentDeclaration(falCogs),
  th: makeIconComponentDeclaration(fasTh),
  users: makeIconComponentDeclaration(farUsers),
  "users-cog": makeIconComponentDeclaration(falUsersCog),
  "user-cog": makeIconComponentDeclaration(falUserCog),
  bell: makeIconComponentDeclaration(farBell),
  search: makeIconComponentDeclaration(falSearch),
  searchXs: makeIconComponentDeclaration(falSearch, "xs"),
  "sign-out": makeIconComponentDeclaration(falSignOut),
  analytics: makeIconComponentDeclaration(falAnalytics),
  pencil: makeIconComponentDeclaration(fasPencil),
  refresh: makeIconComponentDeclaration(falSyncAlt),
  "arrow-up": makeIconComponentDeclaration(fasArrowUp),
  "arrow-left": makeIconComponentDeclaration(fasArrowLeft),
  redo: makeIconComponentDeclaration(farRedo),
  "ellipsis-v": makeIconComponentDeclaration(farEllipsisV),
  trash: makeIconComponentDeclaration(farTrash),
  circle: makeIconComponentDeclaration(fasCircle),
  "external-link": makeIconComponentDeclaration(farExternalLink),
  "user-circle": makeIconComponentDeclaration(falUserCircle),
  eye: makeIconComponentDeclaration(farEye),
  "eye-slash": makeIconComponentDeclaration(farEyeSlash),
  "user-check": makeIconComponentDeclaration(farUserCheck),
  envelope: makeIconComponentDeclaration(farEnvelope),
  "clipboard-user": makeIconComponentDeclaration(falClipboardUser),
  "shield-alt": makeIconComponentDeclaration(fasShieldAlt),
  "empty-circle": makeIconComponentDeclaration(farCircle),
  "check-circle": makeIconComponentDeclaration(farCheckCircle),
  "plus-regular": makeIconComponentDeclaration(farPlus),
  print: makeIconComponentDeclaration(farPrint),
  sun: makeIconComponentDeclaration(fasSun),
  moon: makeIconComponentDeclaration(fasMoon),
  "exclamation-circle": makeIconComponentDeclaration(farExclamationCircle),
  "times-circle": makeIconComponentDeclaration(farTimesCircle),
  "info-circle": makeIconComponentDeclaration(farInfoCircle),
  "question-circle": makeIconComponentDeclaration(farQuestionCircle),
  percent: makeIconComponentDeclaration(farPercent),
  dollar: makeIconComponentDeclaration(farDollarSign),
  camera: makeIconComponentDeclaration(farCamera),
  "calendar-check": makeIconComponentDeclaration(farCalendarCheck),
  "user-plus": makeIconComponentDeclaration(farUserPlus),
  calendar: makeIconComponentDeclaration(farCalendar),
  sortAlt: makeIconComponentDeclaration(farSortAlt),
  filePdf: makeIconComponentDeclaration(farFilePdf),
  save: makeIconComponentDeclaration(farSave),
  note: makeIconComponentDeclaration(farStickyNote),
  home: makeIconComponentDeclaration(farHome),
  handHoldingMedical: makeIconComponentDeclaration(farHandHoldingMedical),
  download: makeIconComponentDeclaration(farDownload),
  "file-plus": makeIconComponentDeclaration(farFilePlus),
  copy: makeIconComponentDeclaration(farCopy),
  minusSquare: makeIconComponentDeclaration(falMinusSquare),
  plusSquare: makeIconComponentDeclaration(falPlusSquare),
  expandAlt: makeIconComponentDeclaration(falExpandAlt),
  compressAlt: makeIconComponentDeclaration(falCompressAlt),
  responsibleParties: makeIconComponentDeclaration(falUserFriends),
  shapes: makeIconComponentDeclaration(falShapes),
  filterEmpty: makeIconComponentDeclaration(farFilter),
  filterFull: makeIconComponentDeclaration(fasFilter),
  archive: makeIconComponentDeclaration(falArchive),
  archiveFull: makeIconComponentDeclaration(fasArchive),
  plusCircle: makeIconComponentDeclaration(falPlusCircle),
  sliders: makeIconComponentDeclaration(falSliders),
  briefcase: makeIconComponentDeclaration(falBriefcase),
  fileInvoiceDollarLight: makeIconComponentDeclaration(falFileInvoiceDollar),
  fileSignature: makeIconComponentDeclaration(falFileSignature),
  fileUser: makeIconComponentDeclaration(falFileUser),
  filesMedical: makeIconComponentDeclaration(falFilesMedical),
  filePdfLight: makeIconComponentDeclaration(falFilePdf),
  history: makeIconComponentDeclaration(farHistory),
  historyLight: makeIconComponentDeclaration(falHistory),
  lock: makeIconComponentDeclaration(farLock),
  mobile: makeIconComponentDeclaration(farMobile),
  fileSpreadsheet: makeIconComponentDeclaration(farFileSpreadsheet),
  ban: makeIconComponentDeclaration(falBan),
  signsPost: makeIconComponentDeclaration(farSignsPost),
  circleQuestion: makeIconComponentDeclaration(falCircleQuestion),
  exit: makeIconComponentDeclaration(farRightFromBracket),
  maximize: makeIconComponentDeclaration(farArrowRightArrowLeftFromCenter),
  continue: makeIconComponentDeclaration(farArrowRight),
  help: makeIconComponentDeclaration(falCircleQuestion),
  helpSolid: makeIconComponentDeclaration(fasCircleQuestion),
  youtube: makeIconComponentDeclaration(fabYoutube),
  textSize: makeIconComponentDeclaration(farTextSize),
  admin: makeIconComponentDeclaration(falUserCrown),
  clients: makeIconComponentDeclaration(farUsersGroup),
  wrench: makeIconComponentDeclaration(farWrench),
  bug: makeIconComponentDeclaration(farBug),
  "calendar-star": makeIconComponentDeclaration(farCalendarStar),
  "calendar-clock": makeIconComponentDeclaration(farCalendarClock),
  "file-lines": makeIconComponentDeclaration(farFileLines),
  "comment-question": makeIconComponentDeclaration(farCommentQuestion),
  bellSlash: makeIconComponentDeclaration(fasBellSlash),
  "envelope-dot": makeIconComponentDeclaration(farEnvelopeDot),
  "envelope-open-text": makeIconComponentDeclaration(farEnvelopeOpenText),
  "bell-on": makeIconComponentDeclaration(farBellOn),
  "bell-slash": makeIconComponentDeclaration(farBellSlash),
  "bell-solid": makeIconComponentDeclaration(fasBell),
  "circle-chevron-up": makeIconComponentDeclaration(farCircleChevronUp),
  "circle-chevron-down": makeIconComponentDeclaration(farCircleChevronDown),
  "people-arrows": makeIconComponentDeclaration(fasPeopleArrows),
  "slash": makeIconComponentDeclaration(farSlash),
  "briefcase-blank": makeIconComponentDeclaration(fasBriefcaseBlank),
  "split": makeIconComponentDeclaration(falSplit),
};

//Usages:
//RECOMMENDED:
// for built-in icons in vuetify: <font-awesome-icon slot="prepend">
// otherwise use the <font-awesome-icon :icon="['fab', 'font-awesome']" />
// more info and options on the vue-fontawesome https://github.com/FortAwesome/vue-fontawesome
//SECONDARY:
//<v-icon>$vuetify.icons.menu</v-icon>
//<v-icon>$menu</v-icon>
