import {Module} from "vuex";
import {RootState} from "../index";
import Vue from "vue";
import {UIService} from "../../services/UIService";
import {
    APP_TYPES,
    APP_UI_THEME_BROADCAST_CHANNEL,
    BROADCAST_CHANNEL_OPTIONS,
    BROWSER_TYPES,
    Themes
} from "../constants";
import {BroadcastChannel} from 'broadcast-channel';
import {WarningNotification} from "@shared/functions/NotificationFunctions";

function isChromium(navigator: any) {
    if(!navigator) {
        return false;
    }

  for (const brand_version_pair of navigator?.userAgentData?.brands) {   // eslint-disable-line
    if (brand_version_pair?.brand == "Chromium"){
      return true;
    }
  }
  return false;
}

export interface UIState {
    globalTheme?: string;
    isSafari: boolean;
    isChromium: boolean;
    browserType?: string;
}

export enum UIActions {
    LOAD_GLOBAL_THEME = "UIModule/loadGlobalTheme",
    SAVE_GLOBAL_THEME = "UIModule/saveGlobalTheme",
    SAVE_BROWSER = "UIModule/saveBrowser",
}

export enum UIMutations {
    SET_GLOBAL_THEME = "UIModule/setGlobalTheme",
}

export enum UIGetters {
    GLOBAL_THEME = "UIModule/globalTheme",
    IS_DARK_THEME = "UIModule/isDarkTheme",
    IS_SAFARI = "UIModule/isSafari",
    // IS_CHROMIUM = "UIModule/isChromium",
    BROWSER_TYPE = "UIModule/browserType",
}

const initialState = () => ({
    globalTheme: undefined,
    isSafari: false,
    isChromium: false,
    browserType: undefined,
});
export const UIModule: Module<UIState, RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadGlobalTheme(
            {commit},
            payload: {
                service: UIService;
            }
        ) {
            try {
                const {service} = payload;
                const theme = process.env.VUE_APP_TYPE === APP_TYPES.PORTAL ? Themes.LIGHT : await service.getGlobalTheme();
                commit("setGlobalTheme", theme);
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error("error", error);
                }
            }
        },
        async saveGlobalTheme(
            {commit},
            payload: {
                theme: string;
                service: UIService;
            }
        ) {
            try {
                const {theme, service} = payload;
                await service.saveGlobalTheme(theme);
                commit("setGlobalTheme", theme);
                const bcTheme = new BroadcastChannel(APP_UI_THEME_BROADCAST_CHANNEL, BROADCAST_CHANNEL_OPTIONS);
                await bcTheme?.postMessage(theme);
                await bcTheme?.close();
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        },
        async saveBrowser(
            {commit, getters, dispatch
            },
            payload: {
                navigator: Navigator;
            }
        ) {
            try {
                const {navigator} = payload;
                if(!navigator)  {
                    console.error("Unable to determine user agent");
                    return;
                }
                commit("setBrowserType", navigator);
                if (getters.browserType === BROWSER_TYPES.INTERNET_EXPLORER) {
                    await WarningNotification(dispatch, "Internet Explorer is not a supported browser, we highly recommended you use Chrome for best results or another chromium browser like (firefox, edge)");
                }
            } catch (error) {
                if (process.env.NODE_ENV == "development") {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        },
    },
    mutations: {
        setGlobalTheme(state: UIState, theme: string) {
            Vue.set(state, "globalTheme", theme);
        },
        setBrowserType(state: UIState, navigator: Navigator) {
            // // Get the user-agent string
            const userAgentString =
                navigator?.userAgent || "";

            // Detect Chrome
            let chromeAgent =
                userAgentString.indexOf("Chrome") > -1;

            // Detect Internet Explorer
            const iExplorerAgent =
                userAgentString.indexOf("MSIE") > -1 ||
                userAgentString.indexOf("rv:") > -1;

            // Detect Firefox
            const firefoxAgent =
                userAgentString.indexOf("Firefox") > -1;

            // Detect Safari
            let safariAgent =
                userAgentString.indexOf("Safari") > -1;

            // Discard Safari since it also matches Chrome
            if ((chromeAgent) && (safariAgent))
              safariAgent = false;

            // Detect Opera
            const operaAgent =
                userAgentString.indexOf("OP") > -1;

            // Discard Chrome since it also matches Opera
            if ((chromeAgent) && (operaAgent))
              chromeAgent = false;


            //browserType
            let browserType = '';
            if(chromeAgent) browserType = BROWSER_TYPES.CHROME;
            else if(safariAgent) browserType = BROWSER_TYPES.SAFARI;
            else if(firefoxAgent) browserType = BROWSER_TYPES.FIREFOX;
            else if(iExplorerAgent) browserType = BROWSER_TYPES.INTERNET_EXPLORER;
            else if(operaAgent) browserType = BROWSER_TYPES.OPERA;


            Vue.set(state, "browserType", browserType);
            Vue.set(state, "isSafari", safariAgent);
            Vue.set(state, "isChromium", isChromium(navigator));
        },
    },
    getters: {
        globalTheme: (state) => state.globalTheme,
        isDarkTheme: (state) => state.globalTheme === 'dark',
        isChromium: (state) => state.isChromium,
        isSafari: (state) => state.isSafari,
        browserType: (state) => state.browserType,
    },
};
