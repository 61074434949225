import {AxiosInstance} from "axios";

import {PatientTreatmentPlanCommunicationsService as IPatientTreatmentPlanCommunicationsService} from "../interfaces";
import {PatientTreatmentPlanCommunicationsSubset} from "@shared/store";
import {NEW_ITEM_ID} from "@shared/store/constants";
import {ESignerData, PatientTreatmentPlanWithAdjustments} from "@shared/store/treatment-plans/interfaces";

export class PatientTreatmentPlanCommunicationsService
    implements IPatientTreatmentPlanCommunicationsService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getOfficeInfo(patientTreatmentPlanId: string, userId: string): Promise<PatientTreatmentPlanCommunicationsSubset> {
        const response = await this.http.get(
            `${this.apiUrl}/api/PatientTreatmentPlanCommunications/OfficeInfo`,
            {
                params: {patientTreatmentPlanId: patientTreatmentPlanId, userId: userId ? userId : NEW_ITEM_ID},
            }
        );
        return response.data;
    }

    async contractSigningSession(eSignerData: ESignerData): Promise<string> {
        const response = await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlanCommunications/ContractSigningSession`,
            eSignerData,
            {
                params: {
                    patientContractId: eSignerData.patientContractId,
                    patientTreatmentPlanId: eSignerData.patientTreatmentPlanId
                }
            }
        );

        if (Array.isArray(response.data)) {
            return response.data.length ? response.data[0] : "";
        }

        return response.data;
    }

    async adjustPatientTreatmentPlan(id: string, patientTreatmentPlanWithAdjustments: PatientTreatmentPlanWithAdjustments, isPatient: boolean = true): Promise<number> {
        const response = await this.http.patch(
            `${this.apiUrl}/api/PatientTreatmentPlanCommunications/${id}`,
            patientTreatmentPlanWithAdjustments,
            {
                params: {isPatient},
            }
        );
        return response.data;
    }

    async declineTreatment(id: string, contractId: string): Promise<number> {
        return await this.http.post(
            `${this.apiUrl}/api/PatientTreatmentPlanCommunications/${id}/DeclineTreatment`,
          null,
            {
                params: {contractId: contractId},
            }
        );
    }
}
