/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {ProductTypeService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {ProductType} from "../interfaces";
import {ProductFee} from "../../calculations/interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface ProductTypesState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  productTypesDetail?: ProductType[];
  productTypesDetailSortedAlphabetically?: ProductType[];
}

export enum ProductTypesActions {
  LOAD_PRODUCT_TYPES_DETAIL = "ProductTypesModule/loadProductTypesDetail",
  DELETE_PRODUCT_TYPE = "ProductTypesModule/deleteProductType",
  UPDATE_PRODUCT_TYPE = "ProductTypesModule/updateProductType",
  CREATE_PRODUCT_TYPE = "ProductTypesModule/createProductType",
  RESET = "ProductTypesModule/reset",
}

export enum ProductTypesMutations {
  SET_LOADING = "ProductTypesModule/setLoading",
  SET_PRODUCT_TYPES_DETAIL = "ProductTypesModule/setProductTypesDetail",
  RESET = "ProductTypesModule/reset",
}

export enum ProductTypesGetters {
  LOADING = "ProductTypesModule/loading",
  SAVING = "ProductTypesModule/saving",
  DELETING = "ProductTypesModule/deleting",
  PRODUCT_TYPES_DETAIL = "ProductTypesModule/productTypesDetail",
  PRODUCT_TYPES_DETAIL_SORTED_ALPHABETICALLY = "ProductTypesModule/productTypesDetailSortedAlphabetically",
  HAS_PRODUCT_TYPES_DETAIL = "ProductTypesModule/hasProductTypesDetail",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  productTypesDetail: undefined,
  productTypesDetailSortedAlphabetically: undefined,
});

export const ProductTypesModule: Module<ProductTypesState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadProductTypesDetail(
      { commit, dispatch },
      payload: {
        service: ProductTypeService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const productTypes: ProductType[] = await service.getProductTypesDetail();
        commit("setProductTypesDetail", productTypes);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async deleteProductType(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: ProductTypeService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted: ProductType = await service.deleteProductType(id);
        if (deleted.productTypeId === id) {
          const productTypes = cloneDeep(getters.productTypesDetail).filter(
            (productType: ProductType) => productType.productTypeId !== id
          );
          commit("setProductTypesDetail", productTypes);
          success = await SuccessNotification(dispatch, `Deleted product type!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async updateProductType(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: ProductType;
        service: ProductTypeService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values } = payload;
        const response = await service.updateProductType(id, values);

        if (response === 204) {
          const productTypes = cloneDeep(getters.productTypesDetail);
          productTypes.forEach((item: ProductType, index: number) => {
            if (item.productTypeId === id) {
              productTypes[index] = values;
            }
          });
          commit("setProductTypesDetail", productTypes);
          success = true;
        }
        await SuccessNotification(dispatch, `Updated Product Type!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async createProductType(
      { commit, dispatch, getters },
      payload: {
        values: ProductType;
        service: ProductTypeService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newProductType = await service.createProductType(values);
        const productTypes = cloneDeep(getters.productTypesDetail);
        productTypes.push(newProductType);
        commit("setProductTypesDetail", productTypes);
        success = await SuccessNotification(dispatch, `Created product type!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: ProductTypesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setProductTypesDetail(
      state: ProductTypesState,
      productTypes?: ProductType[]
    ) {
      productTypes?.sort((a: ProductType, b: ProductType) => {
        if (a?.name?.toLocaleLowerCase() < b?.name?.toLocaleLowerCase()) {
          return -1;
        }
        if (a?.name?.toLocaleLowerCase() > b?.name?.toLocaleLowerCase()) {
          return 1;
        }
        return 0;
      });
      Vue.set(state, "productTypesDetail", cloneDeep(productTypes));

      productTypes?.forEach((pt) => {
        pt?.productFees?.sort((a: ProductFee, b: ProductFee) => {
          if (a?.productName?.toLocaleLowerCase() < b?.productName?.toLocaleLowerCase()) {
            return -1;
          }
          if (a?.productName?.toLocaleLowerCase() > b?.productName?.toLocaleLowerCase()) {
            return 1;
          }
          return 0;
        });
      });
      Vue.set(state, "productTypesDetailSortedAlphabetically", cloneDeep(productTypes));
    },
      reset: function (state: ProductTypesState) {
          const newState = initialState();
          Object.keys(newState).forEach(key => {
              try {
                  // @ts-ignore
                  state[key] = newState[key];
              } catch (ex) {
                  console.error('ProductTypesState Reset Error: ', ex.message);
              }
          });
      },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    productTypesDetail: (state) => state.productTypesDetail,
    productTypesDetailSortedAlphabetically: (state) => state.productTypesDetailSortedAlphabetically,
    hasProductTypesDetail: (state) => state.productTypesDetail?.length,
  },
};
