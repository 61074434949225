import {AppNames} from "@shared/store/constants";

export default [
  {
    path: "/subscriptions/dashboard",
    name: "subscriptions-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../pages/dashboard/DashboardPage.vue"
        ),
    meta: {
      layout: "Subscription",
      title: "Dashboard",
      type: AppNames.SUBSCRIPTIONS,
      rank: -1,
    },
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "subscriptions" */ "../pages/subscriptions/SubscriptionsPage.vue"
      ),
    meta: {
      type: AppNames.SUBSCRIPTIONS,
      layout:"Subscription",
      title: "Subscriptions",
      rank: 1,
    },
  },
];
