import {TreatmentCoordinatorsService as ITreatmentCoordinatorsService} from "../interfaces";
import {TreatmentCoordinator} from "../../store/practice/interfaces";
import {AxiosInstance} from "axios";

export class TreatmentCoordinatorsService
  implements ITreatmentCoordinatorsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getCoordinators(): Promise<TreatmentCoordinator[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentCoordinators`);
    return response.data;
  }

  async updateCoordinator(
    id: string,
    values: TreatmentCoordinator,
    treatmentCoordinatorId?: string
  ): Promise<number> {
    const params: { id: string; treatmentCoordinatorId?: string } = { id };
    if (treatmentCoordinatorId) {
      params.treatmentCoordinatorId = treatmentCoordinatorId;
    }
    const response = await this.http.put(
      `${this.apiUrl}/api/TreatmentCoordinators`,
      { ...values },
      {
        params,
      }
    );
    return response.status;
  }

  async createCoordinator(values: TreatmentCoordinator) {
    const response = await this.http.post(
      `${this.apiUrl}/api/TreatmentCoordinators`,
      { ...values }
    );
    return response.data;
  }
}
