/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
// DO NOT MOVE THIS OR ELSE
import {PatientInsuranceVerification} from "../../interfaces";
import {AdminInsuranceVerificationService} from "../../../services/interfaces";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface AdminInsuranceVerificationState {
  loading: boolean;
  saving: boolean;
  insuranceVerifications?: PatientInsuranceVerification[];
}

export enum AdminInsuranceVerificationActions {
  LOAD_VERIFICATIONS = "AdminInsuranceVerificationModule/loadVerifications",
  RESET = "AdminInsuranceVerificationModule/reset",
}

export enum AdminInsuranceVerificationMutations {
  SET_LOADING = "AdminInsuranceVerificationModule/setLoading",
  SET_INSURANCE_VERIFICATIONS = "AdminInsuranceVerificationModule/setInsuranceVerifications",
  RESET = "AdminInsuranceVerificationModule/reset",
}

export enum AdminInsuranceVerificationGetters {
  LOADING = "AdminInsuranceVerificationModule/loading",
  SAVING = "AdminInsuranceVerificationModule/saving",
  INSURANCE_VERIFICATIONS = "AdminInsuranceVerificationModule/insuranceVerifications",
}

const initialState = () => ({
  loading: true,
  saving: false,
  insuranceVerifications: undefined,
});

export const AdminInsuranceVerificationModule: Module<
  AdminInsuranceVerificationState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadVerifications(
      { commit, dispatch },
      payload: {
        service: AdminInsuranceVerificationService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const verifications: PatientInsuranceVerification[] = await service.getPatientInsuranceVerifications();

        commit("setVerifications", verifications);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: AdminInsuranceVerificationState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setVerifications(
      state: AdminInsuranceVerificationState,
      verifications?: PatientInsuranceVerification[]
    ) {
      Vue.set(state, "insuranceVerifications", verifications);
    },
    reset: function (state: AdminInsuranceVerificationState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('AdminInsuranceVerificationState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    insuranceVerifications: (state) => state.insuranceVerifications,
  },
};
