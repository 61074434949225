import {inject, provide} from "vue";
import {PatientIntakeDocumentsService} from '../services/document-services/PatientIntakeDocumentsService';

export const patientIntakeDocumentsServiceSymbol = Symbol();

export function providePatientIntakeDocumentsService(service: PatientIntakeDocumentsService) {
  return provide(patientIntakeDocumentsServiceSymbol, service);
}

export function usePatientIntakeDocumentsService() {
  const patientIntakeDocumentsService = inject<PatientIntakeDocumentsService>(patientIntakeDocumentsServiceSymbol);
  if (!patientIntakeDocumentsService) {
    throw Error("No Patient Intake Documents Service to Inject!!");
  }
  return patientIntakeDocumentsService;
}