import {AppNames} from "@shared/store/constants";

export default [
  {
    path: "/auth/signin",
    name: "auth-signin",
    alias: "/auth/login",
    component: () =>
      import(
        /* webpackChunkName: "auth-signin" */ "@portal/pages/auth/PortalSigninPage.vue"
      ),
    meta: {
      layout: "Auth",
      type: AppNames.AUTH,
      title: "Sign In",
    },
  },
   {
    path: "/error/not-found",
    name: "error-not-found",
    component: () =>
      import(
        /* webpackChunkName: "error-not-found" */ "@shared/pages/error/NotFoundPage.vue"
      ),
    meta: {
      layout: "Error",
      title: "Not Found",
    },
  },
  {
    path: "/error/unexpected",
    name: "error-unexpected",
    component: () =>
      import(
        /* webpackChunkName: "error-unexpected" */ "@shared/pages/error/UnexpectedPage.vue"
      ),
    meta: {
      layout: "Error",
      title: "Unexpected Error",
    },
  },
  {
    path: "/utility/maintenance",
    name: "utility-maintenance",
    component: () =>
      import(
        /* webpackChunkName: "utility-maintenance" */ "@shared/pages/utility/MaintenancePage.vue"
      ),
    meta: {
      layout: "Auth",
      title: "Maintenance",
    },
  },
  {
    path: "/utility/coming-soon",
    name: "utility-soon",
    component: () =>
      import(
        /* webpackChunkName: "utility-soon" */ "@shared/pages/utility/SoonPage.vue"
      ),
    meta: {
      layout: "Auth",
      title: "Coming Soon",
    },
  },
  {
    path: "/utility/help",
    name: "utility-help",
    component: () =>
      import(
        /* webpackChunkName: "utility-help" */ "@shared/pages/utility/HelpPage.vue"
      ),
  },
];
