import {PaymentPlansService} from "../services/interfaces";
import {inject, provide} from "vue";

export const paymentPlansServiceSymbol = Symbol();

export function providePaymentPlansService(service: PaymentPlansService) {
  return provide(paymentPlansServiceSymbol, service);
}

export function usePaymentPlansService() {
  const paymentPlansService = inject<PaymentPlansService>(
    paymentPlansServiceSymbol
  );
  if (!paymentPlansService) {
    throw Error("No Payment Plans Service to Inject!!");
  }
  return paymentPlansService;
}
