/* eslint-disable no-unused-vars */
import { Module } from "vuex";
import { NavMenu, NavMenuItem, PatientDataSearchParams, RootState, TreatmentPlan } from "../index";
import Vue from "vue";
import { PatientService, PatientTreatmentPlansService, ResponsiblePartiesService } from "../../services/interfaces";
import { cloneDeep, isEqual } from "lodash";

// DO NOT MOVE THIS OR ELSE
import { DuplicatePatient, Patient } from "../interfaces";
import {
  DUPLICATION_TYPES,
  LoadingType,
  NEW_ITEM_ID,
  PATIENT_FILTER_STATUSES_TYPES,
  PATIENT_FILTERED_STATUSES_TOTALS_DEFAULT, PatientStatuses,
  SORT_ORDER_DIRECTION
} from "../constants";
import { ClientSubscriptionsGetters } from "../financials/client-subscriptions";
import moment from "moment/moment";
import { PatientResponsiblePartyTier, ResponsibleParty } from "../responsible-parties/interfaces";
import { ListPatient, ListPatientInfo } from "./interfaces";
import {
  PatientTreatmentPlansActions,
  PatientTreatmentPlansGetters,
  PatientTreatmentPlansMutations
} from "../treatment-plans";
import { PatientFormsAndSignaturesMutations } from "./patient-forms-and-signatures";
import { PatientContractsGetters, PatientContractsMutations } from "../documents/patient-contracts";
import { PatientContract } from "../documents/patient-contracts/interfaces";
import { getDate } from "@shared/functions/dateFunctions";
import { ErrorNotification, SuccessNotification, WarningNotification } from "@shared/functions/NotificationFunctions";
import { Guid } from "guid-typescript";

export interface PatientsState {
  loading: boolean;
  getting: boolean;
  gettingList: boolean;
  saving: boolean;
  searching: boolean;
  deleting: boolean;
  restoring: boolean;
  reload: boolean;
  selectedPatientUpdated: boolean;
  recentlyModified?: ListPatient[];
  latestProcessingDateTime?: Date | string;
  patients?: ListPatient[];
  filteredPatients?: ListPatient[];
  filteredPatientsTotals?: typeof PATIENT_FILTERED_STATUSES_TOTALS_DEFAULT;
  patientsWithDetail?: Patient[];
  patientDataFilters?: PatientDataSearchParams;
  selectedPatient?: Patient;
  selectedPatientSideNav?: NavMenu[];
  displaySearchedPatients?: { text: string, value: string; group: string; patient: Patient };
  searchedPatients?: ListPatient[];
  duplicatePatient?: DuplicatePatient;
}

export enum PatientsActions {
  LIST_PATIENTS = "PatientModule/listPatients",
  SEARCH_PATIENTS = "PatientModule/searchPatients",
  CREATE_OR_EDIT_PATIENT = "PatientModule/createOrEditPatient",
  DELETE_PATIENT = "PatientModule/deletePatient",
  GET_PATIENT_DETAIL = "PatientModule/getPatientDetail",
  SAVE_RESPONSIBLE_PARTIES_EMAIL = "PatientModule/saveResponsiblePartiesEmail",
  SAVE_RESPONSIBLE_PARTY = "PatientModule/saveResponsibleParty",
  BULK_ARCHIVE = "PatientModule/bulkArchive",
  BULK_DELETE = "PatientModule/bulkDelete",
  BULK_RESTORE = "PatientModule/bulkRestore",
  RESET = "PatientModule/reset",
}

export enum PatientsMutations {
  SET_LOADING = "PatientModule/setLoading",
  SET_PATIENTS = "PatientModule/setPatients",
  SET_SEARCHED_PATIENTS = "PatientModule/setSearchedPatients",
  SET_LATEST_PROCESSING_DATE_TIME = "PatientModule/setLatestProcessingDateTime",
  SET_PATIENTS_WITH_DETAIL = "PatientModule/setPatientsWithDetail",
  SET_SELECTED_PATIENT = "PatientModule/setSelectedPatient",
  SET_PATIENT_DATA_FILTERS = "PatientModule/setPatientDataFilters",
  SET_SELECTED_PATIENT_SIDE_NAV = "PatientModule/setSelectedPatientSideNav",
  SET_RELOAD = "PatientModule/setReload",
  SET_DUPLICATE_PATIENT = "PatientModule/setDuplicatePatient",
  SET_SELECTED_PATIENT_UPDATED = "PatientModule/setSelectedPatientUpdated",
  RESET = "PatientModule/reset",
}

export enum PatientsGetters {
  LOADING = "PatientModule/loading",
  GETTING = "PatientModule/getting",
  GETTING_LIST = "PatientModule/gettingList",
  SEARCHING = "PatientModule/searching",
  SAVING = "PatientModule/saving",
  DELETING = "PatientModule/deleting",
  RESTORING = "PatientModule/restoring",
  RELOAD = "PatientModule/reload",
  LATEST_PROCESSING_DATE_TIME = "PatientModule/latestProcessingDateTime",
  SELECTED_PATIENT_UPDATED = "PatientModule/selectedPatientUpdated",
  PATIENTS = "PatientModule/patients",
  RECENTLY_MODIFIED = "PatientModule/recentlyModified",
  PATIENT_DATA_FILTERS = "PatientModule/patientDataFilters",
  DISPLAY_SEARCHED_PATIENTS = "PatientModule/displaySearchedPatients",
  SEARCHED_PATIENTS = "PatientModule/searchedPatients",
  SELECTED_PATIENT_SIDE_NAV = "PatientModule/selectedPatientSideNav",
  SELECTED_PATIENT = "PatientModule/selectedPatient",
  DUPLICATE_PATIENT = "PatientModule/duplicatePatient",
  SELECTED_PATIENT_RESPONSIBLE_SELECT = "PatientModule/selectedPatientResponsibleSelect",
  SELECTED_PATIENT_RESPONSIBLE_OBJECT_SELECT = "PatientModule/selectedPatientResponsibleObjectSelect",
  FILTERED_PATIENTS = "PatientModule/filteredPatients",
  //dashboard
  FILTERED_PATIENTS_INCLUDING_STATUSES = "PatientModule/filteredPatientsIncludingStatuses",
  FILTERED_PATIENT_TOTALS = "PatientModule/filteredPatientsTotals",
  PENDING_SIGNED_INTAKE_DOCUMENTS = "PatientModule/pendingSignedIntakeDocuments",
  PENDING_SIGNED_ANYTIME_DOCUMENTS = "PatientModule/pendingSignedAnytimeDocuments",
  PENDING_SIGNED_TX_START_DOCUMENTS = "PatientModule/pendingSignedTxStartDocuments",
  PARTIALLY_SIGNED_INTAKE_DOCUMENTS = "PatientModule/partiallySignedIntakeDocuments",
  PARTIALLY_SIGNED_ANYTIME_DOCUMENTS = "PatientModule/partiallySignedAnytimeDocuments",
  PARTIALLY_SIGNED_TX_START_DOCUMENTS = "PatientModule/partiallySignedTxStartDocuments",
}

function emptyResponsiblePartyTier() {
  return {
    patientResponsiblePartyTierTypeId: null,
    patientResponsiblePartyTierDownPayment: {
      amount: null,
      isPercentage: false
    }
  } as PatientResponsiblePartyTier;
}

const initialState = () => ({
  loading: false,
  getting: false,
  gettingList: false,
  searching: false,
  saving: false,
  deleting: false,
  restoring: false,
  reload: false,
  recentlyModified: undefined,
  latestProcessingDateTime: undefined,
  patients: undefined,
  patientsWithDetail: undefined,
  patientDataFilters: undefined,
  filteredPatients: undefined,
  filteredPatientsTotals: undefined,
  selectedPatient: undefined,
  searchedPatients: undefined,
  displaySearchedPatients: undefined,
  selectedPatientSideNav: undefined,
  duplicatePatient: undefined,
  selectedPatientUpdated: false
});
export const PatientModule: Module<PatientsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async searchPatients(
      { commit, dispatch, rootGetters, getters },
      payload: {
        length?: number;
        search?: string;
        service: PatientService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SEARCHING
        });

        const {
          service,
          length,
          search
        } = payload;

        let modifiedSearch = search;
        if (modifiedSearch?.includes(" - DOB:")) {
          modifiedSearch = modifiedSearch?.substr(0, modifiedSearch.indexOf(" - DOB:") - 1)?.trim();
        }

        if (modifiedSearch?.includes(" - ID:")) {
          modifiedSearch = modifiedSearch?.substr(0, modifiedSearch.indexOf(" - ID:") - 1)?.trim();
        }
        modifiedSearch = modifiedSearch?.replace(/[^a-z0-9\-/]+/gi, " ")?.substr(0, 100);

        const values: PatientDataSearchParams = {
          start: 0,
          length: length ? length : 1000,
          search: modifiedSearch ? modifiedSearch : undefined,
          sortOrderEnabled: false,
          sortOrder: undefined,
          sortOrderDirection: undefined,
          fromLastTouchedDate: undefined,
          toLastTouchedDate: undefined,
          patientStatus: PatientStatuses.ACTIVE,
          showArchived: true,
          treatmentCoordinatorIds: [],
          doctorIds: [],
          includeTreatmentPlans: -1,
          officeIds: [],
          statuses: undefined,
          isRIV:
            rootGetters[
              ClientSubscriptionsGetters.IS_INSURANCE_VERIFICATION_ENABLED
              ] || false
        };

        const listPatientInfo: ListPatientInfo = await service.getPatients(values);
        if (listPatientInfo.recordsTotal > (values.length || 0)) {
          await WarningNotification(dispatch, `Please add more to search if you do not see your result below, ${values.length} of ${listPatientInfo.recordsTotal} patients were returned (limiting result set for performance).`);
        }
        commit("setSearchedPatients", listPatientInfo?.patients || []);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.SEARCHING
      });
      return success;
    },
    async listPatients(
      { commit, dispatch, rootGetters, getters },
      payload: {
        patientDataSearchParams?: PatientDataSearchParams;
        start?: number;
        length?: number;
        search?: string;
        sortOrderEnabled?: boolean;
        sortOrder?: string; //could be refined to columns only (enum)
        sortOrderDirection?: SORT_ORDER_DIRECTION;
        treatmentCoordinatorIds?: string[];
        doctorIds?: string[];
        officeIds?: string[];
        fromLastTouchedDate?: string;
        toLastTouchedDate?: string;
        patientStatus?: string;
        showArchived?: boolean;
        includeTreatmentPlans?: number;
        statuses?: string[];
        dateRange?: number;
        service: PatientService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: [LoadingType.GETTING, "gettingList"]
        });

        const {
          service,
          patientDataSearchParams,
          start,
          length,
          search,
          sortOrderEnabled,
          sortOrder,
          sortOrderDirection,
          fromLastTouchedDate,
          toLastTouchedDate,
          treatmentCoordinatorIds,
          doctorIds,
          officeIds,
          patientStatus,
          showArchived,
          includeTreatmentPlans,
          statuses,
          dateRange
        } = payload;

        //TODO full proof this with loop
        const values: PatientDataSearchParams = patientDataSearchParams ? patientDataSearchParams : {
          fromLastTouchedDate: fromLastTouchedDate ? fromLastTouchedDate : moment(moment().subtract(30, "days")).format("YYYY-MM-DD"),
          toLastTouchedDate: toLastTouchedDate ? toLastTouchedDate : undefined,
          start: start ? start : 0,
          length: length ? length : 5000,
          search: search ? search : undefined,
          sortOrderEnabled: sortOrderEnabled ? sortOrderEnabled : false,
          sortOrder: sortOrder ? sortOrder : undefined,
          sortOrderDirection: sortOrderDirection ? sortOrderDirection : undefined,
          patientStatus: patientStatus || 'Active',
          showArchived: showArchived ? true : false, //typeof showArchived === 'undefined' || showArchived ? true : false
          treatmentCoordinatorIds: treatmentCoordinatorIds && !!treatmentCoordinatorIds.length ? treatmentCoordinatorIds : [],
          doctorIds: doctorIds && !!doctorIds.length ? doctorIds : [],
          includeTreatmentPlans: typeof includeTreatmentPlans !== "undefined" ? includeTreatmentPlans : -1,
          officeIds: officeIds && !!officeIds.length ? officeIds : [],
          statuses: statuses && statuses?.length ? statuses : [],
          dateRange: typeof dateRange === "number" ? dateRange : 30,
          isRIV:
            rootGetters[
              ClientSubscriptionsGetters.IS_INSURANCE_VERIFICATION_ENABLED
              ] || false
        };

        //previous month get all data thru current
        const prevToLastTouchedDate = values.toLastTouchedDate;
        if (typeof values?.dateRange === "number" && values?.dateRange === 31) {
          values.toLastTouchedDate = undefined;
        }

        //only get latest from modified date since filter is the same
        const filterUpdateModifiedDateOnly = isEqual(values?.toLastTouchedDate, getters?.patientDataFilters?.toLastTouchedDate) && isEqual(values?.fromLastTouchedDate, getters?.patientDataFilters?.fromLastTouchedDate);
        if (filterUpdateModifiedDateOnly) {
          values.fromModifiedDateSubset = getters.latestProcessingDateTime;
        }

        //force to get all results filter on front-end only
        const listPatientInfo: ListPatientInfo = await service.getPatients({
          fromLastTouchedDate: values.fromLastTouchedDate,
          toLastTouchedDate: values.toLastTouchedDate,
          start: values.start,
          length: values.length,
          patientStatus: values.patientStatus,
          showArchived: true,
          includeTreatmentPlans: -1,
          treatmentCoordinatorIds: [],
          doctorIds: [],
          officeIds: [],
          fromModifiedDateSubset: values?.fromModifiedDateSubset
        });

        if (listPatientInfo.recordsTotal > (values.length || 0)) {
          await WarningNotification(dispatch, `Please add a filter above, ${values.length} of ${listPatientInfo.recordsTotal} patients were returned (limiting result set for performance).  If you have any questions please contact our support team.`);
        }
        commit("setLatestProcessingDateTime", listPatientInfo?.latestProcessingDateTime?.toString());
        //update / add
        if (listPatientInfo?.isSubset) {
          const data: ListPatient[] = cloneDeep(getters.patients);
          const recentlyModified: ListPatient[] = cloneDeep(getters.recentlyModified);
          cloneDeep(listPatientInfo?.patients)?.forEach((patient: ListPatient) => {
            const index = data.findIndex((p) => p.patientId === patient.patientId);
            const isObsolete = !!patient?.isObsolete;

            if (index === -1) {
              if (!isObsolete) {
                data.push(patient);
                recentlyModified.push(patient);
              }
            } else { //already included
              if (isObsolete) {
                data?.splice(index, 1);
              } else {
                data[index] = patient;
                const recentlyModifiedIndex = recentlyModified?.findIndex((p) => p.patientId === patient.patientId);
                recentlyModifiedIndex === -1 ? recentlyModified.push(patient) : recentlyModified[recentlyModifiedIndex] = patient;
              }
            }
            if (isObsolete) {
              const recentlyModifiedIndex = recentlyModified?.findIndex((p) => p.patientId === patient.patientId);
              recentlyModifiedIndex !== -1 && recentlyModified?.splice(recentlyModifiedIndex, 1);
            }
            commit("setPatients", data);
            commit("setRecentlyModified", recentlyModified);
          });
        } else {
          commit("setPatients", listPatientInfo?.patients || []);
          commit("setRecentlyModified", listPatientInfo?.patients || []);
        }
        //dont reset if values didn't change
        if (values && values.fromModifiedDateSubset) {
          delete values["fromModifiedDateSubset"];
        }
        //previous month get all data through current (RESET to ORIGINAL VALUE)
        if (typeof values?.dateRange === "number" && values?.dateRange === 31) {
          values.toLastTouchedDate = prevToLastTouchedDate;
        }
        commit("setPatientDataFilters", values);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: [LoadingType.GETTING, "gettingList"]
      });
      return success;
    },
    async createOrEditPatient(
      { commit, dispatch, getters },
      payload: {
        values: Patient;
        service: PatientService;
        treatmentPlanService: PatientTreatmentPlansService;
        force?: boolean;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING
        });
        const { service, values, treatmentPlanService, force } = payload;
        let patient = cloneDeep(values);
        if (values && values.patientId && values.patientId !== NEW_ITEM_ID) {
          const rtnServiceCalls = await Promise.all([service.createOrEditPatient(
            values,
            !!force
          ), dispatch(
            PatientTreatmentPlansActions.LOAD_SELECTED_PATIENT_TREATMENT_PLANS,
            { service: treatmentPlanService, id: values.patientId },
            { root: true })]);
          patient = rtnServiceCalls[0];
        } else {
          patient = await service.createOrEditPatient(
            values,
            !!force
          );
          commit(
            PatientTreatmentPlansMutations.SET_SELECTED_PATIENT_TREATMENT_PLANS,
            { patientId: patient.patientId }, { root: true });
        }
        if (patient) {
          if (patient.isNameDuplicate || patient.isNameAndBdayDuplicate || patient.isExtPatientIdDuplicate) {
            const duplicationType = patient.isNameAndBdayDuplicate
              ? DUPLICATION_TYPES.NAME_AND_BIRTHDAY_DUPLICATE
              : patient.isNameDuplicate
                ? DUPLICATION_TYPES.NAME_DUPLICATE
                : DUPLICATION_TYPES.EXTERNAL_ID_DUPLICATE;
            commit("setDuplicatePatient", {
              patientId: patient.patientId,
              duplicationType: duplicationType
            });
          } else {
            //office isn't being reloaded
            if (patient.officeId) {
              patient.office = cloneDeep(values.office);
            }
            commit("setSelectedPatient", {
              patient: cloneDeep(patient),
              type: LoadingType.SAVING,
              id: patient.patientId
            });
            success = await SuccessNotification(dispatch,
              `${values.patientId ? "Updated" : "Created"} patient ${values.firstName} ${values.lastName}!`
            );
          }
        } else {
          throw Error("Patient was empty!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async getPatientDetail(
      { commit, dispatch, getters, rootGetters },
      payload: {
        id: string;
        service: PatientService;
        treatmentPlanService: PatientTreatmentPlansService;
        skipLoadSelectedTxPlans: boolean;
      }
    ) {
      let success = false;
      try {

        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.GETTING
        });
        const { id, service, treatmentPlanService, skipLoadSelectedTxPlans } = payload;
        commit("setSelectedPatient", { patient: undefined, type: LoadingType.SEARCHING, id: id });
        let rtnServiceCalls = null;
        let patient = null;
        if (typeof id !== "undefined" && Guid.isGuid(id) && id !== NEW_ITEM_ID) {
          if (!skipLoadSelectedTxPlans) {
            rtnServiceCalls = await Promise.all([service.getPatientDetail(
              id
            ), dispatch(
              PatientTreatmentPlansActions.LOAD_SELECTED_PATIENT_TREATMENT_PLANS,
              { service: treatmentPlanService, id: id },
              { root: true })]);
            patient = rtnServiceCalls[0];
          } else {
            patient = await service.getPatientDetail(id);
          }
        }

        if (patient) {
          const patientContracts: PatientContract[] | undefined = await rootGetters[PatientContractsGetters.PATIENT_CONTRACTS];
          const navMenu: NavMenu[] | undefined = await rootGetters[PatientContractsGetters.CONTRACTS_SIDE_NAV];
          if ((patientContracts?.length && patient?.patientId !== patientContracts[0]?.patientId) || (typeof patientContracts === "undefined" || patientContracts?.length === 0 && typeof navMenu !== "undefined")) {
            commit(PatientContractsMutations.SET_PATIENT_CONTRACTS, undefined, { root: true });
            commit(PatientContractsMutations.SET_PATIENT_CONTRACTS_SIDE_NAV, {
              patientContracts: undefined,
              treatmentPlan: undefined
            }, { root: true });
          }
          commit("setSelectedPatient", {
            patient: cloneDeep(patient),
            type: LoadingType.GETTING,
            id: patient.patientId
          });
          commit(PatientFormsAndSignaturesMutations.SET_PATIENT_HISTORY_SIDE_NAV, patient.patientId, { root: true });

          success = true;
        } else {
          throw Error("No Patient!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.GETTING
      });
      if (success) commit("setReload", false);
      return success;
    },
    async saveResponsiblePartiesEmail(
      { commit, dispatch, getters, rootGetters },
      payload: {
        responsibleParties: ResponsibleParty[] | ResponsibleParty;
        responsiblePartiesService: ResponsiblePartiesService;
        suppressNotification: boolean;
        treatmentPlanService: PatientTreatmentPlansService;
      }
    ) {
      let success = false;
      try {
        const {
          responsibleParties,
          responsiblePartiesService,
          suppressNotification,
          treatmentPlanService
        } = payload;
        const rps = Array.isArray(responsibleParties) ? responsibleParties : [responsibleParties];

        const selectedPatient = cloneDeep(getters.selectedPatient);
        if (!selectedPatient || !selectedPatient.patientId) {
          throw Error("Responsible Party email updates require a patient.");
        }

        const preFetchDataPromises: Promise<any>[] = [];
        for (const rp of rps as ResponsibleParty[]) {
          const index = (selectedPatient.patientResponsibleParties as ResponsibleParty[])?.findIndex((x: ResponsibleParty) => x?.patientResponsiblePartyId === rp?.patientResponsiblePartyId);
          if (index > -1 && rp?.patientResponsiblePartyId && selectedPatient?.patientResponsibleParties[index]?.patientResponsiblePartyId && (selectedPatient?.patientResponsibleParties[index]?.email?.toLowerCase() !== rp?.email?.toLowerCase())) {
            preFetchDataPromises.push(responsiblePartiesService.saveResponsiblePartyEmail(rp));
            selectedPatient.patientResponsibleParties[index].email = rp.email;
          }
        }

        if (preFetchDataPromises.length) {
          commit("setLoading", {
            loading: true,
            loadingType: LoadingType.SAVING
          });
          const responses = await Promise.all(preFetchDataPromises);
          for (const response of responses) {
            if (response && response?.lastModifiedDate && response?.patientResponsiblePartyId) {
              const idx = (selectedPatient.patientResponsibleParties as ResponsibleParty[])?.findIndex((x: ResponsibleParty) => x.patientResponsiblePartyId === response.patientResponsiblePartyId);
              if (idx > -1) {
                selectedPatient.patientResponsibleParties[idx].lastModifiedDate = response.lastModifiedDate;
              }
            } else {
              throw Error("Responsible Party email did not save correctly");
            }
          }

          const selectedPatientTreatmentPlan = rootGetters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLAN] as TreatmentPlan;
          if (treatmentPlanService && selectedPatientTreatmentPlan?.patientTreatmentPlanId) {
            await dispatch(PatientTreatmentPlansActions.UPDATE_TREATMENT_PLAN_LAST_ASKED_TO_REGENERATE,
              {
                id: selectedPatientTreatmentPlan.patientTreatmentPlanId,
                service: treatmentPlanService
              }, { root: true });
          }

          commit("setSelectedPatient", {
            patient: selectedPatient,
            type: LoadingType.SAVING,
            id: selectedPatient.patientId
          });
        }

        if (!suppressNotification && preFetchDataPromises.length) {
          await SuccessNotification(dispatch, rps?.length === 1 ? `Updated responsible party email address!` : `Updated multiple responsible party email addresses!`);
        }
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.SAVING
      });
      return success;
    },
    async saveResponsibleParty(
      { commit, dispatch, getters },
      payload: {
        responsibleParty: ResponsibleParty;
        responsiblePartiesService: ResponsiblePartiesService;
        suppressNotification: boolean;
      }
    ) {
      let success = false;
      let savedResponsibleParty = null;
      try {
        const {
          responsibleParty,
          responsiblePartiesService,
          suppressNotification

        } = payload;
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING
        });

        const selectedPatient = cloneDeep(getters.selectedPatient);
        if (!selectedPatient || !selectedPatient.patientId) {
          throw Error("Responsible Party email updates require a patient.");
        }

        if (!responsibleParty.patientId || responsibleParty.patientId === NEW_ITEM_ID) {
          responsibleParty.patientId = selectedPatient.patientId;
        }
        const response = await responsiblePartiesService.saveResponsibleParty(responsibleParty);
        if (response && response?.patientResponsiblePartyId && response?.patientResponsiblePartyId !== NEW_ITEM_ID)  {
          savedResponsibleParty = cloneDeep(response);
          Array.isArray(selectedPatient?.patientResponsibleParties) ? selectedPatient.patientResponsibleParties.push(savedResponsibleParty) : (selectedPatient.patientResponsibleParties = [cloneDeep(savedResponsibleParty)]);
        } else {
          throw Error("Responsible Party did not save correctly");
        }

        commit("setSelectedPatient", {
          patient: selectedPatient,
          type: LoadingType.SAVING,
          id: selectedPatient.patientId
        });
        success = await SuccessNotification(dispatch, `Created Responsible Party!`, suppressNotification);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.SAVING
      });
      return {success: success, responsibleParty: savedResponsibleParty};
    },
    async deletePatient(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: PatientService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING
        });
        const { service, id } = payload;
        const deleted = await service.deletePatient(id);
        if (deleted.patientId === id) {
          commit("setSelectedPatient", { patient: undefined, type: LoadingType.DELETING, id: id });
          success = await SuccessNotification(dispatch, `Deleted Patient!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING
      });
      return success;
    },
    async bulkArchive(
      { commit, dispatch, getters },
      payload: {
        ids: string[];
        service: PatientService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING
        });
        const { service, ids } = payload;
        const archived = await service.archivePatients(ids);
        if (archived === 200) {
          const patients = cloneDeep(getters.patients || []);
          const recentlyModified: ListPatient[] = cloneDeep(getters.recentlyModified || []);
          patients.forEach((patient: ListPatient, index: number) => {
            if (ids.includes(patient.patientId as string)) {
              patients[index].isArchived = true;
              patients[index].linkInformation.isArchived = true;
              patients[index].lastModifiedDate = moment.utc().toISOString();
              const recentlyModifiedIndex = recentlyModified?.findIndex((p) => p.patientId === patient.patientId);
              recentlyModifiedIndex === -1 ? recentlyModified.push(patients[index]) : recentlyModified[recentlyModifiedIndex] = patient;
            }
          });
          commit("setRecentlyModified", recentlyModified);
          commit("setPatients", patients);
          const patientsWithDetails = cloneDeep(getters.patientsWithDetail);
          patientsWithDetails?.forEach((patient: Patient, index: number) => {
            if (ids?.includes(patient.patientId as string)) {
              patientsWithDetails[index].isArchived = true;
            }
          });
          commit("setPatientsWithDetail", patientsWithDetails);
          commit("setPatientDataFilters"); //this forces the filtered patient list

          const selectedPatient = cloneDeep(getters.selectedPatient);
          if (selectedPatient && ids?.findIndex((id: string) => id === selectedPatient.patientId) !== -1) {
            selectedPatient.isArchived = true;
            commit("setSelectedPatient", {
              patient: selectedPatient,
              type: LoadingType.SAVING,
              id: selectedPatient.patientId
            });
          }
          success = await SuccessNotification(dispatch, ids?.length === 1 ? `Archived patient!` : `Archived patients!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async bulkDelete(
      { commit, dispatch, getters },
      payload: {
        ids: string[];
        service: PatientService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING
        });
        const { service, ids } = payload;
        const deleted = await service.deletePatients(ids);
        if (deleted === 200) {
          let patients = cloneDeep(getters.patients);
          const recentlyModified: ListPatient[] = cloneDeep(getters.recentlyModified || []);
          patients?.forEach((patient: Patient) => {
            if (ids?.includes(patient.patientId as string)) {
              const recentlyModifiedIndex = recentlyModified?.findIndex((p) => p.patientId === patient.patientId);
              if (recentlyModifiedIndex !== -1) {
                recentlyModified.splice(recentlyModifiedIndex, 1);
              }
              patients = patients.filter(
                (item: Patient) => item.patientId !== patient.patientId
              );
            }
          });
          commit("setRecentlyModified", recentlyModified);
          commit("setPatients", patients);
          let patientsWithDetails = cloneDeep(getters.patientsWithDetail);
          patientsWithDetails?.forEach((patient: Patient) => {
            if (ids?.includes(patient.patientId as string)) {
              patientsWithDetails = patientsWithDetails.filter(
                (item: Patient) => item.patientId !== patient.patientId
              );
            }
          });
          commit("setPatientsWithDetail", patientsWithDetails);
          commit("setPatientDataFilters"); //this forces the filtered patient list
          success = await SuccessNotification(dispatch, ids?.length === 1 ? `Deleted Patient!` : `Deleted Patients!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING
      });
      return success;
    },
    async bulkRestore(
      { commit, dispatch, getters },
      payload: {
        ids: string[];
        service: PatientService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.RESTORING
        });
        const { service, ids } = payload;
        const restored = await service.restorePatients(ids);
        if (restored === 200) {
          //TODO optimize this only update the state of the one instead of replacing all
          const patients = cloneDeep(getters.patients || []);
          const recentlyModified: ListPatient[] = cloneDeep(getters.recentlyModified || []);
          patients.forEach((patient: ListPatient, index: number) => {
            if (ids.includes(patient.patientId as string)) {
              patients[index].isArchived = false;
              patients[index].linkInformation.isArchived = false;
              patients[index].lastModifiedDate = moment.utc().toISOString();
              const recentlyModifiedIndex = recentlyModified?.findIndex((p) => p.patientId === patient.patientId);
              recentlyModifiedIndex === -1 ? recentlyModified.push(patients[index]) : recentlyModified[recentlyModifiedIndex] = patient;
            }
          });
          commit("setRecentlyModified", recentlyModified);
          commit("setPatients", patients);
          const patientsWithDetails = cloneDeep(getters.patientsWithDetail);
          patientsWithDetails?.forEach((patient: Patient, index: number) => {
            if (ids?.includes(patient.patientId as string)) {
              patientsWithDetails[index].isArchived = false;
            }
          });
          commit("setPatientsWithDetail", patientsWithDetails);
          commit("setPatientDataFilters"); //this forces the filtered patient list
          const selectedPatient = cloneDeep(getters.selectedPatient);
          if (selectedPatient && ids?.findIndex((id: string) => id === selectedPatient.patientId) !== -1) {
            selectedPatient.isArchived = false;
            commit("setSelectedPatient", {
              patient: selectedPatient,
              type: LoadingType.SAVING,
              id: selectedPatient.patientId
            });
          }
          success = await SuccessNotification(dispatch, ids?.length === 1 ? `Restored Patient!` : `Restored patients!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.RESTORING
      });
      return success;
    },
    reset({ commit }, payload: {
      patientDataFiltersOnly?: boolean
    }) {
      const { patientDataFiltersOnly } = payload;
      commit("reset", !!patientDataFiltersOnly);
    }
  },
  mutations: {
    setLoading(
      state: PatientsState,
      payload: { loading: boolean; loadingType: string | string[] }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          if (Array.isArray(payload.loadingType)) {
            payload.loadingType.forEach((item) => {
              Vue.set(state, item, payload.loading);
            });
          } else {
            Vue.set(state, payload.loadingType, payload.loading);
          }
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setReload(state: PatientsState, reload: boolean = false) {
      Vue.set(state, "reload", reload);
    },
    setSelectedPatientUpdated(state: PatientsState, updated: boolean = false) {
      Vue.set(state, "selectedPatientUpdated", updated);
    },
    setLatestProcessingDateTime(
      state: PatientsState,
      latestProcessingDateTime?: Date | string
    ) {
      Vue.set(state, "latestProcessingDateTime", moment(latestProcessingDateTime)?.toISOString() || undefined);
    },
    setRecentlyModified(state: PatientsState, patients?: ListPatient[]) {
      const recentlyModified = cloneDeep(patients || []).sort((a, b) => {
        if (moment(a.lastModifiedDate) < moment(b.lastModifiedDate)) {
          return 1;
        } else if (moment(a.lastModifiedDate) > moment(b.lastModifiedDate)) {
          return -1;
        } else {
          return 0;
        }
      });
      const patientsRecentlyModified = recentlyModified?.length ?
        recentlyModified?.length === 1
          ? [recentlyModified[0]]
          : [recentlyModified[0], recentlyModified[1]]
        : [];
      Vue.set(state, "recentlyModified", patientsRecentlyModified);
    },
    setPatients(state: PatientsState, patients?: ListPatient[]) {
      Vue.set(state, "patients", patients);
    },
    setSearchedPatients(state: PatientsState, patients?: Patient[]) {
      Vue.set(state, "searchedPatients", patients);
    },
    setPatientsWithDetail(state: PatientsState, patients?: Patient[]) {
      Vue.set(state, "patientsWithDetail", patients);
    },
    setDuplicatePatient(
      state: PatientsState,
      duplicatePatient?: DuplicatePatient
    ) {
      Vue.set(state, "duplicatePatient", duplicatePatient);
    },
    setPatientDataFilters(
      state: PatientsState,
      filter?: PatientDataSearchParams
    ) {
      if (filter) {
        Vue.set(state, "patientDataFilters", filter);
      }
      const patientDataFilters = filter ? cloneDeep(filter) : state.patientDataFilters;
      const fromDate = moment.utc(patientDataFilters?.fromLastTouchedDate);
      const toDate = patientDataFilters?.toLastTouchedDate ? moment.utc(patientDataFilters?.toLastTouchedDate) : undefined;
      const currentDateTime = moment();

      const totals = cloneDeep(PATIENT_FILTERED_STATUSES_TOTALS_DEFAULT);

      const hasFilterStatusPendingAnytimePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PENDING_ANYTIME_PACKETS);
      const hasFilterStatusPendingTxStartPackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PENDING_TREATMENT_TX_START_PACKETS);
      const hasFilterStatusPendingIntakePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PENDING_INTAKE_PACKETS);

      const hasFilterStatusPartialAnytimePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PARTIALLY_SIGNED_ANYTIME_PACKETS);
      const hasFilterStatusPartialTxStartPackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PARTIALLY_SIGNED_TX_START_PACKETS);
      const hasFilterStatusPartialIntakePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PARTIALLY_SIGNED_INTAKE_PACKETS);

      const hasFilterStatusCompletedAnytimePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.COMPLETED_ANYTIME_PACKETS);
      const hasFilterStatusCompletedTxStartPackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.COMPLETED_TX_START_PACKETS);
      const hasFilterStatusCompletedIntakePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.COMPLETED_INTAKE_PACKETS);

      const hasFilterStatusDeletedAnytimePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.DELETED_ANYTIME_PACKETS);
      const hasFilterStatusDeletedTxStartPackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.DELETED_TX_START_PACKETS);
      const hasFilterStatusDeletedIntakePackets = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.DELETED_INTAKE_PACKETS);

      const hasFilterStatusPatientsNoTxPlan = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PATIENTS_NO_TX_PLAN);
      const hasFilterStatusPatientsNoResponsibleParty = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.PATIENTS_NO_RESPONSIBLE_PARTY);

      const hasFilterStatusTreatmentPlansAccepted = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_ACCEPTED);
      const hasFilterStatusTreatmentPlansDeclined = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_DECLINED);
      const hasFilterStatusTreatmentPlansCreated = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_CREATED);
      const hasFilterStatusTreatmentPlansExpiring = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_EXPIRING);
      const hasFilterStatusTreatmentPlansSent = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_SENT);
      const hasFilterStatusTreatmentPlansExpired = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_EXPIRED);

      const hasFilterStatusStatsPatients = patientDataFilters?.statuses?.includes(PATIENT_FILTER_STATUSES_TYPES.STATS_PATIENTS);

      const filteredPatientsIncludingStatuses = (state.patients || []).filter((p) => {
        if (patientDataFilters?.patientStatus?.toLowerCase() === PatientStatuses.ACTIVE && p.isArchived) {
          return false;
        } else if (patientDataFilters?.patientStatus?.toLowerCase() === PatientStatuses.ARCHIVED && typeof p.isArchived === 'boolean' && !p.isArchived) {
          return false;
        }
   
        if (patientDataFilters?.treatmentCoordinatorIds?.length) {
          if (!p?.linkInformation?.patientTreatmentPlans?.length) return false;
          let match = false;
          if ((patientDataFilters?.treatmentCoordinatorIds?.findIndex(x => x === p.treatmentCoordinatorId) > -1)
            || (patientDataFilters?.treatmentCoordinatorIds?.findIndex(x => x === NEW_ITEM_ID) > -1
              && (!p.treatmentCoordinatorId || p.treatmentCoordinatorId === NEW_ITEM_ID))) {
            match = true;
          }
          if (!match) return false;
        }

        if (patientDataFilters?.doctorIds?.length) {
          if (!p?.linkInformation?.patientTreatmentPlans?.length) return false;
          let match = false;
          if ((patientDataFilters?.doctorIds?.findIndex(x => x === p.doctorId) > -1)
            || (patientDataFilters?.doctorIds?.findIndex(x => x === NEW_ITEM_ID) > -1
              && (!p.doctorId || p.doctorId === NEW_ITEM_ID))) {
            match = true;
          }
          if (!match) return false;
        }

        if (patientDataFilters?.officeIds?.length) {
          if (!p?.linkInformation?.patientTreatmentPlans?.length) return false;
          let match = false;
          if ((patientDataFilters?.officeIds?.findIndex(x => x === p.officeId) > -1)
            || (patientDataFilters?.officeIds?.findIndex(x => x === NEW_ITEM_ID) > -1
              && (!p.officeId || p.officeId === NEW_ITEM_ID))) {
            match = true;
          }
          if (!match) return false;
        }

        //statuses section start
        const statuses = cloneDeep(patientDataFilters?.statuses || []);
        let hasMatchingStatus = false;

        //patients
        if (moment(p?.createdDate).isAfter(fromDate)
          && moment(p?.createdDate).isBefore(toDate)) {
          if (!p?.linkInformation?.treatmentPlanIds?.length) {
            if (hasFilterStatusPatientsNoTxPlan) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PATIENTS_NO_TX_PLAN].total++;
          }
          if (!p?.linkInformation?.responsiblePartyIds?.length) {
            if (hasFilterStatusPatientsNoResponsibleParty) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PATIENTS_NO_RESPONSIBLE_PARTY].total++;
          }
          if (hasFilterStatusStatsPatients) hasMatchingStatus = true;
          totals[PATIENT_FILTER_STATUSES_TYPES.STATS_PATIENTS].total++;
        }

        //tx plans
        for (let k = 0; k < (p?.linkInformation?.patientTreatmentPlans?.length || 0); k++) {
          const info = p.linkInformation?.patientTreatmentPlans?.[k];

          if (info?.createdDate
            && moment(info?.createdDate).isAfter(fromDate)
            && moment(info?.createdDate).isBefore(toDate)) {
            if (hasFilterStatusTreatmentPlansCreated) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.STATS_TX_PLANS].total++;
          }

          const txStartEsignDocumentInformationIndex = (p?.linkInformation?.txStartEsignDocumentInformation || [])?.findIndex(x => x?.patientTreatmentPlanId === info?.patientTreatmentPlanId);
          if (txStartEsignDocumentInformationIndex === -1) {
            if ((info?.acceptedOrDeclinedDate
              && moment(info?.acceptedOrDeclinedDate).isAfter(fromDate)
              && moment(info?.acceptedOrDeclinedDate).isBefore(toDate))) {
              switch (info?.status) {
                case 1:
                  totals[PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_ACCEPTED].total++;
                  if (hasFilterStatusTreatmentPlansAccepted) hasMatchingStatus = true;
                  break;
                case 0:
                  totals[PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_DECLINED].total++;
                  if (hasFilterStatusTreatmentPlansDeclined) hasMatchingStatus = true;
                  break;
                default:
                  if (((p?.linkInformation?.emailedSlidersDocumentInformation || [])?.findIndex(x => x?.patientTreatmentPlanId === info?.patientTreatmentPlanId)) === -1) { //otherwise this should be tracked by emailedSlidersDocumentInformation section to get the latest status
                    if (hasFilterStatusTreatmentPlansCreated) hasMatchingStatus = true;
                    totals[PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_CREATED].total++;
                  }
                  break;
              }
            }
          }
        }

        //emailedSlidersDocumentInformation (tx plans extended)
        for (let k = 0; k < (p?.linkInformation?.emailedSlidersDocumentInformation?.length || 0); k++) {
          const info = p.linkInformation?.emailedSlidersDocumentInformation?.[k];

          const txStartEsignDocumentInformationIndex = (p?.linkInformation?.txStartEsignDocumentInformation || [])?.findIndex(x => x?.patientTreatmentPlanId === info?.patientTreatmentPlanId);
          if (txStartEsignDocumentInformationIndex > -1) {
            continue; // already in signing process past the tx plan section so no action required
          }

          const txPlan = p?.linkInformation?.patientTreatmentPlans?.find(x => x?.patientTreatmentPlanId === info?.patientTreatmentPlanId);
          if (txPlan && typeof txPlan?.status === "number" && txPlan.status > -1) {
            continue; //already counted as accepted or declined
          }

          if (info?.dateStatusTriggered
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (info?.status === 0) {
              if (info?.dateStatusTriggered && currentDateTime.diff(moment(info?.dateStatusTriggered), "days") > 23) {
                totals[PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_EXPIRING].total++;
                if (hasFilterStatusTreatmentPlansExpiring) hasMatchingStatus = true;
              } else {
                totals[PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_SENT].total++;
                if (hasFilterStatusTreatmentPlansSent) hasMatchingStatus = true;
              }
            }
            if (info?.status === 1 || info?.status === 2) {
              totals[PATIENT_FILTER_STATUSES_TYPES.TREATMENT_PLANS_EXPIRED].total++;
              if (hasFilterStatusTreatmentPlansExpired) hasMatchingStatus = true;
            }
          }
        }

        //tx start docs
        for (let k = 0; k < (p?.linkInformation?.txStartEsignDocumentInformation?.length || 0); k++) {
          // @ts-ignore
          const info = p.linkInformation.txStartEsignDocumentInformation[k];
          if (info?.status === -1
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (moment(p?.linkInformation?.anytimeFormsDocumentsInformation?.[0]?.createdDate).isSame(moment(info?.createdDate))) {
              if (hasFilterStatusDeletedTxStartPackets) hasMatchingStatus = true;
              totals[PATIENT_FILTER_STATUSES_TYPES.DELETED_TX_START_PACKETS].total++;
            }
            totals[PATIENT_FILTER_STATUSES_TYPES.STATS_DEL_TX_START_PACKETS].total++;
          }
          if (info?.status === 0
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusPendingTxStartPackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PENDING_TREATMENT_TX_START_PACKETS].total++;
          }
          if (info?.status === 1
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusPartialTxStartPackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PARTIALLY_SIGNED_TX_START_PACKETS].total++;
          }
          if ((info?.status === 2 || info?.status === 3)
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusCompletedTxStartPackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.STATS_SIGNED_TX_START_PACKETS].total++;
            totals[PATIENT_FILTER_STATUSES_TYPES.COMPLETED_TX_START_PACKETS].total++;
          }
        }
        //intake docs
        for (let k = 0; k < (p?.linkInformation?.intakeFormsDocumentsInformation?.length || 0); k++) {
          // @ts-ignore
          const info = p.linkInformation.intakeFormsDocumentsInformation[k];
          if (info?.status === -1
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (moment(p?.linkInformation?.anytimeFormsDocumentsInformation?.[0]?.createdDate).isSame(moment(info?.createdDate))) {
              if (hasFilterStatusDeletedIntakePackets) hasMatchingStatus = true;
              totals[PATIENT_FILTER_STATUSES_TYPES.DELETED_INTAKE_PACKETS].total++;
            }
            totals[PATIENT_FILTER_STATUSES_TYPES.STATS_DEL_INTAKE_PACKETS].total++;
          }
          if (info?.status === 0
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusPendingIntakePackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PENDING_INTAKE_PACKETS].total++;
          }
          if (info?.status === 1
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusPartialIntakePackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PARTIALLY_SIGNED_INTAKE_PACKETS].total++;
          }
          if ((info?.status === 2 || info?.status === 3)
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusCompletedIntakePackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.COMPLETED_INTAKE_PACKETS].total++;
          }
        }
        //anytime docs
        for (let k = 0; k < (p?.linkInformation?.anytimeFormsDocumentsInformation?.length || 0); k++) {
          // @ts-ignore
          const info = p.linkInformation.anytimeFormsDocumentsInformation[k];
          if (info?.status === -1
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (moment(p?.linkInformation?.anytimeFormsDocumentsInformation?.[0]?.createdDate).isSame(moment(info?.createdDate))) {
              if (hasFilterStatusDeletedAnytimePackets) hasMatchingStatus = true;
              totals[PATIENT_FILTER_STATUSES_TYPES.DELETED_ANYTIME_PACKETS].total++;
            }
            totals[PATIENT_FILTER_STATUSES_TYPES.STATS_DEL_ANYTIME_PACKETS].total++;
          }
          if (info?.status === 0
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusPendingAnytimePackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PENDING_ANYTIME_PACKETS].total++;
          }
          if (info?.status === 1
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusPartialAnytimePackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.PARTIALLY_SIGNED_ANYTIME_PACKETS].total++;
          }
          if ((info?.status === 2 || info?.status === 3)
            && moment(info?.dateStatusTriggered).isAfter(fromDate)
            && moment(info?.dateStatusTriggered).isBefore(toDate)) {
            if (hasFilterStatusCompletedAnytimePackets) hasMatchingStatus = true;
            totals[PATIENT_FILTER_STATUSES_TYPES.COMPLETED_ANYTIME_PACKETS].total++;
          }
        }

        totals[PATIENT_FILTER_STATUSES_TYPES.STATS_RATIO_SIGNED_TX_START_OVER_TX_PLANS].total =
          totals[PATIENT_FILTER_STATUSES_TYPES.STATS_TX_PLANS]?.total > 0
            ? totals[PATIENT_FILTER_STATUSES_TYPES.COMPLETED_TX_START_PACKETS]?.total / totals[PATIENT_FILTER_STATUSES_TYPES.STATS_TX_PLANS]?.total
            : 0;


        totals[PATIENT_FILTER_STATUSES_TYPES.STATS_RATIO_TX_PLANS_OVER_PATIENTS].total =
          totals[PATIENT_FILTER_STATUSES_TYPES.STATS_PATIENTS]?.total > 0
            ? totals[PATIENT_FILTER_STATUSES_TYPES.STATS_TX_PLANS]?.total / totals[PATIENT_FILTER_STATUSES_TYPES.STATS_PATIENTS]?.total
            : 0;

        if (statuses.length && !hasMatchingStatus) return false;
        //statuses section end

        if (typeof patientDataFilters?.includeTreatmentPlans === "number" && patientDataFilters?.includeTreatmentPlans !== -1) {
          if (patientDataFilters?.includeTreatmentPlans === 0 && p?.linkInformation?.treatmentPlanIds?.length) return false;
          if (patientDataFilters?.includeTreatmentPlans === 1 && !p?.linkInformation?.treatmentPlanIds?.length) return false;
        }
        return true;
      });
      Vue.set(state, "filteredPatients", filteredPatientsIncludingStatuses);
      Vue.set(state, "filteredPatientsTotals", totals);


    },
    setSelectedPatient: function(state: PatientsState, payload: {
      patient?: Patient,
      type?: LoadingType,
      id?: string
    }) {
      const { id, type } = payload ? payload : { type: "default", id: undefined };
      let { patient } = payload ? payload : { patient: undefined };
      if (state.duplicatePatient) {
        Vue.set(state, "duplicatePatient", undefined);
      }

      let patientsWithDetail: Patient[] =
        cloneDeep(state.patientsWithDetail) || [];
      const index = patientsWithDetail
        .map((patient: Patient) => patient.patientId)
        .indexOf(id);

      switch (type) {
        case "default":
          break;
        case LoadingType.SEARCHING:
          if (index > -1) {
            patient = patientsWithDetail[index];
          }
          break;
        case LoadingType.GETTING:
        case LoadingType.SAVING:
          if (patient) {
            if (index > -1) {
              patientsWithDetail[index] = cloneDeep(patient);
            } else {
              patientsWithDetail.push(cloneDeep(patient));
            }
          } else {
            patientsWithDetail = patientsWithDetail.filter(
              (patient: Patient) => patient.patientId !== id
            );
          }
          Vue.set(state, "patientsWithDetail", patientsWithDetail);
          break;
        case LoadingType.DELETING:
          patientsWithDetail = patientsWithDetail.filter(
            (patient: Patient) => patient.patientId !== id
          );
          Vue.set(state, "patients", cloneDeep(state.patients || []).filter(
            (patient: Patient) => patient.patientId !== id
          ));
          Vue.set(state, "patientsWithDetail", patientsWithDetail);
          break;
      }

      //SIDE NAV
      let sideNav: NavMenu[] | undefined = undefined;
      if (typeof patient !== "undefined" && !!patient) {
        //convert dates
        if (patient?.birthDate) {
          patient.birthDate = getDate(patient.birthDate, "YYYY-MM-DD", "YYYY-MM-DD");
        }
        if (
          patient?.initialExamDateTime &&
          moment(patient.initialExamDateTime).isValid()
        ) {
          patient.initialExamDateTime = moment(patient.initialExamDateTime).toISOString();
        }
//side nav general patient start
        const sideNavItemPatient = {
          icon: "$clipboard-user",
          key: `${patient?.firstName} ${patient?.lastName}`,
          text: `${patient?.firstName} ${patient?.lastName}`,
          link: `/patients/${patient?.patientId}`,
          group: `/patients/${patient?.patientId}`
        };
        sideNav = [{
          key: "Patients",
          text: "Patients",
          regex: /^\/patients/,
          group: `/patients/${patient?.patientId}`,
          items: [sideNavItemPatient]
        }] as NavMenu[];
//side nav general patient end
//side nav resp party start
        if (patient?.patientResponsibleParties?.length) {
          const sideNavRespParties = {
            key: "Responsible Parties",
            text: "Responsible Parties",
            regex: /\/responsible-parties/,
            group: "/responsible-parties",
            items: []
          } as NavMenu;
          const sideNavItemRespParties = {
            icon: "$responsibleParties",
            key: "Responsible Parties",
            text: "Responsible Parties",
            link: `/patients/${patient.patientId}/responsible-parties/`,
            regex: /patients\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\/responsible-parties\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/,
            group: "/responsible-parties",
            count: (patient.patientResponsibleParties || []).length,
            items: []
          } as NavMenuItem;
          for (
            let index = 0;
            index < (patient.patientResponsibleParties || []).length;
            index++
          ) {
            if (
              !patient.patientResponsibleParties[index]
                ?.patientResponsiblePartyTier
            ) {
              patient.patientResponsibleParties[
                index
                ].patientResponsiblePartyTier = emptyResponsiblePartyTier();
            }
            if (patient?.patientResponsibleParties[index]?.birthDate) {
              patient.patientResponsibleParties[index].birthDate = getDate(patient.patientResponsibleParties[index].birthDate, "YYYY-MM-DD", "YYYY-MM-DD");
            }
            const linkRespPartyId = `/patients/${patient?.patientId}/responsible-parties/${patient.patientResponsibleParties[index]?.patientResponsiblePartyId}`;
            const respPartyFullName = patient?.patientResponsibleParties[index]?.fullName;
            const sideNavItemRespParty = {
              icon: "$external-link",
              key: respPartyFullName,
              text: respPartyFullName,
              link: linkRespPartyId,
              group: `/responsible-parties/${patient.patientResponsibleParties[index]?.patientResponsiblePartyId}`,
              items: []
            } as NavMenuItem;
            for (
              let insuranceIndex = 0;
              insuranceIndex < (patient.patientResponsibleParties[index].patientInsurances || []).length;
              insuranceIndex++
            ) {
              // @ts-ignore
              if (Array.isArray(patient.patientResponsibleParties[index]?.patientInsurances)) {
                const insurance = (patient?.patientResponsibleParties[index]?.patientInsurances || [])[insuranceIndex];
                // @ts-ignore
                patient.patientResponsibleParties[index].patientInsurances[insuranceIndex].hasDeductible = !!(patient?.patientResponsibleParties[index]?.patientInsurances || [])[insuranceIndex]?.deductibleAmount;
                const insuranceAmountDisplay = insurance?.insuranceAmount ? insurance?.isInsuranceAmountPercentage ? ` - ${insurance?.insuranceAmount}%` : ` - $${insurance?.insuranceAmount}` : "";
                const insuranceCompanyNameDisplay = insurance?.insuranceCompanyName ? insurance?.insuranceCompanyName?.length > 14 ? insurance.insuranceCompanyName.substring(0, 14) + "..." : insurance.insuranceCompanyName : "(no name)";
                const sideNavItemRespPartyInsurance = {
                  // @ts-ignore
                  key: `${insuranceCompanyNameDisplay}${insuranceAmountDisplay}`,
                  text: `${insuranceCompanyNameDisplay}${insuranceAmountDisplay}`,
                  link: `${linkRespPartyId}/insurances/${insurance.patientInsuranceId}`,
                  group: `/responsible-parties/${patient.patientResponsibleParties[index]?.patientResponsiblePartyId}`
                } as NavMenuItem;
                sideNavItemRespParty?.items?.push(sideNavItemRespPartyInsurance);
              }
            }
            if (!sideNavItemRespParty?.items?.length) {
              sideNavItemRespParty.items = undefined;
            }
            sideNavItemRespParties?.items?.push(sideNavItemRespParty);
          }
          sideNavRespParties?.items?.push(sideNavItemRespParties);
          sideNav?.push(sideNavRespParties);
        }
//side nav resp party end

      }
      Vue.set(state, "selectedPatientSideNav", sideNav);
      //SIDE NAV

      Vue.set(state, "selectedPatient", patient);
    },
    reset: function(state: PatientsState, patientDataFiltersOnly: boolean) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          if (patientDataFiltersOnly && key === "patientDataFilters") {
            // @ts-ignore
            state[key] = newState[key];
          } else if (key !== "patientDataFilters") {
            // @ts-ignore
            state[key] = newState[key];
          }
        } catch (ex) {
          console.error("PatientsState Reset Error: ", ex.message);
        }
      });
    }
  },
  getters: {
    loading: (state) => state.loading,
    getting: (state) => state.getting,
    gettingList: (state) => state.gettingList,
    saving: (state) => state.saving,
    searching: (state) => state.searching,
    deleting: (state) => state.deleting,
    restoring: (state) => state.restoring,
    reload: (state) => state.reload,
    recentlyModified: (state) => state.recentlyModified,
    selectedPatientUpdated: (state) => state.selectedPatientUpdated,
    latestProcessingDateTime: (state) => state.latestProcessingDateTime,
    patients: (state) => state.patients,
    filteredPatients: (state) => state.filteredPatients,
    filteredPatientsTotals: (state) => state.filteredPatientsTotals,
    patientsWithDetail: (state) => state.patientsWithDetail,
    patientDataFilters: (state) => state.patientDataFilters,
    selectedPatient: (state) => state.selectedPatient,
    selectedPatientSideNav: (state) => state.selectedPatientSideNav,
    duplicatePatient: (state) => state.duplicatePatient,
    searchedPatients: (state) => state.searchedPatients,
    displaySearchedPatients: (state) =>
      ((state.patients || []).concat((state.searchedPatients|| [])) || []).map((patient: ListPatient) => {
        return {
          text:
            `${patient.fullName}` +
            (patient.birthDate
              ? ` - DOB: ${patient.birthDate.toString().substring(0, 10)}`
              : "") +
            (patient.externalPatientId
              ? ` - ID: ${patient.externalPatientId}`
              : ""),
          value: patient.patientId,
          group: "Patient",
          patient: patient
        };
      }),
    selectedPatientResponsibleSelect: (state) =>
      state.selectedPatient?.patientResponsibleParties?.map(
        (rp: ResponsibleParty) => {
          return { text: rp.fullName, value: rp.patientResponsiblePartyId };
        }
      ),
    selectedPatientResponsibleObjectSelect: (state) =>
      state.selectedPatient?.patientResponsibleParties?.map(
        (rp: ResponsibleParty) => {
          return {
            birthDate: rp.birthDate,
            createdDate: rp.createdDate,
            email: rp.email,
            firstName: rp.firstName,
            fullName: rp.fullName,
            isObsolete: rp.isObsolete,
            lastModifiedBy: rp.lastModifiedBy,
            lastModifiedDate: rp.lastModifiedDate,
            lastName: rp.lastName,
            patientId: rp.patientId,
            patientResponsiblePartyId: rp.patientResponsiblePartyId,
            patientResponsiblePartyRelationshipId:
            rp.patientResponsiblePartyRelationshipId
          } as ResponsibleParty;
        }
      )
  }
};
