import {AdditionalCharge, TreatmentClass, TreatmentType} from '@shared/store/interfaces';

//TODO change these from any but removed the types since there was a build issue
export function useUpdateSelectsFromProps(
  props: any,
  additionalCharges: AdditionalCharge[],
  treatmentTypes: TreatmentType[] ,
  treatmentClasses: TreatmentClass[],
  treatmentClass?: TreatmentClass
) {
  if (props.additionalCharges?.length) {
    props.additionalCharges = (
      (additionalCharges as AdditionalCharge[]) || []
    ).map((item: any) => item.productFeeId);
  }
  if (props.treatmentTypes?.length) {
    props.treatmentTypes = ((treatmentTypes as TreatmentType[]) || []).map(
      (item: any) => item.treatmentTypeId
    );
  }
  if (props.treatmentClasses?.length) {
    props.treatmentClasses = (
     (treatmentClasses as TreatmentClass[]) || []
    ).map((item: any) => item.treatmentClassId);
  }

  return props;
}
