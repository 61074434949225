import {AppNames} from "@shared/store/constants";

export default [
  {
    path: "/admin/dashboard",
    name: "admin-dashboard",
    component: () =>
        import(
            /* webpackChunkName: "admin-dashboard" */ "../pages/dashboard/DashboardPage.vue"
            ),
    meta: {
      layout: "Admin",
      title: "Dashboard",
      type: AppNames.ADMIN,
      rank: -1,
    },
  },
  {
    path: '/admin/notifications/list',
    alias: ['/admin/notifications'],
    name: "admin-notifications-list",
    component: () =>
      import(
        /*webpackChunkName: "admin-notifications-list" */ "../pages/admin/notifications/ListNotificationsPage.vue"
        ),
    meta: {
      layout: "Admin",
      title: "Admin Notifications",
      type: AppNames.ADMIN,
    },
  },
  {
    path: '/admin/notifications/create',
    name: "admin-notifications-create",
    component: () =>
      import(
        /*webpackChunkName: "admin-notifications-create-or-edit" */ "../pages/admin/notifications/NotificationPage.vue"
        ),
    meta: {
      layout: "Admin",
      title: "Create Notification",
      type: AppNames.ADMIN,
    },
  },
  {
    path: "/admin/notifications/:id",
    name: "admin-notifications-edit",
    component: () =>
      import(
        /*webpackChunkName: "admin-notifications-create-or-edit" */ "../pages/admin/notifications/NotificationPage.vue"
        ),
    meta: {
      layout: "Admin",
      title: "Edit Notification",
      type: AppNames.ADMIN,
    },
  },
  {
    path: '/admin/clients/list',
    alias: ['/admin', '/admin/clients'],
    name: "admin-clients-list",
    component: () =>
        import(
            /*webpackChunkName: "admin-client-list" */ "../pages/admin/clients/ListClientsPage.vue"
            ),
    meta: {
      layout: "Admin",
      title: "Admin Clients",
      type: AppNames.ADMIN,
    },
  },
  {
    path: "/admin/clients/signup",
    name: "admin-clients-signup",
    component: () =>
      import(
        /*webpackChunkName: "admin-client-signup" */ "../pages/admin/clients/SignupPage.vue"
      ),
    meta: {
      layout: "Admin",
      title: "Admin Client Sign Up",
      type: AppNames.ADMIN,
    },
  },
];
