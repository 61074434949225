import {TreatmentTypesService as ITreatmentTypesService} from "../interfaces";
import {AxiosInstance} from "axios";
import {TreatmentType} from "../../store/interfaces";

export class TreatmentTypesService implements ITreatmentTypesService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getTreatmentTypes(): Promise<TreatmentType[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentTypes
      `
    );
    return response.data;
  }

  async getTreatmentTypesDetail(): Promise<TreatmentType[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentTypes/Detail`
    );
    return response.data;
  }

  async updateTreatmentType(
    id: string,
    values: TreatmentType
  ): Promise<Number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/TreatmentTypes`,
      values,
      {
        params: { id },
      }
    );
    return response.status;
  }

  async deleteTreatmentType(id: string): Promise<TreatmentType> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/TreatmentTypes`,
      {
        params: { id },
      }
    );

    return response.data;
  }

  async createTreatmentType(values: TreatmentType): Promise<TreatmentType> {
    const response = await this.http.post(`${this.apiUrl}/api/TreatmentTypes`, {
      ...values,
    });
    return response.data;
  }

  async updateOrder(values: TreatmentType[]): Promise<Number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/TreatmentTypes/Sort`,
      values
    );
    return response.status;
  }

  async updateTreatmentFeeByComplexities(
    treatmentTypeId: string,
    values: TreatmentType
  ): Promise<TreatmentType> {
    const response = await this.http.post(
      `${this.apiUrl}/api/TreatmentTypes/${treatmentTypeId}/TreatmentFeeByComplexities`,
      values
    );
    return response.data;
  }

  async getTreatmentTypesWithClasses(): Promise<TreatmentType[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentTypes/TreatmentTypesWithClasses`);
    return response.data;
  }
}
