export default[
      {
        key: "Create User",
        icon: "$user-plus",
        text: "Create User",
        link: "/users/create",
        group: "/users/create",
      },
      {
        key: "Users",
        icon: "$users",
        text: "Users",
        link: "/users/list",
        group: "/users/list",
      }
]


