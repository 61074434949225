import {UserService as IUserService} from "./interfaces";
import {AxiosInstance} from "axios";
import { Role, User } from "../store/interfaces";

export class UserService implements IUserService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getUsers(): Promise<User[]> {
    const response = await this.http.get(`${this.apiUrl}/api/Users`);
    return response.data;
  }

  async getClientRoles(): Promise<Role[]> {
    const response = await this.http.get(`${this.apiUrl}/api/Users/roles`);
    return response.data;
  }

  async updateUser(id: string, values: User): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/Users`,
      { ...values },
      { params: { userId: id } }
    );
    return response.status;
  }

  async getHistoryRecipientUsers(recipientEmails: string[]): Promise<any[]>  {
    const response = await this.http.post(
        `${this.apiUrl}/api/Users/history-recipient-users`,
        recipientEmails
    );
    return response.data;
  }

  async createUser(values: User): Promise<User> {
    const response = await this.http.post(`${this.apiUrl}/api/Users`, {
      ...values,
    });
    return response.data;
  }

  async deleteUser(id: string): Promise<number> {
    const response = await this.http.delete(`${this.apiUrl}/api/Users`, {
      params: { userId: id },
    });
    return response.status;
  }

  async resendEmailConfirmation(id: string): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/Users/${id}/sendEmailConfirmation`,
      { id }
    );
    return response.status;
  }
}
