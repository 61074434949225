/* eslint-disable no-unused-vars */
import { Module } from "vuex";
import {
  NavMenu,
  NavMenuItem,
  Patient,
  PatientResponsibleParty,
  PatientSplitTreatmentPlanPortion,
  RootState,
  TreatmentPlan,
  TreatmentType
} from "../../index";
import Vue from "vue";
import {
  PatientContractsService,
  PatientEsignDocumentsService, PatientService,
  PatientTreatmentPlanAdditionalChargesService,
  PatientTreatmentPlanDiscountsService,
  PatientTreatmentPlansService,
  PatientWetSignDocumentsService,
  ResponsiblePartiesService
} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import { MainSubMenu as IMainSubMenu } from "../../interfaces";
import {
  ISignedContract,
  PatientContract,
  PatientEsignature,
  PatientEsignDocument,
  PatientWetSignDocument
} from "./interfaces";
import { PaymentPlansGetters } from "../../calculations/payment-plans";
import { TreatmentTypesGetters } from "../../treatment/treatment-types";
import { cloneDeep } from "lodash";
import { PaymentPlan } from "../../calculations/interfaces";
import {
  ADJUST_DATES_KEYS,
  APP_TYPES,
  CONTRACT_LOADING_TYPES,
  CONTRACT_NOTES_KEY,
  DOCTOR_NOTES_KEY,
  ESIGNATURE_DATA,
  LoadingType,
  NEW_ITEM_ID,
  SIGNING_TYPES
} from "../../constants";
import moment from "moment";
import { UIGetters } from "../../ui-customizations";
import {
  PatientTreatmentPlansActions,
  PatientTreatmentPlansGetters,
  PatientTreatmentPlansMutations
} from "../../treatment-plans";
import { PatientsActions, PatientsGetters, PatientsMutations } from "../../patients";
import { ESignerData, PatientTreatmentPlanWithAdjustments } from "@shared/store/treatment-plans/interfaces";
import {
  PatientTreatmentPlanCommunicationsService
} from "@shared/services/patient-services/PatientTreatmentPlanCommunicationsService";
import { ErrorNotification, SuccessNotification } from "@shared/functions/NotificationFunctions";
import { PatientResponsiblePartyPaymentPlan, ResponsibleParty } from "@shared/store/responsible-parties/interfaces";
import { getContractLastMarkedAsPrimary } from "@shared/functions/contractFunctions";
import { getTreatmentPlanStatusText } from "@shared/functions/statusFunctions";

export interface PatientContractsState {
  loading: boolean;
  restoring: boolean;
  refreshing: boolean;
  saving: boolean;
  deleting: boolean;
  signing: boolean;
  getting: boolean;
  gettingDetail: boolean;
  gettingLite: boolean;
  gettingSignedDocument: boolean;
  exporting: boolean;
  sending: boolean;
  sendingPdfOnly: boolean;
  adjusting: boolean;
  removingElectronicSignatures: boolean;
  removingWetSignatures: boolean;
  splittingFinancialResponsibility: boolean;
  mergingFinancialResponsibility: boolean;
  reload: boolean;
  refresh: boolean;
  patientContracts?: PatientContract[];
  contractsSideNav?: IMainSubMenu[];
  firstSignedContract?: ISignedContract | null;
  secondSignedContract?: ISignedContract | null;
  areContractsSplit?: boolean;
  arePaymentOptionsFixedLegacy: boolean;
  navContractId?: string;
  includeSliders: boolean;
}

export enum PatientContractsActions {
  LOAD_PATIENT_CONTRACTS = "PatientContractsModule/loadPatientContracts",
  LOAD_PATIENT_CONTRACTS_LIST = "PatientContractsModule/loadPatientContractsList",

  LOAD_PDFS = "PatientContractsModule/loadPdfs",
  UPDATE_NOTES_AND_ADJUST_DATES = "PatientContractsModule/updateNotesAndAdjustDates",
  UPDATE_PATIENT_CONTRACT = "PatientContractsModule/updatePatientContract",
  SPLIT_PATIENT_CONTRACTS = "PatientContractsModule/splitPatientContracts",
  UNSPLIT_PATIENT_CONTRACTS = "PatientContractsModule/unSplitPatientContracts",
  SAVE_WET_SIGNATURE = "PatientContractsModule/saveWetSignature",
  DELETE_WET_SIGNATURE = "PatientContractsModule/deleteWetSignature",
  DELETE_E_SIGNATURE = "PatientContractsModule/deleteESignature",
  GET_SIGNED_DOCUMENT = "PatientContractsModule/getSignedDocument",
  EMAIL_SIGNING_SESSION = "PatientContractsModule/patientEmailSigningSession",
  SAVE_INCLUDE_SLIDERS = "PatientContractsModule/saveIncludeSliders",
  LOAD_INCLUDE_SLIDERS = "PatientContractsModule/loadIncludeSliders",
  RESET = "PatientContractsModule/reset",
  ADJUST_PATIENT_TREATMENT_PLAN = "PatientContractsModule/adjustPatientTreatmentPlan",
}

export enum PatientContractsMutations {
  SET_LOADING = "PatientContractsModule/setLoading",
  SET_PATIENT_CONTRACTS = "PatientContractsModule/setPatientContracts",
  SET_PATIENT_CONTRACTS_SIDE_NAV = "PatientContractsModule/setPatientContractsSideNav",
  SET_RELOAD = "PatientContractsModule/setReload",
  SET_REFRESH = "PatientContractsModule/setRefresh",
  SET_INCLUDE_SLIDERS = "PatientContractsModule/setIncludeSliders",
  RESET = "PatientContracts/reset",
}

export enum PatientContractsGetters {
  LOADING = "PatientContractsModule/loading",
  SAVING = "PatientContractsModule/saving",
  RESTORING = "PatientContractsModule/restoring",
  REFRESHING = "PatientContractsModule/refreshing",
  RELOAD = "PatientContractsModule/reload",
  REFRESH = "PatientContractsModule/refresh",
  DELETING = "PatientContractsModule/deleting",
  GETTING = "PatientContractsModule/deleting",
  SIGNING = "PatientContractsModule/signing",
  GETTING_DETAIL = "PatientContractsModule/gettingDetail",
  GETTING_LITE = "PatientContractsModule/gettingLite",
  GETTING_SIGNED_DOCUMENT = "PatientContractsModule/gettingSignedDocument",
  EXPORTING = "PatientContractsModule/exporting",
  SENDING = "PatientContractsModule/sending",
  SENDING_PDF_ONLY = "PatientContractsModule/sendingPdfOnly",
  ADJUSTING = "PatientContractsModule/adjusting",
  REMOVING_ELECTRONIC_SIGNATURES = "PatientContractsModule/removingElectronicSignatures",
  REMOVING_WET_SIGNATURES = "PatientContractsModule/removingWetSignatures",
  SPLITTING_FINANCIAL_RESPONSIBILITY = "PatientContractsModule/splittingFinancialResponsibility",
  MERGING_FINANCIAL_RESPONSIBILITY = "PatientContractsModule/mergingFinancialResponsibility",
  PATIENT_CONTRACTS = "PatientContractsModule/patientContracts",
  CONTRACTS_SIDE_NAV = "PatientContractsModule/contractsSideNav",
  FIRST_SIGNED_CONTRACT = "PatientContractsModule/firstSignedContract",
  SECOND_SIGNED_CONTRACT = "PatientContractsModule/secondSignedContract",
  ARE_CONTRACTS_SPLIT = "PatientContractsModule/areContractsSplit",
  ARE_PAYMENT_OPTIONS_FIXED_LEGACY = "PatientContractsModule/arePaymentOptionsFixedLegacy",
  NAV_CONTRACT_ID = "PatientContractsModule/navContractId",
  INCLUDE_SLIDERS = "PatientContractsModule/includeSliders",
}

const initialState = () => ({
  loading: false,
  restoring: false,
  refreshing: false,
  saving: false,
  deleting: false,
  getting: false,
  signing: false,
  gettingDetail: false,
  gettingLite: false,
  gettingSignedDocument: false,
  exporting: false,
  sending: false,
  sendingPdfOnly: false,
  adjusting: false,
  removingElectronicSignatures: false,
  removingWetSignatures: false,
  splittingFinancialResponsibility: false,
  mergingFinancialResponsibility: false,
  adjustingPaymentOptions: false,
  reload: false,
  refresh: false,
  patientContracts: undefined,
  contractsSideNav: undefined,
  firstSignedContract: undefined,
  secondSignedContract: undefined,
  areContractsSplit: false,
  arePaymentOptionsFixedLegacy: false,
  navContractId: undefined,
  includeSliders: true
});


function arePaymentOptionsFixed(patientContracts?: PatientContract[], responsiblePartyId?: string | null) {
  let rtnVal = false;
  if (!patientContracts || patientContracts?.length === 0) {
    return rtnVal;
  }
  const contractsRespPartyCanAdjust = patientContracts?.filter(c => c?.patientContractCommunications?.length && (!responsiblePartyId || c.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === responsiblePartyId)) || [];

  contractsRespPartyCanAdjust?.forEach((pc) => {
    const adjustments = pc?.patientContractCommunications?.filter((communication) => !communication?.isReadOnly
      && communication?.completedDate
      && typeof communication?.completedDate === "string"
      && moment(communication?.completedDate).isValid() && Number(moment(communication.completedDate).format("YYYY")) > 1900) || [];
    if (adjustments?.length) rtnVal = true;
  });

  return rtnVal;
}

//Used in signed contract
function getEsignDocDescription(eSignDocsSigned?: PatientEsignature) {
  if (eSignDocsSigned && eSignDocsSigned?.signedTimestamp || eSignDocsSigned?.signingStatus == ESIGNATURE_DATA.executed.name) {
    if (eSignDocsSigned.signingStatus == ESIGNATURE_DATA.partiallySigned.name) {
      return "* Partially E-Signed (" + eSignDocsSigned.currentWorkflowSignSequence + " of " + eSignDocsSigned.workflowSignSequenceTotal + ")";
    } else {
      return "* E-Signed" + (eSignDocsSigned.signedTimestamp ? " on " +
        (typeof eSignDocsSigned?.signedTimestamp === "string" && moment(eSignDocsSigned.signedTimestamp).isValid()
          ? moment(eSignDocsSigned?.signedTimestamp)?.format("l")
          : "N/A")
        : "");
    }
  } else {
    return "* Awaiting E-Signature";
  }
};

//Used in signed contract
function getWetSignDocDescription(wetSignDocsSigned: PatientWetSignDocument) {
  if (wetSignDocsSigned) {
    return wetSignDocsSigned?.signedTimestamp ? "* Signed (wet) on " +
      (typeof wetSignDocsSigned?.signedTimestamp === "string" && moment(wetSignDocsSigned.signedTimestamp).isValid()
        ? moment(wetSignDocsSigned?.signedTimestamp)?.format("l")
        : "N/A")
      : "* Signed (wet)";
  } else "";
}

function getSignedContract(patientContracts?: PatientContract[], responsiblePartyId?: string | null) {
  let rtnInfo: ISignedContract | null = null;

  if (!patientContracts || patientContracts?.length === 0) {
    return rtnInfo;
  }

  const contractsCreatedEsignDocs = patientContracts?.filter(c => c.patientEsignDocuments && c.patientEsignDocuments?.length && (!responsiblePartyId || c.patientEsignDocuments?.filter((x: PatientEsignDocument) => x?.patientResponsiblePartyId === responsiblePartyId)?.length)) || [];
  if (contractsCreatedEsignDocs?.length) {
    contractsCreatedEsignDocs.forEach((pc) => {
      const docs = pc?.patientEsignDocuments?.filter((doc) => {
        return doc?.patientEsignature && (doc.patientEsignature.signingStatus == ESIGNATURE_DATA.signed.name || doc.patientEsignature.signingStatus == ESIGNATURE_DATA.partiallySigned.name || doc.patientEsignature.signingStatus == ESIGNATURE_DATA.executed.name
          || doc.patientEsignature.signingStatus == ESIGNATURE_DATA.created.name || doc.patientEsignature.signingStatus == ESIGNATURE_DATA.emailed.name);
      }) || [];
      rtnInfo = docs?.length
        ? {
          treatmentTypeId: pc.treatmentTypeId,
          document: cloneDeep(docs[docs.length - 1]),
          patientEsignature: cloneDeep(docs[docs.length - 1])?.patientEsignature,
          description: getEsignDocDescription(docs[docs.length - 1].patientEsignature),
          patientContractId: pc.patientContractId,
          patientResponsiblePartyId: responsiblePartyId,
          type: SIGNING_TYPES.ELECTRONIC
        }
        : rtnInfo ? rtnInfo : null;
    });
    if (rtnInfo) return rtnInfo;
  }
  const contractsCreatedWetSignDocs = patientContracts?.filter(c => c.patientWetSignDocuments && c.patientWetSignDocuments?.length) || [];
  if (contractsCreatedWetSignDocs?.length) {
    contractsCreatedWetSignDocs?.forEach((pc) => {
      const docs = pc?.patientWetSignDocuments?.filter((doc) => doc && doc.signingStatus == ESIGNATURE_DATA.signed.name) || [];
      rtnInfo = docs?.length
        ? {
          treatmentTypeId: pc.treatmentTypeId,
          patientEsignature: undefined,
          document: cloneDeep(docs[docs.length - 1]),
          description: getWetSignDocDescription(docs[docs.length - 1]) || "",
          patientContractId: pc.patientContractId,
          patientResponsiblePartyId: responsiblePartyId,
          type: SIGNING_TYPES.WET
        }
        : null;
    });
    if (rtnInfo) return rtnInfo;
  }
  return rtnInfo;
}

export const PatientContractsModule: Module<PatientContractsState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
      async loadPatientContractsList(
        { commit, dispatch, rootGetters },
        payload: {
          id: string;
          service: PatientContractsService;
        }
      ) {
        try {
          commit("setLoading", {
              loading: true,
              loadingType: [CONTRACT_LOADING_TYPES.GETTING, CONTRACT_LOADING_TYPES.GETTING_LITE]
            }
          );
          const { service, id } = payload;
          const patientContracts: PatientContract[] = await service.getPatientContractsList(id);
          const paymentPlans = rootGetters[
            PaymentPlansGetters.PAYMENT_PLANS_WITH_DISPLAY_TEXT];
          const treatmentTypes = rootGetters[TreatmentTypesGetters.TREATMENT_TYPES];
          patientContracts?.forEach((contract: any, index) => {
            const txTypeIndex = treatmentTypes?.findIndex((x: TreatmentType) => x.treatmentTypeId === contract?.treatmentTypeId);
            if (typeof txTypeIndex === "number" && txTypeIndex > -1) {
              patientContracts[index].treatmentType = cloneDeep(treatmentTypes[txTypeIndex]);
            }
            const paymentPlansIndex = paymentPlans?.findIndex((x: PaymentPlan) => x.paymentPlanId === contract?.paymentPlanId);
            if (typeof paymentPlansIndex === "number" && paymentPlansIndex > -1) {
              patientContracts[index].paymentPlan = cloneDeep(paymentPlans[paymentPlansIndex]);
            }
          });
          commit("setPatientContracts", patientContracts);
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {
            loading: false,
            loadingType: [CONTRACT_LOADING_TYPES.GETTING, CONTRACT_LOADING_TYPES.GETTING_LITE]
          }
        );
      },
      async loadPatientContracts(
        { commit, dispatch, getters },
        payload: {
          id: string;
          service: PatientContractsService;
          suppressNotification: boolean;
        }
      ) {
        let success = false;
        const isRefresh = getters.refresh;
        const loadingTypes = isRefresh ? [CONTRACT_LOADING_TYPES.REFRESHING, CONTRACT_LOADING_TYPES.GETTING, CONTRACT_LOADING_TYPES.GETTING_DETAIL] : [CONTRACT_LOADING_TYPES.GETTING, CONTRACT_LOADING_TYPES.GETTING_DETAIL];
        const { service, id, suppressNotification } = payload;
        try {
          commit("setLoading", {
              loading: true,
              loadingType: loadingTypes
            }
          );

          const patientContracts: PatientContract[] = await service.getPatientContracts(id, isRefresh);
          commit("setPatientContracts", patientContracts);
          success = true;
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        if (success) {
          if (getters.refresh && !suppressNotification) {
            await SuccessNotification(dispatch, `Documents have been regenerated!`);
          }
          commit("setReload", false);
          commit("setRefresh", false);
        }
        commit("setLoading", {
            loading: false,
            loadingType: loadingTypes
          }
        );
        return success;
      },
      async saveWetSignature(
        { commit, dispatch, getters },
        payload: {
          patientTreatmentPlanId: string;
          patientResponsiblePartyId: string;
          patientContractId: string;
          signedTimestamp: string;
          service: PatientWetSignDocumentsService;
          contractsService: PatientContractsService;
          saveAndSign: boolean;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
              loading: true,
              loadingType: LoadingType.SIGNING
            }
          );
          const {
            service,
            contractsService,
            patientContractId,
            patientTreatmentPlanId,
            patientResponsiblePartyId,
            signedTimestamp,
            saveAndSign
          } = payload;
          const patientWetSignDocument = {
            patientContractId: patientContractId,
            patientResponsiblePartyId: patientResponsiblePartyId,
            signingStatus: ESIGNATURE_DATA.signed.name,
            lastEmailedTimestamp: undefined,
            signedTimestamp: signedTimestamp || moment().format("YYYY-MM-DD")
          } as PatientWetSignDocument;
          await service.savePatientWetSignDocument(patientTreatmentPlanId, patientWetSignDocument);
          const patientContracts: PatientContract[] = await contractsService.getPatientContracts(patientTreatmentPlanId, false);
          commit("setPatientContracts", patientContracts);
          success = await SuccessNotification(dispatch, saveAndSign ? `Treatment Start documents were saved and wet signed!` : `Treatment Start documents wet signed!`);
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {
            loading: false,
            loadingType: LoadingType.SIGNING
          }
        );
        return success;
      },
      async deleteWetSignature(
        { commit, dispatch, getters },
        payload: {
          patientTreatmentPlanId: string;
          patientContractId: string;
          service: PatientWetSignDocumentsService;
          contractsService: PatientContractsService;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
              loading: true,
              loadingType: [CONTRACT_LOADING_TYPES.DELETING, CONTRACT_LOADING_TYPES.REMOVING_WET_SIGNATURES]
            }
          );
          const {
            service,
            contractsService,
            patientContractId,
            patientTreatmentPlanId
          } = payload;
          const statusCode = await service.deletePatientWetSignDocument(patientContractId);
          if (statusCode !== 200) {
            throw Error("Unable to delete signature, please refresh the page and try again");
          }
          await SuccessNotification(dispatch, `Signature was deleted!`);
          //refresh contracts if deleting the only signed document
          if (!getters.firstSignedContract || !getters.secondSignedContract) {
              commit("setRefresh", true);
          }
          commit(PatientsMutations.SET_RELOAD, true, { root: true });
          commit(PatientTreatmentPlansMutations.SET_RELOAD, true, { root: true });
          commit("setReload", true);
          success = true;
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
          ;
        }
        commit("setLoading", {
            loading: false,
            loadingType: [CONTRACT_LOADING_TYPES.DELETING, CONTRACT_LOADING_TYPES.REMOVING_WET_SIGNATURES]
          }
        );
        return success;
      },
      async deleteESignature(
        { commit, dispatch, getters },
        payload: {
          patientTreatmentPlanId: string;
          patientContractId: string;
          service: PatientEsignDocumentsService;
          contractsService: PatientContractsService;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
              loading: true,
              loadingType: [CONTRACT_LOADING_TYPES.DELETING, CONTRACT_LOADING_TYPES.REMOVING_ELECTRONIC_SIGNATURES]
            }
          );
          const {
            service,
            contractsService,
            patientContractId,
            patientTreatmentPlanId
          } = payload;
          const response = await service.deleteEsignature(patientContractId);
          if (response?.length === 0) {
            throw Error("Unable to delete signature, please refresh the page and try again");
          }
          await SuccessNotification(dispatch, `Signature was deleted!`);
          //refresh contracts if deleting the only signed document
          if (!getters.firstSignedContract || !getters.secondSignedContract) {
            commit("setRefresh", true);
          }
          commit(PatientsMutations.SET_RELOAD, true, { root: true });
          commit(PatientTreatmentPlansMutations.SET_RELOAD, true, { root: true });
          commit("setReload", true);
          success = true;
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {
            loading: false,
            loadingType: [CONTRACT_LOADING_TYPES.DELETING, CONTRACT_LOADING_TYPES.REMOVING_ELECTRONIC_SIGNATURES]
          }
        );
        return success;
      },
      async getSignedDocument(
        { commit, dispatch },
        payload: {
          patientContractId: string;
          service: PatientEsignDocumentsService;
        }
      ) {
        let response = "";
        try {
          commit("setLoading", {
              loading: true,
              loadingType: [CONTRACT_LOADING_TYPES.GETTING, CONTRACT_LOADING_TYPES.RESTORING]
            }
          );
          const {
            service,
            patientContractId
          } = payload;
          response = await service.getPatientEsignDocumentAccessUrl(patientContractId);
          if (response?.length === 0) {
            throw Error("Unable to get signed document, please refresh the page and try again");
          }
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {
            loading: false,
            loadingType: [CONTRACT_LOADING_TYPES.GETTING, CONTRACT_LOADING_TYPES.RESTORING]
          }
        );
        return response;
      },
      async patientEmailSigningSession(
        { commit, dispatch },
        payload: {
          service: PatientTreatmentPlanCommunicationsService;
          patientContractService: PatientContractsService;
          eSignerData: ESignerData;
        }
      ) {
        let urlDocument = "";
        try {
          commit("setLoading", { loading: true, loadingType: LoadingType.SIGNING });
          const { service, patientContractService, eSignerData } = payload;
          urlDocument = await service.contractSigningSession(eSignerData);
          if (!urlDocument || urlDocument.length <= 0) {
            throw Error("Unable to create email signing session");
          }

          //TODO update patient contract manually instead
          await dispatch(
            PatientContractsActions.LOAD_PATIENT_CONTRACTS,
            {
              id: eSignerData.patientTreatmentPlanId,
              service: patientContractService,
              suppressNotification: true
            }, { root: true });
          await SuccessNotification(dispatch, `Treatment start documents emailed for signing session`);
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", { loading: false, loadingType: LoadingType.SIGNING });
        return urlDocument;
      },
      async updateNotesAndAdjustDates(
        { commit, dispatch, getters },
        payload: {
          id: string;
          patientContract: PatientContract;
          applyContractNotesToAllContracts: boolean;
          applyDoctorNotesToAllContracts: boolean;
          applyAdjustDatesToAllContracts: boolean;
          service: PatientContractsService;
          suppressNotification: boolean;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
            loading: true,
            loadingType: CONTRACT_LOADING_TYPES.SAVING
          });
          const {
            service,
            id,
            patientContract,
            applyDoctorNotesToAllContracts,
            applyAdjustDatesToAllContracts,
            applyContractNotesToAllContracts,
            suppressNotification
          } = payload;
          let lastModifiedDate = moment.utc().format();

          const response = await service.updatePatientContractsNotesAndAdjustDates(id, patientContract, applyDoctorNotesToAllContracts, applyAdjustDatesToAllContracts, applyContractNotesToAllContracts);
          if (!response || !response.lastModifiedDate) {
            Error("Unable to update tx plan notes");
          } else {
            lastModifiedDate = response.lastModifiedDate;
          }

          const patientContracts = cloneDeep(
            getters.patientContracts
          );
          const contractIndex = patientContracts?.findIndex((x: PatientContract) => x.patientContractId === id);
          if (contractIndex > -1) {
            patientContracts[contractIndex] = cloneDeep(patientContract);
          } else {
            throw Error("Unable to save treatment start documents due to data issue, please refresh the page and try again");
          }
          if (applyDoctorNotesToAllContracts || applyAdjustDatesToAllContracts || applyContractNotesToAllContracts) {
            (patientContracts || []).forEach(
              (item: PatientContract, index: number) => {
                patientContracts[index].lastModifiedDate = lastModifiedDate;
                if (applyDoctorNotesToAllContracts) {
                  patientContracts[index][DOCTOR_NOTES_KEY] = patientContract[DOCTOR_NOTES_KEY];
                }
                if (applyContractNotesToAllContracts) {
                  patientContracts[index][CONTRACT_NOTES_KEY] = patientContract[CONTRACT_NOTES_KEY];
                }
                if (applyAdjustDatesToAllContracts) {
                  (ADJUST_DATES_KEYS || [])?.forEach((key) => {
                      // @ts-ignore
                      patientContracts[index][key] = patientContract[key];
                    }
                  );
                }
              }
            );
          }
          commit("setPatientContracts", patientContracts);
          success = await SuccessNotification(dispatch, `Updated Patient Contract!`, suppressNotification);
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", { loading: false, loadingType: CONTRACT_LOADING_TYPES.SAVING });
        return success;
      },
      async updatePatientContract(
        { commit, dispatch, getters },
        payload: {
          id: string;
          patientContract: PatientContract;
          applyDatesToAllContracts: boolean;
          isEmailSignInProgress: boolean;
          service: PatientContractsService;
          isOfficeMode: boolean;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
            loading: true,
            loadingType: LoadingType.SAVING
          });
          const {
            service,
            id,
            patientContract,
            applyDatesToAllContracts,
            isEmailSignInProgress,
            isOfficeMode
          } = payload;
          await service.updatePatientContract(id, patientContract, applyDatesToAllContracts, isEmailSignInProgress, isOfficeMode);

          const patientContracts = cloneDeep(
            getters.patientContracts
          );
          const contractIndex = patientContracts?.findIndex((x: PatientContract) => x.patientContractId === id);
          if (contractIndex > -1) {
            patientContracts[contractIndex] = cloneDeep(patientContract);
          } else {
            throw Error("Unable to save patient payment options information, please refresh the page and try again");
          }
          //todo might need to include applyDatesToAllContracts / isEmailSignInProgress
          commit("setPatientContracts", patientContracts);
          success = await SuccessNotification(dispatch, `Updated Patient Payment Schedule!`);
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
        return success;
      },
      async adjustPatientTreatmentPlan(
        { commit, dispatch, getters, rootGetters },
        payload: {
          id: string;
          patientTreatmentPlanId: string;
          isPatient: boolean;
          patientTreatmentPlanWithAdjustments: PatientTreatmentPlanWithAdjustments;
          patientTreatmentPlanCommunicationsService: PatientTreatmentPlanCommunicationsService;
          service: PatientContractsService;
          patientTreatmentPlansService: PatientTreatmentPlansService,
          patientTreatmentPlanDiscountsService: PatientTreatmentPlanDiscountsService,
          patientTreatmentPlanAdditionalChargesService: PatientTreatmentPlanAdditionalChargesService,
          patientService: PatientService,
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
            loading: true,
            loadingType: LoadingType.SAVING
          });

          const {
            service,
            patientTreatmentPlanCommunicationsService,
            id,
            patientTreatmentPlanId,
            isPatient,
            patientTreatmentPlanWithAdjustments,
            patientTreatmentPlansService,
            patientTreatmentPlanDiscountsService,
            patientTreatmentPlanAdditionalChargesService,
            patientService
          } = payload;

          if (patientTreatmentPlanId == NEW_ITEM_ID || id == NEW_ITEM_ID) {
            await ErrorNotification(dispatch, undefined, `No Treatment Plan Found!`);
          } else {
            const statusCode = await patientTreatmentPlanCommunicationsService.adjustPatientTreatmentPlan(id, patientTreatmentPlanWithAdjustments, !!isPatient);
            if (statusCode < 200 && statusCode >= 300) {
              throw Error("Unable to accept treatment at this time");
            }
            // reload contracts
            const getPromises: Promise<any>[] = [dispatch(
              "loadPatientContracts",
              {
                id: patientTreatmentPlanId,
                service: service,
                suppressNotification: true
              })];
            if (process.env.VUE_APP_TYPE === APP_TYPES.MAIN) {
              const patientDataSearchParams = rootGetters[PatientsGetters.PATIENT_DATA_FILTERS];
              getPromises.push(dispatch(PatientTreatmentPlansActions.LOAD_SELECTED_TREATMENT_PLAN, {
                  id: patientTreatmentPlanId,
                  service: patientTreatmentPlansService,
                  patientTreatmentPlanDiscountsService: patientTreatmentPlanDiscountsService,
                  patientTreatmentPlanAdditionalChargesService: patientTreatmentPlanAdditionalChargesService
                },
                { root: true }
              ));
              getPromises.push(dispatch(PatientsActions.LIST_PATIENTS, {
                service: patientService,
                patientDataSearchParams: patientDataSearchParams
              }, { root: true }));
            }

            const promiseResponse = await Promise.all(getPromises);
            const patientContracts = getters["patientContracts"];
            const treatmentPlan = rootGetters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLAN];
            commit("setPatientContractsSideNav", { patientContracts, treatmentPlan });
            success = true;
          }
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
        return success;
      },
      async splitPatientContracts(
        { commit, dispatch, rootGetters },
        payload: {
          patientSplitTreatmentPlanPortions: PatientSplitTreatmentPlanPortion[];
          service: PatientContractsService;
          responsiblePartiesService: ResponsiblePartiesService;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
              loading: true,
              loadingType: [CONTRACT_LOADING_TYPES.SAVING, CONTRACT_LOADING_TYPES.SPLITTING_FINANCIAL_RESPONSIBILITY]
            }
          );
          const {
            service,
            patientSplitTreatmentPlanPortions,
            responsiblePartiesService
          } = payload;

          const selectedPatientTreatmentPlan = rootGetters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLAN] as TreatmentPlan;

          if (!(selectedPatientTreatmentPlan?.patientTreatmentPlanId && patientSplitTreatmentPlanPortions?.length === 2)) {
            throw Error("Split payment has to have 2 responsible parties and done within a selected treatment plan.");
          }
          const selectedPatient = rootGetters[PatientsGetters.SELECTED_PATIENT] as Patient;
          if (!(selectedPatient && selectedPatient.patientId)) {
            throw Error("Split payment has to a patient to complete.");
          }
          let rpSuccess = true;

          const rpSaveEmailSuccess = await dispatch(PatientsActions.SAVE_RESPONSIBLE_PARTIES_EMAIL, {
            responsibleParties: [cloneDeep(patientSplitTreatmentPlanPortions[0]?.patientResponsibleParty), cloneDeep(patientSplitTreatmentPlanPortions[1]?.patientResponsibleParty)],
            responsiblePartiesService: responsiblePartiesService,
            suppressNotification: true
          }, { root: true });

          if (!patientSplitTreatmentPlanPortions?.[0]?.patientResponsibleParty?.patientResponsiblePartyId || patientSplitTreatmentPlanPortions?.[0]?.patientResponsibleParty?.patientResponsiblePartyId === NEW_ITEM_ID) {
            const newResponsibleParty = cloneDeep(patientSplitTreatmentPlanPortions[0]?.patientResponsibleParty);
            (newResponsibleParty as ResponsibleParty).patientResponsiblePartyPaymentPlans = [] as PatientResponsiblePartyPaymentPlan[];
            for (const respPartyPaymentPlan of cloneDeep(patientSplitTreatmentPlanPortions[1]?.patientResponsibleParty as ResponsibleParty)?.patientResponsiblePartyPaymentPlans || [] as PatientResponsiblePartyPaymentPlan[]) {
              (newResponsibleParty as ResponsibleParty).patientResponsiblePartyPaymentPlans?.push({
                patientResponsibleParty_PatientResponsiblePartyId: NEW_ITEM_ID,
                paymentPlan_PaymentPlanId: respPartyPaymentPlan.paymentPlan_PaymentPlanId
              } as PatientResponsiblePartyPaymentPlan);
            }
            const {
              success: rpSaveSuccess,
              responsibleParty: responsibleParty
            } = await dispatch(PatientsActions.SAVE_RESPONSIBLE_PARTY, {
              responsibleParty: newResponsibleParty,
              responsiblePartiesService: responsiblePartiesService,
              suppressNotification: true
            }, { root: true });
            patientSplitTreatmentPlanPortions[0].patientResponsibleParty = cloneDeep(responsibleParty);
            if (!rpSaveSuccess || responsibleParty?.patientResponsiblePartyId === NEW_ITEM_ID) {
              rpSuccess = false;
            }
          }

          if (!patientSplitTreatmentPlanPortions?.[1]?.patientResponsibleParty?.patientResponsiblePartyId || patientSplitTreatmentPlanPortions?.[1]?.patientResponsibleParty?.patientResponsiblePartyId === NEW_ITEM_ID) {
            const newResponsibleParty = cloneDeep(patientSplitTreatmentPlanPortions[1]?.patientResponsibleParty);
            (newResponsibleParty as ResponsibleParty).patientResponsiblePartyPaymentPlans = [] as PatientResponsiblePartyPaymentPlan[];
            for (const respPartyPaymentPlan of cloneDeep(patientSplitTreatmentPlanPortions[0]?.patientResponsibleParty as ResponsibleParty)?.patientResponsiblePartyPaymentPlans || [] as PatientResponsiblePartyPaymentPlan[]) {
              (newResponsibleParty as ResponsibleParty).patientResponsiblePartyPaymentPlans?.push({
                patientResponsibleParty_PatientResponsiblePartyId: NEW_ITEM_ID,
                paymentPlan_PaymentPlanId: respPartyPaymentPlan.paymentPlan_PaymentPlanId
              } as PatientResponsiblePartyPaymentPlan);
            }
            const {
              success: rpSaveSuccess,
              responsibleParty: responsibleParty
            } = await dispatch(PatientsActions.SAVE_RESPONSIBLE_PARTY, {
              responsibleParty: newResponsibleParty,
              responsiblePartiesService: responsiblePartiesService,
              suppressNotification: true
            }, { root: true });
            patientSplitTreatmentPlanPortions[1].patientResponsibleParty = cloneDeep(responsibleParty);
            if (!rpSaveSuccess || responsibleParty?.patientResponsiblePartyId === NEW_ITEM_ID) {
              rpSuccess = false;
            }
          }

          //throw error if patientSplitTreatmentPlanPortions patientResponsiblePartyPaymentPlans don't have at least 1 overlapping paymentPlanId
          const rp0PaymentPlans = cloneDeep(patientSplitTreatmentPlanPortions[0]?.patientResponsibleParty as ResponsibleParty)?.patientResponsiblePartyPaymentPlans || [];
          const rp1PaymentPlans = cloneDeep(patientSplitTreatmentPlanPortions[1]?.patientResponsibleParty as ResponsibleParty)?.patientResponsiblePartyPaymentPlans || [];
          let overlappingPaymentPlanExists = false;
          for (const rp1PaymentPlan of rp1PaymentPlans) {
            // check if rp1PaymentPlans.paymentPlan_PaymentPlanId is in rp0PaymentPlans
            if (rp0PaymentPlans.find((x) => x.paymentPlan_PaymentPlanId === rp1PaymentPlan.paymentPlan_PaymentPlanId)) {
              overlappingPaymentPlanExists = true;
              break;
            }
          }
          if (!overlappingPaymentPlanExists) {
            throw Error("Split financial responsibility has to have at least one overlapping payment plan between the two responsible parties.");
          }


          if (rpSaveEmailSuccess && rpSuccess) {
            selectedPatientTreatmentPlan.patient = selectedPatient;
            await service.splitPatientContracts(patientSplitTreatmentPlanPortions, selectedPatientTreatmentPlan);
            await SuccessNotification(dispatch, `Split payment added and regenerated treatment start / payment options documents!`);
            commit(PatientsMutations.SET_RELOAD, true, { root: true });
            commit(PatientTreatmentPlansMutations.SET_RELOAD, true, { root: true });
            commit("setReload", true);
            success = true;
          }
        } catch (error) {
          await ErrorNotification(dispatch, error, typeof error === "string" ? error : error.data);
        }
        commit("setLoading", {
            loading: false,
            loadingType: [CONTRACT_LOADING_TYPES.SAVING, CONTRACT_LOADING_TYPES.SPLITTING_FINANCIAL_RESPONSIBILITY]
          }
        );
        return success;
      },
      async unSplitPatientContracts(
        { commit, dispatch, getters, rootGetters },
        payload: {
          patientTreatmentPlanId: string;
          service: PatientContractsService;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {
              loading: true,
              loadingType: [CONTRACT_LOADING_TYPES.SAVING, CONTRACT_LOADING_TYPES.MERGING_FINANCIAL_RESPONSIBILITY]
            }
          );
          const {
            service,
            patientTreatmentPlanId
          } = payload;
          const patientContracts = await service.unsplitPatientContracts(patientTreatmentPlanId);
          const treatmentPlan = rootGetters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLAN];
          if (treatmentPlan && treatmentPlan?.patientSplitTreatmentPlanPortions) {
            treatmentPlan.patientSplitTreatmentPlanPortions = [];
          }

          if (patientContracts?.length === 0) {
            throw Error("No treatment start documents were generated something went wrong removing the split payments.");
          }
          commit("setPatientContracts", patientContracts);
          commit("setPatientContractsSideNav", { patientContracts, treatmentPlan });
          await SuccessNotification(dispatch, `Removed split payments and regenerated treatment start / payment options documents!`);
          commit(PatientsMutations.SET_RELOAD, true, { root: true });
          commit(PatientTreatmentPlansMutations.SET_RELOAD, true, { root: true });
          commit("setReload", true);
          success = true;
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {
            loading: false,
            loadingType: [CONTRACT_LOADING_TYPES.SAVING, CONTRACT_LOADING_TYPES.MERGING_FINANCIAL_RESPONSIBILITY]
          }
        );
        return success;
      },
      loadPdfs: async function(
        { commit, dispatch, rootGetters },
        payload: {
          service: PatientContractsService;
          contractId: string,
          showLetterhead?: boolean,
        }
      ) {
        try {
          commit("setLoading", {
              loading: true,
              loadingType: CONTRACT_LOADING_TYPES.RESTORING
            }
          );
          commit("setLoading", { loading: true, loadingType: CONTRACT_LOADING_TYPES.RESTORING });
          const { service, contractId, showLetterhead } = payload;

          const browserType = rootGetters[
            UIGetters.BROWSER_TYPE];
          await service.postPdf(
            contractId,
            !!showLetterhead,
            browserType
          );

        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", { loading: false, loadingType: CONTRACT_LOADING_TYPES.RESTORING });
      },
      async loadIncludeSliders(
        { commit },
        payload: {
          service: PatientContractsService;
        }
      ) {
        try {
          const { service } = payload;
          const value = await service.getIncludeSliders();

          commit("setIncludeSliders", value);
        } catch (error) {
          if (process.env.NODE_ENV == "development") {
            // eslint-disable-next-line no-console
            console.error("error", error);
          }
        }
      },
      async saveIncludeSliders(
        { commit },
        payload: {
          value: boolean;
          service: PatientContractsService;
        }
      ) {
        try {
          const { value, service } = payload;
          await service.saveIncludeSliders(value);
          commit("setIncludeSliders", value);
        } catch (error) {
          if (process.env.NODE_ENV == "development") {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        }
      },
      reset({ commit }) {
        commit("reset");
      }
    },
    mutations: {
      setLoading(
        state: PatientContractsState,
        payload: { loading: boolean; loadingType: string | string[] }
      ) {
        if (typeof payload === "boolean") {
          Vue.set(state, "loading", payload);
        } else {
          if (payload.loadingType) {
            if (Array.isArray(payload.loadingType)) {
              payload.loadingType.forEach((item) => {
                Vue.set(state, item, payload.loading);
              });
            } else {
              Vue.set(state, payload.loadingType, payload.loading);
            }
          }
          Vue.set(state, "loading", payload.loading);
        }
      },
      setReload(state: PatientContractsState, reload: boolean = false) {
        Vue.set(state, "reload", reload);
      },
      setRefresh(state: PatientContractsState, refresh: boolean = false) {
        Vue.set(state, "refresh", refresh);
      },
      setIncludeSliders(state: PatientContractsState, includeSliders: boolean = true) {
        Vue.set(state, "includeSliders", includeSliders);
      },
      setPatientContracts(state: PatientContractsState, patientContracts?: PatientContract[]
      ) {
        const areContractsSplit = (patientContracts?.length || 0) > 1 ? !!patientContracts?.[0]?.patientSplitTreatmentPlanPortion : false;
        const firstPatientResponsiblePartyId = areContractsSplit ? patientContracts?.[0]?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId : undefined;

        Vue.set(state, "areContractsSplit", areContractsSplit);

        Vue.set(state, "arePaymentOptionsFixedLegacy", arePaymentOptionsFixed(cloneDeep(patientContracts), firstPatientResponsiblePartyId));


        const signedContract = (areContractsSplit ? getSignedContract(cloneDeep(patientContracts), firstPatientResponsiblePartyId)
          : getSignedContract(cloneDeep(patientContracts))) as ISignedContract | null | undefined;
        if (signedContract) {
          Vue.set(state, "navContractId", signedContract?.patientContractId);
        } else {
          const contract = getContractLastMarkedAsPrimary(cloneDeep(patientContracts?.filter((x) => (!firstPatientResponsiblePartyId || x.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === firstPatientResponsiblePartyId))));
          if (contract) {
            Vue.set(state, "navContractId", contract?.patientContractId);
          }
        }

        const secondPatientResponsiblePartyId =
          areContractsSplit
            ? patientContracts?.find((item) =>
              item?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId !== firstPatientResponsiblePartyId
              && typeof item?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === "string"
              && item?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId?.length)?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId
            : undefined;
        const secondSignedContract = areContractsSplit ? getSignedContract(cloneDeep(patientContracts), secondPatientResponsiblePartyId)
          : undefined;
        Vue.set(state, "secondSignedContract", secondSignedContract);
        Vue.set(state, "firstSignedContract", signedContract);
        Vue.set(state, "patientContracts", patientContracts);
      },
      setPatientContractsSideNav(state: PatientContractsState
        , payload: { patientContracts?: PatientContract[], treatmentPlan?: TreatmentPlan }
      ) {
        const { patientContracts, treatmentPlan } = payload;

        if (!patientContracts || !treatmentPlan || !Array.isArray(patientContracts) || !patientContracts?.length) {
          Vue.set(state, "contractsSideNav", undefined);
          return;
        }
        const isSplit = !!(treatmentPlan?.patientSplitTreatmentPlanPortions && treatmentPlan?.patientSplitTreatmentPlanPortions?.length > 1);
        let fixedTreatmentTypeId: string | null | undefined = undefined;
        let adjustedTreatmentTypeId: string | null | undefined = undefined;
        let adjustedPaymentPlanId: string | null | undefined = undefined;
        let adjustedDate: string | null | undefined | Date = undefined;
        if ((treatmentPlan.status as number) === 0 || (treatmentPlan.status as number) === 1 || (treatmentPlan.status as number) === 2) {
          //check payment options fixed (if fixed should have a last marked primary date)
          const contracts = cloneDeep(patientContracts) || [];
          const primaryContracts = contracts?.filter((item: PatientContract) => item.lastMarkedPrimaryDate) as PatientContract[];
          // sort those contracts in chronological order of being marked primary, so we can get the most recent one
          const sortedPrimaryContracts = primaryContracts?.sort((a: PatientContract, b: PatientContract) => {
            if (moment(a?.lastMarkedPrimaryDate) < moment(b?.lastMarkedPrimaryDate)) {
              return 1;
            } else if (moment(a?.lastMarkedPrimaryDate) > moment(b?.lastMarkedPrimaryDate)) {
              return -1;
            } else {
              return 0;
            }
          });
          if (sortedPrimaryContracts && sortedPrimaryContracts?.length > 0) {
            adjustedTreatmentTypeId = sortedPrimaryContracts?.[0]?.treatmentTypeId;
            adjustedPaymentPlanId = sortedPrimaryContracts?.[0]?.paymentPlanId;
            adjustedDate = treatmentPlan?.acceptedOrDeclinedDate;
            if (isSplit) {
              fixedTreatmentTypeId = sortedPrimaryContracts?.[0]?.treatmentTypeId;
            }
          }
        }
        //format dates
        for (const index in patientContracts) {
          if (!patientContracts[index]) continue;

          if ((patientContracts[index] as PatientContract)?.recurringPaymentStartDate && moment(patientContracts[index]?.recurringPaymentStartDate).isValid()) {
            patientContracts[index].recurringPaymentStartDate =
              moment.utc(patientContracts[index].recurringPaymentStartDate as string, "YYYY-MM-DD").format("YYYY-MM-DD");
          }
          if (patientContracts[index]?.downPaymentDate && moment(patientContracts[index]?.downPaymentDate).isValid()) {
            patientContracts[index].downPaymentDate =
              moment.utc(patientContracts[index].downPaymentDate as string, "YYYY-MM-DD").format("YYYY-MM-DD");
          }
          if (patientContracts[index]?.downPayment2Date && moment(patientContracts[index]?.downPayment2Date).isValid()) {
            patientContracts[index].downPayment2Date =
              moment.utc(patientContracts[index].downPayment2Date as string, "YYYY-MM-DD").format("YYYY-MM-DD");
          }
        }


        const patientId = Array.isArray(patientContracts) && (patientContracts || [])?.length ? patientContracts[0].patientId : undefined;
        const txPlanId = Array.isArray(patientContracts) && (patientContracts || [])?.length ? patientContracts[0].patientTreatmentPlanId : undefined;
        const baseLink = `/patients/${patientId}/treatment-plans/${txPlanId}`;

        const treatmentTypes = [...new Map((patientContracts || [])?.map(item =>
          [item["treatmentTypeId"], item.treatmentType])).values()]?.sort(
          (a: TreatmentType, b: TreatmentType) => {
            return a?.rank - b?.rank;
          });
        const contractsMainSubMenu = [] as IMainSubMenu[];
        let respPartyIndex = 0;

        if (state?.firstSignedContract?.treatmentTypeId) {
          fixedTreatmentTypeId = state?.firstSignedContract?.treatmentTypeId;
        }
        if (state?.secondSignedContract?.treatmentTypeId) {
          fixedTreatmentTypeId = state?.secondSignedContract?.treatmentTypeId;
        }

        for (const respParty of treatmentPlan?.patientResponsibleParties as PatientResponsibleParty[]) {
          const patientSplitTreatmentPlanPortion = isSplit ? (treatmentPlan?.patientSplitTreatmentPlanPortions?.find((x) => x.patientResponsiblePartyId === respParty.patientResponsiblePartyId)) : undefined;

          let respPartyDisplay = respParty?.fullName;
          let respPartyPaymentOptionsLink = baseLink + "/payment-options";
          let respPartyTxStartDocsLink = baseLink + "/treatment-start-docs";
          let respPartyId = respParty.patientResponsiblePartyId;
          let signedContract: ISignedContract | undefined | null = null;
          let respPartyAdjustedPaymentPlanId = isSplit ? undefined : adjustedPaymentPlanId;
          let respPartyAdjustedTreatmentTypeId = isSplit ? undefined : adjustedTreatmentTypeId;
          let respPartyAdjustedDate = isSplit ? patientSplitTreatmentPlanPortion?.acceptedOrDeclinedDate : adjustedDate;
          const respPartyTxPlanStatus = isSplit ? patientSplitTreatmentPlanPortion?.status : treatmentPlan?.status;

          const paymentPlans = [...new Map((patientContracts || [])?.filter((x) => !isSplit || x?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === respPartyId)?.map(item =>
            [item["paymentPlanId"], item.paymentPlan])).values()]?.sort(
            (a: PaymentPlan, b: PaymentPlan) => {
              return a?.paymentPlanType?.order - b?.paymentPlanType?.order;
            });

          if (isSplit) {
            if (state?.firstSignedContract?.patientResponsiblePartyId === respPartyId) {
              signedContract = cloneDeep(state?.firstSignedContract);
            }
            if (state?.secondSignedContract?.patientResponsiblePartyId === respPartyId) {
              signedContract = cloneDeep(state?.secondSignedContract);
            }

            if (!signedContract) {
              const contracts = cloneDeep(patientContracts) || [];
              const primaryContracts = contracts?.filter((item: PatientContract) => item.lastMarkedPrimaryDate && respPartyId === item?.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId) as PatientContract[];
              // sort those contracts in chronological order of being marked primary, so we can get the most recent one
              const sortedPrimaryContracts = primaryContracts?.sort((a: PatientContract, b: PatientContract) => {
                if (moment(a?.lastMarkedPrimaryDate) < moment(b?.lastMarkedPrimaryDate)) {
                  return 1;
                } else if (moment(a?.lastMarkedPrimaryDate) > moment(b?.lastMarkedPrimaryDate)) {
                  return -1;
                } else {
                  return 0;
                }
              });
              if (sortedPrimaryContracts && sortedPrimaryContracts?.length > 0) {
                respPartyAdjustedTreatmentTypeId = sortedPrimaryContracts?.[0]?.treatmentTypeId;
                respPartyAdjustedPaymentPlanId = sortedPrimaryContracts?.[0]?.paymentPlanId;
                respPartyAdjustedDate = sortedPrimaryContracts?.[0]?.lastMarkedPrimaryDate;
              }
            }
          } else {
            signedContract = cloneDeep(state?.firstSignedContract);
          }

          if (isSplit) {
            if (patientSplitTreatmentPlanPortion) {
              respPartyDisplay += patientSplitTreatmentPlanPortion?.isRespPartyAmtPercentage
                ? " - " + patientSplitTreatmentPlanPortion?.responsiblePartyAmount + "%"
                : " - $" + patientSplitTreatmentPlanPortion?.responsiblePartyAmount;
            }
          } else {
            if (respPartyIndex > 0) {
              // @ts-ignore
              //THIS KEEPS US FROM LOOPING OVER EVERYTHING WHEN IT IS ONLY NEEDED ON SPLIT TO GO THROUGH BOTH RESPOSIBLE PARTIES
              break;
            }
            if (treatmentPlan?.patientResponsibleParties?.length > 1 && (treatmentPlan?.patientResponsibleParties as PatientResponsibleParty[])[1]) {
              if (respParty?.lastName === (treatmentPlan?.patientResponsibleParties as PatientResponsibleParty[])[1]?.lastName) {
                respPartyDisplay = respParty?.firstName + " & " + (treatmentPlan?.patientResponsibleParties as PatientResponsibleParty[])[1]?.fullName;
              } else {
                respPartyDisplay += " & " + (treatmentPlan?.patientResponsibleParties as PatientResponsibleParty[])[1]?.fullName;
              }
              respPartyId = "multiple";
            }
          }
          respPartyPaymentOptionsLink += `/responsible-parties/${respPartyId}`;
          respPartyTxStartDocsLink += `/responsible-parties/${respPartyId}`;
          const sideNavPaymentOptions = {
            key: "Payment Options",
            text: "Payment Options",
            regex: /\/payment-options/,
            group: `/payment-options/responsible-parties/${respPartyId}`,
            items: []
          } as NavMenu;
          const sideNavItemPaymentOptions = {
            icon: "$fileInvoiceDollar",
            key: "Payment Options",
            text: "Payment Options",
            regex: new RegExp(`\/payment-options\/responsible-parties\/${respPartyId}\/treatment-types\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})` + "$"),
            link: respPartyPaymentOptionsLink,
            count: treatmentTypes?.length || 0,
            group: `/payment-options/responsible-parties/${respPartyId}`,
            items: []
          } as NavMenuItem;
          const sideNavItemTxPlanComparison = {
            icon: "$fileSpreadsheet",
            key: "Comparison",
            text: "Comparison",
            regex: new RegExp(`\/payment-options\/responsible-parties\/${respPartyId}\/treatment-types\/comparison/`),
            link: `${respPartyPaymentOptionsLink}/treatment-types/comparison`,
            group: `/responsible-parties/${respPartyId}`
          } as NavMenuItem;

          const sideNavTxStartDocs = {
            key: "Treatment Start Docs",
            text: "Treatment Start Docs",
            regex: /\/treatment-start-docs/,
            group: "/treatment-start-docs",
            items: []
          } as NavMenu;

          const sideNavItemTxStartDocs = {
            icon: "$filePdf",
            key: "Treatment Start Docs",
            text: "Treatment Start Docs",
            link: respPartyTxStartDocsLink,
            count: treatmentTypes?.length || 0,
            group: isSplit ? `/treatment-start-docs/responsible-parties/${respPartyId}` : `/treatment-start-docs`,
            regex: /\/treatment-start-docs/,
            items: []
          } as NavMenuItem;
          let regexSideNavItemTxStartDocsContractIds = "";
          treatmentTypes?.forEach((txType: TreatmentType, index) => {
            const subTitlePaymentOptionsDisplayText = signedContract && signedContract?.treatmentTypeId === txType?.treatmentTypeId
              ? signedContract?.description
              : respPartyAdjustedTreatmentTypeId === txType?.treatmentTypeId
                ? respPartyAdjustedDate
                  ? getTreatmentPlanStatusText(respPartyTxPlanStatus, true) + " on " + moment(respPartyAdjustedDate).format("MM/DD/YYYY hh:mm A")
                  : ""
                : "";
            const subTitleTxStartDocsDisplayText = signedContract && signedContract?.treatmentTypeId === txType?.treatmentTypeId
              ? signedContract?.description
              : respPartyAdjustedTreatmentTypeId === txType?.treatmentTypeId
                ? respPartyAdjustedDate
                  ? getTreatmentPlanStatusText(respPartyTxPlanStatus, true) + " on " + moment(respPartyAdjustedDate).format("MM/DD/YYYY")
                  : ""
                : "";
            if (index == 0 && treatmentTypes?.length > 1) {
              sideNavItemPaymentOptions?.items?.push(sideNavItemTxPlanComparison);
            }
            if (typeof fixedTreatmentTypeId !== "string" || txType?.treatmentTypeId === fixedTreatmentTypeId) {
              const txPlanDisplay = `${txType?.name}`;
              const sideNavItemPaymentOptionsTxPlan = {
                key: txPlanDisplay,
                text: txPlanDisplay,
                subTitle: subTitlePaymentOptionsDisplayText,
                link: `${respPartyPaymentOptionsLink}/treatment-types/${txType?.treatmentTypeId}`,
                group: `/responsible-parties/${respPartyId}/treatment-types/${txType?.treatmentTypeId}`
              } as NavMenuItem;
              sideNavItemPaymentOptions?.items?.push(sideNavItemPaymentOptionsTxPlan);

              const sideNavItemTxStartDocsTxTypes = {
                key: txPlanDisplay,
                text: txPlanDisplay,
                link: `${respPartyTxStartDocsLink}/treatment-types/${txType?.treatmentTypeId}`,
                count: paymentPlans?.length || 0,
                regex: /\/treatment-start-docs/,
                group: `/responsible-parties/${respPartyId}/treatment-types/`,
                items: []
              } as NavMenuItem;

              let regexTxStartDocsTxTypes = "";
              paymentPlans?.forEach((paymentPlan: PaymentPlan) => {
                const paymentPlanDisplay = paymentPlan?.nameOverride && paymentPlan?.nameOverride?.length > 0 ? paymentPlan?.nameOverride : paymentPlan?.paymentPlanType?.description;
                const contractIndex = patientContracts?.findIndex((c) => c.treatmentTypeId === txType.treatmentTypeId && c.paymentPlanId === paymentPlan.paymentPlanId && (!c.patientSplitTreatmentPlanPortion || respPartyId === "multiple" || c.patientSplitTreatmentPlanPortion?.patientResponsiblePartyId === respPartyId));
                if (!signedContract || signedContract?.patientContractId === patientContracts[contractIndex]?.patientContractId) {
                  const sideNavItemTxStartDocsPaymentPlans = {
                    key: paymentPlanDisplay,
                    text: paymentPlanDisplay,
                    subTitle: signedContract || (respPartyAdjustedPaymentPlanId && respPartyAdjustedPaymentPlanId === paymentPlan?.paymentPlanId) ? subTitleTxStartDocsDisplayText : "",
                    link: contractIndex > -1 ? `${baseLink}/treatment-start-docs/${patientContracts[contractIndex]?.patientContractId}` : `${respPartyTxStartDocsLink}/treatment-types/${txType?.treatmentTypeId}/payment-plans/${paymentPlan.paymentPlanId}`,
                    regex: new RegExp(`\/treatment-start-docs\/${patientContracts[contractIndex]?.patientContractId}`),
                    group: `/responsible-parties/${respPartyId}/treatment-types/${txType?.treatmentTypeId}/payment-plans/${paymentPlan.paymentPlanId}`
                  } as NavMenuItem;
                  sideNavItemTxStartDocsTxTypes?.items?.push(sideNavItemTxStartDocsPaymentPlans);
                }
                regexTxStartDocsTxTypes += regexTxStartDocsTxTypes.length === 0 ? patientContracts[contractIndex]?.patientContractId : "|" + patientContracts[contractIndex]?.patientContractId;
              });
              if (regexTxStartDocsTxTypes?.length > 0) {
                sideNavItemTxStartDocsTxTypes.regex = new RegExp(`\/treatment-start-docs\/(${regexTxStartDocsTxTypes})` + "$");
                sideNavItemTxStartDocs.regex = new RegExp(`\/treatment-start-docs\/(${regexTxStartDocsTxTypes})` + "$");
                regexSideNavItemTxStartDocsContractIds += regexSideNavItemTxStartDocsContractIds.length === 0 ? `${regexTxStartDocsTxTypes}` : `|${regexTxStartDocsTxTypes}`;
              }

              if (sideNavItemTxStartDocsTxTypes?.items?.length) {
                sideNavItemTxStartDocs?.items?.push(sideNavItemTxStartDocsTxTypes);
                sideNavItemTxStartDocs.regex = new RegExp(`\/treatment-start-docs\/(${regexSideNavItemTxStartDocsContractIds})` + "$");
              }
            }
          });

          respPartyIndex += 1;
          sideNavPaymentOptions?.items?.push(sideNavItemPaymentOptions);
          sideNavTxStartDocs?.items?.push(sideNavItemTxStartDocs);
          contractsMainSubMenu.push(
            {
              classProps: "",
              title: respPartyDisplay,
              menu: [cloneDeep(sideNavPaymentOptions), cloneDeep(sideNavTxStartDocs)],
              defaultExpanded: true,
              defaultSubMenuExpanded: false,
              key: `contracts-sub-menu-${respPartyId}-${respPartyDisplay}`
            });
        }
        Vue.set(state, "contractsSideNav", contractsMainSubMenu);
      },
      reset: function(state: PatientContractsState) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
          try {
            // @ts-ignore
            state[key] = newState[key];
          } catch (ex) {
            console.error("PatientContractsState Reset Error: ", ex.message);
          }
        });
      }

    }
    ,
    getters: {
      loading: (state) => state.loading,
      reload: (state) => state.reload,
      refresh: (state) => state.refresh,
      saving: (state) => state.saving,
      restoring: (state) => state.restoring,
      refreshing: (state) => state.refreshing,
      deleting: (state) => state.deleting,
      getting: (state) => state.getting,
      signing: (state) => state.signing,
      gettingDetail: (state) => state.gettingDetail,
      gettingLite: (state) => state.gettingLite,
      gettingSignedDocument: (state) => state.gettingSignedDocument,
      exporting: (state) => state.exporting,
      sending: (state) => state.sending,
      sendingPdfOnly: (state) => state.sendingPdfOnly,
      adjusting: (state) => state.adjusting,
      removingElectronicSignatures: (state) => state.removingElectronicSignatures,
      removingWetSignatures: (state) => state.removingWetSignatures,
      splittingFinancialResponsibility: (state) => state.splittingFinancialResponsibility,
      mergingFinancialResponsibility: (state) => state.mergingFinancialResponsibility,
      patientContracts: (state) => state.patientContracts,
      contractsSideNav: (state) => state.contractsSideNav,
      firstSignedContract: (state) => state.firstSignedContract,
      secondSignedContract: (state) => state.secondSignedContract,
      areContractsSplit: (state) => state.areContractsSplit,
      arePaymentOptionsFixedLegacy: (state) => state.arePaymentOptionsFixedLegacy,
      includeSliders: (state) => state.includeSliders,
      navContractId: (state) => state.navContractId
    } //end getters
  }
;
