import {PDFService as IPDFService} from "./interfaces";
import {AxiosInstance} from "axios";
import {EsignPDFUpload} from "../store/interfaces";

export class PDFService implements IPDFService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async uploadPDF(file: File): Promise<EsignPDFUpload> {
    const form = new FormData();
    form.append('file', file);
    const response = await this.http.post(
      `${this.apiUrl}/api/pdfFileUploads
      `, form, {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  }
}