import {useStore} from "@shared/providers/use-store";
import {AdditionalCharge, TreatmentClass, TreatmentType} from "@shared/store";
import {AdditionalChargesGetters} from "@shared/store/treatment/additional-charges";
import {TreatmentClassesGetters} from "@shared/store/treatment/treatment-classes";
import {TreatmentTypesGetters} from "@shared/store/treatment/treatment-types";
import {computed} from "vue";
import {Select} from '@shared/store/interfaces';

export function useSelects() {
  const store = useStore();

  const additionalChargesLoading = computed<boolean>(
    () => store.getters[AdditionalChargesGetters.LOADING]
  );
  const treatmentTypesLoading = computed<boolean>(
    () => store.getters[TreatmentTypesGetters.LOADING]
  );
  const treatmentClassesLoading = computed<boolean>(
    () => store.getters[TreatmentClassesGetters.LOADING]
  );
  const additionalCharges = computed<AdditionalCharge[]>(
    () => store.getters[AdditionalChargesGetters.ADDITIONAL_CHARGES]
  );
  const treatmentTypes = computed<TreatmentType[]>(
    () => store.getters[TreatmentTypesGetters.TREATMENT_TYPES]
  );
  const treatmentClasses = computed<TreatmentClass[]>(
    () => store.getters[TreatmentClassesGetters.TREATMENT_CLASSES]
  );
  const treatmentClassesSelect = computed<Select[]>(
    () => store.getters[TreatmentClassesGetters.TREATMENT_CLASSES_SELECT]
  );
  const treatmentTypesSelect = computed<Select[]>(
    () => store.getters[TreatmentTypesGetters.TREATMENT_TYPES_SELECT]
  );
  const additionalChargesSelect = computed<Select[]>(
    () => store.getters[AdditionalChargesGetters.ADDITIONAL_CHARGES_SELECT]
  );

  const treatmentClassesWithLinked = computed<TreatmentClass[]>(()=> store.getters[TreatmentClassesGetters.TREATMENT_CLASSES_WITH_LINKED])
  const treatmentClassesWithLinkedSelect = computed<Select[]>(()=> store.getters[TreatmentClassesGetters.TREATMENT_CLASSES_WITH_LINKED_SELECT])

  return {
    additionalChargesLoading,
    treatmentTypesLoading,
    treatmentClassesLoading,
    additionalCharges,
    treatmentTypes,
    treatmentClasses,
    additionalChargesSelect,
    treatmentClassesSelect,
    treatmentTypesSelect,
    treatmentClassesWithLinked,
    treatmentClassesWithLinkedSelect
  };
}
