<template>
  <v-snackbar
    v-model="alert"
    :color="snackbar.status"
    :timeout="snackbar.timeout"
    :top="snackbar.position === 'top'"
    :right="snackbar.position === 'right'"
    :centered="snackbar.position === 'centered'"
    rounded
    :elevation="4"
    :style="isAuthLayout && $vuetify.breakpoint.mdAndUp ? 'margin-left: 216px' : '' "
  >
    <v-row no-gutters align="center" justify="start">
      <v-col cols="2" class="animate__animated animate__pulse">
        <v-icon :large="!$vuetify.breakpoint.smAndDown" dense dark>{{
          snackbar.icon
        }}</v-icon>
      </v-col>

      <v-col cols="10">
        <span>{{ snackbar.message }}</span>
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        dense
        v-bind="attrs"
        @click.stop.prevent="alert = false"
      >
        <v-icon :small="$vuetify.breakpoint.smAndDown">$close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import {Alert, AlertStatus} from "@shared/store";
import {NotificationActions, NotificationGetters} from "@shared/store/notification";
import {useStore} from "@shared/providers";
import {useRouteComponents} from "@main/composables/helpers";
import {SessionGetters} from "@shared/store/session";
import {APP_TYPES, AppNames} from "@shared/store/constants";

export default defineComponent({
  name: "Snackbar",
  setup() {
    const store = useStore();
    const {routeMeta} = useRouteComponents();
    const snackbar = ref<Alert>({
      message: "",
      status: AlertStatus.INFO,
      timeout: 5000,
      position: undefined,
    });

    const alert = ref<boolean>(false);
    const latestAlert = computed<Alert | null>(
      () => store.getters[NotificationGetters.LATEST_ALERT]
    );

    const authenticated = computed<boolean>(
        () => store.getters[SessionGetters.AUTHENTICATED]
    );

    const isAuthLayout = computed<boolean>(
        () => (routeMeta?.value?.layout === AppNames.AUTH || !authenticated.value) && process.env.VUE_APP_TYPE === APP_TYPES.MAIN
    );

    watch(latestAlert, (current) => {
      if (current) {
        alert.value = true;
        switch (current.status) {
          case AlertStatus.SUCCESS:
            (snackbar.value as Alert).title = "Success";
            (snackbar.value as Alert).status = current.status;
            (snackbar.value as Alert).message = current.message ? current.message : "Success!";
            (snackbar.value as Alert).icon = "$check-circle";
            (snackbar.value as Alert).position = current?.position ? current.position : undefined;
            (snackbar.value as Alert).timeout = 4000;
            break;
          case AlertStatus.INFO:
            (snackbar.value as Alert).title = "Information";
            (snackbar.value as Alert).status = current.status;
            (snackbar.value as Alert).message = current.message;
            (snackbar.value as Alert).icon = "$info-circle";
            (snackbar.value as Alert).position = current?.position ? current.position : undefined;
            (snackbar.value as Alert).timeout = 6000;
            break;
          case AlertStatus.WARNING:
            (snackbar.value as Alert).title = "Warning";
            (snackbar.value as Alert).status = current.status;
            (snackbar.value as Alert).message = current.message;
            (snackbar.value as Alert).icon = "$exclamation-circle";
            (snackbar.value as Alert).position = current?.position ? current.position : undefined;
            (snackbar.value as Alert).timeout = 7000;
            break;
          case AlertStatus.ERROR:
            (snackbar.value as Alert).title = "Error";
            (snackbar.value as Alert).status = current.status;
            (snackbar.value as Alert).message = current.message ? current.message : "Unknown error has occurred";
            (snackbar.value as Alert).icon = "$times-circle";
            (snackbar.value as Alert).position = current?.position ? current.position : undefined;
            (snackbar.value as Alert).timeout = 8000;
            break;
          default:
            (snackbar.value as Alert).status = current.status;
            (snackbar.value as Alert).message = current.message;
            (snackbar.value as Alert).icon = "$question-circle";
            (snackbar.value as Alert).position = current?.position ? current.position : undefined;
            (snackbar.value as Alert).timeout = 8000;
        }
      }
    });

    watch(alert, async (current, previous) => {
      if (previous && !current) {
        alert.value = false;
        await store.dispatch(
          NotificationActions.CLEAR_ALERT,
          latestAlert.value
        );
      }
    });

    return {
      snackbar,
      alert,
      isAuthLayout
    };
  },
});
</script>

<style lang="scss" scoped>
.snackbar-title {
  font-weight: 500;
  font-size: 1rem;
}
</style>
