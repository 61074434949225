/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {InsuranceRemoteVerificationOptions} from "../interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {InsuranceVerificationService} from "../../../services/interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface InsuranceVerificationState {
    loading: boolean;
    saving: boolean;
    insuranceVerificationOptions?: InsuranceRemoteVerificationOptions;
}

export enum InsuranceVerificationActions {
    LOAD_INSURANCE_VERIFICATION_OPTIONS = "InsuranceVerificationModule/loadInsuranceVerificationOptions",
    UPDATE_INSURANCE_VERIFICATION_OPTIONS = "InsuranceVerificationModule/updateInsuranceVerificationOptions",
    RESET = "InsuranceVerificationModule/reset",
}

export enum InsuranceVerificationMutations {
    SET_LOADING = "InsuranceVerificationModule/setLoading",
    SET_INSURANCE_VERIFICATION_OPTIONS = "InsuranceVerificationModule/setInsuranceVerificationOptions",
    RESET = "InsuranceVerificationModule/reset",
}

export enum InsuranceVerificationGetters {
    LOADING = "InsuranceVerificationModule/loading",
    SAVING = "InsuranceVerificationModule/saving",
    INSURANCE_VERIFICATION_OPTIONS = "InsuranceVerificationModule/insuranceVerificationOptions",
}

const initialState = () => ({
    loading: true,
    saving: false,
    insuranceVerificationOptions: undefined,
});

export const InsuranceVerificationModule: Module<
    InsuranceVerificationState,
    RootState
    > = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadInsuranceVerificationOptions(
            { commit, dispatch },
            payload: {
                service: InsuranceVerificationService;
            }
        ) {
            try {
                commit("setLoading", true);
                const { service } = payload;
                const verificationOptions: InsuranceRemoteVerificationOptions = await service.getInsuranceRemoteVerificationOptions();
                if(verificationOptions.additionalNotificationRecipients && typeof  verificationOptions.additionalNotificationRecipients === 'string' && verificationOptions.additionalNotificationRecipients.length) {
                    verificationOptions.additionalNotificationRecipients =  verificationOptions.additionalNotificationRecipients.split(";");
                } else {
                    verificationOptions.additionalNotificationRecipients = [];
                }

                commit("setInsuranceVerificationOptions", verificationOptions);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async updateInsuranceVerificationOptions(
            { commit, dispatch },
            payload: {
                values: InsuranceRemoteVerificationOptions;
                service: InsuranceVerificationService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, values} = payload;
                const formattedValues = { ...values };

                formattedValues.additionalNotificationRecipients = formattedValues && formattedValues?.additionalNotificationRecipients?.length && Array.isArray(formattedValues.additionalNotificationRecipients)
                    ? formattedValues.additionalNotificationRecipients.join(';')
                        : "";

                const response = await service.updateInsuranceRemoteVerificationOptions(formattedValues);

                if (response === 204) {
                    commit("setInsuranceVerificationOptions", values);
                    success = true;
                }
                await SuccessNotification(dispatch, `Updated insurance verification options!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
            return success;
        },
        reset({ commit }) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: InsuranceVerificationState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setInsuranceVerificationOptions(
            state: InsuranceVerificationState,
            insuranceVerificationOptions?: InsuranceRemoteVerificationOptions
        ) {
            Vue.set(state, "insuranceVerificationOptions", insuranceVerificationOptions);
        },
        reset: function (state: InsuranceVerificationState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('InsuranceVerificationState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        insuranceVerificationOptions: (state) => state.insuranceVerificationOptions,
    },
};