import {LogoService as ILogoService} from "../interfaces";
import {Logo, LogoWithOfficeInfo} from "../../store/practice/interfaces";
import {AxiosInstance} from "axios";

export class LogoService implements ILogoService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getLogos(): Promise<Logo[]> {
    const response = await this.http.get(`${this.apiUrl}/api/Logos`, );
    return response.data;
  }

  async getLogo(id: string): Promise<Logo> {
    const response = await this.http.get(`${this.apiUrl}/api/Logos`,
        {
          params: {id},
        }
    );
    return response.data;
  }


  async getLogoByTreatmentPlanId(id: string): Promise<LogoWithOfficeInfo> {
    const response = await this.http.get(`${this.apiUrl}/api/Logos/ForTreatmentPlan`,
        {
          params: {patientTreatmentPlanId: id},
        }
    );
    return response.data;
  }

  async updateLogo(id: string, values: Logo): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/Logos`,
      { ...values },
      { params: { id } }
    );
    return response.status;
  }

  async deleteLogo(id: string): Promise<Logo> {
    const response = await this.http.delete(`${this.apiUrl}/api/Logos`, {
      params: { id },
    });
    return response.data;
  }

  async createLogo(values: Logo): Promise<Logo> {
    const response = await this.http.post(`${this.apiUrl}/api/logos`, {
      ...values,
    });
    return response.data;
  }
}
