import {PatientService as IPatientService} from "../interfaces";
import {AxiosInstance} from "axios";
import {Patient, PatientDataSearchParams} from "../../store/interfaces";
import {ListPatientInfo} from "../../store/patients/interfaces";

export class PatientService implements IPatientService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPatients(values: PatientDataSearchParams): Promise<ListPatientInfo> {
    const response = await this.http.post(
      `${this.apiUrl}/api/patients/list/`,
      values
    );
    return response.data;
  }

  async createOrEditPatient(
    values: Patient,
    skipDuplicateCheck = false
  ): Promise<Patient> {
    const response = await this.http.post(
      `${this.apiUrl}/api/Patients`,
      { ...values },
      {
        params: { skipDuplicateCheck },
      }
    );
    return response.data;
  }

  async getPatientDetail(id: string): Promise<Patient> {
    const response = await this.http.get(
        `${this.apiUrl}/api/Patients/${id}/Detail`
    );
    return response.data;
  }

  async deletePatient(id: string): Promise<Patient> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/Patients/${id}`
    );
    return response.data;
  }

  async archivePatients(ids: string[]): Promise<number> {
    const response = await this.http.patch(
      `${this.apiUrl}/api/Patients/Bulk-Archive`,
      [...ids]
    );
    return response.status;
  }

  async deletePatients(ids: string[]): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/Patients/Bulk-Delete`,
      [...ids]
    );
    return response.status;
  }

  async restorePatients(ids: string[]): Promise<number> {
    const response = await this.http.patch(
      `${this.apiUrl}/api/Patients/Bulk-Restore`,
      [...ids]
    );
    return response.status;
  }
}
