import {ClientSubscriptions} from "@shared/store/constants";

export default [
  {
    icon: "$fileMedicalAlt",
    key: "Treatment",
    text: "Treatment",
    regex: /^\/configuration\/treatment/,
    group: "/configuration/treatment",
    items: [
      {
        key: "Types",
        text: "Types",
        link: "/configuration/treatment/types",
        group: "/configuration/treatment",
      },
      {
        key: "Classes",
        text: "Classes",
        link: "/configuration/treatment/classes",
        group: "/configuration/treatment",
      },
      {
        key: "Complexities",
        text: "Complexities",
        link: "/configuration/treatment/complexities",
        group: "/configuration/treatment",
      },
    ],
  },
  {
    icon: "$fileInvoiceDollar",
    key: "Financials",
    text: "Financials",
    regex: /^\/configuration\/financials/,
    group: "/configuration/financials/",
    items: [
      {
        key: "Treatment Fees",
        text: "Treatment Fees",
        link: "/configuration/financials/fees",
        group: "/configuration/financials/",
      },
      {
        key: "Additional Charges",
        text: "Additional Charges",
        link: "/configuration/financials/additional-charges",
        group: "/configuration/financials/",
      },
      {
        key: "Discount",
        text: "Discount",
        link: "/configuration/financials/discount",
        regex: /^\/configuration\/financials\/discount/,
        group: "/configuration/financials/discount/",
        items: [
          {
            key: "General",
            text: "General",
            link: "/configuration/financials/discount/general",
            group: "/configuration/financials/discount",
          },
          {
            key: "Types",
            text: "Types",
            link: "/configuration/financials/discount/types",
            group: "/configuration/financials/discount",
          },
        ],
      },
      {
        key: "Insurance",
        text: "Insurance",
        link: "/configuration/financials/insurance",
        regex: /^\/configuration\/financials\/insurance/,
        group: "/configuration/financials/insurance",
        items: [
          {
            key: "General",
            text: "General",
            link: "/configuration/financials/insurance/general",
            group: "/configuration/financials/insurance",
          },
          {
            key: "PPO Options",
            text: "PPO Options",
            link: "/configuration/financials/insurance/ppo-options",
            group: "/configuration/financials/insurance",
          },
          {
            key: "Verification",
            text: "Verification",
            link: "/configuration/financials/insurance/verification",
            group: "/configuration/financials/insurance",
            subscription: ClientSubscriptions.INSURANCE_VERIFICATION,
          },
        ],
      },
    ],
  },
  {
    icon: "$calculatorAlt",
    key: "Calculation Rules",
    text: "Calculation Rules",
    regex: /^\/configuration\/calculation/,
    group: "/configuration/calculation-rules/",
    items: [
      {
        key: "General",
        text: "General",
        link: "/configuration/calculation-rules/general",
        group: "/configuration/calculation-rules/",
      },
      {
        key: "Sliders Advanced Options",
        text: "Sliders Advanced Options",
        link: "/configuration/calculation-rules/sliders-advanced-options",
        group: "/configuration/calculation-rules/",
      },
      {
        key: "Responsible Party Tiers",
        text: "Responsible Party Tiers",
        link: "/configuration/calculation-rules/tiers",
        group: "/configuration/calculation-rules/",
      },
      {
        key: "Down Payments",
        text: "Down Payments",
        link: "/configuration/calculation-rules/down-payments",
        group: "/configuration/calculation-rules/",
      },
      {
        key: "Balance Completions",
        text: "Balance Completions",
        link: "/configuration/calculation-rules/balance-completions",
        group: "/configuration/calculation-rules/",
      },
      {
        key: "Payment Plans",
        text: "Payment Plans",
        link: "/configuration/calculation-rules/payment-plans",
        group: "/configuration/calculation-rules/",
      },
   
    ],
  },
  {
    icon: "$clinicMedical",
    key: "Practice",
    text: "Practice",
    regex: /^\/configuration\/practice/,
    group: "/configuration/practice/",
    items: [
      {
        key: "Offices",
        text: "Offices",
        link: "/configuration/practice/offices",
        group: "/configuration/practice/",
      },
      {
        key: "Logos",
        text: "Logos",
        link: "/configuration/practice/logos",
        group: "/configuration/practice/",
      },
      {
        key: "Doctors",
        text: "Doctors",
        link: "/configuration/practice/doctors",
        group: "/configuration/practice/",
      },
      {
        key: "Treatment Coordinators",
        text: "Treatment Coordinators",
        link: "/configuration/practice/treatment-coordinators",
        group: "/configuration/practice/",
      },
      {
        key: "Notifications",
        text: "Notifications",
        link: "/configuration/practice/notifications",
        group: "/configuration/practice/",
      },
      {
        key: "Patient Types",
        text: "Patient Types",
        link: "/configuration/practice/patient-types",
        group: "/configuration/practice/",
      },
    ],
  },
  {
    icon: "$alignLeft",
    key: "Input Fields",
    text: "Input Fields",
    link: "/configuration/input-field-options",
  },
  {
    icon: "$fileContract",
    key: "Documents",
    text: "Documents",
    regex: /^\/configuration\/documents/,
    group: "/configuration/documents/",
    items: [
      {
        key: "General",
        text: "General",
        link: "/configuration/documents/general",
        group: "/configuration/documents/",
      },
      {
        key: "Intake Forms",
        text: "Intake Forms",
        link: "/configuration/documents/intake-forms",
        group: "/configuration/documents/",
      },
      {
        key: "Payment Options",
        text: "Payment Options",
        link: "/configuration/documents/payment-options",
        group: "/configuration/documents/",
      },
      {
        key: "Contracts",
        text: "Contracts",
        link: "/configuration/documents/contracts",
        group: "/configuration/documents/",
      },
      {
        key: "Informed Consent",
        text: "Informed Consent",
        link: "/configuration/documents/informed-consent",
        group: "/configuration/documents/",
      },
      {
        key: "Auto Payments",
        text: "Auto Payments",
        link: "/configuration/documents/auto-payments",
        group: "/configuration/documents/",
      },
      {
        key: "Custom Forms",
        text: "Custom Forms",
        link: "/configuration/documents/custom-forms",
        group: "/configuration/documents/",
      },
      {
        key: "Anytime Forms",
        text: "Anytime Forms",
        link: "/configuration/documents/anytime-forms",
        group: "/configuration/documents/",
      },
      {
        key: "Reminders",
        text: "Reminders",
        link: "/configuration/documents/reminders",
        group: "/configuration/documents/",
      },
    ],
  },
];
