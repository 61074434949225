/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {ClientReminderForm, RootState} from "../../index";
import Vue from "vue";
import {ClientRemindersService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from '../../constants';
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";


export interface ClientRemindersState {
  loading: boolean;
  saving: boolean;
  clientReminders?: ClientReminderForm;
}

export enum ClientRemindersActions {
  LOAD_CLIENT_REMINDERS = "ClientRemindersModule/loadClientReminders",
  UPDATE_CLIENT_REMINDERS = "ClientRemindersModule/updateClientReminders",
  RESET = "ClientRemindersModule/reset",
  }

export enum ClientRemindersMutations {
  SET_LOADING = "ClientRemindersModule/setLoading",
  SET_CLIENT_REMINDERS = "ClientRemindersModule/setClientReminders",
  RESET = "ClientRemindersModule/reset",
}

export enum ClientRemindersGetters {
  LOADING = "ClientRemindersModule/loading",
  SAVING = "ClientRemindersModule/saving",
  CLIENT_REMINDERS = "ClientRemindersModule/clientReminders",
}

const initialState = () => ({
  loading: true,
  saving: false,
  clientReminders: undefined,
});

export const ClientRemindersModule: Module<
    ClientRemindersState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadClientReminders(
        { commit, dispatch },
      payload: {
        service: ClientRemindersService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const clientReminders: ClientReminderForm = await service.getClientReminders();
        commit("setClientReminders", clientReminders);

      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async updateClientReminders(
        { commit, dispatch },
        payload: {
          clientId: string;
          clientRemindersOptions: ClientReminderForm ;
          service: ClientRemindersService;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {loading: true, loadingType: LoadingType.SAVING});
          const { service, clientId, clientRemindersOptions } = payload;
          await service.updateClientReminders(clientId, clientRemindersOptions);
          commit("setClientReminders", clientRemindersOptions);
          success = await SuccessNotification(dispatch, "Updated!");
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
        return success;
      },
    reset({ commit }) {
      commit("reset");
    },

  },
  mutations: {
    setLoading(state: ClientRemindersState, payload: {loading: boolean, loadingType: string } ) {
        if(typeof payload === "boolean" ){
          Vue.set(state, "loading", payload);
        }
        else {
          if(payload.loadingType) {
            Vue.set(state, payload.loadingType, payload.loading);
          }
          Vue.set(state, "loading", payload.loading);
        }
      },
    setClientReminders(
      state: ClientRemindersState,
      clientReminders?: ClientReminderForm
    ) {
      Vue.set(state, "clientReminders", clientReminders);
    },
    reset: function (state: ClientRemindersState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('ClientRemindersState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    clientReminders: (state) => state.clientReminders,
  },
};
