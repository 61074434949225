import {AccountService as IAccountService} from "./interfaces";
import {
  ChangePassword,
  Credentials,
  ForgotPassword,
  Password,
  PatientLoginCredentials,
  Session,
  User
} from "../store/interfaces";
import {AxiosInstance} from "axios";
import {SessionStatuses} from "../store/constants";

export class AccountService implements IAccountService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async antiForgery(): Promise<string> {
    const response = await this.http.get(`${this.apiUrl}/api/account/session/antiforgery`);
    return response.data;
  }

  async extendSession(): Promise<number> {
    const response = await this.http.post(`${this.apiUrl}/api/account/session/extend`);
    return response.status;
  }

  async session(): Promise<Session> {
    const response = await this.http.get(`${this.apiUrl}/api/account/session`);
    return response.data;
  }

  async login(body: Credentials): Promise<User | string> {
    const creds = btoa(`${body.email}:${body.password}:${body.xsrfToken}`);
    const response = await this.http.post(`${this.apiUrl}/api/account/login`, {
      credentials: creds,
    });
    if (response.status === 202) {
      return SessionStatuses.VALIDATE_EMAIL;
    }
    return response.data;
  }

  async patientLogin(body: PatientLoginCredentials): Promise<User | string> {
    const response = await this.http.post(`${this.apiUrl}/api/account/patientlogin`, body);
    return response.data;
  }

  async textPatientLogin(code: string): Promise<string> {
    const response = await this.http.get(`${this.apiUrl}/api/account/textpatientlogin?code=${code}`);
    return response.data;
  }

  async logoff(): Promise<void> {
    const response = await this.http.post(`${this.apiUrl}/api/account/logoff`);
    return response.data;
  }
  async forgotPassword(body: ForgotPassword): Promise<void> {
    const response = await this.http.post(
      `${this.apiUrl}/api/account/forgotpassword`,
      body
    );
    return response.data;
  }
  async createPassword(values: Password): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/account/createpassword`,
      { ...values }
    );
    return response.status;
  }
  async resetPassword(values: Password): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/account/resetpassword`,
      { ...values }
    );
    return response.status;
  }

  async confirmEmail(values: {
    userId: string;
    code: string;
  }): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/account/confirmemail`,
      { ...values }
    );
    return response.status;
  }

  async changePassword(values: ChangePassword): Promise<number> {
    const response = await this.http.post(
      `${this.apiUrl}/api/account/changepassword`,
      { ...values }
    );
    return response.status;
  }
}
