import {inject, provide} from "vue";
import {DeletedEsignDocumentsService} from "@shared/services/document-services/DeletedEsignDocumentsService";


export const deletedEsignDocumentsSymbol = Symbol();

export function provideDeletedEsignDocumentsService(
  service: DeletedEsignDocumentsService
) {
  return provide(deletedEsignDocumentsSymbol, service);
}

export function useDeletedEsignDocumentsService() {
  const deletedEsignDocumentsService = inject<DeletedEsignDocumentsService>(
      deletedEsignDocumentsSymbol
  );
  if (!deletedEsignDocumentsService) {
    throw Error("No Deleted Esign Documents Service to Inject!!");
  }
  return deletedEsignDocumentsService;
}
