/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {AutoPaymentForm, RootState} from "../../index";
import Vue from "vue";
import {AutoPaymentsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from '../../constants';
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";


export interface AutoPaymentsState {
  loading: boolean;
  saving: boolean;
  autoPayments?: AutoPaymentForm;
}

export enum AutoPaymentsActions {
  LOAD_AUTO_PAYMENTS = "AutoPaymentsModule/loadAutoPayments",
  UPDATE_AUTO_PAYMENTS = "AutoPaymentsModule/updateAutoPayments",
  RESET = "AutoPaymentsModule/reset",
  }

export enum AutoPaymentsMutations {
  SET_LOADING = "AutoPaymentsModule/setLoading",
  SET_AUTO_PAYMENTS = "AutoPaymentsModule/setAutoPayments",
  RESET = "AutoPaymentsModule/reset",
}

export enum AutoPaymentsGetters {
  LOADING = "AutoPaymentsModule/loading",
  SAVING = "AutoPaymentsModule/saving",
  AUTO_PAYMENTS = "AutoPaymentsModule/autoPayments",
}

const initialState = () => ({
  loading: true,
  saving: false,
  autoPayments: undefined,
});

export const AutoPaymentsModule: Module<
AutoPaymentsState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadAutoPayments(
        { commit, dispatch },
      payload: {
        service: AutoPaymentsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const autoPayments: AutoPaymentForm = await service.getAutoPayments();
        commit("setAutoPayments", autoPayments);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async updateAutoPayments(
        { commit, dispatch },
        payload: {
          clientId: string;
          autoPaymentOptions: AutoPaymentForm ;
          service: AutoPaymentsService;
        }
      ) {
        let success = false;
        try {
          commit("setLoading", {loading: true, loadingType: LoadingType.SAVING});
          const { service, clientId, autoPaymentOptions } = payload;
          await service.updateAutoPayments(clientId, autoPaymentOptions);
          commit("setAutoPayments", autoPaymentOptions);
          success = await SuccessNotification(dispatch, "Updated!");
        } catch (error) {
          await ErrorNotification(dispatch, error, error.data);
        }
        commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
        return success;
      },
    reset({ commit }) {
      commit("reset");
    },

  },
  mutations: {
    setLoading(state: AutoPaymentsState, payload: {loading: boolean, loadingType: string } ) {
        if(typeof payload === "boolean" ){
          Vue.set(state, "loading", payload);
        }
        else {
          if(payload.loadingType) {
            Vue.set(state, payload.loadingType, payload.loading);
          }
          Vue.set(state, "loading", payload.loading);
        }
      },
    setAutoPayments(
      state: AutoPaymentsState,
      autoPayments?: AutoPaymentForm
    ) {
      Vue.set(state, "autoPayments", autoPayments);
    },
    reset: function (state: AutoPaymentsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('AutoPaymentsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    autoPayments: (state) => state.autoPayments,
  },
};
