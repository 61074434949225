import {AdditionalCharge, TreatmentClass, TreatmentType} from "@shared/store";

export function useMapSelectsToValues(values: any, props: any) {
    if (values.additionalCharges) {
      const addCharges: AdditionalCharge[] = [];
      (values.additionalCharges as string[]).forEach((item: string) => {
        addCharges.push(
          props.additionalCharges.filter(
            (charge: AdditionalCharge) => item === charge.productFeeId
          )[0]
        );
      });
      values.additionalCharges = addCharges;
    }

    if (values.treatmentTypes) {
      const treatTypes: TreatmentType[] = [];
      (values.treatmentTypes as string[]).forEach((item: string) => {
        treatTypes.push(
          props.treatmentTypes.filter(
            (type: TreatmentType) => item === type.treatmentTypeId
          )[0]
        );
      });
      values.treatmentTypes = treatTypes;
    }

    if (values.treatmentClasses) {
      const treatClasses: TreatmentClass[] = [];
      (values.treatmentClasses as string[]).forEach((item: string) => {
        treatClasses.push(
          props.treatmentClasses.filter(
            (type: TreatmentClass) => item === type.treatmentClassId
          )[0]
        );
      });
      values.treatmentClasses = treatClasses;
    }
    return values;
  }