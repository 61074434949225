import Vue from "vue";
import moment from 'moment';

Vue.filter("formatDate", (value: string | number | any[] | moment.Moment | Date | moment.MomentInputObject | null | undefined) => {
    if (value) {
        if (typeof value === 'string' && value.length < 10) {
            return "";
        }

        const date = moment.utc(value, 'YYYY-MM-DD');
        if (date.isValid()) {
            return date?.format("MM/DD/YYYY");
        } else {
            return "";
        }
    }

    return "";
});
