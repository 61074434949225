import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

const axiosExternal = axios.create({
});

const requestHandler = async (request: InternalAxiosRequestConfig<any>) => {
    request.url = (request.url || "").toLowerCase();
    return request;
};

const responseSuccessHandler = async (response: AxiosResponse) => {
    return response;
};

const responseErrorHandler = async (error: AxiosError) => {
        return Promise.reject();
};
axiosExternal.defaults.timeout = process.env.VUE_APP_EXTERNAL_TIMEOUT_IN_SECS ? (parseInt(process.env.VUE_APP_EXTERNAL_TIMEOUT_IN_SECS) * 1000) : 10000;
axiosExternal.interceptors.request.use((request: InternalAxiosRequestConfig<any>) => requestHandler(request));
axiosExternal.interceptors.response.use(
    (response: AxiosResponse) => responseSuccessHandler(response),
    (error: AxiosError) => responseErrorHandler(error)
);

export default axiosExternal;
