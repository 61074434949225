import {ClientSubscriptionService as IClientSubscriptionService} from "./interfaces";
import {ClientSubscription} from "../store/financials/interfaces";
import {AxiosInstance} from "axios";

export class ClientSubscriptionService implements IClientSubscriptionService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getSubscriptions(): Promise<ClientSubscription[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/ClientSubscriptions`
        );
        return response.data;
    }

    async updateSubscription(
        id: string,
        values: ClientSubscription
    ): Promise<number> {
        const response = await this.http.put(
            `${this.apiUrl}/api/ClientSubscriptions`,
            { ...values },
            {
                params: { id },
            }
        );
        return response.status;
    }

    async deleteSubscription(
    ): Promise<number> {
        const response = await this.http.delete(
            `${this.apiUrl}/api/ClientSubscriptions`,
        );
        return response.status;
    }
}