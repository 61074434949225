import {TreatmentClassesService as ITreatmentClassesService} from "../interfaces";
import {AxiosInstance} from "axios";
import {TreatmentClass, TreatmentComplexity} from "../../store/interfaces";

export class TreatmentClassesService implements ITreatmentClassesService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getTreatmentClasses(): Promise<TreatmentClass[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentClasses
      `
    );
    return response.data;
  }

  async getTreatmentClassesConfigFees(): Promise<TreatmentClass[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentClasses/TreatmentClassesConfigFees`
    );
    return response.data;
  }

  async getTreatmentClassesWithComplexities(): Promise<TreatmentClass[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentClasses/TreatmentClassesWithTreatmentComplexities`
    );
    return response.data;
  }

  async getTreatmentClassesWithTreatmentTypes(): Promise<TreatmentClass[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentClasses/TreatmentClassesWithTreatmentTypes`
    );
    return response.data;
  }

  async getTreatmentClassesWithLinked(): Promise<TreatmentClass[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/TreatmentClasses/TreatmentClassesWithLinked`
    );
    return response.data;
  }

  async updateTreatmentClassComplexities(
    id: string,
    values: TreatmentClass
  ): Promise<TreatmentClass> {
    const response = await this.http.patch(
      `${this.apiUrl}/api/TreatmentClasses`,
      { ...values },
      {
        params: { id },
      }
    );
    response.data.treatmentComplexities = response.data.treatmentComplexities.sort(
      (a: TreatmentComplexity, b: TreatmentComplexity) => {
        return a.rank - b.rank;
      }
    );
    return response.data;
  }
  async updateTreatmentClassWithType(
    id: string,
    values: TreatmentClass
  ): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/TreatmentClasses`,
      { ...values },
      {
        params: { id },
      }
    );
    return response.status;
  }

  async deleteTreatmentClass(id: string): Promise<TreatmentClass> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/TreatmentClasses`,
      {
        params: { id },
      }
    );
    return response.data;
  }

  async createTreatmentClass(values: TreatmentClass): Promise<TreatmentClass> {
    const response = await this.http.post(
      `${this.apiUrl}/api/TreatmentClasses`,
      { ...values }
    );
    return response.data;
  }
}
