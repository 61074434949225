/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {NotificationRecipientsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {NotificationRecipients} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface NotificationRecipientsState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  notificationRecipients?: NotificationRecipients[];
}

export enum NotificationRecipientsActions {
  LOAD_NOTIFICATION_RECIPIENTS = "NotificationRecipientsModule/loadNotificationRecipients",
  DELETE_NOTIFICATION_RECIPIENT = "NotificationRecipientsModule/deleteNotificationRecipient",
  CREATE_NOTIFICATION_RECIPIENT = "NotificationRecipientsModule/createNotificationRecipient",
  RESET = "NotificationRecipientsModule/reset",
}

export enum NotificationRecipientsMutations {
  SET_LOADING = "NotificationRecipientsModule/setLoading",
  SET_NOTIFICATION_RECIPIENTS = "NotificationRecipientsModule/setNotificationRecipients",
  RESET = "NotificationRecipientsModule/reset",
}

export enum NotificationRecipientsGetters {
  LOADING = "NotificationRecipientsModule/loading",
  SAVING = "NotificationRecipientsModule/saving",
  DELETING = "NotificationRecipientsModule/deleting",
  NOTIFICATION_RECIPIENTS = "NotificationRecipientsModule/notificationRecipients",
  HAS_NOTIFICATION_RECIPIENTS = "NotificationRecipientsModule/hasNotificationRecipients",
  EMAILS = "NotificationRecipientsModule/emails",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  notificationRecipients: undefined,
});

export const NotificationRecipientsModule: Module<
  NotificationRecipientsState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadNotificationRecipients(
      { commit, dispatch },
      payload: {
        service: NotificationRecipientsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const notificationRecipients: NotificationRecipients[] = await service.getRecipients();
        commit("setNotificationRecipients", notificationRecipients);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async createNotificationRecipient(
      { commit, dispatch, getters },
      payload: {
        recipient: NotificationRecipients;
        service: NotificationRecipientsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, recipient } = payload;
        const newRecipient = await service.createRecipient(recipient);
        const recipients = cloneDeep(getters.notificationRecipients);
        recipients.push(newRecipient);
        commit("setNotificationRecipients", recipients);
        success = await SuccessNotification(dispatch, `Created notification recipient ${recipient.firstName} ${recipient.lastName}`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteNotificationRecipient(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: NotificationRecipientsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteRecipients(id);
        if (deleted.notificationRecipientId === id) {
          const recipients = cloneDeep(getters.notificationRecipients).filter(
            (recipient: NotificationRecipients) =>
              recipient.notificationRecipientId !== id
          );
          commit("setNotificationRecipients", recipients);
          success = await SuccessNotification(dispatch, `Deleted notification recipient ${deleted.firstName} ${deleted.lastName}`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.DELETING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: NotificationRecipientsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setNotificationRecipients(
      state: NotificationRecipientsState,
      notificationRecipients?: NotificationRecipients[]
    ) {
      Vue.set(state, "notificationRecipients", notificationRecipients);
    },
    reset: function (state: NotificationRecipientsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('NotificationRecipientsState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    notificationRecipients: (state) => state.notificationRecipients,
    hasNotificationRecipients: (state) =>
      !!state.notificationRecipients?.length,
    emails: (state) =>
      state.notificationRecipients?.reduce(
        (previousValue: string[], currentValue: NotificationRecipients) => {
          previousValue.push(currentValue.email.toLowerCase());
          return previousValue;
        },
        []
      ),
  },
};
