import {AppNames, ClientSubscriptions} from "@shared/store/constants";

export default [
  {
    path: "/configuration/dashboard",
    name: "configuration-dashboard",
    component: () =>
        import(
            /* webpackChunkName: "dashboard" */ "../pages/dashboard/DashboardPage.vue"
            ),
    meta: {
      layout: "Config",
      title: "Dashboard",
      type: AppNames.CONFIGURATION,
      rank: -1,
    },
  },
  {
    path: "/configuration/calculation-rules/general",
    name: "calculation-rules-general",
    component: () =>
      import(
        /*webpackChunkName: "configuration-calculation-rules-general" */ "../pages/configuration/calculations/GeneralCalculationRulesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Calculation Rules General",
      rank: 2,
    },
  },
  {
    path: "/configuration/calculation-rules/sliders-advanced-options",
    name: "calculation-rules-sliders-advanced-options",
    component: () =>
        import(
            /*webpackChunkName: "configuration-calculation-rules-sliders-advanced-options" */ "../pages/configuration/calculations/SlidersAdvancedOptionsPage.vue"
            ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Calculation Rules Sliders Advanced Options",
      rank: 2,
    },
  },
  {
    path: "/configuration/calculation-rules/tiers",
    name: "responsible-party-tiers",
    component: () =>
      import(
        /*webpackChunkName: "configuration-responsible-party-tiers" */ "../pages/configuration/calculations/ResponsiblePartyTiersPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Responsible Party Tiers",
      rank: 2,
    },
  },
  {
    path: "/configuration/calculation-rules/down-payments",
    name: "down-payments",
    component: () =>
      import(
        /*webpackChunkName: "configuration-down-payments" */ "../pages/configuration/calculations/DownPaymentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Down Payments",
      rank: 2,
    },
  },
  {
    path: "/configuration/calculation-rules/balance-completions",
    name: "balance-completions",
    component: () =>
      import(
        /*webpackChunkName: "configuration-balance-completions" */ "../pages/configuration/calculations/BalanceCompletionsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Balance Completions",
      rank: 2,
    },
  },
  {
    path: "/configuration/calculation-rules/payment-plans",
    name: "payment-plans",
    component: () =>
      import(
        /*webpackChunkName: "configuration-payment-plans" */ "../pages/configuration/calculations/PaymentPlansPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Payment Plans",
      rank: 2,
    },
  },
  {
    path: "/configuration/financials/fees",
    name: "treatment-fees",
    component: () =>
      import(
        /*webpackChunkName: "configuration-treatment-fees" */ "../pages/configuration/financials/TreatmentFeesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Treatment Fees",
      rank: 2,
    },
  },
  {
    path: "/configuration/financials/additional-charges",
    name: "additional-charges",
    component: () =>
      import(
        /*webpackChunkName: "configuration-additional-charges" */ "../pages/configuration/financials/AdditionalChargesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Additional Charges",
      rank: 2,
    },
  },
  {
    path: "/configuration/financials/insurance/general",
    alias: "/configuration/financials/insurance",
    name: "insurance-general",
    component: () =>
        import(
            /*webpackChunkName: "configuration-insurance-general" */ "../pages/configuration/financials/insurance/GeneralInsurancePage.vue"
            ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Insurance - PPO Options",
      rank: 2,
    },
  },
  {
    path: "/configuration/financials/insurance/ppo-options",
    name: "insurance-ppo-options",
    component: () =>
        import(
            /*webpackChunkName: "configuration-insurance-ppo-options" */ "../pages/configuration/financials/insurance/PpoOptionsPage.vue"
            ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Insurance - PPO Options",
      rank: 2,
    },
  },
  {
    path: "/configuration/financials/insurance/verification",
    name: "insurance-verification",
    component: () =>
      import(
        /*webpackChunkName: "configuration-insurance-verification" */ "../pages/configuration/financials/insurance/VerificationPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Insurance - Verification",
      rank: 2,
      subscription: ClientSubscriptions.INSURANCE_VERIFICATION,
    },
  },
  {
    path: "/configuration/financials/discount/general",
    alias: ["/configuration/financials/discount", "/configuration/financials/discounts"],
    name: "discount-general",
    component: () =>
        import(
            /*webpackChunkName: "configuration-discount-general" */ "@main/pages/configuration/financials/discount/DiscountGeneralPage.vue"
            ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Discount General",
      rank: 2,
    },
  },
  {
    path: "/configuration/financials/discount/types",
    alias: ["/configuration/financials/discounts/types"],
    name: "discount-types",
    component: () =>
      import(
        /*webpackChunkName: "configuration-discount-types" */ "@main/pages/configuration/financials/discount/DiscountTypesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Discount Types",
      rank: 2,
    },
  },
  {
    path: "/configuration/treatment/types",
    name: "treatment-types",
    component: () =>
      import(
        /*webpackChunkName: "configuration-treatment-types" */ "../pages/configuration/treatment/TreatmentTypesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Treatment Types",
      rank: 2,
    },
  },
  {
    path: "/configuration/treatment/classes",
    name: "treatment-classes",
    component: () =>
      import(
        /*webpackChunkName: "configuration-treatment-classes" */ "../pages/configuration/treatment/TreatmentClassesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Treatment Classes",
      rank: 2,
    },
  },
  {
    path: "/configuration/treatment/complexities",
    name: "treatment-complexities",
    component: () =>
      import(
        /* webpackChunkName: "configuration-treatment-complexities" */ "../pages/configuration/treatment/ComplexitiesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Complexities",
      rank: 2,
    },
  },
  {
    path: "/configuration/input-field-options",
    name: "input-fields",
    component: () =>
      import(
        /* webpackChunkName: "configuration-input-fields" */ "../pages/configuration/InputFieldsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Input Fields",
      rank: 1,
    },
  },
  //Practice Routes
  {
    path: "/configuration/practice",
    name: "practice",
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Practice",
      rank: 1,
    },
  },
  {
    path: "/configuration/practice/offices",
    name: "offices",
    component: () =>
      import(
        /* webpackChunkName: "configuration-offices" */ "../pages/configuration/practice/OfficesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Offices",
      rank: 2,
    },
  },
  {
    path: "/configuration/practice/logos",
    name: "logos",
    component: () =>
      import(
        /* webpackChunkName: "configuration-logos" */ "../pages/configuration/practice/LogosPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Logos",
      rank: 2,
    },
  },
  {
    path: "/configuration/practice/doctors",
    name: "doctors",
    component: () =>
      import(
        /* webpackChunkName: "configuration-doctors" */ "../pages/configuration/practice/DoctorsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Doctors",
      rank: 2,
    },
  },
  {
    path: "/configuration/practice/notifications",
    name: "notifications",
    component: () =>
      import(
        /* webpackChunkName: "configuration-notifications" */ "../pages/configuration/practice/NotificationRecipientsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Notification Recipients",
      rank: 2,
    },
  },
  {
    path: "/configuration/practice/treatment-coordinators",
    name: "treatment-coordinators",
    component: () =>
      import(
        /* webpackChunkName: "configuration-treatment-coordinators" */ "../pages/configuration/practice/TreatmentCoordinatorsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Treatment Coordinators",
      rank: 2,
    },
  },
  {
    path: "/configuration/practice/patient-types",
    name: "patient-types",
    component: () =>
      import(
        /* webpackChunkName: "configuration-patient-types" */ "../pages/configuration/practice/PatientTypesPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Patient Types",
      rank: 2,
    },
  },
  //Document Routes
  {
    path: "/configuration/documents",
    name: "documents",
    /* TODO move this to the treatment types when we add that one*/
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Documents",
      rank: 1,
    },
  },
  {
    path: "/configuration/documents/general",
    // alias: "/configuration",
    name: "documents-general",
    component: () =>
      import(
        /* webpackChunkName: "configuration-documents-general" */ "../pages/configuration/documents/TemplateDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Document General Settings",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/payment-options",
    name: "payment-options",
    component: () =>
      import(
        /* webpackChunkName: "configuration-payment-options" */ "../pages/configuration/documents/TemplateDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Payment Options",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/contracts",
    name: "contracts",
    component: () =>
      import(
        /* webpackChunkName: "configuration-contracts" */ "../pages/configuration/documents/TemplateDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Contracts",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/intake-forms",
    alias: ["/configuration/documents-intake-forms", "/configuration/documents-intake"],
    name: "intake-forms",
    component: () =>
      import(
        /* webpackChunkName: "configuration-intake" */ "../pages/configuration/documents/PatientIntakeDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Intake Forms",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/intake-forms/template/:id",
    alias: ["/configuration/documents-intake-forms/template/:id", "/configuration/documents-intake/template/:id"],
    name: "intake-forms-dialog",
    props: { showDialog: true },
    // handles redirect from e-sign genie
    component: () =>
      import(
        /* webpackChunkName: "configuration-intake" */ "../pages/configuration/documents/PatientIntakeDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Intake Forms",
      rank: -1,
    },
  },
  {
    path: "/configuration/documents/anytime-forms",
    alias: ["/configuration/documents-anytime-forms", "/configuration/documents-anytime"],
    name: "anytime-forms",
    component: () =>
      import(
        /* webpackChunkName: "configuration-anytime" */ "../pages/configuration/documents/AnytimeDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Anytime Forms",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/anytime-forms/template/:id",
    alias: ["/configuration/documents-anytime-forms/template/:id", "/configuration/documents-anytime/template/:id"],
    name: "anytime-forms-dialog",
    props: { showDialog: true },
    // handles redirect from e-sign genie
    component: () =>
      import(
        /* webpackChunkName: "configuration-anytime" */ "../pages/configuration/documents/AnytimeDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Anytime Forms",
      rank: -1,
    },
  },
  {
    path: "/configuration/documents/informed-consent",
    name: "informed-consent",
    component: () =>
      import(
        /* webpackChunkName: "configuration-informed-consent" */ "../pages/configuration/documents/InformedConsentDocumentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Informed Consent",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/custom-forms",
    alias: ["/configuration/documents-custom-forms", "/configuration/documents-custom"],
    name: "custom-forms",
    component: () =>
      import(
        /* webpackChunkName: "configuration-custom-forms" */ "../pages/configuration/documents/CustomFormsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Custom Forms",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/custom-forms/template/:id",
    alias: ["/configuration/documents-custom-forms/template/:id", "/configuration/documents-custom/template/:id"],
    name: "custom-forms-dialog",
    props: { showDialog: true },
    // handles redirect from e-sign genie
    component: () =>
      import(
        /* webpackChunkName: "configuration-custom-forms" */ "../pages/configuration/documents/CustomFormsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Custom Forms",
      rank: -1,
    },
  },
  {
    path: "/configuration/documents/auto-payments",
    name: "auto-payments",
    component: () =>
      import(
        /* webpackChunkName: "configuration-auto-payments" */ "../pages/configuration/documents/AutoPaymentsPage.vue"
      ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Auto Payments",
      rank: 2,
    },
  },
  {
    path: "/configuration/documents/reminders",
    name: "reminders",
    component: () =>
        import(
            /* webpackChunkName: "configuration-reminders" */ "../pages/configuration/documents/ClientRemindersPage.vue"
            ),
    meta: {
      layout: "Config",
      type: AppNames.CONFIGURATION,
      title: "Reminders",
      rank: 2,
    },
  },
  // TODO This breaks the   /configuration#!/* legacy calls which is why it is commented out anytime forms modal is an example
  // {
  //   path: "/configuration",
  //   name: "configuration",
  //   /* TODO move this to the treatment types when we add that one*/
  //   redirect: { name: "documents-general" },
  //   meta: {
  //     layout: "Config",
  //     type: AppNames.CONFIGURATION,
  //     title: "Configuration",
  //     rank: 1,
  //   },
  // },
];
