import {inject, provide} from "vue";
import {FileService} from "../services/interfaces";

export const fileServiceSymbol = Symbol();

export function provideFileService(
  service: FileService
) {
  return provide(fileServiceSymbol, service);
}

export function useFileService() {
  const fileService = inject<FileService>(
    fileServiceSymbol
  );
  if (!fileService) {
    throw Error("No File Service to Inject!!");
  }
  return fileService;
}
