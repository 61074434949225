/* eslint-disable no-unused-vars */
import { Module } from "vuex";
import { Role, RootState } from "../index";
import Vue from "vue";
import { AccountService, UserService } from "../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import { User } from "../interfaces";
import { LoadingType } from "../constants";
import { cloneDeep } from "lodash";
import { SessionActions, SessionGetters } from "../session";
import { ErrorNotification, SuccessNotification, WarningNotification } from "@shared/functions/NotificationFunctions";

export interface UsersState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  verifying: boolean;
  users?: User[];
  selectedUser?: User;
  recent?: User[];
  clientRoles?: Role[];
}

export enum UsersActions {
  LIST_USERS = "UsersModule/listUsers",
  UPDATE_USER = "UsersModule/updateUser",
  CREATE_USER = "UsersModule/createUser",
  DELETE_USER = "UsersModule/deleteUser",
  SEND_VERIFICATION = "UsersModule/sendVerification",
  LIST_CLIENT_ROLES = "UsersModule/listClientRoles",
  RESET = "UsersModule/reset",
}

export enum UsersMutations {
  SET_LOADING = "UsersModule/setLoading",
  SET_USERS = "UsersModule/setUsers",
  SET_SELECTED_USER = "UsersModule/setSelectedUser",
  SET_CLIENT_ROLES = "UsersModule/setClientRoles",
  RESET = "UsersModule/reset",
}

export enum UsersGetters {
  LOADING = "UsersModule/loading",
  SAVING = "UsersModule/saving",
  DELETING = "UsersModule/deleting",
  VERIFYING = "UsersModule/verifying",
  USERS = "UsersModule/users",
  SELECTED = "UsersModule/selected",
  RECENT = "UsersModule/recent",
  CLIENT_ROLES = "UsersModule/clientRoles",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  verifying: false,
  users: undefined,
  selectedUser: undefined,
  recent: undefined,
  clientRoles: undefined,
});

export const UsersModule: Module<UsersState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async listUsers(
      { commit, dispatch },
      payload: {
        service: UserService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const users: User[] = await service.getUsers();
        commit("setUsers", users);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async listClientRoles(
      { commit, dispatch },
      payload: {
        service: UserService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const roles: Role[] = await service.getClientRoles();
        commit("setClientRoles", roles);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateUser(
      { commit, dispatch, getters, rootGetters },
      payload: {
        id: string;
        values: User;
        service: UserService;
        accountService: AccountService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values, accountService } = payload;
        const response = await service.updateUser(id, values);
        let wasEmailUpdated = false;
        if (response === 200) {
          const users: User[] = cloneDeep(getters.users);
          const userIndex =  users.findIndex((user) => user.id === id);
          if(users[userIndex]?.email?.toLowerCase() !== values?.email?.toLowerCase()) {
            wasEmailUpdated = true;
          }
          users[userIndex] = values;
          const sessionUser: User | undefined = cloneDeep(rootGetters[SessionGetters.USER]);
          if(sessionUser?.id === values?.id) {
            await dispatch(
              SessionActions.LOAD_USER,
              {service: accountService},
              {root: true}
            );
          }
          commit("setUsers", users);
          commit("setSelectedUser", id);
          if(wasEmailUpdated) {
            await WarningNotification(dispatch, `Profile has been updated! Please verify your new email address.`);
            success = true;
          } else
          success = await SuccessNotification(dispatch, `Updated ${values.firstName} ${values.lastName}!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async createUser(
      { commit, dispatch, getters },
      payload: {
        values: User;
        service: UserService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newUser = await service.createUser(values);
        if (newUser.id) {
          const users = cloneDeep(getters.users);
          if (users?.length) {
            users.push(newUser);
          }
          commit("setUsers", users);
          commit("setSelectedUser", newUser.id);
          success = await SuccessNotification(dispatch, `Created ${newUser.firstName} ${newUser.lastName}!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async sendVerification(
      { commit, dispatch },
      payload: {
        id: string;
        service: UserService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.VERIFYING,
        });
        const { service, id } = payload;
        const response = await service.resendEmailConfirmation(id);
        if (response === 200) {
          success = await SuccessNotification(dispatch, `Verification sent!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.VERIFYING,
      });
      return success;
    },
    async deleteUser(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: UserService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteUser(id);
        if (deleted === 200) {
          const users = cloneDeep(getters.users).filter(
            (user: User) => user.id !== id
          );
          commit("setUsers", users);
          commit("setSelectedUser", undefined);
          success = await SuccessNotification(dispatch, `Deleted User!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },

  },
  mutations: {
    setLoading(
      state: UsersState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },

    setUsers(state: UsersState, users?: User[]) {
      Vue.set(
        state,
        "users",
        users?.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        })
      );
    },
    setClientRoles(
      state: UsersState,
      roles?: Role[]
    ) {
      Vue.set(state, "clientRoles", roles);
    },
    setSelectedUser(state: UsersState, userId: string) {
      let user;
      let unique: User[] = [];
      const lastFour = state.recent?.length ? [...state.recent] : [];
      if (userId) {
        user = state.users?.filter((user) => user.id === userId)[0];
      } else user = undefined;
      if (user && lastFour) {
        if (lastFour.length < 4) {
          lastFour.unshift(user);
          unique = [
            ...new Map(
              lastFour.map((obj) => [JSON.stringify(obj), obj])
            ).values(),
          ];
        } else {
          lastFour.unshift(user);
          unique = [
            ...new Map(
              lastFour.map((obj) => [JSON.stringify(obj), obj])
            ).values(),
          ];
          lastFour.pop();
        }
      }
      Vue.set(state, "selectedUser", user);
      Vue.set(state, "recent", [...unique]);
    },
    reset: function (state: UsersState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('UsersState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    verifying: (state) => state.verifying,
    users: (state) => state.users,
    selected: (state) => state.selectedUser,
    recent: (state) => state.recent,
    clientRoles: (state) => state.clientRoles,
  },
};