/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {TreatmentComplexitiesService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {TreatmentComplexity} from "../../interfaces";
import {ErrorNotification} from "@shared/functions/NotificationFunctions";

export interface TreatmentComplexitiesState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    treatmentComplexitiesDetail?: TreatmentComplexity[];
}

export enum TreatmentComplexitiesActions {
    LOAD_TREATMENT_COMPLEXITIES_DETAIL = "TreatmentComplexitiesModule/loadTreatmentComplexitiesDetail",
}

export enum TreatmentComplexitiesMutations {
    SET_LOADING = "TreatmentComplexitiesModule/setLoading",
    SET_TREATMENT_COMPLEXITIES_DETAIL = "TreatmentComplexitiesModule/setTreatmentComplexitiesDetail",
    RESET = "TreatmentComplexitiesModule/reset",
}

export enum TreatmentComplexitiesGetters {
    LOADING = "TreatmentComplexitiesModule/loading",
    SAVING = "TreatmentComplexitiesModule/saving",
    DELETING = "TreatmentComplexitiesModule/deleting",
    TREATMENT_COMPLEXITIES_DETAIL = "TreatmentComplexitiesModule/treatmentComplexitiesDetail",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    treatmentComplexitiesDetail: undefined,
});


export const TreatmentComplexitiesModule: Module<TreatmentComplexitiesState,
    RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadTreatmentComplexitiesDetail(
            {commit, dispatch},
            payload: {
                service: TreatmentComplexitiesService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const treatmentComplexities: TreatmentComplexity[] = await service.getTreatmentComplexitiesDetail();
                commit("setTreatmentComplexitiesDetail", treatmentComplexities);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
    },
    mutations: {
        setLoading(
            state: TreatmentComplexitiesState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setTreatmentComplexitiesDetail(
            state: TreatmentComplexitiesState,
            treatmentComplexities?: TreatmentComplexity[]
        ) {
            treatmentComplexities?.sort(
                (a: TreatmentComplexity, b: TreatmentComplexity) => {
                    return a.rank - b.rank;
                });
            Vue.set(state, "treatmentComplexitiesDetail", treatmentComplexities);
        },
        reset: function (state: TreatmentComplexitiesState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('TreatmentComplexitiesState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        treatmentComplexitiesDetail: (state) => state.treatmentComplexitiesDetail,
    },
};
