import {ClientSubscriptionService} from "../services/interfaces";
import {inject, provide} from "vue";

export const clientSubscriptionServiceSymbol = Symbol();

export function provideClientSubscriptionService(
  service: ClientSubscriptionService
) {
  return provide(clientSubscriptionServiceSymbol, service);
}

export function useClientSubscriptionService() {
  const clientSubscriptionService = inject<ClientSubscriptionService>(
    clientSubscriptionServiceSymbol
  );
  if (!clientSubscriptionService) {
    throw Error("No Client Subscription Service to Inject!!");
  }
  return clientSubscriptionService;
}
