import Vue from "vue";
import Router from "vue-router";

// Routes
import {
    APP_LOCALSTORAGE_KEY,
    APP_NAME,
    AppNames,
    BROADCAST_CHANNEL_OPTIONS,
    PORTAL_APP_SESSION_BROADCAST_CHANNEL,
    SESSION_EXPIRY_DATE,
    SessionStatuses
} from "@shared/store/constants";
import PagesRoutes from "@portal/router/pages.routes";
import {SessionActions, SessionGetters, SessionMutations} from "@shared/store/session";
import store, {PatientPortalInfo} from "@shared/store";
import {AccountService} from "@shared/services/AccountService";
import axios from "axios";
import moment from "moment";
import {BroadcastChannel} from 'broadcast-channel';

Vue.use(Router);

export const routes = [
    {
        path: "/",
        name: "utility-patient-portal-home",
        alias: ["/home"],
        component: () =>
            import(
                /* webpackChunkName: "utility-patient-portal-home" */ "@portal/pages/HomePage.vue"
                ),
        meta: {
            layout: "Auth",
            title: "Home",
            rank: 1,
        },
    },
    {
        path: "/text-login",
        name: "auth-patient-portal-text-login",
        component: () =>
            import(
                /* webpackChunkName: "auth-patient-portal-text-login" */ "@portal/pages/auth/PortalTextSigninPage.vue"
                ),
        meta: {
            layout: "Auth",
            title: "Text Login",
            rank: 2,
        },
    },
    {
        path: "/payment-options/overview",
        name: "patient-portal-payment-options-overview",
        alias: ["/treatment-plan/:treatmentPlanId/payment-options",
                "/treatment-plan/:treatmentPlanId/payment-options/overview",
                "/overview", "/payment-options"],

        component: () =>
            import(
                /* webpackChunkName: "patient-portal-payment-options-overview" */ "@portal/pages/payment-options/OverviewPage.vue"
                ),
        meta: {
            layout: "Default",
            type: "Patients",
            title: "Overview - Payment Options",
            rank: 2,
        },
    },
    {
        path: "/payment-options/adjust",
        name: "patient-portal-payment-options-adjust-sliders",
        alias: ["/treatment-plan/:treatmentPlanId/payment-options/adjust",
                "/adjust"],
        component: () =>
            import(
                /* webpackChunkName: "patient-portal-payment-options-adjust" */ "@main/pages/patients/payment-options/PaymentOptionsSlidersPage.vue"
                ),
        meta: {
            layout: "Default",
            type: "Patients",
            title: "Adjust - Payment Options",
            rank: 2,
        },
    },
    {
        path: "/signing-documents/review",
        name: "patient-portal-signing-documents-review",
        alias: ["/review", "/signing-documents"],
        component: () =>
            import(
                /* webpackChunkName: "patient-portal-signing-documents-review" */ "@portal/pages/signing-documents/ReviewPage.vue"
                ),
        meta: {
            layout: "Default",
            type: "Patients",
            title: "Review - Signing Documents",
            rank: 2,
        },
    },
    {
        path: "/signing-documents/sign",
        name: "patient-portal-signing-documents-sign",
        component: () =>
            import(
                /* webpackChunkName: "patient-portal-signing-documents-sign" */ "@portal/pages/signing-documents/EsignDocumentsCreatedPage.vue"
                ),
        meta: {
            layout: "Default",
            type: "Patients",
            title: "Sign - Signing Documents",
            rank: 2,
        },
    },
    {
        path: "/directions",
        name: "patient-portal-directions",
        component: () =>
            import(
                /* webpackChunkName: "patient-portal-directions" */ "@portal/pages/DirectionsPage.vue"
                ),
        meta: {
            layout: "Default",
            type: "Patients",
            title: "Directions",
            rank: 1,
        },
    },
    {
        path: "/complete",
        name: "utility-patient-portal-complete",
        component: () =>
            import(
                /* webpackChunkName: "utility-patient-portal-complete" */ "@portal/pages/CompletePage.vue"
                ),
        meta: {
            layout: "Auth",
            title: "Complete",
            rank: 1,
        },
    },
    {
        path: "/decline",
        name: "utility-patient-portal-decline",
        component: () =>
            import(
                /* webpackChunkName: "utility-patient-portal-decline" */ "@portal/pages/DeclinePage.vue"
                ),
        meta: {
            layout: "Auth",
            title: "Complete",
            rank: 1,
        },
    },
    {
        path: "/contact-us",
        name: "utility-patient-portal-contact-us",
        component: () =>
            import(
                /* webpackChunkName: "utility-patient-portal-contact-us" */ "@portal/pages/ContactUsPage.vue"
                ),
        meta: {
            layout: "Auth",
            title: "Contact Us",
            rank: 1,
        },
    },
    {
        path: "/error/invalid-link",
        name: "error-patient-portal-invalid-link",
        component: () =>
            import(
                /* webpackChunkName: "error-patient-portal-invalid-link" */ "@portal/pages/InvalidLinkErrorPage.vue"
                ),
        meta: {
            layout: "Error",
            title: "Invalid Link",
        },
    },
    ...PagesRoutes,
    {
        path: "*",
        name: "error",
        component: () =>
            import(/* webpackChunkName: "error" */ "@shared/pages/error/NotFoundPage.vue"),
        meta: {
            layout: "Error",
            title: "Page Not Found",
        },
    },

];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL || "/",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        return {x: 0, y: 0};
    },
    routes,
});

const routeSessionsStatuses: SessionStatuses[] = [
    SessionStatuses.LOGIN_STARTED,
    SessionStatuses.LOGIN_SUCCESSFUL,
    SessionStatuses.LOADING_USER_STARTED,
    SessionStatuses.LOADING_USER_SUCCESSFUL,
    SessionStatuses.SIGN_OUT_STARTED,
    SessionStatuses.SIGN_OUT_SUCCESSFUL];

/**
 * Before each route update
 */

router.beforeEach(async (to, from, next) => {
    //required here because app doesn't see url from the start and flashes login screen otherwise (which is ugly)
    if (to.name === 'auth-patient-portal-text-login') {
        if (!to?.query?.code) {
            return next("error-patient-portal-invalid-link");
        } else {
            const returnUrl:string = await store.dispatch(SessionActions.TEXT_PATIENT_LOGIN, {
                service: new AccountService(axios),
                code: to.query.code,
            });
            if (returnUrl) {
                const splitOnUrl = returnUrl?.startsWith(process.env.VUE_APP_PORTAL_URL + ":443") ? process.env.VUE_APP_PORTAL_URL + ":443" : process.env.VUE_APP_PORTAL_URL;
                const returnUrlPath =  returnUrl?.slice((splitOnUrl || "")?.length);
                if ((returnUrlPath || "")?.length) {
                    next({
                        path: returnUrlPath,
                    });
                } else {
                    return next("error-patient-portal-invalid-link");
                }
            } else {
                return next("error-patient-portal-invalid-link");
            }
        }
    }

    //logged in and session expired sign out
    if (store.getters[SessionGetters.AUTHENTICATED]) {
        const currentExpiry = localStorage.getItem(SESSION_EXPIRY_DATE);
        if (!currentExpiry || (moment.utc(currentExpiry).isValid() && moment.utc(currentExpiry).isBefore(moment.utc()))) {
            await store.dispatch(SessionActions.SIGN_OUT, {
                service: new AccountService(axios),
            });
            return next();
        }
        const patientPortalInfo = store.getters[SessionGetters.PATIENT_PORTAL_INFO] as PatientPortalInfo;
        if(!patientPortalInfo || !patientPortalInfo?.patientTreatmentPlanId || !patientPortalInfo?.code || !patientPortalInfo?.userId) {
            return next("error-patient-portal-invalid-link");
        }

        if(to.name === 'utility-patient-portal-contact-us' && to?.meta?.layout) {
            to.meta.layout = 'Default';
        }
    }
    //not logged in try to get params and query string stuff
    else {
        const patientPortalInfo = store.getters[SessionGetters.PATIENT_PORTAL_INFO] as PatientPortalInfo || {} as PatientPortalInfo;
        const storageItem = localStorage.getItem(APP_LOCALSTORAGE_KEY);
        const commonData = storageItem ? JSON.parse(storageItem) : {};
        const prevCode = commonData?.SessionModule?.patientPortalInfo?.code ? commonData?.SessionModule?.patientPortalInfo?.code : "";
        if(to?.params?.treatmentPlanId) {
            patientPortalInfo.patientTreatmentPlanId = to.params.treatmentPlanId as string;
        }
        if(to?.query?.treatmentPlanId) {
            patientPortalInfo.patientTreatmentPlanId = to.query.treatmentPlanId as string;
        }
        if(to?.query?.code) {
            patientPortalInfo.code = to.query.code as string;
        }
        if(to?.query?.id) {
            patientPortalInfo.userId = to.query.id as string;
        }
        if(to?.query?.isEmail?.toString()?.length) {
            patientPortalInfo.isEmail = to.query.isEmail.toString() === '1';
        }
        //TODO remove this after 11/20/23 since it was to make it backwards compatible with out sent out links
        if(to?.query?.isReadonly?.toString()?.length) {
            patientPortalInfo.isReadOnly = to.query.isReadonly.toString() === '1';
        }
        if (to?.query?.isReadOnly?.toString()?.length) {
            patientPortalInfo.isReadOnly = to.query.isReadOnly.toString() === '1';
        }
        if(prevCode?.length && prevCode != patientPortalInfo?.code){
            const bc = new BroadcastChannel(PORTAL_APP_SESSION_BROADCAST_CHANNEL, BROADCAST_CHANNEL_OPTIONS);
            await bc?.postMessage({
                sessionStatus: SessionStatuses.NO_STATUS,
                diff: false
            });
            await bc?.close();
        }
        store.commit(SessionMutations.SET_PATIENT_PORTAL_INFO, patientPortalInfo);
    }

    if (
        (!store.getters[SessionGetters.USER] || !store.getters[SessionGetters.AUTHENTICATED])
        && !routeSessionsStatuses.includes(store.getters[SessionGetters.STATUS])
    ) {
        await store.dispatch(SessionActions.LOAD_USER, {
            service: new AccountService(axios),
        });
    }

    //don't allow routes to auth if already signed in
    if (
        (store.getters[SessionGetters.AUTHENTICATED] &&
            to?.name?.startsWith("auth-"))
    ) {
        if (to.name === 'auth-signin') {
            return next({name: 'patient-portal-directions'});
        } else {
            const success = await store.dispatch(SessionActions.SIGN_OUT, {
                service: new AccountService(axios),
            });

            return success ? next() : next({name: 'patient-portal-directions'});
        }
    }

//default layout so it doesn't switch

    //todo should this only be for dashboard or where else does this apply?
    if (!to.meta?.layout) {
        if (from?.meta?.layout && from?.meta?.layout !== 'Error' && from?.meta?.layout !== 'Simple' && from?.meta?.layout !== 'Auth' && to?.meta) to.meta.layout = from.meta.layout;
    }

    document.title =
        to?.meta?.title && to?.meta?.type
            ? to?.meta?.type.endsWith('s') ? `${to.meta.title} - ${to.meta.type} - ${APP_NAME}` : `${to.meta.title} - ${to.meta.type}s - ${APP_NAME}`
            : to?.meta?.title
                ? `${to.meta.title} - Patient Portal - ${APP_NAME}`
                : `${AppNames.DASHBOARD} - Patient Portal - ${APP_NAME}`;

//used for redirects from legacy site for sure reason #! is not in the path otherwise
    if (to.fullPath.startsWith("/configuration#!")) {
        return next({
            path: to.fullPath.replace("/configuration#!", "/configuration"),
        });
    } else if (to.fullPath.startsWith("/patients#!")) {
        return next({path: to.fullPath.replace("/patients#!", "/patients")});
    } else if (to.fullPath.startsWith("/dashboard/patients")) {
        return next({path: to.fullPath.replace("/dashboard/patients", "/patients").replaceAll("#!", "")});
    } else if (to.fullPath.includes("#!")) {
        return next({path: to.fullPath.replaceAll("#!", "")});
    }

    return next();
});


export default router;
