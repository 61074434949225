import {AppNames} from "@shared/store/constants";

export default [
  {
    path: "/auth/signin",
    name: "auth-signin",
    alias: "/auth/login",
    component: () =>
      import(
        /* webpackChunkName: "auth-signin" */ "@shared/pages/auth/SigninPage.vue"
      ),
    meta: {
      layout: "Auth",
      type: AppNames.AUTH,
      title: "Sign In",
    },
  },
  {
    path: "/auth/verify-email",
    name: "auth-verify-email",
    component: () =>
      import(
        /* webpackChunkName: "auth-verify-email" */ "@shared/pages/auth/VerifyEmailPage.vue"
      ),
    meta: {
      layout: "Auth",
      type: AppNames.AUTH,
      title: "Verify Email",
    },
  },
  {
    path: "/auth/forgot-password",
    name: "auth-forgot",
    component: () =>
      import(
        /* webpackChunkName: "auth-forgot" */ "@shared/pages/auth/ForgotPage.vue"
      ),
    meta: {
      layout: "Auth",
      type: AppNames.AUTH,
      title: "Forgot Password",
    },
  },
  {
    path: "/auth/reset-password",
    alias: "/auth/set-password",
    name: "auth-reset",
    component: () =>
      import(/* webpackChunkName: "auth-reset" */ "@shared/pages/auth/Password.vue"),
    meta: {
      layout: "Auth",
      type: AppNames.AUTH,
      title: "Set Password",
    },
  },
  {
    path: "/auth/email-confirmation",
    name: "auth-email-confirmation",
    component: () =>
        import(/* webpackChunkName: "auth-email-confirmation" */ "@shared/pages/auth/VerifyingEmailPage.vue"),
    meta: {
      layout: "Auth",
      type: AppNames.AUTH,
      title: "Verifying Email",
      cache: false
    },
  },
  {
    path: "/error/not-found",
    name: "error-not-found",
    component: () =>
      import(
        /* webpackChunkName: "error-not-found" */ "@shared/pages/error/NotFoundPage.vue"
      ),
    meta: {
      layout: "Error",
      title: "Not Found",
    },
  },
  {
    path: "/error/unexpected",
    name: "error-unexpected",
    component: () =>
      import(
        /* webpackChunkName: "error-unexpected" */ "@shared/pages/error/UnexpectedPage.vue"
      ),
    meta: {
      layout: "Error",
      title: "Unexpected Error",
    },
  },
  {
    path: "/utility/maintenance",
    name: "utility-maintenance",
    component: () =>
      import(
        /* webpackChunkName: "utility-maintenance" */ "@shared/pages/utility/MaintenancePage.vue"
      ),
    meta: {
      layout: "Auth",
      title: "Maintenance",
    },
  },
  {
    path: "/utility/coming-soon",
    name: "utility-soon",
    component: () =>
      import(
        /* webpackChunkName: "utility-soon" */ "@shared/pages/utility/SoonPage.vue"
      ),
    meta: {
      layout: "Auth",
      title: "Coming Soon",
    },
  },
  {
    path: "/utility/help",
    name: "utility-help",
    component: () =>
      import(
        /* webpackChunkName: "utility-help" */ "@shared/pages/utility/HelpPage.vue"
      ),
  },
  {
    path: "/utility/subscription-inactive",
    name: "utility-subscription-inactive",
    meta: {
      layout: "Auth",
      title: "Subscription Inactive",
    },
    component: () =>
        import(
            /* webpackChunkName: "utility-subscription-inactive" */ "@shared/pages/utility/SubscriptionInactivePage.vue"
            ),
  },
];
