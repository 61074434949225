import {ChargeOverService as IChargeOverService} from "./interfaces";
import {AxiosInstance} from "axios";

export class ChargeOverService implements IChargeOverService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getDoesCustomerExist(): Promise<boolean> {
    const response = await this.http.get(`${this.apiUrl}/api/chargeOver/customer/exists`);
    return response.status === 200;
  }

  async getSigninUrl(): Promise<string> {
    const response = await this.http.get(`${this.apiUrl}/api/chargeOver/user/loginUrl`);
    return response.data;
  }
}