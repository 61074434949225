/* eslint-disable no-unused-vars */
import { Module } from "vuex";
import Vue from "vue";
import { RootState } from "@shared/store";
// DO NOT MOVE THIS OR ELSE
import { ErrorNotification } from "@shared/functions/NotificationFunctions";
import { Video } from "@shared/store/videos/interfaces";
import { IVideosService } from "@shared/services/interfaces";


export interface VideosState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    videos?: Video[];
}

export enum VideosActions {
    LOAD_VIDEOS = "VideosModule/loadVideos",
    RESET = "VideosModule/reset",
}

export enum VideosMutations {
    SET_LOADING = "VideosModule/setLoading",
    SET_VIDEOS = "VideosModule/setVideos",
    RESET = "VideosModule/reset",
}

export enum VideosGetters {
    LOADING = "VideosModule/loading",
    SAVING = "VideosModule/saving",
    DELETING = "VideosModule/deleting",
    VIDEOS = "VideosModule/videos",
}

const initialState = () => ({
    loading: false,
    saving: false,
    deleting: false,
    videos: undefined,
});

export const VideosModule: Module<VideosState, RootState> = {
        namespaced: true,
        state: initialState(),
        actions: {
            async loadVideos(
                {commit, dispatch},
                payload: {
                    service: IVideosService; //todo add service type
                }
            ) {
                try {
                    commit("setLoading", true);
                    const {service } = payload;
                    const videos: Video[] = await service.getVideos();
                    commit("setVideos", videos);
                } catch (error) {
                    await ErrorNotification(dispatch, error, error.data);
                }
                commit("setLoading", false);
            },
            reset({commit}) {
                commit("reset");
            },
        },
        mutations: {
            setLoading(
                state: VideosState,
                payload: { loading: boolean; loadingType: string }
            ) {
                if (typeof payload === "boolean") {
                    Vue.set(state, "loading", payload);
                } else {
                    if (payload.loadingType) {
                        Vue.set(state, payload.loadingType, payload.loading);
                    }
                    Vue.set(state, "loading", payload.loading);
                }
            },
            setVideos(state: VideosState, videos?: Video[]) {
                Vue.set(state, "videos", videos);
            },
            reset: function (state: VideosState) {
                const newState = initialState();
                Object.keys(newState).forEach(key => {
                    try {
                        // @ts-ignore
                        state[key] = newState[key];
                    } catch (ex) {
                        console.error('VideosState Reset Error: ', ex.message);
                    }
                });
            },
        },
        getters: {
            loading: (state) => state.loading,
            saving: (state) => state.saving,
            deleting: (state) => state.deleting,
            videos: (state) => state.videos?.sort((a, b) => a?.type - b?.type || a?.rank - b?.rank)
        },
    }
;
