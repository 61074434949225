import {IVideosService} from "./interfaces";
import {AxiosInstance} from "axios";
import { Video } from "@shared/store/videos/interfaces";

export class VideosService implements IVideosService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getVideos(): Promise<Video[]> {
    const response = await this.http.get(`${this.apiUrl}/api/Videos`);
    return response.data;
  }
}
