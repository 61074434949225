export default {
    key: "Payment Options",
    text: "Payment Options",
    regex: /^\/payment-options/,
    group: "/payment-options",
    items: [
        {
            key: "Overview",
            icon: "$fileInvoiceDollarLight",
            text: "Overview",
            link: "/payment-options/overview",
            group: "/payment-options/overview",
        },
        {
            key: "Adjust",
            icon: "$sliders",
            text: "Adjust",
            link: "/payment-options/adjust",
            group: "/payment-options/adjust",
        },
    ]
}



