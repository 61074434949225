import {computed} from "vue";
import {useRouter} from "@shared/providers";
import {Route} from "vue-router";
import {Dictionary, Location} from "vue-router/types/router";

export function useRouteComponents() {
  const router = useRouter();
  const routeParams = computed<Dictionary<string>>(() => router.app.$route?.params || {});
  const routeName = computed<string>(
    () => router.app.$route?.name || ""
  );
  const routeId = computed<string | null>( () => (router.app?.$route?.params)?.["id"] || null);
  const routePath = computed<string>(() => router.app.$route?.path);
  const routeQuery = computed< Dictionary<string | (string | null)[]>>(() => router.app.$route?.query);
  const routeMeta = computed<any>(() => router.app.$route?.meta);
  const route = computed<Route>(() => router.app.$route);

  async function clickNavigateTo(event: PointerEvent, path: string, force:boolean = false) {
    if(event?.ctrlKey) {
      const baseUrl = window?.location?.origin;
      window.open(baseUrl + path, '_blank');
      return;
    }

    if (routePath.value !== path || force) {
      await router.push(path);
    }
  }

  async function clickNavigateToRoute(event: PointerEvent, route: Location, force: boolean = false) {
    if (event?.ctrlKey) {
      const baseUrl = window?.location?.origin;
      if (route?.path) {
        window.open(baseUrl + route.path, '_blank');
        return;
      }
    }

    if (route?.path && (routePath.value !== route?.path || force)) {
      await router.push(route);
      return;
    }

    if (route?.name && (routeName.value !== route?.name || force)) {
      routeName.value?.toLowerCase() === route?.name ? await router.replace(route) : await router.push(route);
      return;
    }
  }
  async function navigateTo(path: string, force:boolean = false) {
    if (routePath.value !== path || force) {
      await router.push(path);
    }
  }
  async function navigateToName(name: string, force:boolean = false, params: Dictionary<string> = {}, query: Dictionary<string | (string | null)> = {}) {
    if (routeName.value !== name || force) {
      routeName.value?.toLowerCase() === name?.toLowerCase() ? await router.replace({ name, params, query }) : await router.push({ name, params, query });
    }
  }

  return {
    routeId,
    routeQuery,
    routeParams,
    routePath,
    routeName,
    routeMeta,
    route,
    router,
    navigateTo,
    navigateToName,
    clickNavigateTo,
    clickNavigateToRoute,
  };
}
