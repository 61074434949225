import {OfficeService} from "../services/interfaces";
import {inject, provide} from "vue";

export const officeServiceSymbol = Symbol();

export function provideOfficeService(service: OfficeService) {
  return provide(officeServiceSymbol, service);
}

export function useOfficeService() {
  const officeService = inject<OfficeService>(officeServiceSymbol);
  if (!officeService) {
    throw Error("No Office Service to Inject!!");
  }
  return officeService;
}
