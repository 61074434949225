import moment from "moment";

export function getTreatmentPlanStatusText(status?: number, includeAsterisk: boolean = false, defaultText = '') {
  let text = defaultText;
  if (typeof status === "number") {
    switch (status) {
      case 0: //declined
        text = "Declined";
        break;
      case 1: //accepted
        text = "Accepted";
        break;
      case 2: //partial Accepted
        text = "(1 of 2) Partially Accepted";
        break;
      default:        //-1 is pending or no selection
        text = defaultText;
        break;
    }
  }
  if (includeAsterisk && text) {
    text = " *" + text;
  }
  return text;
}

export function getTreatmentPlanStatusTextAndDate(status?: number, dateStatusTriggered?: string, includeAsterisk: boolean = false, defaultText = '') {
  let text = defaultText;
  text = getTreatmentPlanStatusText(status, includeAsterisk, defaultText);

  if (text && dateStatusTriggered) {
    text = text + " tx plan on " + moment(dateStatusTriggered).format("MM/DD/YYYY hh:mm A");
  }
  return text;
}

export function getTreatmentPlanStatusColor(status?: number, defaultColor: string = "") {
  let color = defaultColor;
  if (typeof status === "number") {
    switch (status) {
      case -1:
        color = defaultColor; //undecided or default
        break;
      case 0: //declined
        color = "error";
        break;
      case 1:
        // accepted on both
        color = "success";
        break;
      case 2: //split one accepted
        color = "warning lighten-3";
        break;
      default:
        color = "grey";
      // code block
    }
  }
  return color;
}


export function getStatusText(type: string | undefined, status?: number, dateStatusTriggered?: string) {
  let text = " status unknown";
  if (typeof status === "number") {
    switch (status) {
      case -1:
        text = " cancelled/deleted on ";
        text += moment(dateStatusTriggered).format("LLL");
        break;
      case 0: //sent or expiring
              // eslint-disable-next-line no-case-declarations
        const currentDateTime = moment();
        if (type !== "signing" && dateStatusTriggered && currentDateTime.diff(moment(dateStatusTriggered), "days") > 23) {
          const daysLeft = process.env.VUE_APP_LINK_EXPIRING_TIMEOUT_IN_DAYS && isNaN(parseInt(process.env.VUE_APP_LINK_EXPIRING_TIMEOUT_IN_DAYS)) ? 30 - currentDateTime.diff(moment(dateStatusTriggered), "days") : Number(process.env.VUE_APP_LINK_EXPIRING_TIMEOUT_IN_DAYS) - currentDateTime.diff(moment(dateStatusTriggered), "days");
          switch (daysLeft) {
            case 0:
              text = ` expiring TODAY sent on `;
              break;
            case 1:
              text = ` expiring in 1 day sent on `;
              break;
            default:
              text = ` expiring in ${daysLeft} days sent on `;
              break;
          }
        } else {
          text = " sent on ";
        }
        text += moment(dateStatusTriggered).format("LLL");
        break;
      case 1:
        //signing means partially signed instead of expired
        text = type === "signing" ? " partially signed on " : " expired on ";
        text += moment(dateStatusTriggered).format("LLL");
        break;
      case 2:
        text = type === "signing" ? " signed on " : type === "sliders" ? " accepted on " : " expired on ";
        text += moment(dateStatusTriggered).format("LLL");
        break;
      case 3:
        text = " signed (wet) on ";
        text += moment(dateStatusTriggered).format("LLL");
        break;
      default:
        text = "";
      // code block
    }
  }
  return text;
}

