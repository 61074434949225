import {NotificationRecipientsService as INotificationRecipientsService} from "../interfaces";
import {NotificationRecipients} from "../../store/practice/interfaces";
import {AxiosInstance} from "axios";

export class NotificationRecipientsService
  implements INotificationRecipientsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getRecipients(): Promise<NotificationRecipients[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/notificationRecipients`
    );
    return response.data;
  }

  async deleteRecipients(id: string): Promise<NotificationRecipients> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/notificationRecipients`,
      {
        params: { id },
      }
    );
    return response.data;
  }

  async createRecipient(
    recipient: NotificationRecipients
  ): Promise<NotificationRecipients> {
    const response = await this.http.post(
      `${this.apiUrl}/api/notificationRecipients`,
      { ...recipient }
    );
    return response.data;
  }
}
