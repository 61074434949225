/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {CustomForm, CustomFormPdf, RootState} from "../../index";
import Vue from "vue";
import {CustomFormService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import router from "@main/router";
import {ErrorNotification, SuccessNotification, WarningNotification} from "@shared/functions/NotificationFunctions";

export interface CustomFormState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  customForms?: CustomForm[];
  customFormPDFs?: CustomFormPdf[];
}

export enum CustomFormActions {
  LOAD_CUSTOM_FORMS = "CustomFormModule/loadCustomForms",
  UPDATE_CUSTOM_FORMS = "CustomFormModule/updateCustomForms",
  UPDATE_CUSTOM_FORMS_FIELDS = "CustomFormModule/updateFormFields",
  UPDATE_CUSTOM_FORMS_ORDER = "CustomFormModule/updateCustomFormsOrder",
  CREATE_CUSTOM_FORMS = "CustomFormModule/createCustomForm",
  DELETE_CUSTOM_FORM = "CustomFormModule/deleteCustomForm",
  LOAD_PDFS = "CustomFormModule/loadPdfs",
  RESET = "CustomFormModule/reset",
}

export enum CustomFormMutations {
  SET_LOADING = "CustomFormModule/setLoading",
  SET_CUSTOM_FORMS = "CustomFormModule/setCustomForms",
  SET_CUSTOM_FORM_PDFS  = "CustomFormModule/setCustomFormPDFs",
  RESET = "CustomFormModule/reset",
}

export enum CustomFormGetters {
  LOADING = "CustomFormModule/loading",
  SAVING = "CustomFormModule/saving",
  DELETING = "CustomFormModule/deleting",
  CUSTOM_FORMS = "CustomFormModule/customForms",
  CUSTOM_FORM_PDFS = "CustomFormModule/customFormPDFs",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  customForms: undefined,
  customFormPDFs: undefined,
});

export const CustomFormModule: Module<CustomFormState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    loadCustomForms: async function (
      { commit, dispatch, getters },
      payload: {
        service: CustomFormService;
        refresh: {
          type: Boolean;
          default: true;
        };
      }
    ) {
      let success = false;
      try {
        commit("setLoading", true);
        const { service } = payload;
        const customForms = await service.getCustomForms();

        commit("setCustomForms", customForms);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
      return success;
    },
    loadPdfs: async function (
        { commit, dispatch},
        payload: {
          service: CustomFormService;
          forms: {formId: string,
            initialFormId: string,
            contractId: string}[]
        }
    ) {
      try {
        commit("setLoading", true);
        const { service,forms } = payload;
        const pdfArray: CustomFormPdf[] = [];
        const preFetchDataPromises: Promise<any>[] = [];
        for (const form of forms) {
          preFetchDataPromises.push(service.postPdf(
              form.formId,
              form.initialFormId,
              form.contractId
          ));
        }
        const pdfs = await Promise.all(preFetchDataPromises);
        for (let i = 0; i < forms?.length; i++) {
          pdfArray.push({ formId: forms[i]?.formId,
            initialFormId: forms[i]?.initialFormId,
            contractId: forms[i]?.contractId,
            data: pdfs[i] });
        }
        commit("setCustomFormPDFs", pdfArray);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateCustomForms(
      { commit, dispatch, getters },
      payload: {
        formId: string;
        values: CustomForm;
        service: CustomFormService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values, formId } = payload;
        const updatedCustomForm = await service.updateCustomForms(
          formId,
          values
        );
        const customForms = cloneDeep(getters.customForms);
        customForms.forEach((form: CustomForm, index: number) => {
          if (form.formId === formId) {
            customForms[index] = updatedCustomForm;
          }
        });
        commit("setCustomForms", customForms);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateFormFields(
        { commit, dispatch, getters },
        payload: {
          formId: string;
          service: CustomFormService;
        }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, formId } = payload;
        const response = await service.updateFormFields(
            formId
        );
        success = response;
        if(success){
          await SuccessNotification(dispatch, "Updated!");
          //update the store
          const loadIsSuccessful: boolean = await dispatch("loadCustomForms", {
            service: service
          });
          if (!loadIsSuccessful) {
            await router.push({name: "custom-forms"});
          }
        } else {
          await WarningNotification(dispatch, "Form updates failed!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateCustomFormsOrder(
      { commit, dispatch, getters },
      payload: {
        customForms: CustomForm[];
        service: CustomFormService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, customForms } = payload;
        const response = await service.reorderCustomForms(customForms);
        commit("setCustomForms", customForms);
        if (response) {
          success = true;
        }
        await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async createCustomForm(
        { commit, dispatch },
        payload: {
          values: CustomForm;
          formId: string;
          service: CustomFormService;
        }
    ) {
      let success: boolean | string = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values, formId } = payload;
        const response = await service.createCustomForm(formId, values);
        if (response) {
          success = response;
        }
        await SuccessNotification(dispatch, "Saved!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteCustomForm(
      { commit, dispatch, getters },
      payload: {
        formId: string;
        service: CustomFormService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, formId } = payload;
        const deleted = await service.deleteForm(formId);
        if (deleted.formId === formId) {
          const customForms = cloneDeep(getters.customForms).filter(
            (form: CustomForm) => form.formId !== formId
          );
          commit("setCustomForms", customForms);
          success = await SuccessNotification(dispatch, "Deleted!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.DELETING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: CustomFormState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setCustomForms(state: CustomFormState, customForms?: CustomForm[]) {
      customForms = (customForms as CustomForm[]).sort(
        (a: CustomForm, b: CustomForm) => {
          return (a.orderId as number) - (b.orderId as number);
        }
      );
      Vue.set(state, "customForms", customForms);
    },
    setCustomFormPDFs(state: CustomFormState, pdfArray: CustomFormPdf[]) {
      Vue.set(state, "customFormPDFs", pdfArray);
    },
    reset: function (state: CustomFormState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('CustomFormState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    customForms: (state) => state.customForms,
    customFormPDFs:  (state) => state.customFormPDFs,
  },
};
