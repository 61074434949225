import {PatientTreatmentPlanDiscountsService as IPatientTreatmentPlanDiscountsService} from "../interfaces";
import {AxiosInstance} from "axios";
import {PatientDiscount} from "../../store/patients/interfaces";

export class PatientTreatmentPlanDiscountsService
  implements IPatientTreatmentPlanDiscountsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getSelectedTreatmentPlanDiscounts(id: string): Promise<PatientDiscount[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/PatientDiscounts/Detail?patientTreatmentPlanId=${id}`,
    );
    return response.data;
  }
}
