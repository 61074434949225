import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDateTime', (value: any) => {

  if (value) {
    return moment(value).format( 'MM/DD/YYYY hh:mm A')
  }

  return ''
})
