import {OfficeService as IOfficeService} from "../interfaces";
import {Office} from "../../store/practice/interfaces";
import {AxiosInstance} from "axios";

export class OfficeService implements IOfficeService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getOffices(): Promise<Office[]> {
        const response = await this.http.get(`${this.apiUrl}/api/Offices/Detail`);
        return response.data;
    }

    async updateOffice(id: string, office: Office): Promise<number> {
        const response = await this.http.put(`${this.apiUrl}/api/Offices`, office, {
            params: {
                id,
            },
        });
        return response.status;
    }

    async createOffice(office: Office): Promise<Office> {
        const response = await this.http.post(`${this.apiUrl}/api/Offices`, office);
        return response.data;
    }

    async deleteOffice(id: string, office: Office): Promise<number> {
        const response = await this.http.put(
            `${this.apiUrl}/api/Offices`,
            {...office},
            {
                params: {id},
            }
        );
        return response.status;
    }
}
