<template>
  <!--  TODO would love to remove the rounding or white around edges-->
  <v-dialog v-model="dialog"
            max-width="500"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn v-blur rounded color="warning" v-on="{ ...on, ...tooltip }"
                 icon :disabled="loading || disable" large
          >
            <v-icon>$help</v-icon>
          </v-btn>
        </template>
        <span>Do you need help?</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar
          dark
          flat
          color="primary"
          :rounded="false"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $vuetify.breakpoint.width > 500 ? 'Welcome to the Patient Portal!' : 'Welcome!' }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              dark
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="ma-0 my-2 body-1 text--primary">
        <p class="ma-1 body-2 primary--text"><i>Navigate using the&nbsp;<v-icon
            small color="primary">$menu
        </v-icon>&nbsp;in the top left corner</i></p>
        <p class="ma-1 body-2 primary--text" v-if="$vuetify.breakpoint.width <768"><i>*Please Note: Viewing the
          documents in landscape (horizontal) mode is best, which may require you to rotate your device.</i></p>
        <p class="ma-1">For guidance, check out the patient portal directions, complete with videos illustrating how to
          use each page:</p>
        <div class="text-center">
          <v-btn link rounded color="primary"
                 @click="routeName !== 'patient-portal-directions' ? navigate('patient-portal-directions') : dialog = false">
            View Directions
          </v-btn>
        </div>
        <p class="ma-1">If you are still having issues, feel free to reach out to us:</p>
        <div class="text-center">
          <v-btn link rounded color="primary"
                 @click="routeName !== 'utility-patient-portal-contact-us' ? navigate('utility-patient-portal-contact-us') : dialog = false">
            Contact Us
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import {useRouteComponents} from "@main/composables/helpers";
import {SessionStatuses} from "@shared/store/constants";
import store, {User} from "@shared/store";
import {SessionGetters} from "@shared/store/session";
import moment from "moment";
import Vue from "vue";

interface Props {
  loading: boolean;
  disable: boolean;
}

export default defineComponent({
  name: "ToolbarHelp",
  props: {
    loading: Boolean,
    disable: Boolean,
  },
  components: {},
  setup(props: Props) {
    const dialog = ref<boolean>(false);
    const {routeName, navigateToName} = useRouteComponents();
    const sessionStatus = computed<string | null>(
        () => store.getters[SessionGetters.STATUS]
    );
    const currentUser = computed<User>(
        () => store.getters[SessionGetters.USER]
    );

    async function navigate(routeName: string = 'patient-portal-directions') {
      await navigateToName(routeName);
      dialog.value = false;
    }

    watch(
        sessionStatus,
        async (currentStatus, prevStatus) => {
          if (currentStatus === prevStatus) {
            return;
          }

          if (currentStatus === SessionStatuses.LOGIN_SUCCESSFUL && (!currentUser.value?.lastLoginDate || moment.utc().subtract(7, 'days').isAfter(moment.utc(currentUser.value?.lastLoginDate)))) {
            await Vue.nextTick();
            dialog.value = true;
          }
        },
        {immediate: true}
    );

    return {
      dialog,
      routeName,
      navigateToName,
      navigate
    };
  },
});
</script>
