/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {ProductFeesService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {ProductFee} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface ProductFeesState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  productFeesLite?: ProductFee[];
}

export enum ProductFeesActions {
  LOAD_PRODUCT_FEES_LITE = "ProductFeesModule/loadProductFeesLite",
  UPDATE_UPFRONT_CHARGES = "ProductFeesModule/updateUpfrontCharges",
}

export enum ProductFeesMutations {
  SET_LOADING = "ProductFeesModule/setLoading",
  SET_PRODUCT_FEES_LITE = "ProductFeesModule/setProductFeesLite",
  RESET = "ProductFeesModule/reset",
}

export enum ProductFeesGetters {
  LOADING = "ProductFeesModule/loading",
  SAVING = "ProductFeesModule/saving",
  DELETING = "ProductFeesModule/deleting",
  PRODUCT_FEES_LITE = "ProductFeesModule/productFeesLite",
  PRODUCT_FEES_SELECT = "ProductFeesModule/productFeesSelect",
  RESET = "ProductFeesModule/reset",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  productFeesLite: undefined,
});

export const ProductFeesModule: Module<ProductFeesState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadProductFeesLite(
      { commit, dispatch },
      payload: {
        service: ProductFeesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const productFees: ProductFee[] = await service.getProductFeesLite();
        commit("setProductFeesLite", productFees);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateUpfrontCharges(
      { commit, dispatch },
      payload: {
        valuesToAdd: string[];
        valuesToRemove: string[];
        values: ProductFee[];
        service: ProductFeesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, valuesToAdd, valuesToRemove, values } = payload;
        let addResponse = 204;
        let removeResponse = 204;
        if (valuesToAdd.length) {
          addResponse = await service.enableUpfrontChargesForList(valuesToAdd);
        }
        if (valuesToRemove.length) {
          removeResponse = await service.disableUpfrontChargesForList(
            valuesToRemove
          );
        }
        if (addResponse === 204 && removeResponse === 204) {
          commit("setProductFeesLite", values);
          success = true;
        }

        await SuccessNotification(dispatch, `Updated!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: ProductFeesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setProductFeesLite(state: ProductFeesState, productFees?: ProductFee[]) {
      Vue.set(state, "productFeesLite", productFees);
    },
    reset: function (state: ProductFeesState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('ProductFeesState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    productFeesLite: (state) => state.productFeesLite,
    productFeesSelect: (state) =>
      state.productFeesLite?.map((item) => {
        return { text: item.productName, value: item.productFeeId };
      }),
  },
};
