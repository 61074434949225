/* eslint-disable no-unused-vars */
import {GetterTree, Module} from "vuex";
import {PatientPortalInfo, PatientTreatmentPlanCommunicationsSubset, RootState} from "../../index";
import Vue from "vue";
import {OfficeService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType, NEW_ITEM_ID} from "../../constants";
import {cloneDeep} from "lodash";
import {Office} from "../interfaces";
import {
    PatientTreatmentPlanCommunicationsService
} from "@shared/services/patient-services/PatientTreatmentPlanCommunicationsService";
import {SessionGetters} from "@shared/store/session";
import {ContactInfo} from "@shared/store/practice/offices/interfaces";
import {LogosActions} from "@shared/store/practice/logos";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface OfficesState {
    loading: boolean;
    saving: boolean;
    deleting: boolean;
    offices?: Office[];
    contactInfo?: ContactInfo
}

export interface IOfficesGetters extends GetterTree<OfficesState, RootState> {
    loading: (state: OfficesState) => boolean;
    saving: (state: OfficesState) => boolean;
    deleting: (state: OfficesState) => boolean;
    hasOffices: (state: OfficesState) => boolean;
    missingTaxIdInfo: (state: OfficesState) => boolean;
    officesSelect: (state: OfficesState) => { text: string, value: string | undefined, disabled: boolean };
    officesWithEmptySelect: (state: OfficesState) => { text: string, value: string | undefined, disabled: boolean };
    officesSelectIncludingObsolete: (state: OfficesState) => { text: string, value: string | undefined, disabled: boolean };
    contactInfo: (state: OfficesState) => ContactInfo;
}

export enum OfficesActions {
    LOAD_OFFICES = "OfficesModule/loadOffices",
    UPDATE_OFFICE = "OfficesModule/updateOffice",
    CREATE_OFFICE = "OfficesModule/createOffice",
    DELETE_OFFICE = "OfficesModule/deleteOffice",
    LOAD_CONTACT_INFO = "OfficesModule/loadContactInfo",
    RESET = "OfficesModule/reset",
}

export enum OfficesMutations {
    SET_LOADING = "OfficesModule/setLoading",
    SET_OFFICES = "OfficesModule/setOffices",
    SET_CONTACT_INFO = "OfficesModule/setContactInfo",
    RESET = "OfficesModule/reset",
}

export enum OfficesGetters {
    LOADING = "OfficesModule/loading",
    SAVING = "OfficesModule/saving",
    DELETING = "OfficesModule/deleting",
    OFFICES = "OfficesModule/offices",
    HAS_OFFICES = "OfficesModule/hasOffices",
    OFFICES_SELECT = "OfficesModule/officesSelect",
    OFFICES_WITH_EMPTY_SELECT = "OfficesModule/officesWithEmptySelect",
    MISSING_TAX_ID_INFO = "OfficesModule/missingTaxIdInfo",
    CONTACT_INFO = "OfficesModule/contactInfo",
}


const initialState = () => ({
    loading: false, //related to caching why it isn't true
    saving: false,
    deleting: false,
    offices: undefined,
    contactInfo: undefined,
});

export const OfficesModule: Module<OfficesState, RootState> = {
    namespaced: true,
    state: initialState(),
    actions: {
        async loadOffices(
            {commit, dispatch},
            payload: {
                service: OfficeService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const offices: Office[] = await service.getOffices();
                commit("setOffices", offices);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async loadContactInfo(
            {commit, dispatch, rootGetters},
            payload: {
                service: PatientTreatmentPlanCommunicationsService;
            }
        ) {
            try {
                commit("setLoading", true);
                const {service} = payload;
                const patientPortalInfo = rootGetters[SessionGetters.PATIENT_PORTAL_INFO] as PatientPortalInfo;
                if (patientPortalInfo.patientTreatmentPlanId && patientPortalInfo.userId) {
                    const subset: PatientTreatmentPlanCommunicationsSubset = await service.getOfficeInfo(patientPortalInfo.patientTreatmentPlanId, patientPortalInfo.userId);
                    if (!subset?.officeId) {
                        throw Error("Unable to retrieve patient communications information by treatment plan");
                    }
                    commit("setContactInfo", {
                        email: subset?.officeContactEmail,
                        name: subset?.officeName,
                        phoneNumber: subset?.officePhoneNumber,
                        officeId: subset?.officeId
                    });
                    if (subset.hasLogo) {
                       await dispatch(
                            LogosActions.LOAD_LOGO_BY_TX_PLAN_ID,
                           { logo: {
                                logoId: subset.logoId,
                                imageBase64: subset.imageBase64,
                                pageWidthPercent: subset.pageWidthPercent
                            }},
                            {root: true}
                        );
                    }
                } else {
                    throw Error("Missing patient portal info inorder to process contact info");
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", false);
        },
        async updateOffice(
            {commit, dispatch, getters},
            payload: {
                id: string;
                office: Office;
                service: OfficeService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, id, office} = payload;
                const response = await service.updateOffice(id, office);

                const offices = cloneDeep(getters.offices);
                offices.forEach((item: Office, index: number) => {
                    if (item.officeId === id) {
                        offices[index] = office;
                    }
                });
                commit("setOffices", offices);
                if (response === 204) {
                    success = true;
                }
                await SuccessNotification(dispatch, `Updated office ${office.name}!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async createOffice(
            {commit, dispatch, getters},
            payload: {
                office: Office;
                service: OfficeService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.SAVING,
                });
                const {service, office} = payload;
                const newOffice = await service.createOffice(office);
                const offices = cloneDeep(getters.offices);
                offices.push(newOffice);
                commit("setOffices", offices);
                success = await SuccessNotification(dispatch, `Created office ${office.name}!`);
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {loading: false, loadingType: LoadingType.SAVING});
            return success;
        },
        async deleteOffice(
            {commit, dispatch, getters},
            payload: {
                id: string;
                office: Office;
                service: OfficeService;
            }
        ) {
            let success = false;
            try {
                commit("setLoading", {
                    loading: true,
                    loadingType: LoadingType.DELETING,
                });
                const {service, id, office} = payload;
                const deleted = await service.deleteOffice(id, office);
                if (deleted === 204) {
                    const offices = cloneDeep(getters.offices).filter(
                        (office: Office) => office.officeId !== id
                    );
                    commit("setOffices", offices);
                    success = await SuccessNotification(dispatch, `Deleted office ${office.name}!`);
                }
            } catch (error) {
                await ErrorNotification(dispatch, error, error.data);
            }
            commit("setLoading", {
                loading: false,
                loadingType: LoadingType.DELETING,
            });
            return success;
        },
        reset({commit}) {
            commit("reset");
        },
    },
    mutations: {
        setLoading(
            state: OfficesState,
            payload: { loading: boolean; loadingType: string }
        ) {
            if (typeof payload === "boolean") {
                Vue.set(state, "loading", payload);
            } else {
                if (payload.loadingType) {
                    Vue.set(state, payload.loadingType, payload.loading);
                }
                Vue.set(state, "loading", payload.loading);
            }
        },
        setOffices(state: OfficesState, offices?: Office[]) {
            Vue.set(state, "offices", offices);
        },
        setContactInfo(state: OfficesState, contactInfo?: ContactInfo) {
            let {
                phoneNumber,
                email,
                name,
                officeId
            } = state["contactInfo"] ? cloneDeep(state["contactInfo"]) : {
                email: "",
                phoneNumber: "",
                name: "",
                officeId: ""
            };

            let reset = false;
            if (officeId && officeId !== contactInfo?.officeId) {
                reset = true;
            }
            if (reset || contactInfo?.phoneNumber) {
                phoneNumber = contactInfo?.phoneNumber;
            }
            if (reset || contactInfo?.email) {
                email = contactInfo?.email;
            }
            if (reset || contactInfo?.name) {
                name = contactInfo?.name;
            }
            officeId = contactInfo?.officeId;

            Vue.set(state, "contactInfo", {phoneNumber, email, name, officeId});
        },
        reset: function (state: OfficesState) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                try {
                    // @ts-ignore
                    state[key] = newState[key];
                } catch (ex) {
                    console.error('OfficesState Reset Error: ', ex.message);
                }
            });
        },
    },
    getters: {
        loading: (state) => state.loading,
        saving: (state) => state.saving,
        deleting: (state) => state.deleting,
        offices: (state) => state.offices,
        contactInfo: (state) => state.contactInfo,
        missingTaxIdInfo: (state) => {
            return state.offices?.findIndex((item) => typeof item.taxIdNumber === 'undefined' || !item.taxIdNumber) !== -1;
        },
        hasOffices: (state) => !!state.offices?.filter((office: Office) => !office?.isObsolete)?.length,
        officesSelect: (state) => {
            return state.offices?.map((item) => {
                if (!item.isObsolete) {
                    return {text: item.name, value: item.officeId, disabled: false};
                }
            });
        },
        officesWithEmptySelect: (state) => {
            const offices = (state.offices?.map((item) => {
                if (!item.isObsolete) {
                    return {text: item.name, value: item.officeId, disabled: false};
                }
            }));
            if (offices?.length) {
                offices?.push({text: "-Not Yet Chosen-", value: NEW_ITEM_ID, disabled: false});
            }
            return offices;
        },
    },
};
