export function decodeText(input: any, excludedFields: string[] = []) {
    // Ignore things that aren't objects.
    if (typeof input !== "object") return input;

    for (const key in input) {
        // eslint-disable-next-line no-prototype-builtins
        if (input && !input?.hasOwnProperty(key) && // don't parse prototype or non-string props
            toString.call(input[key]) !== '[object String]') continue;

        // Check for string properties which look like dates.
        if (input[key] && typeof input[key] === "string" && !key.endsWith("Id") && !excludedFields.includes(key)) {
            input[key] = decodeURIComponent(input[key]);
        } else if (typeof input[key] === "object") {
            //special functionality for sections
            if (key === "sections" && Array.isArray(input[key])) {
                input[key]?.forEach((entry: any, index: number) => {
                    for (const [subKey, value] of Object.entries(entry)) {
                        if (value && typeof value === "string" && !key.endsWith("Id") && !excludedFields.includes(key)) {
                            input[key][index][subKey] = decodeURIComponent(value);
                        }
                    }
                });
            } else {
                decodeText(input[key]);
            }
        }
    }
    return input;
}

export function encodeText(input: any, excludedFields: string[] = []) {
    // Ignore things that aren't objects.
    if (typeof input !== "object") return input;

    for (const key in input) {
        // eslint-disable-next-line no-prototype-builtins
        if (input && !input?.hasOwnProperty(key) && // don't parse prototype or non-string props
            toString.call(input[key]) !== '[object String]') continue;

        // Check for string properties which look like dates.
        if (input[key] && typeof input[key] === "string" && !key.endsWith("Id") && !excludedFields.includes(key)) {
            input[key] = encodeURIComponent(input[key]);
        } else if (typeof input[key] === "object") {
            //special functionality for sections
            if (key === "sections" && Array.isArray(input[key])) {
                input[key]?.forEach((entry: any, index: number) => {
                    for (const [subKey, value] of Object.entries(entry)) {
                        if (value && typeof value === "string" && !key.endsWith("Id") && !excludedFields.includes(key)) {
                            input[key][index][subKey] = encodeURIComponent(value);
                        }
                    }
                });
            } else {
                encodeText(input[key]);
            }
        }
    }
    return input;
}