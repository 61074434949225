import {ConditionalTextService} from "../services/interfaces";
import {inject, provide} from "vue";

export const conditionalTextServiceSymbol = Symbol();

export function provideConditionalTextService(service: ConditionalTextService) {
  return provide(conditionalTextServiceSymbol, service);
}

export function useConditionalTextService() {
  const conditionalTextService = inject<ConditionalTextService>(conditionalTextServiceSymbol);
  if (!conditionalTextService) {
    throw Error("No Conditional Text Service to Inject!!");
  }
  return conditionalTextService;
}