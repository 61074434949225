import {AdminClientSubscriptionService as IAdminClientSubscriptionService} from "@shared/services/interfaces";
import {ClientSubscriptionDetail} from "@shared/store/financials/interfaces";
import {AxiosInstance} from "axios";

export class AdminClientSubscriptionsService implements IAdminClientSubscriptionService {
    private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    async getAdminClientSubscriptions(): Promise<ClientSubscriptionDetail[]> {
        const response = await this.http.get(
            `${this.apiUrl}/api/admin/ClientSubscriptions`
        );
        return response.data;
    }
}