/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {AnytimeForm, RootState} from "../../index";
import Vue from "vue";
import {AnytimeDocumentsService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {AnytimePdf, SharedDocumentsFormUpdate} from "../../interfaces";
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {NewPatientPacket} from "../../responsible-parties/interfaces";
import router from "@main/router";
import {ErrorNotification, SuccessNotification, WarningNotification} from "@shared/functions/NotificationFunctions";

export interface AnytimeFormsState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  customForms?: AnytimeForm[];
  sharedForms?: AnytimeForm[];
  anytimeForms?: AnytimeForm[];
  anytimePdfs?: AnytimePdf[];
}

export enum AnytimeFormsActions {
  LOAD_ANYTIME_FORMS = "AnytimeFormsModule/loadAnytimeForms",
  LOAD_CUSTOM_FORMS = "AnytimeFormsModule/loadCustomForms",
  LOAD_SHARED_FORMS = "AnytimeFormsModule/loadSharedForms",
  UPDATE_SHARED_FORMS = "AnytimeFormsModule/updateSharedForms",
  UPDATE_CUSTOM_FORMS = "AnytimeFormsModule/updateCustomForms",
  UPDATE_CUSTOM_FORMS_FIELDS = "AnytimeFormsModule/updateFormFields",
  UPDATE_ANYTIME_FORMS_ORDER = "AnytimeFormsModule/updateAnytimeFormsOrder",
  CREATE_CUSTOM_FORM = "AnytimeFormsModule/createCustomForm",
  DELETE_CUSTOM_FORM = "AnytimeFormsModule/deleteCustomForm",
  GET_ESIGN_URL = "AnytimeFormsModule/getEsignUrl",
  LOAD_ANYTIME_PDFS = "AnytimeFormsModule/loadIntakePdfs",
  EMAIL_ANYTIME_FORMS = "AnytimeFormsModule/postPdf",
  RESET = "AnytimeFormsModule/reset",
}

export enum AnytimeFormsMutations {
  SET_LOADING = "AnytimeFormsModule/setLoading",
  SET_CUSTOM_FORMS = "AnytimeFormsModule/setCustomForms",
  SET_SHARED_FORMS = "AnytimeFormsModule/setSharedForms",
  SET_ANYTIME_FORMS = "AnytimeFormsModule/setAnytimeForms",
  SET_ANYTIME_PDFS = "AnytimeFormsModule/setAnytimePdfs",
  RESET = "AnytimeFormsModule/reset",
}

export enum AnytimeFormsGetters {
  LOADING = "AnytimeFormsModule/loading",
  SAVING = "AnytimeFormsModule/saving",
  DELETING = "AnytimeFormsModule/deleting",
  CUSTOM_FORMS = "AnytimeFormsModule/customForms",
  SHARED_FORMS = "AnytimeFormsModule/sharedForms",
  ANYTIME_FORMS = "AnytimeFormsModule/anytimeForms",
  ANYTIME_PDFS = "AnytimeFormsModule/anytimePdfs",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  customForms: undefined,
  sharedForms: undefined,
  anytimeForms: undefined,
  anytimePdfs: undefined,
});

export const AnytimeFormsModule: Module<AnytimeFormsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async postPdf(
      { commit, dispatch },
      payload: {
        values: NewPatientPacket;
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const response = await service.postPdf(values);
        if (response === 204) {
          success = await SuccessNotification(dispatch, "Submitted!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async loadAnytimePdfs(
      { commit, dispatch },
      payload: {
        anytimeForms: AnytimeForm[];
        service: AnytimeDocumentsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service, anytimeForms } = payload;
        const pdfArray: AnytimePdf[] = [];
        const preFetchDataPromises: Promise<any>[] = [];
        for (const form of anytimeForms) {
          preFetchDataPromises.push(service.postSinglePdf(
            form.formId,
            form.isSharedLibraryForm as boolean
          ));
        }
        const pdfs = await Promise.all(preFetchDataPromises);
        for (let i = 0; i < anytimeForms.length; i++) {

          pdfArray.push({ formId: anytimeForms[i]?.formId, data: pdfs[i] });
        }

        commit("setAnytimePdfs", pdfArray);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadAnytimeForms(
      { commit, dispatch },
      payload: {
        service: AnytimeDocumentsService;
        skipLoadingPdfData?: boolean;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service, skipLoadingPdfData } = payload;
        const anytimeForms: AnytimeForm[] = await service.getAnytimeForms();
        if(!skipLoadingPdfData) {
          await dispatch("loadAnytimePdfs", {
            service,
            anytimeForms,
          });
        }
        commit("setAnytimeForms", anytimeForms);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadCustomForms(
      { commit, dispatch },
      payload: {
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", true);
        const { service } = payload;
        const customForms: AnytimeForm[] = await service.getCustomForms();
        commit("setCustomForms", customForms);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
      return success;
    },

    async loadSharedForms(
      { commit, dispatch },
      payload: {
        service: AnytimeDocumentsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const sharedForms: AnytimeForm[] = await service.getSharedForms();
        commit("setSharedForms", sharedForms);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateSharedForms(
      { commit, dispatch },
      payload: {
        values: SharedDocumentsFormUpdate;
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        await service.updateShareForms(values);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateCustomForms(
      { commit, dispatch, getters },
      payload: {
        formId: string;
        values: AnytimeForm;
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values, formId } = payload;
        const response: AnytimeForm = await service.updateCustomForms(
          formId,
          values
        );
        const customForms = cloneDeep(getters.customForms);
        customForms.forEach((item: AnytimeForm, index: number) => {
          if (item.formId === formId) {
            customForms[index] = response;
          }
        });
        commit("setCustomForms", customForms);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateFormFields(
        { commit, dispatch, getters },
        payload: {
          formId: string;
          service: AnytimeDocumentsService;
        }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, formId } = payload;
        const response = await service.updateFormFields(
            formId
        );
        success = response;
        if(success){
          await SuccessNotification(dispatch, "Updated!");
          //update the store
          const loadIsSuccessful: boolean = await dispatch("loadCustomForms", {
            service: service
          });
          if (!loadIsSuccessful) {
            await router.push({name: "anytime-forms"});
          }
        } else {
          await WarningNotification(dispatch, "Form updates failed!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateAnytimeFormsOrder(
      { commit, dispatch, getters },
      payload: {
        anytimeForms: AnytimeForm[];
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, anytimeForms } = payload;
        await service.updateAnytimeFormsOrder(anytimeForms);
        commit("setCustomForms", anytimeForms);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async createCustomForm(
      { commit, dispatch },
      payload: {
        values: AnytimeForm;
        formId: string;
        service: AnytimeDocumentsService;
      }
    ) {
      let success: boolean | string = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values, formId } = payload;
        const response = await service.createCustomForm(formId, values);
        if (response) {
          success = response;
        }
        await SuccessNotification(dispatch, "Saved!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteCustomForm(
      { commit, dispatch, getters },
      payload: {
        formId: string;
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, formId } = payload;
        const deleted = await service.deleteForm(formId);
        if (deleted.formId === formId) {
          const customForms = cloneDeep(getters.customForms).filter(
            (form: AnytimeForm) => form.formId !== formId
          );
          commit("setCustomForms", customForms);
          success = await SuccessNotification(dispatch, "Deleted!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async getEsignUrl(
      { commit, dispatch, getters },
      payload: {
        initialFormId: string;
        service: AnytimeDocumentsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, initialFormId } = payload;
        const response = await service.requestEsignURL(initialFormId);
        if (response) {
          const customForms = await cloneDeep(getters.customForms);
          customForms.forEach((form: AnytimeForm, index: number) => {
            if (form.initialFormId === initialFormId) {
              customForms[index].requested = true;
            }
            commit("setCustomForms", customForms);
          });
          success = true;
        }
        await SuccessNotification(dispatch, "Successfully sent request for the e-sign url!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: AnytimeFormsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setAnytimeForms(state: AnytimeFormsState, anytimeForms?: AnytimeForm[]) {
      anytimeForms = anytimeForms?.sort((a: AnytimeForm, b: AnytimeForm) => {
        return (a.orderId as number) - (b.orderId as number);
      });

      Vue.set(state, "anytimeForms", anytimeForms);
    },
    setCustomForms(state: AnytimeFormsState, customForms?: AnytimeForm[]) {
      customForms = customForms?.sort((a: AnytimeForm, b: AnytimeForm) => {
        if (!a.orderId) {
          a.orderId = 0;
        }
        if (!b.orderId) {
          b.orderId = 0;
        }
        return (a.orderId as number) - (b.orderId as number);
      });

      Vue.set(state, "customForms", customForms);
    },

    setSharedForms(state: AnytimeFormsState, sharedForms?: AnytimeForm) {
      Vue.set(state, "sharedForms", sharedForms);
    },
    setAnytimePdfs(state: AnytimeFormsState, pdfArray: AnytimePdf[]) {
      Vue.set(state, "anytimePdfs", pdfArray);
    },
    reset: function (state: AnytimeFormsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('AnytimeFormsState Reset Error: ', ex.message);
        }
      });
    },
  },

  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    customForms: (state) => state.customForms,
    sharedForms: (state) => state.sharedForms,
    anytimeForms: (state) => state.anytimeForms,
    anytimePdfs: (state) => state.anytimePdfs,
  },
};
