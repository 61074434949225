/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState, rtnCalculationRules} from "../../index";
import Vue from "vue";
import {CalculationRulesService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {CalculationRule} from "../../interfaces";
import {CalculationRuleTypes, LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface CalculationRulesState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  calculationRules?: CalculationRule[];
  calculationRulesExcludingTxTypeSpecific?: CalculationRule[];
}

export enum CalculationRulesActions {
  LOAD_CALCULATION_RULES = "CalculationRulesModule/loadCalculationRules",
  UPDATE_CALCULATION_RULE = "CalculationRulesModule/updateCalculationRule",
  SORT_CALCULATION_RULES = "CalculationRulesModule/sortCalculationRules",
  UPDATE_CALCULATION_RULES_LIST = "CalculationRulesModule/updateCalculationRulesList",
  DELETE_CALCULATION_RULE = "CalculationRulesModule/deleteCalculationRule",
  CREATE_CALCULATION_RULE = "CalculationRulesModule/createCalculationRule",
  RESET = "CalculationRulesModule/reset",
}

export enum CalculationRulesMutations {
  SET_LOADING = "CalculationRulesModule/setLoading",
  SET_CALCULATION_RULES = "CalculationRulesModule/setCalculationRules",
  RESET = "CalculationRulesModule/reset",
}

export enum CalculationRulesGetters {
  LOADING = "CalculationRulesModule/loading",
  SAVING = "CalculationRulesModule/saving",
  DELETING = "CalculationRulesModule/deleting",
  CALCULATION_RULES = "CalculationRulesModule/calculationRules",
  CALCULATION_RULES_EXCLUDING_TX_TYPE_SPECIFIC = "CalculationRulesModule/calculationRulesExcludingTxTypeSpecific",
  HAS_CALCULATION_RULES = "CalculationRulesModule/hasCalculationRules",
  HAS_PIF_OVERRIDES = "CalculationRulesModule/hasPIFOverrides",
  HAS_PIF_CREDIT_OVERRIDES = "CalculationRulesModule/hasPIFCreditOverrides",
  HAS_DOWN_PAYMENT_OVERRIDES = "CalculationRulesModule/hasDownPaymentOverrides",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  calculationRules: undefined,
  calculationRulesExcludingTxTypeSpecific: undefined,
});

export const CalculationRulesModule: Module<
  CalculationRulesState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadCalculationRules(
      { commit, dispatch },
      payload: {
        service: CalculationRulesService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const calculationRules: CalculationRule[] = await service.getCalculationRules();
        commit("setCalculationRules", calculationRules);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updateCalculationRule(
      { commit, dispatch, getters },
      payload: {
        id: string;
        values: CalculationRule;
        service: CalculationRulesService;
        suppressNotification?: boolean;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, values,suppressNotification } = payload;
        const response = await service.updateCalculationRule(id, values);
        if (response === 204) {
          const rules = cloneDeep(getters.calculationRules);
          rules.forEach((item: CalculationRule, index: number) => {
            if (item.calculationRuleId === id) {
              rules[index] = values;
            }
          });
          commit("setCalculationRules", rules);
          success = true;
        }
        await SuccessNotification(dispatch, `Updated!`, suppressNotification);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },

    async updateCalculationRulesList(
      { commit, dispatch, getters },
      payload: {
        values: CalculationRule[];
        service: CalculationRulesService;
        initiatedExternally: boolean;
      }
    ) {
      let success: boolean | rtnCalculationRules = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values, initiatedExternally } = payload;
        const response = await service.updateCalculationRulesList(values);
        if (!(response || []).length) {
          throw Error("Save Calculation Rules failed to return a response!");
        }

        const rules = cloneDeep(getters.calculationRules) || [];
        response.forEach((respCalcRule: CalculationRule) => {
          const calcRuleIndex = rules.findIndex(
            (item: CalculationRule) =>
              item.calculationRuleId === respCalcRule.calculationRuleId
          );
          calcRuleIndex > -1
            ? (rules[calcRuleIndex] = respCalcRule)
            : rules.push(respCalcRule);
        });
        commit("setCalculationRules", rules);
        success = initiatedExternally
          ? { success: true, calculationRules: response }
          : true;
        await SuccessNotification(dispatch, `Updated!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },

    async sortCalculationRules(
      { commit, dispatch, getters },
      payload: {
        values: CalculationRule[];
        service: CalculationRulesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const response = await service.sortFeeOrder(values);
        if (response === 204) {
          const rules = cloneDeep(getters.calculationRules);
          values.forEach((value) => {
            const ruleIndex = rules.findIndex(
              (item: CalculationRule) =>
                item.calculationRuleId === value.calculationRuleId
            );
            if (ruleIndex >= 0) {
              rules[ruleIndex] = value;
            }
          });
          commit("setCalculationRules", rules);
          success = true;
        }
        await SuccessNotification(dispatch, `Updated!`);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteCalculationRule(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: CalculationRulesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteCalculationRule(id);
        if (deleted.calculationRuleId === id) {
          const rules = cloneDeep(getters.calculationRules).filter(
            (rule: CalculationRule) => rule.calculationRuleId !== id
          );
          commit("setCalculationRules", rules);
          success = await SuccessNotification(dispatch, `Deleted!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    async createCalculationRule(
      { commit, dispatch, getters },
      payload: {
        values: CalculationRule;
        service: CalculationRulesService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const newRule = await service.createCalculationRule(values);
        if (newRule.calculationRuleId) {
          const rules = cloneDeep(getters.calculationRules);
          rules.push(newRule);
          commit("setCalculationRules", rules);
          success = await SuccessNotification(dispatch, `Created!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: CalculationRulesState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setCalculationRules(
      state: CalculationRulesState,
      calculationRules?: CalculationRule[]
    ) {
      Vue.set(state, "calculationRules", calculationRules);
    },
    reset: function (state: CalculationRulesState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('CalculationRulesState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    calculationRules: (state) => state.calculationRules,
    calculationRulesExcludingTxTypeSpecific: (state) => state.calculationRules?.filter((calcRule) =>
        calcRule.calculationRuleTypeId === 7
        || calcRule.calculationRuleTypeId === 8
        || calcRule.calculationRuleTypeId === 11
        || (calcRule.calculationRuleTypeId >= 16 && calcRule.calculationRuleTypeId <= 32)),
    hasCalculationRules: (state) => !!state.calculationRules?.length,
    hasPIFOverrides: (state) =>
      !!state.calculationRules?.filter(
        (item) =>
          item.calculationRuleTypeId ===
            CalculationRuleTypes.PAY_IN_FULL_DISCOUNT && !!item.treatmentTypeId
      ).length,
    hasPIFCreditOverrides: (state) =>
      !!state.calculationRules?.filter(
        (item) =>
          item.calculationRuleTypeId ===
            CalculationRuleTypes.PAY_IN_FULL_DISCOUNT_CREDIT &&
          !!item.treatmentTypeId
      ).length,
    hasDownPaymentOverrides: (state) =>
      !!state.calculationRules?.filter(
        (item) =>
          item.calculationRuleTypeId ===
            CalculationRuleTypes.INTERNAL_FINANCE_DOWN_PAYMENT_OVERRIDES &&
          !!item.treatmentTypeId
      ).length,
  },
};
