import {PatientTreatmentPlansService} from "../services/interfaces";
import {inject, provide} from "vue";


export const patientTreatmentPlansServiceSymbol = Symbol();

export function providePatientTreatmentPlansService(
  service: PatientTreatmentPlansService
) {
  return provide(patientTreatmentPlansServiceSymbol, service);
}

export function usePatientTreatmentPlansService() {
  const patientTreatmentPlansService = inject<PatientTreatmentPlansService>(
    patientTreatmentPlansServiceSymbol
  );
  if (!patientTreatmentPlansService) {
    throw Error("No Patient Treatment Plans Service to Inject!!");
  }
  return patientTreatmentPlansService;
}
