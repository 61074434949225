/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {InformedConsent, InformedConsentSection, RootState} from "../../index";
import Vue from "vue";
import {InformedConsentService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {cloneDeep} from "lodash";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface InformedConsentState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  informedConsent?: InformedConsent;
}

export enum InformedConsentActions {
  LOAD_INFORMED_CONSENT = "InformedConsentModule/loadInformedConsent",
  UPDATE_INFORMED_CONSENT = "InformedConsentModule/updateInformedConsent",
  UPDATE_INFORMED_CONSENT_SECTION = "InformedConsentModule/updateInformedConsentSection",
  CREATE_INFORMED_CONSENT_SECTION = "InformedConsentModule/createInformedConsentSection",
  DELETE_INFORMED_CONSENT_SECTION = "InformedConsentModule/deleteInformedConsentSection",
  UPDATE_INFORMED_CONSENT_SECTION_ORDER = "InformedConsentModule/updateInformedConsentSectionOrder",
  RESET = "InformedConsentModule/reset",
}

export enum InformedConsentMutations {
  SET_LOADING = "InformedConsentModule/setLoading",
  SET_INFORMED_CONSENT = "InformedConsentModule/setInformedConsent",
  RESET = "InformedConsentModule/reset",
}

export enum InformedConsentGetters {
  LOADING = "InformedConsentModule/loading",
  SAVING = "InformedConsentModule/saving",
  DELETING = "InformedConsentModule/deleting",
  INFORMED_CONSENT = "InformedConsentModule/informedConsent",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  informedConsent: undefined,
});

export const InformedConsentModule: Module<InformedConsentState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadInformedConsent(
      { commit, dispatch },
      payload: {
        service: InformedConsentService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const informedConsent: InformedConsent = await service.getInformedConsent();
        commit("setInformedConsent", informedConsent);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },

    async updateInformedConsent(
      { commit, dispatch },
      payload: {
        clientId: string;
        informedConsent: InformedConsent;
        service: InformedConsentService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, clientId, informedConsent } = payload;
        await service.updateInformedConsent(clientId, informedConsent);
        commit("setInformedConsent", informedConsent);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },

    async updateInformedConsentSection(
      { commit, dispatch, getters },
      payload: {
        id: string;
        informedConsentSection: InformedConsentSection;
        service: InformedConsentService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, id, informedConsentSection } = payload;
        const updatedSection = await service.updateInformedConsentSection(
          id,
          informedConsentSection
        );
        const informedConsent = cloneDeep(getters.informedConsent);
        informedConsent.sections.forEach(
          (section: InformedConsentSection, index: number) => {
            if (
              section.informedConsentSectionId ===
              informedConsentSection.informedConsentSectionId
            ) {
              informedConsent.sections[index] = updatedSection;
            }
          }
        );
        commit("setInformedConsent", informedConsent);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async updateInformedConsentSectionOrder(
      { commit, dispatch, getters },
      payload: {
        informedConsentSections: InformedConsentSection[];
        service: InformedConsentService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, informedConsentSections } = payload;
        await service.updateInformedConsentOrder(informedConsentSections);
        const informedConsent = cloneDeep(getters.informedConsent);
        informedConsent.sections = informedConsentSections;
        commit("setInformedConsent", informedConsent);
        success = await SuccessNotification(dispatch, "Updated!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async createInformedConsentSection(
      { commit, dispatch, getters },
      payload: {
        informedConsentSection: InformedConsentSection;
        service: InformedConsentService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, informedConsentSection } = payload;
        const updatedSection = await service.newInformedConsentSection(
          informedConsentSection
        );
        const informedConsent = cloneDeep(getters.informedConsent);
        informedConsent.sections.push(updatedSection);
        commit("setInformedConsent", informedConsent);
        success = await SuccessNotification(dispatch, "Saved!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async deleteInformedConsentSection(
      { commit, dispatch, getters },
      payload: {
        id: string;
        service: InformedConsentService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteInformedConsentSection(id);
        if (deleted.informedConsentSectionId === id) {
          const informedConsent = cloneDeep(getters.informedConsent);
          informedConsent.sections = informedConsent.sections.filter(
            (section: InformedConsentSection) =>
              section.informedConsentSectionId !== id
          );
          commit("setInformedConsent", informedConsent);
          success = await SuccessNotification(dispatch, "Deleted!");
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.DELETING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: InformedConsentState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setInformedConsent(
      state: InformedConsentState,
      informedConsent?: InformedConsent
    ) {
      if (informedConsent?.sections && informedConsent.sections.length) {
        informedConsent.sections = informedConsent.sections.sort(
          (a: InformedConsentSection, b: InformedConsentSection) => {
            return a.orderId - b.orderId;
          }
        );
      }
      Vue.set(state, "informedConsent", informedConsent);
    },
    reset: function (state: InformedConsentState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('InformedConsentState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    informedConsent: (state) => state.informedConsent,
  },
};
