import Vue from 'vue'

/**
 * Animate html elements with the help of the library Animate.css
 * https://animate.style/
 *
 * @param node - The HTML element to animate
 * @param animationName - The name of the animation to apply
 * @param callback - Function to call when the animation ends
 */
Vue.prototype.$animate = function (node: HTMLElement, animationName: string, callback?: () => void): void {
  node.classList.add('animate__animated', `animate__${animationName}`)

  function handleAnimationEnd() {
    node.classList.remove('animate__animated', `animate__${animationName}`)
    node.removeEventListener('animationend', handleAnimationEnd)

    if (typeof callback === 'function') callback()
  }

  node.addEventListener('animationend', handleAnimationEnd)
}