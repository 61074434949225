/* eslint-disable no-unused-vars */
import {Module} from "vuex";
import {RootState} from "../../index";
import Vue from "vue";
import {PaymentPlansService} from "../../../services/interfaces";

// DO NOT MOVE THIS OR ELSE
import {LoadingType} from "../../constants";
import {PaymentPlan, PaymentPlanType} from "../interfaces";
import {ErrorNotification, SuccessNotification} from "@shared/functions/NotificationFunctions";

export interface PaymentPlansState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  paymentPlanTypes?: PaymentPlanType[];
  paymentPlans?: PaymentPlan[];
  paymentPlansWithDisplayText?: PaymentPlan[];
}

export enum PaymentPlansActions {
  LOAD_PAYMENT_PLAN_TYPES = "PaymentPlansModule/loadPaymentPlanTypes",
  LOAD_PAYMENT_PLANS = "PaymentPlansModule/loadPaymentPlans",
  LOAD_PAYMENT_PLANS_WITH_DISPLAY_TEXT = "PaymentPlansModule/loadPaymentPlansWithDisplayText",
  UPDATE_PAYMENT_PLANS = "PaymentPlansModule/updatePaymentPlans",
  RESET = "PaymentPlansModule/reset",
}

export enum PaymentPlansMutations {
  SET_LOADING = "PaymentPlansModule/setLoading",
  SET_PAYMENT_PLAN_TYPES = "PaymentPlansModule/setPaymentPlanTypes",
  SET_PAYMENT_PLANS = "PaymentPlansModule/setPaymentPlans",
  RESET = "PaymentPlansModule/reset",
}

export enum PaymentPlansGetters {
  LOADING = "PaymentPlansModule/loading",
  SAVING = "PaymentPlansModule/saving",
  DELETING = "PaymentPlansModule/deleting",
  PAYMENT_PLAN_TYPES = "PaymentPlansModule/paymentPlanTypes",
  PAYMENT_PLANS = "PaymentPlansModule/paymentPlans",
  PAYMENT_PLANS_WITH_DISPLAY_TEXT = "PaymentPlansModule/paymentPlansWithDisplayText",
  PAYMENT_PLAN_TYPES_SELECT = "PaymentPlansModule/paymentPlanTypesSelect",
}

const initialState = () => ({
  loading: true,
  saving: false,
  deleting: false,
  paymentPlanTypes: undefined,
  paymentPlans:  undefined,
  paymentPlansWithDisplayText: undefined,
});

export const PaymentPlansModule: Module<PaymentPlansState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadPaymentPlanTypes(
      { commit, dispatch },
      payload: {
        service: PaymentPlansService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const paymentPlanTypes: PaymentPlanType[] = await service.getPaymentPlanTypes();
        commit("setPaymentPlanTypes", paymentPlanTypes);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadPaymentPlans(
      { commit, dispatch },
      payload: {
        service: PaymentPlansService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const paymentPlans: PaymentPlan[] = await service.getPaymentPlans();
        commit("setPaymentPlans", paymentPlans);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadPaymentPlansWithDisplayText(
        { commit, dispatch },
        payload: {
          service: PaymentPlansService;
        }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const paymentPlans: PaymentPlan[] = await service.getPaymentPlansWithDisplayText(true);
        commit("setPaymentPlansWithDisplayText", paymentPlans);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async updatePaymentPlans(
      { commit, dispatch },
      payload: {
        values: any[];
        isNameOverride: boolean,
        service: PaymentPlansService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values, isNameOverride } = payload;
        const updatedPaymentPlans = await service.updatePaymentPlans(values, !!isNameOverride);
        commit("setPaymentPlans", updatedPaymentPlans);
        success = await SuccessNotification(dispatch, "Updated Payment Plans!");
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  mutations: {
    setLoading(
      state: PaymentPlansState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setPaymentPlanTypes(
      state: PaymentPlansState,
      paymentPlanTypes?: PaymentPlanType[]
    ) {
      paymentPlanTypes = paymentPlanTypes?.sort(
        (a: PaymentPlanType, b: PaymentPlanType) => {
          return a.order - b.order;
        }
      );
      Vue.set(state, "paymentPlanTypes", paymentPlanTypes);
    },
    setPaymentPlans(state: PaymentPlansState, paymentPlans?: PaymentPlan[]) {
      paymentPlans = paymentPlans?.sort((a: PaymentPlan, b: PaymentPlan) => {
        return a.paymentPlanType.order - b.paymentPlanType.order;
      });
      Vue.set(state, "paymentPlans", paymentPlans);
    },
    setPaymentPlansWithDisplayText(state: PaymentPlansState, paymentPlans?: PaymentPlan[]) {
      paymentPlans = paymentPlans?.sort((a: PaymentPlan, b: PaymentPlan) => {
        return a.paymentPlanType.order - b.paymentPlanType.order;
      });
      Vue.set(state, "paymentPlansWithDisplayText", paymentPlans);
    },
    reset: function (state: PaymentPlansState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error('PaymentPlansState Reset Error: ', ex.message);
        }
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    paymentPlanTypes: (state) => state.paymentPlanTypes,
    paymentPlans: (state) => state.paymentPlans,
    paymentPlansWithDisplayText: (state) => state.paymentPlansWithDisplayText,
    paymentPlanTypesSelect: (state) =>
      state.paymentPlanTypes?.map((type) => {
        return {
          text: type.description,
          value: type.paymentPlanTypeId,
        };
      }),
  },
};
