import {AxiosInstance} from "axios";
import {DeletedEsignDocumentsService as IDeletedEsignDocumentsService} from "../interfaces";
import {DeletedEsignDocuments} from "@shared/store";

export class DeletedEsignDocumentsService implements IDeletedEsignDocumentsService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getDeleteDocumentsInCurrentMonth(): Promise<DeletedEsignDocuments> {
    const response = await this.http.get(
        `${this.apiUrl}/api/DeletedEsignDocuments/CurrentMonth`
    );

    return response.data;
  }
}
