import { Module } from "vuex";
import Vue from "vue";
import { Alert, RootState } from "../index";

import { LoadingType } from "@shared/store/constants";
import { clone, cloneDeep } from "lodash";
import { AppNotificationRecipient } from "@shared/store/financials/interfaces";
import { AppNotificationsService } from "@shared/services/AppNotificationsService";
import { ErrorNotification, SuccessNotification } from "@shared/functions/NotificationFunctions";

export enum AppNotificationsActions {
  LOAD_NOTIFICATIONS = "AppNotificationsModule/loadNotifications",
  UPDATE_NOTIFICATION = "AppNotificationsModule/updateNotification",
  MARK_ALL_NOTIFICATIONS_AS_READ = "AppNotificationsModule/markAllNotificationsAsRead",
  SAVE_HIDE_READ_NOTIFICATIONS = "AppNotificationsModule/saveHideReadNotifications",
  RESET = "AppNotificationsModule/reset",
}

export enum AppNotificationsMutations {
  SET_NOTIFICATIONS = "AppNotificationsModule/setNotifications",
  SET_HIDE_READ_NOTIFICATIONS = "AppNotificationsModule/setHideReadNotifications",
  RESET = "AppNotificationsModule/reset",
}

export enum AppNotificationsGetters {
  NOTIFICATIONS = "AppNotificationsModule/notifications",
  HIDE_READ_NOTIFICATIONS = "AppNotificationsModule/hideReadNotifications",
  LOADING = "AppNotificationsModule/loading",
  SAVING = "AppNotificationsModule/saving",
}

export interface AppNotificationsState {
  notifications: AppNotificationRecipient[];
  hideReadNotifications: boolean;
  loading: boolean;
  saving: boolean;
}


const initialState = () => ({
  notifications: [],
  hideReadNotifications: false,
  loading: false,
  saving: false
});

//TODO add timeout on messages as part of store, base it off character count and 3 second minimum
export const AppNotificationsModule: Module<AppNotificationsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async saveHideReadNotifications({ commit, dispatch, getters }) {
      let success = false;
      try {
        commit("setLoading", { loading: true, loadingType: LoadingType.SAVING });
        const hideReadNotifications = clone(getters.hideReadNotifications);
        commit("setHideReadNotifications", !hideReadNotifications);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async loadNotifications({ commit, dispatch },
                             payload: {
                               service: AppNotificationsService;
                             }) {
      let success = false;
      try {
        commit("setLoading", { loading: true, loadingType: LoadingType.GETTING });
        const { service, } = payload;
        const notifications = await service.getAppNotifications();
        commit("setNotifications", notifications);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.GETTING });
      return success;
    },
    async updateNotification({ commit, dispatch, getters },
                             payload: {
                               service: AppNotificationsService;
                               appNotificationRecipient: AppNotificationRecipient;
                             }) {
      let success = false;
      try {
        commit("setLoading", { loading: true, loadingType: LoadingType.SAVING });
        const { service, appNotificationRecipient } = payload;

        await service.updateAppNotifications(appNotificationRecipient);
        const appNotificationRecipients = cloneDeep(getters.notifications);
        const idx = appNotificationRecipients.findIndex((notification: AppNotificationRecipient) => notification?.appNotificationId === appNotificationRecipient?.appNotificationId);
        if(idx > -1) {
          appNotificationRecipients[idx] = appNotificationRecipient;
        }
        commit("setNotifications", appNotificationRecipients);
        success = await SuccessNotification(dispatch,
          `Updated notification!`
        );
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    async markAllNotificationsAsRead({ commit, dispatch, getters },
                                     payload: {
                                       service: AppNotificationsService;
                                     }) {
      let success = false;
      try {
        commit("setLoading", { loading: true, loadingType: LoadingType.SAVING });
        const { service } = payload;
        //todo look thru notifications and mark them as read
        const appNotificationRecipients = cloneDeep(getters.notifications);
        await service.markAllAsRead();
        appNotificationRecipients.forEach((notification: AppNotificationRecipient) => {
          notification.isRead = true;
        });
        commit("setNotifications", appNotificationRecipients);
        success = await SuccessNotification(dispatch,
          `Marked all notifications as read!`
        );
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", { loading: false, loadingType: LoadingType.SAVING });
      return success;
    },
    alert({ commit }, payload: Alert) {
      commit("addAlert", payload);
    },
    clearAlert({ commit }, payload: Alert) {
      commit("removeAlert", payload);
    },
    reset({ commit }) {
      commit("reset");
    }
  },
  mutations: {
    setLoading(
      state: AppNotificationsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setHideReadNotifications(state: AppNotificationsState, hideReadNotifications: boolean) {
      Vue.set(state, "hideReadNotifications", hideReadNotifications);
    },
    setNotifications(state: AppNotificationsState, notifications: AppNotificationRecipient[]) {
      Vue.set(state, "notifications", notifications);
    },
    reset: function(state: AppNotificationsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          if(key !== 'hideReadNotifications') {
            // @ts-ignore
            state[key] = newState[key];
          }
        } catch (ex) {
          console.error("AppNotificationsModuleState Reset Error: ", ex.message);
        }
      });
    }

  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    notifications: (state) => (state?.notifications?.length ? state.notifications : []),
    hideReadNotifications: (state) => state.hideReadNotifications,
  }
};
