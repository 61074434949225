<template v-if="props.loading">
  <v-container class="d-flex justify-center pa-0" :class="marginClass" fluid>
    <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "LoadingIcon",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    marginClass: {
      type: String,
      default: 'mt-6'
    },
  },
});
</script>
