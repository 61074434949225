import {ClientSubscriptions} from "@shared/store/constants";

export default {
  key: "Patients",
  text: "Patients",
  regex: /\/patient/,
  group: "/patients",
  items: [
    {
      key: "Send Intake/Anytime Form",
      icon: "$file-plus",
      text: "Send Intake/Anytime Form",
      link: "/patients/additional-forms/create",
      group: "/patients/additional-forms",
      subscription: ClientSubscriptions.INTAKE_AND_ANYTIME_FORMS,
    },
    {
      key: "Create Patient",
      icon: "$user-plus",
      text: "Create Patient",
      link: "/patients/create",
      group: "/patients/create",
    },
    {
      key: "Patients",
      icon: "$users",
      text: "Patients",
      link: "/patients/list",
      group: "/patients/list",
    },
  ],
};
