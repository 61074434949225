import {ResponsiblePartyTiersService as IResponsiblePartyTiersService} from "../interfaces";
import {AxiosInstance} from "axios";
import {ResponsiblePartyTier} from "../../store/calculations/interfaces";

export class ResponsiblePartyTiersService
  implements IResponsiblePartyTiersService {
  private readonly apiUrl: string = process.env.VUE_APP_API_URL || "";
  private http: AxiosInstance;

  constructor(http: AxiosInstance) {
    this.http = http;
  }

  async getPartyTiers(includeDeleted = false): Promise<ResponsiblePartyTier[]> {
    const response = await this.http.get(
      `${this.apiUrl}/api/ResponsiblePartyTierTypes/Detail`
        , {params: {includeDeleted: includeDeleted}});
    return response.data;
  }

  async updatePartyTier(
    id: string,
    values: ResponsiblePartyTier
  ): Promise<number> {
    const response = await this.http.put(
      `${this.apiUrl}/api/ResponsiblePartyTierTypes`,
      { ...values },
      {
        params: { id },
      }
    );

    return response.status;
  }

  async deletePartyTier(id: string): Promise<ResponsiblePartyTier> {
    const response = await this.http.delete(
      `${this.apiUrl}/api/ResponsiblePartyTierTypes`,
      {
        params: { id },
      }
    );
    return response.data;
  }

  async createPartyTier(values: ResponsiblePartyTier) {
    const response = await this.http.post(
      `${this.apiUrl}/api/ResponsiblePartyTierTypes`,
      { ...values }
    );
    return response.data;
  }
}
