import {AccountService} from "../services/interfaces";
import {inject, provide} from "vue";

export const accountServiceSymbol = Symbol();

export function provideAcountService(service: AccountService) {
  return provide(accountServiceSymbol, service);
}

export function useAccountService() {
  const accountService = inject<AccountService>(accountServiceSymbol);
  if (!accountService) {
    throw Error("No User Service to Inject!!");
  }
  return accountService;
}
