import {inject, provide} from "vue";
import {TreatmentClassesService} from "../services/interfaces";

export const treatmentClassesServiceSymbol = Symbol();

export function provideTreatmentClassesService(
  service: TreatmentClassesService
) {
  return provide(treatmentClassesServiceSymbol, service);
}

export function useTreatmentClassesService() {
  const treatmentClassesService = inject<TreatmentClassesService>(
    treatmentClassesServiceSymbol
  );
  if (!treatmentClassesService) {
    throw Error("No Treatment Classes Service to Inject!!");
  }
  return treatmentClassesService;
}