import Vue from "vue";
import Vuex from "vuex";
import createCache from "vuex-cache";
import VuexPersistence from "vuex-persist";
// Global vuex
import { NotificationModule } from "@shared/store/notification";
import { SessionModule } from "./session";
import { UIModule } from "./ui-customizations";
import { AppNotificationsModule } from "./app-notifications";

//config modules
import { InputFieldsModule } from "./input-fields";
import { TemplateDocumentsModule } from "./documents/template-documents";
import { PatientModule } from "./patients";
import { PatientIntakeFormsModule } from "./documents/patient-intake-documents";
import { InformedConsentModule } from "./documents/informed-consent-documents";
import { AutoPaymentsModule } from "./documents/auto-payments";
import { ClientRemindersModule } from "./documents/client-reminders";
import { TreatmentTypesModule } from "./treatment/treatment-types";
import { TreatmentClassesModule } from "./treatment/treatment-classes";
import { AdditionalChargesModule } from "./treatment/additional-charges";
import { ConditionalTextModule } from "./documents/conditional-texts";
import { AnytimeFormsModule } from "./documents/anytime-documents";
import { CustomFormModule } from "./documents/custom-forms";
import { OfficesModule } from "./practice/offices";
import { DoctorsModule } from "./practice/doctors";
import { NotificationRecipientsModule } from "./practice/notification-recipients";
import { TreatmentCoordinatorsModule } from "./practice/treatment-coordinators";
import { PatientTypesModule } from "./practice/patient-types";
import { ResponsiblePartyTiersModule } from "./calculations/responsible-party-tiers";
import { DiscountsModule } from "./financials/discounts";
import { ClientSubscriptionsModule } from "./financials/client-subscriptions";
import { PpoOptionsModule } from "./insurance/ppo-options";
import { PpoRateGroupsModule } from "./insurance/ppo-rate-groups";
import { PaymentPlansModule } from "./calculations/payment-plans";
import { CalculationRulesModule } from "./calculations/calculation-rules";
import { ProductFeesModule } from "./calculations/product-fees";
import { InsuranceVerificationModule } from "./insurance/verification-notifications";
import { LogosModule } from "./practice/logos";
import { ProductTypesModule } from "./financials/product-type";
import { UsersModule } from "./user";
import { ResponsiblePartiesModule } from "./responsible-parties";
import { AdminInsuranceVerificationModule } from "./admin/admin-insurance-verification";
import { ClientConfigurationSettingsModule } from "./documents/client-configuration-settings";
import { TreatmentComplexitiesModule } from "./treatment/treatment-complexities";
import { PatientTreatmentPlansModule } from "./treatment-plans";

import { PatientContractsModule } from "./documents/patient-contracts";
import { PatientFormsAndSignaturesModule } from "./patients/patient-forms-and-signatures";
import { PatientTreatmentTypesModule } from "./treatment-plans/patient-treatment-types";
import { PatientVisitsModule } from "./treatment-plans/patient-visits";
import { APP_LOCALSTORAGE_KEY } from "./constants";
import { MaintenanceModule } from "@shared/store/maintenance";
import { AdminClientSubscriptionsModule } from "@shared/store/admin/admin-client-subscriptions";
import { AdminAppNotificationsModule } from "@shared/store/admin/admin-app-notifications";
import { AdminClientUsersModule } from "@shared/store/admin/admin-client-users";
import { VideosModule } from "@shared/store/videos";

export * from "./interfaces";
Vue.use(Vuex);

export interface RootState {
  loading: boolean;
}

export enum RootMutations {
  SET_LOADING = "setLoading",
}

export enum RootGetters {
  LOADING = "loading",
}

export enum RootActions {
  RESET = "reset"
}


const modulesToInclude = {
  //global modules
  NotificationModule,
  SessionModule,
  UIModule,
  AppNotificationsModule,

  PatientModule,
  UsersModule,
  ResponsiblePartiesModule,
  PatientTreatmentPlansModule,
  PatientContractsModule,
  VideosModule,
  //configuration modules
  InputFieldsModule,
  //documents modules
  TemplateDocumentsModule,
  PatientIntakeFormsModule,
  InformedConsentModule,
  AutoPaymentsModule,
  ClientRemindersModule,
  ConditionalTextModule,
  AnytimeFormsModule,
  CustomFormModule,
  ClientConfigurationSettingsModule,
  //practice modules
  OfficesModule,
  DoctorsModule,
  NotificationRecipientsModule,
  TreatmentCoordinatorsModule,
  PatientTypesModule,
  LogosModule,

  //financial modules
  DiscountsModule,
  ClientSubscriptionsModule,
  PpoOptionsModule,
  PpoRateGroupsModule,
  ProductTypesModule,
  //customizations modules
  TreatmentTypesModule,
  TreatmentClassesModule,
  AdditionalChargesModule,
  TreatmentComplexitiesModule,
  //calculation modules
  ResponsiblePartyTiersModule,
  PaymentPlansModule,
  CalculationRulesModule,
  ProductFeesModule,
  InsuranceVerificationModule,
  PatientFormsAndSignaturesModule,
  PatientTreatmentTypesModule,
  PatientVisitsModule,
  MaintenanceModule,
  //admin modules
  AdminClientSubscriptionsModule,
  AdminInsuranceVerificationModule,
  AdminAppNotificationsModule,
  AdminClientUsersModule,
};

const modulesToReset = {
  SessionModule,
  AppNotificationsModule,
  CalculationRulesModule,
  AdminInsuranceVerificationModule,
  AdminAppNotificationsModule,
  AdminClientUsersModule,
  PaymentPlansModule,
  ProductFeesModule,
  ResponsiblePartyTiersModule,
  AnytimeFormsModule,
  AutoPaymentsModule,
  ClientRemindersModule,
  ClientConfigurationSettingsModule,
  ConditionalTextModule,
  CustomFormModule,
  InformedConsentModule,
  PatientIntakeFormsModule,
  TemplateDocumentsModule,
  ClientSubscriptionsModule,
  AdminClientSubscriptionsModule,
  DiscountsModule,
  ProductTypesModule,
  InputFieldsModule,
  PpoOptionsModule,
  PpoRateGroupsModule,
  InsuranceVerificationModule,
  PatientModule,
  DoctorsModule,
  LogosModule,
  NotificationRecipientsModule,
  OfficesModule,
  PatientTypesModule,
  PatientTreatmentPlansModule,
  TreatmentCoordinatorsModule,
  ResponsiblePartiesModule,
  UsersModule,
  AdditionalChargesModule,
  TreatmentClassesModule,
  TreatmentTypesModule,
  TreatmentComplexitiesModule,
  PatientContractsModule,
  PatientFormsAndSignaturesModule,
  PatientTreatmentTypesModule,
  PatientVisitsModule,
  MaintenanceModule,
  VideosModule,
};

const vuexCommonLocal = new VuexPersistence<RootState>({
  key: APP_LOCALSTORAGE_KEY,
  storage: window.localStorage,
  reducer: (state: any) => (
    {
      AppNotificationsModule: {
        hideReadNotifications: state?.AppNotificationsModule?.hideReadNotifications
      },
      SessionModule: {
        xsrfToken: state?.SessionModule?.xsrfToken,
        user: state?.SessionModule?.user,
        authenticated: state?.SessionModule?.authenticated,
        status: state?.SessionModule?.status,
        patientPortalInfo: state?.SessionModule?.patientPortalInfo
      },
      ClientSubscriptionsModule: {
        clientSubscriptions: state?.ClientSubscriptionsModule?.clientSubscriptions,
        clientSubscriptionsEnabled: state?.ClientSubscriptionsModule?.clientSubscriptionsEnabled,
        clientId: state?.ClientSubscriptionsModule?.clientId
      },
      InputFieldsModule: {
        inputFieldOptions: state?.InputFieldsModule?.inputFieldOptions
      },
      OfficesModule: {
        offices: state?.OfficesModule?.offices,
        contactInfo: state?.OfficesModule?.contactInfo
      },
      TreatmentCoordinatorsModule: {
        treatmentCoordinators: state?.TreatmentCoordinatorsModule?.treatmentCoordinators
      },
      UsersModule: {
        users: state?.UsersModule?.users
      },
      LogosModule: {
        logos: state?.LogosModule?.logos
      },
      PatientModule: {
        patientDataFilters: state?.PatientModule?.patientDataFilters
      },
      ClientConfigurationSettingsModule: {
        lastModifiedDate: state?.ClientConfigurationSettingsModule?.lastModifiedDate
      },
      VideosModule: {
        videos: state?.VideosModule?.videos
      }
      //todo determine if we can get this to store in localstorage without issues, pdfs are showing link instead of doc using this for some reason
      // PatientIntakeFormsModule: {
      //     intakeForms: state?.PatientIntakeFormsModule?.intakeForms,
      //     intakePDFs: state?.PatientIntakeFormsModule?.intakePDFs,
      // },
      // AnytimeFormsModule: {
      //     anytimeForms: state?.AnytimeFormsModule?.anytimeForms,
      //     anytimePdfs: state?.AnytimeFormsModule?.anytimePdfs,
      // }
    })
});


/**
 * Main Vuex Store
 */
export default new Vuex.Store<RootState>({
  // strict: process.env.NODE_ENV !== 'production',
  modules: modulesToInclude,
  plugins: [
    vuexCommonLocal.plugin,
    createCache()],
  state: {
    loading: false
  },
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modulesToReset).forEach(moduleName => {
        commit(`${moduleName}/reset`);
      });
    }
  },
  mutations: {
    setLoading(state, payload?: boolean) {
      state.loading = !!payload;
    }
  },
  getters: {
    loading: (state) => state.loading
  }
});
