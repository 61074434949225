String.prototype.isNullOrWhitespace = function (): boolean {
    return  typeof this === 'undefined' || !this.trim()
}
String.prototype.isNullOrEmpty = function (): boolean {
    return typeof this === 'undefined' || !this
}
String.prototype.isNotNullOrWhitespace = function (): boolean {
    return  typeof this !== 'undefined' && !!this.trim()
}
String.prototype.isNotNullOrEmpty = function (): boolean {
    return typeof this !== 'undefined' && !!this
}