import { Module } from "vuex";
import Vue from "vue";
import { Alert, RootState } from "../index";

export enum NotificationActions {
  ALERT = "NotificationModule/alert",
  CLEAR_ALERT = "NotificationModule/clearAlert",
  RESET = "NotificationModule/reset",
}

export enum NotificationMutations {
  ADD_ALERT = "NotificationModule/addAlert",
  REMOVE_ALERT = "NotificationModule/removeAlert",
  RESET = "NotificationModule/reset",
}

export enum NotificationGetters {
  ALERTS = "NotificationModule/alerts",
  LATEST_ALERT = "NotificationModule/latestAlert",
}

export interface NotificationState {
  alerts: Alert[];
}


const initialState = () => ({
  alerts: [],
});

//TODO add timeout on messages as part of store, base it off character count and 3 second minimum
export const NotificationModule: Module<NotificationState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: {
    alert({ commit }, payload: Alert) {
      commit("addAlert", payload);
    },
    clearAlert({ commit }, payload: Alert) {
      commit("removeAlert", payload);
    },
    reset({ commit }) {
      commit("reset");
    }
  },
  mutations: {
    addAlert(state: NotificationState, payload: Alert) {
      const alerts = state.alerts;

      if (alerts?.length) {
        let duplicateMessage = true;
        for (let i = 0; i < alerts?.length; i++) {
          if (alerts?.[i]?.message !== payload?.message) {
            duplicateMessage = false;
            break;
          }
        }
        if (duplicateMessage) return;
      }
      Vue.set(state, "alerts", [...alerts, payload]);
    },
    removeAlert(state: NotificationState, payload: Alert) {
      const alerts = state.alerts;
      const firstIdx = alerts.findIndex(
        (alert) =>
          alert.message === payload.message && alert.status === payload.status
      );
      if (firstIdx > -1) {
        alerts.splice(firstIdx, 1);
      }
      Vue.set(state, "alerts", alerts);
    },
    reset: function(state: NotificationState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
            // @ts-ignore
            state[key] = newState[key];
        } catch (ex) {
          console.error("NotificationModuleState Reset Error: ", ex.message);
        }
      });
    }
  },
  getters: {
    alerts: (state) => state.alerts,
    latestAlert: (state) => (state.alerts.length ? state.alerts[0] : null),
  }
};
