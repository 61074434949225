/* eslint-disable no-unused-vars */
import { Module } from "vuex";
import { RootState } from "../../index";
import Vue from "vue";
// DO NOT MOVE THIS OR ELSE
import { AdminAppNotificationsService } from "@shared/services/interfaces";
import { ErrorNotification, SuccessNotification } from "@shared/functions/NotificationFunctions";
import { AppNotification } from "@shared/store/financials/interfaces";
import { LoadingType } from "@shared/store/constants";
import { cloneDeep } from "lodash";

export interface AdminAppNotificationsState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  appNotifications?: AppNotification[];
  selectedAppNotification?: AppNotification;
}

export enum AdminAppNotificationsActions {
  LOAD_APP_NOTIFICATIONS = "AdminAppNotificationsModule/loadAppNotifications",
  LOAD_SELECTED_APP_NOTIFICATION = "AdminAppNotificationsModule/loadSelectedAppNotification",
  DELETE_APP_NOTIFICATION = "AdminAppNotificationsModule/deleteAppNotification",
  SAVE_APP_NOTIFICATION = "AdminAppNotificationsModule/saveAppNotification",
  RESET = "AdminAppNotificationsModule/reset",
}

export enum AdminAppNotificationsMutations {
  SET_LOADING = "AdminAppNotificationsModule/setLoading",
  SET_APP_NOTIFICATIONS = "AdminAppNotificationsModule/setAppNotifications",
  SET_SELECTED_APP_NOTIFICATION = "AdminAppNotificationsModule/setSelectedAppNotification",
  RESET = "AdminAppNotificationsModule/reset",
}

export enum AdminAppNotificationsGetters {
  LOADING = "AdminAppNotificationsModule/loading",
  SAVING = "AdminAppNotificationsModule/saving",
  DELETING = "AdminAppNotificationsModule/deleting",
  APP_NOTIFICATIONS = "AdminAppNotificationsModule/appNotifications",
  SELECTED_APP_NOTIFICATION = "AdminAppNotificationsModule/selectedAppNotification",
}

const initialState = () => ({
  loading: false,
  saving: false,
  deleting: false,
  appNotifications: undefined,
  selectedAppNotification: undefined
});

export const AdminAppNotificationsModule: Module<
  AdminAppNotificationsState,
  RootState
> = {
  namespaced: true,
  state: initialState(),
  actions: {
    async loadAppNotifications(
      { commit, dispatch },
      payload: {
        service: AdminAppNotificationsService;
      }
    ) {
      try {
        commit("setLoading", true);
        const { service } = payload;
        const notifications: AppNotification[] = await service.getAdminAppNotifications();

        commit("setAppNotifications", notifications);
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
    },
    async loadSelectedAppNotification(
      { commit, dispatch },
      payload: {
        service: AdminAppNotificationsService;
        id: number;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", true);
        const { service, id } = payload;
        const notification: AppNotification = await service.getAdminAppNotificationDetail(id);
        commit("setSelectedAppNotification", notification);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", false);
      return success;
    },
    async saveAppNotification(
      { commit, dispatch },
      payload: {
        service: AdminAppNotificationsService;
        values: AppNotification;
      }) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.SAVING,
        });
        const { service, values } = payload;
        const notification: AppNotification = await service.saveAdminAppNotification(values);
        commit("setSelectedAppNotification", notification);
        success = true;
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.SAVING,
      });
      return success;
    },
    async deleteAppNotification(
      { commit, dispatch, getters },
      payload: {
        id: number;
        service: AdminAppNotificationsService;
      }
    ) {
      let success = false;
      try {
        commit("setLoading", {
          loading: true,
          loadingType: LoadingType.DELETING,
        });
        const { service, id } = payload;
        const deleted = await service.deleteAdminAppNotification(id);
        if (deleted.appNotificationId === id) {
          const appNotifications = cloneDeep(getters.appNotifications)?.filter(
            (appNotification: AppNotification) => appNotification?.appNotificationId !== id
          );
          commit("setAppNotifications", appNotifications);
          commit("setSelectedAppNotification", undefined);
          success = await SuccessNotification(dispatch, `Deleted App Notification!`);
        }
      } catch (error) {
        await ErrorNotification(dispatch, error, error.data);
      }
      commit("setLoading", {
        loading: false,
        loadingType: LoadingType.DELETING,
      });
      return success;
    },
    reset({ commit }) {
      commit("reset");
    }
  },
  mutations: {
    setLoading(
      state: AdminAppNotificationsState,
      payload: { loading: boolean; loadingType: string }
    ) {
      if (typeof payload === "boolean") {
        Vue.set(state, "loading", payload);
      } else {
        if (payload.loadingType) {
          Vue.set(state, payload.loadingType, payload.loading);
        }
        Vue.set(state, "loading", payload.loading);
      }
    },
    setAppNotifications(
      state: AdminAppNotificationsState,
      appNotifications?: AppNotification[]
    ) {
      Vue.set(state, "appNotifications", appNotifications);
    },
    setSelectedAppNotification(
      state: AdminAppNotificationsState,
      appNotification?: AppNotification
    ) {
      Vue.set(state, "selectedAppNotification", appNotification);
    },
    reset: function(state: AdminAppNotificationsState) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        try {
          // @ts-ignore
          state[key] = newState[key];
        } catch (ex) {
          console.error("AdminAppNotificationsState Reset Error: ", ex.message);
        }
      });
    }
  },
  getters: {
    loading: (state) => state.loading,
    saving: (state) => state.saving,
    deleting: (state) => state.deleting,
    appNotifications: (state) => state.appNotifications,
    selectedAppNotification: (state) => state.selectedAppNotification
  }
};
