import Vue from "vue";
import Router from "vue-router";

// Routes
import PagesRoutes from "./pages.routes";
import UsersRoutes from "./users.routes";
import PatientsRoutes from "./patients.routes";
import AdminRoutes from "./admin.routes";
import ConfigurationRoutes from "./configuration.routes";
import SubscriptionsRoutes from "./subscriptions.routes";
import NoAuthRoutes from "./no-auth.routes";
import store from "@shared/store";
import { SessionActions, SessionGetters } from "@shared/store/session";
import { AccountService } from "@shared/services/AccountService";
import axios from "@shared/axios";
import { APP_NAME, AppNames, LS_ROUTE_KEY, SESSION_EXPIRY_DATE, SessionStatuses } from "@shared/store/constants";
import { ClientSubscriptionsActions, ClientSubscriptionsGetters } from "@shared/store/financials/client-subscriptions";
import { ClientSubscriptionService } from "@shared/services/ClientSubscriptionService";
import { InputFieldsActions, InputFieldsGetters } from "@shared/store/input-fields";
import { InputFieldsService } from "@shared/services/InputFieldsService";
import moment from "moment/moment";
import {
  ClientConfigurationSettingsActions,
  ClientConfigurationSettingsGetters
} from "@shared/store/documents/client-configuration-settings";
import { ClientConfigurationSettingsService } from "@shared/services/ClientConfigurationSettingsService";
import { WarningNotification } from "@shared/functions/NotificationFunctions";

Vue.use(Router);

export const routes = [
  //can't be a /dashboard/patients
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    alias: "/dashboard-analytics",
    component: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "dashboard" */ "../pages/dashboard/DashboardPage.vue"
        ),
    meta: {
      title: "Dashboard",
      type: AppNames.DASHBOARD
    }
  },
  {
    path: "/users/profile",
    name: "user-profile",
    component: () =>
      import(/*webpackChunkName :"Profile" */ "../pages/users/ProfilePage.vue"),
    meta: {
      title: "Profile"
    }
  },
  {
    path: "/business-associate-agreement",
    alias: "/BAA",
    name: "BAA",
    component: () =>
      import(
        /* webpackChunkName: "baa" */ "../pages/configuration/BAAPage.vue"
        ),
    meta: {
      layout: "Simple",
      type: AppNames.CONFIGURATION,
      title: "BAA"
    }
  },
  ...PagesRoutes,
  ...PatientsRoutes,
  ...UsersRoutes,
  ...ConfigurationRoutes,
  ...AdminRoutes,
  ...SubscriptionsRoutes,
  ...NoAuthRoutes,
  {
    path: "*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@shared/pages/error/NotFoundPage.vue"),
    meta: {
      layout: "Error",
      title: "Page Not Found"
    }
  }

];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes
});

const routeSessionsStatuses: SessionStatuses[] = [
  SessionStatuses.LOGIN_STARTED,
  SessionStatuses.LOGIN_SUCCESSFUL,
  SessionStatuses.LOADING_USER_STARTED,
  SessionStatuses.LOADING_USER_SUCCESSFUL,
  SessionStatuses.SIGN_OUT_STARTED,
  SessionStatuses.SIGN_OUT_SUCCESSFUL,
  SessionStatuses.SET_PASSWORD_STARTED,
  SessionStatuses.SET_PASSWORD_SUCCESSFUL,
  SessionStatuses.FORGOT_PASSWORD_STARTED,
  SessionStatuses.FORGOT_PASSWORD_SUCCESSFUL,
  SessionStatuses.CONFIRM_EMAIL_STARTED,
  SessionStatuses.CONFIRM_EMAIL_SUCCESSFUL,
  SessionStatuses.VALIDATE_EMAIL];

/**
 * Before each route update
 */

router.beforeEach(async (to, from, next) => {
  try {
    localStorage.setItem(LS_ROUTE_KEY, from?.name?.toString() || "");
  } catch (e) {
    console.error("Exception when trying to save previous route");
  }

  //this checks all routes doesn't try to authenticate if no-auth
  if (to?.name?.startsWith("no-auth-")) {
    return next();
  }


  //TODO look to make store getters variables
  //determine if user is signed in
  //signout user if session is already expired to save errors from printing on screen
  if (store.getters[SessionGetters.AUTHENTICATED]) {
    const currentExpiry = localStorage.getItem(SESSION_EXPIRY_DATE);
    if (!currentExpiry || (moment.utc(currentExpiry).isValid() && moment.utc(currentExpiry).isBefore(moment.utc()))) {
      await store.dispatch(SessionActions.SIGN_OUT, {
        service: new AccountService(axios)
      });
      return next({ name: AppNames.DASHBOARD.toLowerCase() });
    }
  }

  if (
    (!store.getters[SessionGetters.USER] || !store.getters[SessionGetters.AUTHENTICATED])
    && !routeSessionsStatuses.includes(store.getters[SessionGetters.STATUS])
  ) {
    await store.dispatch(SessionActions.LOAD_USER, {
      service: new AccountService(axios)
    });
  }

  //don't allow routes to auth if already signed in
  if (
    (store.getters[SessionGetters.AUTHENTICATED] &&
      to?.name?.startsWith("auth-"))
  ) {
    if (to.name === "auth-signin") {
      return next({ name: AppNames.DASHBOARD.toLowerCase() });
    } else {
      const success = await store.dispatch(SessionActions.SIGN_OUT, {
        service: new AccountService(axios)
      });
      return success ? next() : next({ name: AppNames.DASHBOARD.toLowerCase() });
    }
  }

  if (from?.meta?.type === AppNames.CONFIGURATION && (to?.meta?.type === AppNames.PATIENTS || to?.name === "dashboard") && !store.getters[ClientConfigurationSettingsGetters.LOADING]) {
    store.dispatch(ClientConfigurationSettingsActions.LOAD_CLIENT_CONFIGURATION_SETTINGS, {
      service: new ClientConfigurationSettingsService(axios)
    });
  }

  //don't need other checks if not authenticated since it will go to login screen
  if (!store.getters[SessionGetters.AUTHENTICATED]) {
    return to?.name === "auth-signin"
      ? next({ name: AppNames.DASHBOARD.toLowerCase() })
      : to?.path?.includes("dashboard")
      || to?.path?.includes("redirect")
      || to?.name?.includes("error-")
      || to?.name?.includes("utility-")
      || to?.name?.includes("auth-")
        ? next()
        : next({ name: AppNames.DASHBOARD.toLowerCase(), query: { redirect: to?.path } });
  }

  //insurance verification only redirect them to the default location
  if (
    (to?.meta?.type === AppNames.PATIENTS || to?.meta?.type === AppNames.DASHBOARD)
    && store.getters[SessionGetters.IS_EXT_INSURANCE_VERIFICATION_ADMIN]
    && !store.getters[SessionGetters.IS_ADMIN]
  ) {
    return next({ name: "insurance-verification-list" });
  }

  //determine if user has rights to route
  if (
    (to?.meta?.type === AppNames.CONFIGURATION && !store.getters[SessionGetters.IS_CONFIG_MANAGER])
    || (to?.meta?.type === AppNames.PATIENTS && !store.getters[SessionGetters.CAN_VIEW_PATIENTS])
    || (to?.meta?.type === AppNames.USERS && !store.getters[SessionGetters.IS_USER_MANAGER])
    || (to?.meta?.type === AppNames.INSURANCE_VERIFICATION && !store.getters[SessionGetters.IS_EXT_INSURANCE_VERIFICATION_ADMIN])
    || (to?.meta?.type === AppNames.ADMIN && !store.getters[SessionGetters.IS_ADMIN])
    || (to?.meta?.type === AppNames.SUBSCRIPTIONS && !store.getters[SessionGetters.IS_SUBSCRIPTION_MANAGER])
  ) {

    //todo make this into a function so we can reuse anytime we go to dashboard
    if (store.getters[SessionGetters.CAN_VIEW_PATIENTS]) {
      to.meta.layout = "Default";
      to.meta.type = AppNames.PATIENTS;
    } else {
      if (store.getters[SessionGetters.IS_CONFIG_MANAGER]) {
        to.meta.layout = "Config";
        to.meta.type = AppNames.CONFIGURATION;
      } else {
        if (store.getters[SessionGetters.IS_USER_MANAGER]) {
          to.meta.layout = "User";
          to.meta.type = AppNames.USERS;
        } else {
          if (store.getters[SessionGetters.IS_ADMIN]) {
            to.meta.layout = "Admin";
            to.meta.type = AppNames.ADMIN;
          } else {
            return next({ name: "error-unexpected" });
          }
        }
      }
    }
    if (to?.meta) {
      to.meta.title = "Dashboard";
      to.meta.rank = -1;
    }
    await WarningNotification(store.dispatch, `You do not have the proper permissions to access this page, redirecting you back to the dashboard.  Contact your user manager if you require access.`);
    return next({ name: AppNames.DASHBOARD.toLowerCase() });
  }

  if (to?.meta?.type === AppNames.PATIENTS) {
    if (!store.getters[ClientSubscriptionsGetters.CLIENT_SUBSCRIPTIONS] && !store.getters[ClientSubscriptionsGetters.LOADING]) {
      await store.dispatch(ClientSubscriptionsActions.LOAD_CLIENT_SUBSCRIPTIONS, {
        service: new ClientSubscriptionService(axios)
      });
    }
    if (!store.getters[InputFieldsGetters.INPUT_FIELDS] && !store.getters[InputFieldsGetters.LOADING]) {
      await store.dispatch(InputFieldsActions.LOAD_INPUT_FIELDS, {
        service: new InputFieldsService(axios)
      });
    }
  }

  if (!!to.meta?.subscription && !store.getters[ClientSubscriptionsGetters.CLIENT_SUBSCRIPTIONS_ENABLED]?.includes(to.meta.subscription)) {
    await WarningNotification(store.dispatch, `Subscription does not include: ${to.meta.subscription}, please contact support to help get this set up or use the subscription page to activate service!`);
    return next({ name: AppNames.DASHBOARD.toLowerCase() });
  }

//default layout so it doesn't switch

  //todo should this only be for dashboard or where else does this apply?
  if (!to.meta?.layout) {
    if (from?.meta?.layout && from?.meta?.layout !== "Error" && from?.meta?.layout !== "Simple" && from?.meta?.layout !== "Auth" && to?.meta) to.meta.layout = from.meta.layout;
    else {
      if (!!to.meta && store.getters[SessionGetters.IS_EXT_INSURANCE_VERIFICATION_ADMIN] && !store.getters[SessionGetters.IS_ADMIN]) {
        to.meta.layout = "InsuranceVerification";
      }
      if (!!to.meta && store.getters[SessionGetters.IS_SUBSCRIPTION_MANAGER] && !store.getters[SessionGetters.CAN_VIEW_PATIENTS]) {
        to.meta.layout = "Default";
      }
      if (!!to.meta && store.getters[SessionGetters.IS_CONFIG_MANAGER] && !store.getters[SessionGetters.CAN_VIEW_PATIENTS]) {
        to.meta.layout = "Config";
      }
      if (!!to.meta && store.getters[SessionGetters.IS_USER_MANAGER] && !store.getters[SessionGetters.CAN_VIEW_PATIENTS]) {
        to.meta.layout = "User";
      }
      if (!!to.meta && store.getters[SessionGetters.IS_ADMIN] && !store.getters[SessionGetters.CAN_VIEW_PATIENTS]) {
        to.meta.layout = "Admin";
      }
    }
  }

  document.title =
    to?.meta?.title && to?.meta?.type
      ? to?.meta?.type.endsWith("s") ? `${to.meta.title} - ${to.meta.type} - ${APP_NAME}` : `${to.meta.title} - ${to.meta.type}s - ${APP_NAME}`
      : to?.meta?.title
        ? `${to.meta.title} - ${APP_NAME}`
        : `${AppNames.DASHBOARD} - ${APP_NAME}`;

//used for redirects from legacy site for sure reason #! is not in the path otherwise
  if (to.fullPath.startsWith("/configuration#!")) {
    return next({
      path: to.fullPath.replace("/configuration#!", "/configuration")
    });
  } else if (to.fullPath.startsWith("/patients#!")) {
    return next({ path: to.fullPath.replace("/patients#!", "/patients") });
  } else if (to.fullPath.startsWith("/dashboard/patients")) {
    return next({ path: to.fullPath.replace("/dashboard/patients", "/patients").replaceAll("#!", "") });
  } else if (to.fullPath.includes("#!")) {
    return next({ path: to.fullPath.replaceAll("#!", "") });
  }

  return next();
});


/**
 * After each route update
 */
// router.afterEach((to, from) => {});

export default router;
