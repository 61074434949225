export default
{
    key: "Directions",
    text: "Directions",
    regex: /^\/directions/,
    group: "/directions",
    items: [
        {
            key: "Directions",
            icon: "$signsPost",
            text: "Directions",
            link: "/directions",
            group: "/directions",
        }
    ]
}




